import React from 'react';
import inputStyles from './style.scss';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './RadioButton.scss';
export const RadioButton = ({ label, className, checked, onChange, name, value }) => {
    const containerClassNames = combineClassNames(inputStyles.labeledInput, className);
    return (React.createElement("label", { className: containerClassNames },
        React.createElement("span", { className: inputStyles.label }, label || ''),
        React.createElement("input", { type: "radio", name: name, id: label, value: value, checked: checked, onChange: (e) => { onChange(e.target.checked); }, className: styles.radiobutton })));
};
