import { __rest } from "tslib";
import React from 'react';
const FLOCKLER_IFRAME_ORIGIN = 'https://plugins.flockler.com';
const RESIZE_EVENT_NAME = 'FlockerIframeResizeEvent';
const RECEIVED_EVENT_NAME = 'FlockerIframeResizeReceivedEvent';
const handleFlocklerIframeResizeEvent = (event) => {
    var _a;
    if (event.origin !== FLOCKLER_IFRAME_ORIGIN) {
        return;
    }
    if (event.data.eventName !== RESIZE_EVENT_NAME) {
        return;
    }
    const iframe = document.querySelector(`iframe#flockler-embed-iframe-${event.data.embedUuid}`);
    if (iframe) {
        iframe.height = event.data.nativeHeight;
        (_a = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({ eventName: RECEIVED_EVENT_NAME }, '*');
    }
};
export function FlocklerEmbed(_a) {
    var { siteUuid, embedUuid, style = {} } = _a, rest = __rest(_a, ["siteUuid", "embedUuid", "style"]);
    React.useEffect(() => {
        window.addEventListener('message', handleFlocklerIframeResizeEvent, false);
        return () => {
            window.removeEventListener('message', handleFlocklerIframeResizeEvent);
        };
    }, [handleFlocklerIframeResizeEvent]);
    const styling = Object.assign({ display: 'block', border: 'none', width: '100%' }, style);
    return (React.createElement("iframe", Object.assign({ src: `https://plugins.flockler.com/embed/iframe/${siteUuid}/${embedUuid}`, id: `flockler-embed-iframe-${embedUuid}`, style: styling }, rest)));
}
