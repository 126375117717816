var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    handledUrl: undefined,
};
export const urlSlice = createSlice({
    name: 'url',
    initialState,
    reducers: {
        setHandledUrl: (state, action) => {
            state.handledUrl = action.payload;
        },
    },
});
export const { reducer } = urlSlice;
export const { setHandledUrl } = urlSlice.actions;
