import styles from './classNames.scss';
const nonEmpty = (x) => !!x;
export function combineClassNames(...classNames) {
    return classNames.filter(nonEmpty).join(' ');
}
export function selectClassNames(style, classNames) {
    return combineClassNames(...Object.keys(classNames).filter((c) => classNames[c]).map((c) => style[c]));
}
export const HIDE_ON_MOBILE = styles.hideOnMobile;
export const SHOW_ONLY_ON_MOBILE = styles.showOnlyOnMobile;
export function getVisibilityClassname(visibility) {
    if (visibility === "Desktop" /* ScreenSizeVisibility.desktop */) {
        return HIDE_ON_MOBILE;
    }
    else if (visibility === "Mobiili" /* ScreenSizeVisibility.mobile */) {
        return SHOW_ONLY_ON_MOBILE;
    }
    else {
        return undefined;
    }
}
