import React from 'react';
import { becomeClientPageUrls, translations } from '../staticData.js';
import { HorizontalContainer, VerticalContainer } from './FlexboxContainer.js';
import menuStyles from '../css/navi.scss';
import { combineClassNames } from '../util/css/classNames.js';
import { LinkWithScroll } from '../util/scroll.js';
import styles from './ContactReferrer.scss';
export const ContactReferrer = ({ language }) => {
    const becomeClientLink = becomeClientPageUrls[language];
    const strings = translations[language].common;
    const id = strings.contactUsLinkIdTag;
    const feedbackClass = combineClassNames(styles.navItem, menuStyles.active, menuStyles.labelNormal, menuStyles.black);
    const registrationClass = combineClassNames(styles.navItem, menuStyles.labelNormal, menuStyles.black);
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: styles.container },
        React.createElement("h2", { className: styles.heading, id: id }, strings.contactUsHeader),
        React.createElement(HorizontalContainer, null,
            React.createElement(LinkWithScroll, { className: feedbackClass, to: `#${id}` }, strings.feedbackAndContact),
            React.createElement(LinkWithScroll, { className: registrationClass, to: becomeClientLink }, strings.wantToBeCustomer)),
        React.createElement("p", { className: styles.instructions, dangerouslySetInnerHTML: { __html: strings.commonContactFormInstructions } })));
};
