var _a;
import { languageBasePathRedirectTargetUrls } from '../staticData.js';
import * as rtk from '@reduxjs/toolkit';
import { languageToLocale, } from '../../common/apiTypes.js';
import { getLanguageFromUrl } from '../util/url.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
export var I18nPageType;
(function (I18nPageType) {
    I18nPageType[I18nPageType["LandingPage"] = 0] = "LandingPage";
    I18nPageType[I18nPageType["ContactList"] = 1] = "ContactList";
})(I18nPageType || (I18nPageType = {}));
export const initialI18nSlugs = {
    fi: { url: languageBasePathRedirectTargetUrls.fi, isLocalized: false },
    en: { url: languageBasePathRedirectTargetUrls.en, isLocalized: false },
    sv: { url: languageBasePathRedirectTargetUrls.sv, isLocalized: false },
    ru: { url: languageBasePathRedirectTargetUrls.ru, isLocalized: false },
};
const toI18nSlugCollection = (urlCollection) => {
    const result = Object.assign({}, initialI18nSlugs);
    for (const [language, url] of Object.entries(urlCollection)) {
        if (url) {
            result[language] = { url, isLocalized: true };
        }
    }
    return result;
};
const initialState = {
    pageType: I18nPageType.LandingPage,
    pageSlug: '',
    language: 'fi',
    locale: 'fi-FI',
    localizedSlugs: initialI18nSlugs,
};
export const i18nSlice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        setActiveLanguage: (state, action) => {
            state.language = getLanguageFromUrl(action.payload);
            state.locale = languageToLocale(state.language);
        },
        setI18nSlugsForContactPages: (state, action) => {
            const url = action.payload.url;
            state.language = action.payload.language;
            state.locale = languageToLocale(state.language);
            state.pageSlug = url;
            state.pageType = I18nPageType.ContactList;
            state.localizedSlugs = toI18nSlugCollection(action.payload.urlCollection);
        },
        setI18nSlugsForLandingPages: (state, action) => {
            const payload = action.payload;
            if (state.pageSlug !== action.payload.slug) {
                state.pageType = I18nPageType.LandingPage;
                state.language = getLanguageFromUrl(action.payload.slug);
                state.locale = languageToLocale(state.language);
                state.pageSlug = `/${payload.slug}`;
                state.localizedSlugs = ('localizedSlugs' in payload && payload.localizedSlugs)
                    ? payload.localizedSlugs
                    : initialState.localizedSlugs;
            }
            return state;
        },
    },
});
export const { reducer } = i18nSlice;
export const { setActiveLanguage, setI18nSlugsForLandingPages, setI18nSlugsForContactPages } = i18nSlice.actions;
