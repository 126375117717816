import React from 'react';
import { IdeaInspirationCard, IdeaInspirationCardSkeleton } from './IdeaInspirationCard.js';
import { mergeAnalyticsOptions } from '../util/analyticsDataAttributes.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { LoadMoreButton } from './LoadMoreButton.js';
import style from './IdeaInspirationCardList.scss';
export const applyCardFilters = (filterType, cardsToFilter) => cardsToFilter.filter((card) => ((card.type === 'article' || card.type === 'recipe') && (!filterType || filterType === card.type)));
export const IdeaInspirationCardList = ({ cards, hasMoreCards, isError, isLoading, isLoadingMore, onLoadMore, renderMode, skeletonCardCount, analyticsOptions }) => {
    const cardRenderMode = renderMode !== undefined ? renderMode : 'both';
    if (isError) {
        return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: style.fullWidth },
            React.createElement(ErrorMessage, null),
            renderCards([], cardRenderMode)));
    }
    if (isLoading) {
        return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: style.fullWidth }, renderCardSkeletons(skeletonCardCount || 3)));
    }
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: style.fullWidth },
        renderCards(cards, cardRenderMode, analyticsOptions),
        onLoadMore && hasMoreCards ? React.createElement(LoadMoreButton, { isLoading: isLoadingMore, onClick: onLoadMore }) : undefined));
};
function renderCardSkeletons(count, mode) {
    const cards = Array.from(Array(count)).map(() => (React.createElement(IdeaInspirationCardSkeleton, { mode: mode === 'smallcards' ? 'short' : 'long' })));
    return (React.createElement("div", { className: style.cardContainer }, cards.map((card, i) => (React.createElement("div", { className: mode === 'smallcards' ? style.smallCardContainer : style.bigCardSection, key: i }, card)))));
}
const cardRenderers = {
    both: (cards, analyticsOptions) => cards.map((_, index) => renderBothCardModesCard(cards, index, analyticsOptions)),
    bigcards: (cards, analyticsOptions) => cards.map((card, index) => renderLongCard(card, index, analyticsOptions)),
    smallcards: (cards, analyticsOptions) => cards.map((card, index) => (React.createElement("div", { className: style.smallCardContainer, key: card.slug },
        React.createElement(IdeaInspirationCard, { card: card, mode: "short", analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) })))),
};
function renderCards(cards, renderMode, analyticsOptions) {
    return (React.createElement("div", { className: style.cardContainer }, cards && cards.length ? (cardRenderers[renderMode](cards, analyticsOptions)) : (React.createElement("p", { className: style.arcticlesNoSearchResults }, "Ei hakutuloksia"))));
}
function renderBothCardModesCard(cards, index, analyticsOptions) {
    const remainder = index % 10;
    switch (remainder) {
        case 0:
        case 9:
            return renderLongCard(cards[index], index, analyticsOptions);
        case 1:
        case 3:
        case 5:
        case 7: {
            const secondCard = (index + 1 < cards.length) ? cards[index + 1] : undefined;
            return renderTwoShortCards(cards[index], index, secondCard, analyticsOptions);
        }
        default:
            return React.createElement("div", { key: index });
    }
}
function renderLongCard(card, index, analyticsOptions) {
    return (React.createElement("div", { className: style.bigCardSection, key: card.slug },
        React.createElement(IdeaInspirationCard, { card: card, mode: "long", analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) })));
}
function renderTwoShortCards(card1, index, card2, analyticsOptions) {
    const card2Elem = card2
        ? React.createElement(IdeaInspirationCard, { card: card2, mode: "short", analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) })
        : React.createElement("div", null);
    return (React.createElement("div", { className: style.smallCardSection, key: card1.slug },
        React.createElement(IdeaInspirationCard, { card: card1, mode: "short", analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) }),
        card2Elem));
}
const ErrorMessage = () => React.createElement("p", null, "Tapahtui virhe :( Yrit\u00E4 hetken p\u00E4\u00E4st\u00E4 uudestaan.");
