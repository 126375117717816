import React from 'react';
import { languageToLocale } from '../../../common/apiTypes.js';
import { mergeAnalyticsOptions } from '../../util/analyticsDataAttributes.js';
import { Link } from '../Link.js';
import { VerticalContainer } from '../FlexboxContainer.js';
import { PreloadedImage } from '../PreloadedImage.js';
import { Icon } from '../../Icon.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { getStaticContentfulImgSrcSet } from '../../util/image.js';
import style from './EventCard.scss';
const IMAGE_SIZE = 300;
export const EventCard = (props) => {
    const analyticsOptions = mergeAnalyticsOptions(props.analyticsOptions, {
        promotionType: props.type,
        promotionName: props.eventName,
        promotionLink: props.linkUrl,
    });
    return (React.createElement(EventCardContainer, Object.assign({}, props),
        React.createElement(VerticalContainer, { horizontalAlign: "center", className: style.eventCard, analyticsDataAttributes: analyticsOptions, tabIndex: props.linkUrl ? undefined : 0 },
            renderImage(props),
            renderInfo(props),
            renderBody(props))));
};
function EventCardContainer(props) {
    if (props.linkUrl) {
        const linkText = `Vieraile tapahtuman ${props.eventName} sivulla`;
        return (React.createElement("article", { className: style.cardWrapper },
            React.createElement(Link, { className: style.eventCardLink, isExternalLink: props.isExternalLink, url: props.linkUrl, content: "", ariaLabel: linkText }),
            props.children));
    }
    return React.createElement("article", { className: style.cardWrapper }, props.children);
}
const renderImage = (event) => {
    const { srcSet } = getStaticContentfulImgSrcSet(event.image || '', IMAGE_SIZE);
    return (event.image && (React.createElement(PreloadedImage, { imageUrl: event.image, imageHeight: event.imageHeight, imageWidth: event.imageWidth, imageSrcset: srcSet, alt: event.altText ? event.altText : undefined, imageClassName: style.eventImage, containerClassName: style.imageContainer, style: event.focalPoint ? { objectPosition: event.focalPoint } : undefined })));
};
const formatDateTimeForEvent = (date, locale) => date.toLocaleString(locale === 'en' ? 'en-UK' : locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'short',
});
const formatDateForEvent = (date, locale) => date.toLocaleString(locale === 'en' ? 'en-UK' : locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
});
const formatTimeForEvent = (date, locale) => date.toLocaleString(locale === 'en' ? 'en-UK' : locale, {
    hour: '2-digit',
    minute: '2-digit',
});
const renderInfo = (props) => {
    var _a;
    const startDate = new Date(props.startDate);
    const endDate = new Date(props.endDate);
    const isSameDay = startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();
    const isDifferentEndingTime = startDate.getTime() !== endDate.getTime();
    const locale = languageToLocale(props.language);
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", verticalAlign: "center", className: style.infoContainer },
        React.createElement("div", { className: style.infoText }, (_a = props.eventType) === null || _a === void 0 ? void 0 : _a.toUpperCase()),
        React.createElement("hr", null),
        React.createElement("div", { className: style.centerText }, isSameDay ? (isDifferentEndingTime ? (React.createElement(React.Fragment, null,
            React.createElement("time", { suppressHydrationWarning: true, dateTime: props.startDate, className: style.infoText },
                formatDateForEvent(startDate, locale),
                React.createElement("br", null),
                props.language === 'fi' && 'klo',
                " ",
                formatTimeForEvent(startDate, locale)),
            React.createElement("span", null, "-"),
            React.createElement("time", { suppressHydrationWarning: true, dateTime: props.endDate, className: style.infoText }, formatTimeForEvent(endDate, locale)))) : (React.createElement("time", { suppressHydrationWarning: true, dateTime: props.startDate, className: style.infoText }, formatDateTimeForEvent(startDate, locale)))) : (React.createElement(React.Fragment, null,
            React.createElement("time", { suppressHydrationWarning: true, dateTime: props.startDate, className: style.infoText }, formatDateTimeForEvent(startDate, locale)),
            React.createElement("span", null, "-"),
            React.createElement("br", null),
            React.createElement("time", { suppressHydrationWarning: true, dateTime: props.endDate, className: style.infoText }, formatDateTimeForEvent(endDate, locale)))))));
};
const renderBody = (props) => {
    const linkItem = props.linkUrl && props.linkText ? (React.createElement(Link, { isExternalLink: props.isExternalLink, url: props.linkUrl, content: props.linkText, className: combineClassNames(style.linkText, style.hideOnTablet, style.linkInBody) })) : (React.createElement(React.Fragment, null));
    return (React.createElement("div", { className: style.cardBody },
        React.createElement("h3", { className: style.cardBodyHeading }, props.eventName),
        props.locationName && (React.createElement("div", { className: combineClassNames(style.eventLocationText) },
            React.createElement(Icon, { type: "icon-location", size: "18" }),
            React.createElement(EventLocation, Object.assign({}, props)))),
        props.description && (React.createElement("p", { className: combineClassNames(style.cardBodyText, style.hideOnTablet) }, props.description)),
        linkItem));
};
const EventLocation = (props) => {
    if (props.location) {
        return (React.createElement("a", { href: `https://www.google.com/maps/search/?api=1&query=${props.location.lat}%2C${props.location.lon}`, className: style.linkInBody, target: "_blank", rel: "noopener noreferrer", onClick: (e) => {
                e.stopPropagation();
            } }, props.locationName));
    }
    return React.createElement("div", null, props.locationName);
};
