export const isPartOfPath = (path, searchString) => typeof path === 'string' && searchString.startsWith(path);
export function atMostOneCharDiffAtTheEnd(str1, str2) {
    return (str1 === str2)
        || (!!str1 && !!str2 && (isImmediatePrefix(str1, str2) || isImmediatePrefix(str2, str1)));
}
function isImmediatePrefix(str1, str2) {
    return str1.substring(0, str1.length - 1) === str2;
}
export const htmlDecode = (html) => {
    let returnText = html;
    returnText = returnText.replace(/&nbsp;/gi, ' ');
    returnText = returnText.replace(/&amp;/gi, '&');
    returnText = returnText.replace(/&quot;/gi, `"`);
    returnText = returnText.replace(/&lt;/gi, '<');
    returnText = returnText.replace(/&gt;/gi, '>');
    if (returnText !== html) {
        return htmlDecode(returnText);
    }
    else {
        return returnText;
    }
};
