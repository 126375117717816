import React from 'react';
import { ContentPageSearchResultCardList } from './ContentPageSearchResultCardList.js';
import { setSearchPhrase, updateCombinedSearchDebouncedSearchPhrase } from '../reducers/combinedSearchSlice.js';
import { useAppDispatch } from '../hooks.js';
import { HorizontalContainer } from './FlexboxContainer.js';
import { scrollToTop } from '../util/scroll.js';
import styles from './ContentPageSearchResult.scss';
export const ContentPageSearchResult = (props) => {
    const { isError, isLoadingMore, pages, hasMore, loadMorePages, location, push, replace } = props;
    const dispatch = useAppDispatch();
    return (React.createElement(HorizontalContainer, { className: styles.contentPageResultContentArea },
        React.createElement("h2", { className: styles.title }, "Sis\u00E4ll\u00F6t"),
        React.createElement("div", { className: styles.container },
            React.createElement(ContentPageSearchResultCardList, { cards: pages, hasMoreCards: hasMore, isError: isError, isLoadingMore: isLoadingMore, onLoadMore: loadMorePages }),
            React.createElement(HorizontalContainer, { className: styles.helpTextContainer, horizontalAlign: "center", verticalAlign: "center" },
                React.createElement("p", null,
                    "Etk\u00F6 l\u00F6yt\u00E4nyt haluamaasi sis\u00E4lt\u00F6\u00E4? Muokkaa hakuasi tai",
                    ' ',
                    React.createElement("span", { onClick: () => {
                            dispatch(setSearchPhrase(''));
                            dispatch(updateCombinedSearchDebouncedSearchPhrase('', location, push, replace));
                            scrollToTop();
                        } }, "nollaa rajaukset"))))));
};
