import React from 'react';
import { VerticalContainer } from '../../../components/FlexboxContainer.js';
import style from './style.scss';
const renderIngredientRow = (x, listIndex, index) => (React.createElement("li", { className: style.ingredientRow, key: `ingredient-${listIndex}-${index}` },
    React.createElement("section", { className: style.ingredientFlexContainer },
        React.createElement("section", { className: style.ingredientAmount },
            x.amount,
            "\u00A0",
            x.unit),
        React.createElement("section", { className: style.ingredientName }, x.productDisplayAllowed ? React.createElement("a", { href: `/tuotteet/${x.productSlug}` }, x.name) : React.createElement("span", null, x.name)))));
const renderIngredientList = (x, index) => (React.createElement("div", { key: `ingredientlist-${index}`, className: style.ingredientList },
    x.heading !== '_' && React.createElement("h3", { className: style.ingredientHeading }, x.heading),
    React.createElement("ul", { className: style.ingredientsList }, x.ingredients.map((i, rowIndex) => renderIngredientRow(i, index, rowIndex)))));
export const RecipeIngredients = (props) => {
    const ingredientLists = props.ingredients.map(renderIngredientList);
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: style.ingredientsLayout, wrap: false },
        React.createElement("h2", null, "Ainekset"),
        React.createElement("section", { className: style.ingredientListContainer }, ingredientLists)));
};
