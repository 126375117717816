import React from 'react';
import { HorizontalContainer, VerticalContainer } from '../FlexboxContainer.js';
import { wholesaleListPageUrls } from '../../../common/urlPaths.js';
import { useAppSelector } from '../../hooks.js';
import { LinkWithScroll } from '../../util/scroll.js';
import styles from './WholesalesBox.scss';
export const WholesalesBox = ({ strings, contacts }) => {
    const wholesales = contacts.filter((contact) => contact.contentType === 'wholesale');
    const halfwayIndex = Math.round(wholesales.length / 2);
    const leftList = wholesales.slice(0, halfwayIndex);
    const rightList = wholesales.slice(halfwayIndex);
    return (React.createElement(VerticalContainer, { "data-pw": "footerWholesalesBox", horizontalAlign: "flex-start", className: styles.wholesales },
        React.createElement("h3", null, strings.wholeSaleHeader),
        React.createElement(HorizontalContainer, { horizontalAlign: "space-between", className: styles.links },
            React.createElement(WholesaleList, { wholesales: leftList }),
            React.createElement(WholesaleList, { wholesales: rightList }))));
};
const WholesaleList = ({ wholesales }) => {
    const language = useAppSelector((state) => state.i18n.language);
    return (React.createElement("ul", { className: styles.list }, wholesales.map((wholesale) => (React.createElement("li", { key: wholesale.slug },
        React.createElement(LinkWithScroll, { to: `${wholesaleListPageUrls[language]}/${wholesale.slug}`, className: styles.link }, wholesale.locality))))));
};
