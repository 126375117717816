import React, { useEffect, useState } from 'react';
import { useGetArticleQuery } from '../../services/api.js';
import { baseUrl } from '../../config.js';
import { articleViewedEvent } from '../../reducers/analyticsSlice.js';
import { articleMeta } from '../../meta.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { Article } from '../../components/Article.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import style from './style.scss';
function mapHelmetProps(data, slug, isLoading, isFetching, isError) {
    if (isLoading || isFetching || isError || !data) {
        return {
            lead: '',
            title: '',
            heroImageUrl: '',
            slug,
        };
    }
    else {
        return {
            title: data.title,
            lead: data.lead,
            heroImageUrl: data.heroImageUrl,
            slug,
        };
    }
}
export const ArticlePage = (routeProps) => {
    const searchParams = Object.assign(Object.assign({}, parseCacheQueryParams(routeProps.location)), { slug: routeProps.match.params.articleSlug });
    const { slug } = searchParams;
    const [analyticsEventSentSlug, setAnalyticsEventSentSlug] = useState();
    const useQueryResult = useGetArticleQuery(searchParams);
    const { data: article, isLoading, isFetching, isError } = useQueryResult;
    const helmetProps = mapHelmetProps(article, slug, isLoading, isFetching, isError);
    useEffect(() => {
        if ((article === null || article === void 0 ? void 0 : article.slug) === slug && slug !== analyticsEventSentSlug) {
            articleViewedEvent(article);
            setAnalyticsEventSentSlug(article === null || article === void 0 ? void 0 : article.slug);
        }
    }, [article, analyticsEventSentSlug]);
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { title: helmetProps.title, canonicalPath: `/ideat-ja-inspiraatiot/artikkelit/${slug}`, description: helmetProps.lead, pageMeta: article ? articleMeta(article) : undefined }),
        renderRtkQueryResult(Article, { pageUrl: `${baseUrl}${routeProps.location.pathname}` }, useQueryResult, (props, data) => ({
            pageUrl: props.pageUrl,
            article: data,
        }), { loadingClassName: style.loading, fetch404PageFromContentful: true })));
};
