import React, { useEffect, useRef } from 'react';
import { feedbackly as config } from '../config.js';
import styles from './Feedbackly.scss';
export const Feedbackly = () => {
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current || !config.oid || !config.dmn) {
            // The production script is loaded from Google Tag Manager, it doesn't work in DEV and QA so the script need be explicitly set.
            return undefined;
        }
        const script = document.createElement('script');
        script.innerHTML = `! function() {
            var e = document.createElement("script");
            e.type = "text/javascript", e.async = !0, e.defer = !0, e.src = "https://survey.feedbackly.com/dist/plugin-v4.min.js", (document.getElementsByTagName("body")[0] || document.getElementsByTagName("head")[0]).appendChild(e), window._fblyConf = {
                oid: "${config.oid}",
                pth: "https://survey.feedbackly.com",
                dmn: "${config.dmn}"
            }, window.FBLY = window.FBLY || {
                evbuf: []
            }, window.FBLY.action = window.FBLY.action || function(e, n, t) {
               window.FBLY.evbuf.push([e, n, t])
            }, ["addProperty", "clearProperty", "setLanguage", "open", "addMeta"].forEach(function(e) {
                window.FBLY[e] = window.FBLY[e] || function(n, t) {
                window.FBLY.evbuf.push([e, n, t])
            }
        })}();`;
        ref.current.appendChild(script);
        return () => {
            script.remove();
            window.FBLY = undefined;
        };
    }, [ref]);
    return (React.createElement("div", { className: styles.feedbackly, ref: ref },
        React.createElement("div", { className: "_fbly-main", id: `fbly-embedded-${config.embedId}` })));
};
