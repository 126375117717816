import React from 'react';
import { COMBINED_SEARCH_PRODUCTS_PAGE_PATH } from '../../../common/urlPaths.js';
import { MobileMenu } from './MobileMenu.js';
import { getWindow } from '../../../common/util.js';
import { throttle } from 'lodash-es';
import { VerticalContainer } from '../FlexboxContainer.js';
import { Icon } from '../../Icon.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { LinkWithScroll } from '../../util/scroll.js';
import styles from './SmallHeader.scss';
export function SmallHeader(props) {
    const { menuOpen, toggleMenu, isSticky, setIsSticky, homePageLink, } = props;
    const win = getWindow();
    const oldScrollYRef = React.useRef(win ? win.scrollY || win.document.documentElement.scrollTop : 0);
    React.useEffect(() => {
        const handleScroll = (_event) => {
            const scrollY = window.scrollY || window.document.documentElement.scrollTop;
            const oldScrollY = oldScrollYRef.current;
            if (scrollY > oldScrollY && isSticky && !menuOpen) {
                setIsSticky(false);
            }
            else if (scrollY < oldScrollY && !isSticky) {
                setIsSticky(true);
            }
            oldScrollYRef.current = scrollY;
        };
        const debouncedScroll = throttle(handleScroll, 50);
        window.addEventListener('scroll', debouncedScroll);
        return () => {
            window.removeEventListener('scroll', debouncedScroll);
        };
    }, [isSticky, setIsSticky, menuOpen]);
    const toggleMenuLambda = () => {
        if (!menuOpen) {
            document.body.className = styles.noScroll;
            toggleMenu(true);
        }
        else {
            document.body.className = '';
            toggleMenu(false);
        }
    };
    return (React.createElement(VerticalContainer, { "data-pw": "mobile-header" },
        React.createElement("header", { className: combineClassNames(styles.smallHeader, menuOpen && styles.shadow) },
            React.createElement("div", { className: styles.container },
                React.createElement("button", { className: styles.linkIcon, onClick: toggleMenuLambda, "data-pw": "menuToggle" },
                    React.createElement(Icon, { type: menuOpen ? 'menu-close-x' : 'menu-new', size: 32 }))),
            React.createElement("div", { className: styles.container },
                React.createElement(LinkWithScroll, { to: homePageLink, className: styles.logo, onClick: menuOpen ? toggleMenuLambda : undefined, "data-pw": "logo" },
                    React.createElement(Icon, { type: "kespro-logo-new", size: { width: 76, height: 28 }, title: "Kespro.com etusivulle" }))),
            React.createElement(LinkWithScroll, { to: COMBINED_SEARCH_PRODUCTS_PAGE_PATH, className: styles.linkIcon, title: "Hakusivulle", "data-pw": "searchPageLink" },
                React.createElement(Icon, { type: "search-light", size: 28 }))),
        React.createElement(MobileMenuRenderer, Object.assign({}, props, { toggleMenu: toggleMenuLambda }))));
}
const MobileMenuRenderer = (props) => {
    const { menuOpen, primaryNavigationItems, secondaryNavigationItems, buttonNavigationItems, toggleSubMenu, openMobileSubMenus, pathName, isLoggedIn, toggleMenu, language, languageItems, } = props;
    return (React.createElement("div", { className: !menuOpen ? styles.hidden : '' },
        React.createElement(MobileMenu, { toggleMenu: toggleMenu, primaryNavigationItems: primaryNavigationItems, secondaryNavigationItems: secondaryNavigationItems, buttonNavigationItems: buttonNavigationItems, toggleSubMenu: toggleSubMenu, pathName: pathName, openSubMenus: openMobileSubMenus, isLoggedIn: isLoggedIn, language: language, languageItems: languageItems })));
};
