import React from 'react';
import style from './ProductSearchDescription.scss';
const toTitleCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1, str.length).toLowerCase();
const formatProductSearchDescription = ({ selectedBrand, selectedCategory, productHitCount, searchPhrase, }) => {
    const formattedProductPhrase = productHitCount === 1 ? 'tuote' : 'tuotetta';
    const formattedSearchPhrase = searchPhrase ? `hakusanalla "${searchPhrase}"` : '';
    const formattedSelectedBrand = selectedBrand ? `brändillä "${toTitleCase(selectedBrand)}"` : '';
    const formattedSelectedCategory = `kategoriasta ${selectedCategory && selectedCategory.parent ? `"${selectedCategory.category.name}"` : '"kaikki"'}`;
    return `${productHitCount} ${formattedProductPhrase} ${formattedSearchPhrase} ${formattedSelectedBrand} ${formattedSelectedCategory}`;
};
export const ProductSearchDescription = (props) => (React.createElement("p", { "data-pw": "productSearchDescription", className: style.productSearchDescription }, formatProductSearchDescription(props)));
