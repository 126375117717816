import React from 'react';
import { Link } from 'react-router-dom';
import { HorizontalContainer, VerticalContainer } from './FlexboxContainer.js';
import styles from './KesproInfoBoxes.scss';
export const KesproInfoBoxes = () => React.createElement(HorizontalContainer, { className: styles.infoBoxContainer },
    React.createElement(VerticalContainer, { className: styles.infoBox },
        React.createElement("p", null, "Me Kesprossa autamme kaiken kokoisia asiakkaitamme menestym\u00E4\u00E4n sek\u00E4 kehitt\u00E4m\u00E4\u00E4n liikeideaansa. Meilt\u00E4 saat palvelua niin henkil\u00F6kohtaisesti kuin digitaalisissa kanavissa."),
        React.createElement(Link, { to: '/tukku/asiakkaaksi', className: styles.infoBoxLink }, "Tule asiakkaaksi")),
    React.createElement(VerticalContainer, { className: styles.infoBox },
        React.createElement("p", null, "Asiakkaanamme k\u00E4yt\u00F6ss\u00E4si on Suomen laajin tukkuvalikoima Kespronet-verkkopalvelun kautta. Logistiikka - ja jakeluverkostomme kattaa koko Suomen."),
        React.createElement("a", { href: "https://kespro.fi", target: "_blank", rel: "noopener noreferrer", className: styles.infoBoxLink }, "Tilaa Kespronetist\u00E4")));
