import React from 'react';
import { Footer } from './components/Footer.js';
import { FormSection } from './sections/FormSection.js';
import { Header } from './components/Header.js';
import { Helmet } from 'react-helmet';
import { HeroSection } from './sections/HeroSection.js';
import { IntohimoTyoryhmaSection } from './sections/IntohimoTyoryhmaSection.js';
import { NamifestiSection } from './sections/NamifestiSection.js';
import { QaSection } from './sections/QaSection.js';
import styles from './NamifestiPage.module.scss';
import { FaktatSection } from './sections/FaktatSection.js';
import { VideoSection } from './sections/VideoSection.js';
export const NamifestiPage = () => (React.createElement("div", { className: styles.container },
    React.createElement(Helmet, { htmlAttributes: { lang: 'fi' } },
        React.createElement("title", null, "Ravintola-alan Muutoksen menu"),
        React.createElement("meta", { name: 'description', content: 'Muutoksen Menu on ravintola-alan vetoomus kest\u00E4v\u00E4n tulevaisuuden puolesta. Intohimoty\u00F6ryhm\u00E4 ja Kespro tukevat alan intohimoa p\u00E4\u00E4t\u00F6sten ja konkreettisten ratkaisujen aikaansaamiseksi. Lue lis\u00E4\u00E4 ja liity mukaan!' })),
    React.createElement(Header, null),
    React.createElement("main", null,
        React.createElement(HeroSection, null),
        React.createElement(NamifestiSection, null),
        React.createElement(IntohimoTyoryhmaSection, null),
        React.createElement(FaktatSection, null),
        React.createElement(VideoSection, null),
        React.createElement(FormSection, null),
        React.createElement(QaSection, null)),
    React.createElement(Footer, null)));
