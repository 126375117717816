import React, { useState, useEffect } from 'react';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
import { Svg } from '../components/Svg.js';
import { getAspectRatioImgSrcSet } from '../../../util/image.js';
import { intohimoNyt } from '../../../config.js';
import styles from './VideoSection.module.scss';
import { combineClassNames } from '../../../util/css/classNames.js';
import { Modal } from '../components/Modal.js';
const picWidth = 250;
const picHeight = 250;
export const VideoSection = () => {
    const { srcSet: srcSetLaura, fallback: fallbackLaura } = getAspectRatioImgSrcSet(intohimoNyt.videoPicLaura, 1, 'scale');
    const { srcSet: srcSetPekka, fallback: fallbackPekka } = getAspectRatioImgSrcSet(intohimoNyt.videoPicPekka, 1, 'scale');
    const [isModalOpen, setModalState] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const handleClick = (event) => {
        if (event.currentTarget.value === 'laura') {
            setVideoUrl(intohimoNyt.videoLaura);
        }
        if (event.currentTarget.value === 'pekka') {
            setVideoUrl(intohimoNyt.videoPekka);
        }
        setModalState(true);
    };
    const handleClose = () => {
        setVideoUrl('');
        setModalState(false);
    };
    useEffect(() => {
        function handleResize() {
            if (window) {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (React.createElement("section", { className: styles.container },
        React.createElement("div", { id: "aria-tyoryhma-content", className: styles.content },
            React.createElement("h2", null,
                React.createElement(Svg, { name: windowSize.width && windowSize.width < 1024 ? 'title-video-mobile' : 'title-video-desktop', className: styles.svgHeader }),
                React.createElement(AriaHeadingText, null, "Muutama sana ravintolakulttuurista")),
            React.createElement("div", { className: styles.bubbles },
                React.createElement("button", { onClick: handleClick, value: "laura" },
                    React.createElement("div", { className: styles.pictureWrapper },
                        React.createElement("picture", { className: styles.picture },
                            React.createElement("source", { srcSet: srcSetLaura }),
                            React.createElement(Svg, { className: styles.arrow, name: "arrow" }),
                            React.createElement("img", { alt: "Laura Styyra", loading: "lazy", src: fallbackLaura, width: picWidth, height: picHeight, className: styles.image }))),
                    React.createElement("div", { className: styles.nameWrapper },
                        React.createElement(Svg, { name: "name-ls", className: styles.name }))),
                React.createElement("button", { onClick: handleClick, value: "pekka" },
                    React.createElement("div", { className: styles.pictureWrapper, style: { margin: '0 0 -2.5rem' } },
                        React.createElement("picture", { className: styles.picture },
                            React.createElement("source", { srcSet: srcSetPekka }),
                            React.createElement(Svg, { className: styles.arrow, name: "arrow" }),
                            React.createElement("img", { alt: "Pekka Ter\u00E4v\u00E4", loading: "lazy", src: fallbackPekka, width: picWidth, height: picHeight, className: styles.image }))),
                    React.createElement("div", { className: styles.nameWrapper },
                        React.createElement(Svg, { name: "name-pt", className: combineClassNames(styles.name, styles.pt) })))),
            React.createElement(Modal, { open: isModalOpen, onClose: handleClose },
                React.createElement("video", { className: styles.video, width: 1280, height: 720, key: videoUrl, autoPlay: true, controls: true },
                    React.createElement("source", { src: videoUrl, type: "video/mp4" }))))));
};
