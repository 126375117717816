import styles from './Modal.module.scss';
import React, { useEffect, useRef } from 'react';
import { combineClassNames } from '../../../util/css/classNames.js';
import { VisuallyHidden } from '@kesko/kespro-common-components';
import '@kesko/kespro-common-components/dist/assets/components/VisuallyHidden/visuallyhidden.css';
export const Modal = ({ onClose, open, children, className }) => {
    const dialogRef = useRef(null);
    useEffect(() => {
        var _a, _b;
        if (open) {
            (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
        }
        else {
            (_b = dialogRef.current) === null || _b === void 0 ? void 0 : _b.close();
        }
    }, [open]);
    const handleClose = () => onClose && onClose();
    return (React.createElement("dialog", { ref: dialogRef, className: combineClassNames(styles.dialogBox, className), "aria-labelledby": "modal-title" },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.header },
                React.createElement("button", { className: styles.buttonClose, onClick: handleClose, autoFocus: true },
                    React.createElement("span", { "aria-hidden": "true" }, "\u2716"),
                    React.createElement(VisuallyHidden, null, "Sulje"))),
            React.createElement("div", { className: styles.content }, children))));
};
