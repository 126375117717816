import React from 'react';
import style from './style.scss';
import { ContentPageSearchResultCardList } from '../../components/ContentPageSearchResultCardList.js';
import { LandingPageElementType } from '../../../common/apiTypes.js';
import { TextContent } from '../../components/LandingPage/TextContent.js';
import { partnerMeta } from '../../meta.js';
import { useGetPartnerContentQuery } from '../../services/api.js';
import * as R from 'ramda';
import { BrandCard } from '../../components/BrandCard.js';
import { IdeaInspirationCardList } from '../../components/IdeaInspirationCardList.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
const BrandCards = (props) => {
    const brandCountStyles = R.cond([
        [R.equals(2), () => style.twoCards],
        [R.equals(1), () => style.oneCard],
        [R.T, () => undefined],
    ])(props.brands.length);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: style.brandTitle },
            props.partner.title,
            " - tuotemerkit Kespro.comissa"),
        React.createElement("section", { className: combineClassNames(style.brandCards, brandCountStyles) }, props.brands.map((brand) => (React.createElement(BrandCard, { partnerContent: true, title: brand.title, heroImage: brand.hero || props.defaultImage, logo: brand.logo, key: brand.slug, linkUrl: `/tuotteet/tuotemerkit/${brand.slug}`, cardCount: props.brands.length }))))));
};
const ArticlesAndRecipes = (props) => React.createElement(React.Fragment, null,
    React.createElement("h2", { className: style.articlesAndRecipesTitle },
        props.partner.title,
        " - ideat ja inspiraatiot Kespro.comissa"),
    React.createElement(IdeaInspirationCardList, { cards: props.combinedArticlesAndRecipes }));
const LinkCards = (props) => React.createElement(React.Fragment, null,
    React.createElement("h2", { className: style.linkCardTitle },
        props.partner.title,
        " - sis\u00E4lt\u00F6sivut Kespro.comissa"),
    React.createElement("section", { className: combineClassNames(style.container, style.contentPageResultContainer) },
        React.createElement(ContentPageSearchResultCardList, { partnerPage: true, cards: props.linkCards })));
const PartnerContent = (props) => {
    const partner = props.partner;
    const linkCards = props.linkCards;
    const articlesAndRecipes = props.articlesAndRecipes;
    const brands = props.brands;
    const brandDefaultHeroImageUrl = props.brandDefaultHeroImageUrl;
    if (!partner) {
        return (React.createElement("div", { className: style.noResults },
            React.createElement("h1", null,
                "Kumppanille ",
                props.partner.title,
                " ei l\u00F6ytynyt sis\u00E4lt\u00F6j\u00E4 kespro.comista")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(KesproComHelmet, { title: partner.title, canonicalPath: `/kumppani/${partner.title}`, pageMeta: partnerMeta(partner) }),
        React.createElement("div", { className: style.partnerContentContainer },
            React.createElement("h1", { id: "title" }, partner.title),
            partner && partner.content &&
                React.createElement(TextContent, { type: LandingPageElementType.TextContent, id: `brandPageText-${partner.title}`, textAlignment: "Keskitetty" /* TextContentAlignment.centered */, content: partner.content }),
            linkCards && linkCards.length > 0 &&
                React.createElement(LinkCards, { isError: false, linkCards: linkCards, partner: partner }),
            articlesAndRecipes && articlesAndRecipes.length > 0 &&
                React.createElement(ArticlesAndRecipes, { partner: partner, combinedArticlesAndRecipes: articlesAndRecipes }),
            brands && brands.length > 0 &&
                React.createElement(BrandCards, { partner: partner, brands: brands, defaultImage: brandDefaultHeroImageUrl }))));
};
const PartnerContentPageWrapper = (props) => {
    const partner = props.match.params.partner;
    const searchQueryResult = useGetPartnerContentQuery(Object.assign(Object.assign({}, parseCacheQueryParams(props.location)), { partner }));
    return renderRtkQueryResult(PartnerContent, undefined, searchQueryResult, (_props, data) => data, { fetch404PageFromContentful: true });
};
export { PartnerContentPageWrapper as PartnerContentPage };
