export function getWindow() {
    return typeof window !== 'undefined'
        ? window
        : undefined;
}
export function getDocument() {
    return typeof document !== 'undefined'
        ? document
        : undefined;
}
export const isBrowser = () => typeof window !== 'undefined';
