import React from 'react';
import { LandingPageElementType, } from '../../../common/apiTypes.js';
import { KesproComHelmet } from '../KesproComHelmet.js';
import { Hero } from './Hero.js';
import { LinkCard } from './LinkCard.js';
import { Row } from './Row.js';
import { TextContent } from './TextContent.js';
import { Column } from './Column.js';
import { ContentCarousel } from './ContentCarousel.js';
import { Form } from './Form.js';
import { landingPageMeta } from '../../meta.js';
import { useAppSelector } from '../../hooks.js';
import style from './LandingPage.scss';
import { EventList } from './EventList.js';
import { ABtesting } from './ABTesting.js';
import { renderAlternateLinks } from '../renderAlternateLinks.js';
import { ProductList } from './ProductList.js';
import { Button } from '../Button.js';
import { TukkukorttiWrapper } from '../TukkukorttiWrapper.js';
export const renderLandingPageElement = (element, language, isLoggedIn) => {
    switch (element.type) {
        case LandingPageElementType.Hero:
            return React.createElement(Hero, Object.assign({ key: element.id }, element, { isLoggedIn: isLoggedIn }));
        case LandingPageElementType.LinkCard:
            return React.createElement(LinkCard, Object.assign({ key: element.id }, element));
        case LandingPageElementType.Row:
            return React.createElement(Row, Object.assign({ key: element.id }, element, { language: language, isLoggedIn: isLoggedIn }));
        case LandingPageElementType.TextContent:
            return React.createElement(TextContent, Object.assign({ key: element.id }, element));
        case LandingPageElementType.Column:
            return React.createElement(Column, Object.assign({ key: element.id }, element, { language: language, isLoggedIn: isLoggedIn }));
        case LandingPageElementType.ContentCarousel:
            return React.createElement(ContentCarousel, Object.assign({ key: element.id }, element));
        case LandingPageElementType.Form:
            return React.createElement(Form, Object.assign({ key: element.id }, element, { language: language }));
        case LandingPageElementType.EventList:
            return React.createElement(EventList, Object.assign({ key: element.id }, element, { language: language }));
        case LandingPageElementType.ABTesting:
            return React.createElement(ABtesting, Object.assign({ key: element.id, language: language }, element, { isLoggedIn: isLoggedIn }));
        case LandingPageElementType.ProductList:
            return React.createElement(ProductList, Object.assign({ key: element.id }, element));
        case LandingPageElementType.Button:
            return React.createElement(Button, Object.assign({ key: element.id }, element));
        case LandingPageElementType.Tukkukortti:
            return React.createElement(TukkukorttiWrapper, Object.assign({ key: element.id }, element, { language: language, isLoggedIn: isLoggedIn }));
        default:
            return undefined;
    }
};
const LandingPageContent = (props) => {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    return (React.createElement("div", { className: style.landingPageContent }, props.content.map((e) => renderLandingPageElement(e, props.language, !!isLoggedIn))));
};
export const LandingPage = (props) => (React.createElement("div", null,
    React.createElement(KesproComHelmet, { title: props.page.title, description: props.page.lead, canonicalPath: `/${props.page.slug}`, pageMeta: landingPageMeta(props.page) }, props.page.localizedSlugs && renderAlternateLinks(props.language, props.page.localizedSlugs)),
    React.createElement(LandingPageContent, { content: props.page.content, language: props.language })));
