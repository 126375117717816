import React from 'react';
import { scrollIntoView } from '../util/scroll.js';
export class FragmentScroll extends React.Component {
    render() {
        const { children } = this.props;
        if (Array.isArray(children)) {
            return (React.createElement("div", null, ...children));
        }
        else {
            return children;
        }
    }
    componentDidMount() {
        const hash = getHash(this.props);
        if (hash) {
            scrollIntoView(hash, { block: 'start', behavior: 'smooth' });
        }
    }
    componentDidUpdate(prevProps) {
        const hash = getHash(this.props);
        if (hash) {
            if (hash !== getHash(prevProps)) {
                scrollIntoView(hash, { block: 'start', behavior: 'smooth' });
            }
        }
    }
}
function getHash(props) {
    const hash = props.hash;
    return hash
        ? hash.replace(/^#/, '')
        : '';
}
