import React from 'react';
import { useGetProductBrandsCollectionQuery } from '../../services/api.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { ProductBrandList } from '../../components/ProductBrandList.js';
import { ProductBrandsBanner } from '../../components/ProductBrandsBanner.js';
import { ProductBrandsFilter } from '../../components/ProductBrandsFilter.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import style from './style.scss';
const aInAscii = 65; // A
const zInAscii = 90; // Z
const arrayFromRange = (from, to) => {
    const arr = [];
    for (let i = from; i <= to; i++) {
        arr[i - from] = i;
    }
    return arr;
};
const mapProductBrandsByFirstLetter = (alphabetInAscii, productBrands) => {
    if (!productBrands) {
        return undefined;
    }
    const mappedProductBrands = alphabetInAscii.reduce((prev, alphabet) => {
        const filteredProductBrands = productBrands.filter((productBrand) => productBrand.name.charCodeAt(0) === alphabet);
        return Object.assign(Object.assign({}, prev), { [String.fromCharCode(alphabet)]: filteredProductBrands });
    }, {});
    mappedProductBrands['#'] = productBrands.filter((productBrand) => productBrand.name.charCodeAt(0) < aInAscii || productBrand.name.charCodeAt(0) > zInAscii);
    return mappedProductBrands;
};
const ProductBrandsCollectionPage = ({ content }) => {
    const alphabetsInAscii = arrayFromRange(aInAscii, zInAscii);
    const mappedProductBrands = mapProductBrandsByFirstLetter(alphabetsInAscii, content.productBrands);
    return (React.createElement("div", { className: style.productBrandsCollection },
        React.createElement(KesproComHelmet, { title: 'Tuotemerkit', canonicalPath: "/tuotteet/tuotemerkit", description: "Kespro.com Tuotemerkit" }),
        React.createElement("h1", { className: style.productBrandsCollectionHeading }, "Tuotemerkit"),
        React.createElement("h2", { className: style.productBrandsMonthlyCollectionHeading }, "Kuukauden tuotemerkit"),
        React.createElement(ProductBrandsBanner, { brands: content.highlightedBrands }),
        React.createElement(ProductBrandsFilter, { alphabetsInAscii: alphabetsInAscii }),
        React.createElement(ProductBrandList, { mappedProductBrands: mappedProductBrands })));
};
const ProductBrandsCollectionPageWrapper = (props) => {
    const queryResult = useGetProductBrandsCollectionQuery(parseCacheQueryParams(props.location));
    return renderRtkQueryResult(ProductBrandsCollectionPage, {}, queryResult, (_, content) => ({ content }), { loadingClassName: style.loadingIndicator, fetch404PageFromContentful: true });
};
export { ProductBrandsCollectionPageWrapper as ProductBrandsCollectionPage };
