var _a;
import { __awaiter } from "tslib";
import * as rtk from '@reduxjs/toolkit';
import { IDEA_INSPIRATION_CARDS_DEFAULT_LIMIT, IDEA_INSPIRATION_PAGE_SIZE } from '../../common/constants.js';
import QueryString from 'query-string';
import { IDEA_INSPIRATIONS_SEARCHPHRASE } from '../../common/queryStrings.js';
import { setHandledUrl } from './urlSlice.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    searchPhrase: '',
    debouncedSearchPhrase: '',
    searchResult: undefined,
    searchResultOffset: 0,
    offset: 0,
    hasMore: false,
    homePageLimitVisibleCardCount: true,
    query: {
        searchPhrase: '',
        offset: 0,
    },
};
export const ideaInspirationPageUrlChanged = (location) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { searchPhrase } = getIdeaInspirationStateFromLocation(location);
    dispatch(setIdeaInspirationSearchState({ searchPhrase }));
    dispatch(setHandledUrl(getRelativeUrlFromLocation(location)));
});
export const setDebouncedSearchPhraseWithUpdates = (searchPhrase, location, push, replace) => (dispatch) => {
    dispatch(setDebouncedSearchPhrase(searchPhrase));
    dispatch(updateIdeaInspirationSearchUrl(location, push, replace));
};
export const updateIdeaInspirationSearchUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.ideaInspiration.searchPhrase;
    const newPath = getIdeaInspirationSearchUrl(location, searchPhrase);
    const currentPath = getRelativeUrlFromLocation(location);
    if (newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
export const ideaInspirationSlice = createSlice({
    name: 'idea-inspiration',
    initialState,
    reducers: {
        incrementOffset: (state) => {
            state.homePageLimitVisibleCardCount = false;
            state.offset = state.offset + IDEA_INSPIRATION_PAGE_SIZE;
            state.query = createIdeaInspirationSearchQuery(state.debouncedSearchPhrase, state.offset);
        },
        setDebouncedSearchPhrase: (state, action) => {
            if (state.debouncedSearchPhrase !== action.payload) {
                state.debouncedSearchPhrase = action.payload;
                state.searchResultOffset = 0;
                state.offset = 0;
                state.query = createIdeaInspirationSearchQuery(action.payload);
            }
        },
        setIdeaInspirationSearchResult: (state, action) => {
            if (state.offset === 0 || !state.searchResult || !action.payload) {
                state.searchResultOffset = state.offset;
                state.searchResult = action.payload;
            }
            else if (state.searchResultOffset < state.offset) {
                state.searchResultOffset = state.offset;
                state.searchResult = state.searchResult.concat(action.payload);
            }
            const articleCount = action.payload.filter((item) => item.type === 'article').length;
            const recipeCount = action.payload.filter((item) => item.type === 'recipe').length;
            state.hasMore =
                articleCount === IDEA_INSPIRATION_CARDS_DEFAULT_LIMIT ||
                    recipeCount === IDEA_INSPIRATION_CARDS_DEFAULT_LIMIT;
        },
        setIdeaInspirationSearchState: (state, action) => {
            const { searchPhrase } = action.payload;
            if (state.debouncedSearchPhrase !== searchPhrase) {
                state.searchResultOffset = 0;
                state.offset = 0;
                state.debouncedSearchPhrase = searchPhrase;
                state.searchPhrase = searchPhrase;
                state.query = createIdeaInspirationSearchQuery(searchPhrase);
            }
        },
        setSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
    },
});
export const { reducer } = ideaInspirationSlice;
export const { setIdeaInspirationSearchState, setIdeaInspirationSearchResult, setSearchPhrase, setDebouncedSearchPhrase, incrementOffset, } = ideaInspirationSlice.actions;
const createIdeaInspirationSearchQuery = (searchPhrase, offset = 0) => ({
    // we have to divide the offset by 2 due to the API using the same offset for both recipe and article cards
    searchPhrase,
    offset: offset / 2,
});
export const getIdeaInspirationSearchUrl = (location, searchPhrase) => {
    const newQuery = QueryString.stringify({
        [IDEA_INSPIRATIONS_SEARCHPHRASE]: searchPhrase || undefined,
    });
    return `${location.pathname}?${newQuery}`;
};
export const getIdeaInspirationStateFromLocation = (location) => {
    const queryParams = QueryString.parse(location.search);
    const searchPhrase = (queryParams[IDEA_INSPIRATIONS_SEARCHPHRASE] || '');
    return { searchPhrase };
};
