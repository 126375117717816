import React from 'react';
import { HorizontalContainer } from '../FlexboxContainer.js';
import { RadioButton } from '../Input/RadioButton.js';
import styles from './ContactMethodSelector.scss';
export const ContactMethodSelector = (props) => {
    const { method, allowNoContact, strings } = props;
    const noContactButton = allowNoContact
        ? React.createElement(RadioButton, { key: "no-contact", name: "contactMethod", value: "none", label: strings.noThanks, checked: method === 'no-contact', onChange: selectMethod(props, 'no-contact') })
        : undefined;
    return (React.createElement(HorizontalContainer, { verticalAlign: "center", className: styles.container },
        noContactButton,
        React.createElement(RadioButton, { key: "email", name: "contactMethod", value: "email", label: strings.byEmail, checked: method === 'email', onChange: selectMethod(props, 'email') }),
        React.createElement(RadioButton, { key: "phone", name: "contactMethod", value: "phone", label: strings.byPhone, checked: method === 'phone', onChange: selectMethod(props, 'phone') })));
};
function selectMethod(props, newMethod) {
    return () => {
        props.onChange(newMethod);
    };
}
