import React from 'react';
import { Renderer, marked } from 'marked';
import { EmbeddedContentConsentRequiredHtml } from './EmbeddedContentConsentRequired.js';
import { markdownToString } from '../../common/stringTypes.js';
import { combineClassNames } from '../util/css/classNames.js';
import { getContentfulImgSrcSet } from '../util/image.js';
import { useAppSelector } from '../hooks.js';
import style from './MarkdownPresenter.scss';
function escape(html) {
    return html
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
}
function renderLink(href, title, text) {
    try {
        const encodedHref = encodeURI(href).replace(/%25/g, '%');
        const titleAttribute = title ? ` title="${title}"` : '';
        // Note: function "escape" causes hydration warning due to different implementation in Node and Browser
        return `<a href="${escape(encodedHref)}" rel="noopener"${titleAttribute}>${text}</a>`;
    }
    catch (e) {
        return text;
    }
}
;
function renderImage(href, title, text) {
    const titleAttribute = title ? ` title="${title}"` : '';
    // The markdown presenter is limited to 52% of the parent element,
    // which is limited at 1440px, resulting in ~750px
    const { srcSet, sizes, fallback } = getContentfulImgSrcSet(href, 52);
    return `
        <picture>
            <source srcSet="${srcSet}" sizes="${sizes}" />
            <img class="${style.image}" src="${fallback}?w=750" alt="${text}"${titleAttribute}>
        </picture>
    `;
}
;
// The overrides are based on the default renderers from marked 0.4,
// with some unused optional parts removed and our custom logic added
const renderer = new Renderer();
const noConsentRenderer = new Renderer();
renderer.link = renderLink;
renderer.image = renderImage;
noConsentRenderer.link = renderLink;
noConsentRenderer.image = renderImage;
noConsentRenderer.html = (html) => {
    if (/<iframe/.test(html)) {
        return EmbeddedContentConsentRequiredHtml;
    }
    return html;
};
export const MarkdownPresenter = (props) => {
    const hasConsent = useAppSelector((state) => state.analytics.hasConsentForEmbeddedContent);
    return (React.createElement("div", { className: combineClassNames(style.markdownPresenter, props.className), dangerouslySetInnerHTML: {
            __html: marked.parse(markdownToString(props.markdownContent), { renderer: hasConsent ? renderer : noConsentRenderer }),
        } }));
};
