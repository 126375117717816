import qs from 'query-string';
export const COMBINED_SEARCH_ALL_SEARCHPHRASE = 'kaikki-haku';
export const COMBINED_SEARCH_RECIPE_SEARCHPHRASE = 'resepti-haku';
export const COMBINED_SEARCH_PRODUCTS_CATEGORY = 'tuotekategoria';
export const COMBINED_SEARCH_PRODUCTS_BRAND = 'tuotemerkki';
export const RECIPE_SEARCH_CATEGORY = 'ruokalaji';
export const RECIPE_SEARCH_INGREDIENTS = 'aineosa';
export const contactSearchPhraseParamName = {
    fi: 'kontakti-haku',
    en: 'contact-search',
    sv: 'kontaktsökning',
    ru: '',
};
export const contactTypeFilterParamName = {
    fi: 'tyyppi',
    en: 'type',
    sv: 'typ',
    ru: '',
};
export const contactRegionFilterParamName = {
    fi: 'sijainti',
    en: 'location',
    sv: 'plats',
    ru: '',
};
export const PRODUCT_CATEGORY = 'kategoria';
export const PRODUCT_SEARCHPHRASE = 'tuote-haku';
export const IDEA_INSPIRATIONS_SEARCHPHRASE = 'sisalto-haku';
export const PREVIEW = 'preview';
export const BYPASS_CACHE = 'bypassCache';
export const SSO_POSTLOGIN_URL = 'postLoginUrl';
export const parseCacheQueryParams = (locationOrObject) => {
    const query = 'search' in locationOrObject
        ? qs.parse(locationOrObject.search)
        : locationOrObject;
    return {
        bypassCache: getStringOrNone(query[BYPASS_CACHE]),
        preview: getStringOrNone(query[PREVIEW]),
    };
};
const getStringOrNone = (queryParam) => {
    if (typeof queryParam === 'string') {
        return queryParam;
    }
    return undefined;
};
