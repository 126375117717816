import React from 'react';
import { HorizontalContainer, VerticalContainer } from './FlexboxContainer.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { expandFilter, selectFilterWithUpdates, updateTagFilter, updateTagWithUpdates, } from '../reducers/combinedSearchRecipeSlice.js';
import { RECIPE_SEARCH_INGREDIENTS } from '../../common/queryStrings.js';
import { FilterCardList } from './FilterCardList.js';
import { KesproInfoBoxes } from './KesproInfoBoxes.js';
import { RecipeSearchCategoryFilterString } from './RecipeSearchCategoryFilterString.js';
import { RecipeSearchResultCardList } from './RecipeSearchResultCardList.js';
import { ResultFilterBubble } from './ResultFilterBubble.js';
import { setFilter } from '../util/recipe.js';
import { scrollToTop } from '../util/scroll.js';
import { shallowEqual } from 'react-redux';
import styles from './RecipeSearchResult.scss';
export const RecipeSearchResult = (props) => {
    const dispatch = useAppDispatch();
    const { activeFilters, expandedFilters, filters, tag, error, } = useAppSelector((state) => ({
        activeFilters: state.combinedSearchRecipe.activeFilters,
        expandedFilters: state.combinedSearchRecipe.expandedFilters,
        filters: state.combinedSearchRecipe.filters,
        tag: state.combinedSearchRecipe.tag,
        error: state.combinedSearchRecipe.error,
    }), shallowEqual);
    const recipeSeoData = props.recipeSeo;
    const { isError, isLoadingMore, isMobileFiltersOpen, recipes, hasMore, loadMoreRecipes, lastUsedSearchPhrase, onCloseMobileFilters, location, push, replace, } = props;
    const handleFilterExpanded = (filterIndex) => {
        dispatch(expandFilter(filterIndex));
    };
    const handleFilterSelected = (selection) => {
        dispatch(selectFilterWithUpdates(selection, location, push, replace));
    };
    const closeHandler = (event) => {
        if (event.target.className === event.currentTarget.className) {
            onCloseMobileFilters();
            const recipeFilterLinks = document.getElementById('recipeFilterLinks');
            if (recipeFilterLinks) {
                recipeFilterLinks.scrollIntoView();
            }
        }
    };
    const clearRecipeFilters = () => {
        dispatch(updateTagFilter(undefined));
        dispatch(selectFilterWithUpdates(undefined, location, push, replace));
    };
    const clearTagFilter = () => {
        dispatch(updateTagWithUpdates(undefined, location, push, replace));
    };
    const categoryFilters = getSelectedFilters(activeFilters, handleFilterSelected);
    const tagFilter = getSelectedTagFilter((!error || !!props.isLoaded), tag === null || tag === void 0 ? void 0 : tag.title, clearTagFilter);
    const filterStrings = [...categoryFilters, ...tagFilter];
    const filterCardProps = {
        filters,
        activeFilters,
        expandedFilters,
        onFilterExpanded: handleFilterExpanded,
        onFilterSelected: handleFilterSelected,
    };
    return (React.createElement(HorizontalContainer, { className: styles.recipeResultContentArea },
        React.createElement("div", { className: `${styles.desktop} ${styles.recipeFilterContainer}` },
            React.createElement(FilterCardList, Object.assign({}, filterCardProps))),
        isMobileFiltersOpen &&
            React.createElement("div", { className: `${styles.mobile} ${styles.filterCardModal} ${styles.recipeFilterContainer}`, onClick: closeHandler },
                React.createElement("div", { className: styles.filterCardModalContents },
                    React.createElement(HorizontalContainer, { verticalAlign: "center", horizontalAlign: "space-between", className: styles.recipeFilterMobileHeader },
                        React.createElement("h2", null, "Rajaa hakua"),
                        React.createElement("button", { className: styles.clearFilterButton, onClick: clearRecipeFilters }, "Nollaa rajaukset")),
                    React.createElement(FilterCardList, Object.assign({}, filterCardProps, { className: styles.recipeFilterMobileContainer })),
                    React.createElement(HorizontalContainer, { horizontalAlign: "center", className: styles.useFilterButtonContainer },
                        React.createElement("button", { className: styles.useFilterButton, onClick: closeHandler }, "K\u00E4yt\u00E4")))),
        React.createElement("div", { className: styles.container },
            recipeSeoData ?
                React.createElement(VerticalContainer, { className: styles.seoTextContainer, horizontalAlign: "center" },
                    React.createElement("h1", null, recipeSeoData.title),
                    React.createElement("p", null,
                        " ",
                        recipeSeoData.seoText)) : undefined,
            React.createElement("div", { className: styles.recipeSearchFilterContainer },
                React.createElement(RecipeSearchCategoryFilterString, { searchWords: lastUsedSearchPhrase, selectedFilters: filterStrings, useH2Tag: (recipeSeoData && recipeSeoData.title) ? true : false }),
                filterStrings.length || error
                    ? React.createElement(HorizontalContainer, { className: styles.filterBubbleContainer, wrap: true },
                        renderSelectedFilters(filterStrings),
                        error && tag && !props.isLoaded
                            ? renderErrorMessage(tag.title, clearTagFilter)
                            : undefined)
                    : undefined),
            React.createElement("div", { className: styles.recipeSearchResultsContainer },
                React.createElement(RecipeSearchResultCardList, { cards: recipes, hasMoreCards: hasMore, isError: isError, isLoadingMore: isLoadingMore, onLoadMore: loadMoreRecipes })),
            React.createElement(HorizontalContainer, { className: styles.helpTextContainer, horizontalAlign: "center", verticalAlign: "center" },
                React.createElement("p", null,
                    "Etk\u00F6 l\u00F6yt\u00E4nyt haluamaasi sis\u00E4lt\u00F6\u00E4? Muokkaa hakuasi tai ",
                    ' ',
                    React.createElement("span", { onClick: () => { clearRecipeFilters(); scrollToTop(); } }, "nollaa rajaukset"))),
            React.createElement(KesproInfoBoxes, null))));
};
function renderSelectedFilters(filterStrings) {
    const onClick = (filter) => {
        if (window) {
            const filterToBeDeleted = filter.primaryFilter === 'pääraaka-aine' ? RECIPE_SEARCH_INGREDIENTS : filter.primaryFilter;
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            searchParams.delete(filterToBeDeleted);
            const pathName = window.location.pathname;
            const updatedPathName = pathName.replace(filterToBeDeleted.toLowerCase(), '');
            return window.location.href = `${window.location.origin}${updatedPathName}${searchParams && `?${searchParams}`}${window.location.hash}`;
        }
        return undefined;
    };
    return filterStrings.length ? (filterStrings.map((filter) => (React.createElement(ResultFilterBubble, { key: `${filter.primaryFilter}${filter.secondaryFilter || ''}`, onClick: () => onClick(filter), primaryFilter: filter.primaryFilter, secondaryFilter: filter.secondaryFilter })))) : (undefined);
}
function renderErrorMessage(tag, onClick) {
    return (React.createElement(ResultFilterBubble, { key: tag, onClick: onClick, primaryFilter: tag, className: styles.erroredFilterBubble }));
}
function getSelectedFilters(selectedFilters, selectFilter) {
    let filterStrings = [];
    selectedFilters.forEach((filter) => {
        filterStrings = filterStrings.concat(getFilterBubblePropsRecursively(filter, selectFilter));
    });
    return filterStrings;
}
function getSelectedTagFilter(isLoaded, tag, deSelectTag) {
    const filterStrings = [];
    if (tag && isLoaded) {
        filterStrings.push({
            primaryFilter: tag, onClick: deSelectTag,
        });
    }
    return filterStrings;
}
function getFilterBubblePropsRecursively(selectedFilter, selectFilter) {
    let filterStrings = [];
    const childFilters = selectedFilter.options && selectedFilter.options.length > 0 ? selectedFilter.options : undefined;
    if (childFilters) {
        childFilters.forEach((child) => {
            if (child.options && child.options.length > 0) {
                filterStrings = filterStrings.concat(getFilterBubblePropsRecursively(child, selectFilter));
            }
            else {
                filterStrings.push({
                    primaryFilter: selectedFilter.categoryName.toLowerCase(),
                    secondaryFilter: child.categoryName.toLowerCase(),
                    onClick: () => {
                        setFilter(child.categoryName, selectFilter, 'deselect');
                    },
                });
            }
        });
    }
    else {
        // This should never be executed because you can't select a root filter alone and
        // consequent recursive calls always have child filters.
        // However, this can be executed when a URL with invalid filter ids is used.
        filterStrings.push({
            primaryFilter: selectedFilter.categoryName.toUpperCase(),
            onClick: () => {
                setFilter(selectedFilter.categoryName, selectFilter, 'deselect');
            },
        });
    }
    return filterStrings;
}
