import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import { useAppSelector } from '../hooks.js';
import { LandingPageWrapper } from './LandingPagesWrapper.js';
import { PersonalisedContentWrapper } from './PersonalisedContent/PersonalisedContentWrapper.js';
export const AuthenticatedLandingPage = (props) => {
    var _a;
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    if (!isLoggedIn) {
        return React.createElement(Redirect, { push: true, to: "/" });
    }
    const { location } = props.routeProps;
    const preview = (_a = qs.parse(location.search).preview) === null || _a === void 0 ? void 0 : _a.toString();
    return (React.createElement("div", null,
        React.createElement(PersonalisedContentWrapper, { language: props.language, preview: preview }),
        React.createElement(LandingPageWrapper, Object.assign({}, props))));
};
