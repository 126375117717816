var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import { PAGES_PAGE_SIZE } from '../../common/constants.js';
import QueryString from 'query-string';
import { COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH } from '../../common/urlPaths.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
import { setHandledUrl } from './urlSlice.js';
import { COMBINED_SEARCH_ALL_SEARCHPHRASE } from '../../common/queryStrings.js';
import { setCombinedSearchState, initializeSearchPhrase } from './combinedSearchSlice.js';
const initialState = {
    offset: 0,
    searchResultOffset: 0,
    contentPageSearchResult: undefined,
    hasMore: false,
    query: {
        searchPhrase: '',
        offset: 0,
        limit: PAGES_PAGE_SIZE,
    },
};
export const updateCombinedSearcContentPageUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.combinedSearch.searchPhrase;
    const newPath = getCombinedSearchContentPageUrl(searchPhrase);
    const currentPath = getRelativeUrlFromLocation(location);
    if (location.pathname === COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH && newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
const getCombinedSearchContentPageUrl = (searchPhrase) => {
    const queryParams = QueryString.stringify({
        [COMBINED_SEARCH_ALL_SEARCHPHRASE]: searchPhrase || undefined,
    });
    return `${COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH}?${queryParams}`;
};
export const ContentPageSlice = createSlice({
    name: 'contentPages',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setCombinedSearchState, (state, action) => {
            const { searchResult, searchPhrase } = action.payload;
            state.offset = 0;
            state.searchResultOffset = 0;
            state.contentPageSearchResult = searchResult.hits.contentPages;
            state.hasMore = state.contentPageSearchResult.length === PAGES_PAGE_SIZE;
            state.query = {
                searchPhrase,
                offset: 0,
                limit: PAGES_PAGE_SIZE,
            };
        });
        builder.addCase(initializeSearchPhrase, (state, action) => {
            state.query.searchPhrase = action.payload;
        });
    },
    reducers: {
        incrementContentPageOffset: (state, _action) => {
            state.offset = state.offset + PAGES_PAGE_SIZE;
            state.query.offset = state.offset;
        },
        setContentPagesSearchResult: (state, action) => {
            const { pages } = action.payload;
            if (!state.contentPageSearchResult || !action.payload) {
                state.searchResultOffset = state.offset;
                state.contentPageSearchResult = pages;
            }
            else if (state.searchResultOffset < state.offset) {
                state.searchResultOffset = state.offset;
                state.contentPageSearchResult = state.contentPageSearchResult.concat(pages);
            }
            state.hasMore = state.contentPageSearchResult.length === state.offset + PAGES_PAGE_SIZE;
        },
    },
});
export const { reducer } = ContentPageSlice;
export const { incrementContentPageOffset, setContentPagesSearchResult } = ContentPageSlice.actions;
