import React from 'react';
import { NavLink } from 'react-router-dom';
import { SwipeContainer, SwipeItem } from './SwipeVariant.js';
import { combineClassNames } from '../util/css/classNames.js';
import { isPartOfPath } from '../util/strings.js';
import style from './FilterBar.scss';
export var FilterBarMode;
(function (FilterBarMode) {
    FilterBarMode[FilterBarMode["filterbar"] = 0] = "filterbar";
    FilterBarMode[FilterBarMode["navbar"] = 1] = "navbar";
})(FilterBarMode || (FilterBarMode = {}));
export function FilterBar(props) {
    const subLinks = props.filters.map((filter, index) => (isPartOfPath(filter.to, props.pathName) && filter.subItems
        ? React.createElement("nav", { key: `subnav${index}`, className: combineClassNames(style.filters, style.subFilterLine) }, renderLinks(filter.subItems, props.disableActiveStyle || false, props.hasHero || false))
        : undefined));
    return (React.createElement("div", { className: combineClassNames(style.filterBar, props.className, props.mode === FilterBarMode.navbar && style.navbarWidth) },
        props.mode === FilterBarMode.navbar && (React.createElement("nav", { className: style.showInMobile },
            React.createElement(SwipeContainer, null, props.filters.map((item) => (React.createElement(SwipeItem, { key: item.caption, to: item.to }, item.caption)))))),
        React.createElement("nav", { key: "mainNav", className: combineClassNames(style.filters, props.mode === FilterBarMode.navbar && style.navbar, props.mode === FilterBarMode.navbar && style.hideInMobile) }, renderLinks(props.filters, props.disableActiveStyle || false, props.hasHero || false)),
        subLinks));
}
function renderLinks(filters, disableActiveStyle, hasHero) {
    return filters.map((filter) => (React.createElement(NavLink, { "data-pw": filter.caption, key: filter.caption, to: filter.to, className: combineClassNames(style.quickFilterBase, hasHero ? style.quickFilterWithHero : style.quickFilter), activeClassName: disableActiveStyle ? undefined : hasHero ? style.activeWithHero : style.active, exact: filter.onlyActiveOnIndex }, filter.caption)));
}
