import React, { useMemo, useState } from 'react';
import { locationListPageUrls, wholesaleListPageUrls } from '../../common/urlPaths.js';
import { TextWithIcon } from './TextWithIcon.js';
import { translations } from '../staticData.js';
import { Icon } from '../Icon.js';
import { combineClassNames } from '../util/css/classNames.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './ContactCard.scss';
import { Button } from './Button.js';
export const ContactCard = ({ contact, language, linkText }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };
    const commonProps = {
        language,
        isExpanded,
        onToggleExpanded: handleToggleExpanded,
    };
    switch (contact.contentType) {
        case 'wholesale':
            return React.createElement(WholesaleCard, Object.assign({ data: contact, linkText: linkText }, commonProps));
        case 'salesPerson':
            return React.createElement(SalesPersonCard, Object.assign({ data: contact }, commonProps));
        case 'customerService':
            return React.createElement(CustomerServiceCard, Object.assign({ data: contact }, commonProps));
        case 'wholesalePerson':
            return React.createElement(WholesalePersonCard, Object.assign({ data: contact }, commonProps));
        case 'location':
            return React.createElement(LocationCard, Object.assign({ data: contact, linkText: linkText }, commonProps));
        default:
            return React.createElement(React.Fragment, null);
    }
};
const Header = ({ name, title, icon, iconHideInMobile, isExpanded, onToggleExpanded, extraContentOnLeft, locationIcon }) => (React.createElement("header", { className: style.header, onClick: onToggleExpanded },
    React.createElement("div", { className: style.headerContent },
        React.createElement("div", { className: style.left },
            React.createElement("h3", null,
                React.createElement("span", { className: style.title }, title),
                React.createElement("span", { className: style.name }, name)),
            extraContentOnLeft),
        React.createElement("div", { className: style.right },
            locationIcon ?
                React.createElement("div", { className: combineClassNames(style.icon, iconHideInMobile && style.iconHideInMobile) },
                    React.createElement("img", { src: locationIcon, height: 20, width: 20 }))
                : icon ?
                    React.createElement("div", { className: combineClassNames(style.icon, iconHideInMobile && style.iconHideInMobile) }, icon)
                    :
                        React.createElement(React.Fragment, null),
            React.createElement(Icon, { className: style.expandIcon, type: isExpanded ? 'chevron-up' : 'chevron-down', size: 30 })))));
const CustomerServiceCard = ({ data, isExpanded, onToggleExpanded }) => {
    const { name, email, phone } = data;
    return (React.createElement("article", { className: style.contactCard, "data-pw": "customerContactCard" },
        React.createElement(Header, { name: name, title: "Asiakaspalvelu", isExpanded: isExpanded, onToggleExpanded: onToggleExpanded }),
        React.createElement("section", { className: combineClassNames(style.content, style.contactPerson), "aria-expanded": isExpanded },
            React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-mail" },
                React.createElement("a", { href: `mailto:${email}` }, email)),
            React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-phone" },
                React.createElement("a", { href: `tel:${phone}` }, phone)))));
};
const WholesaleCard = ({ data, isExpanded, language, linkText, onToggleExpanded }) => {
    const { address, locality, slug } = data;
    const url = `${wholesaleListPageUrls[language]}/${slug}`;
    const header = React.createElement(Header, { name: locality, title: translations[language].common.wholesale, icon: React.createElement(Icon, { type: "icon-storeGT" }), isExpanded: isExpanded, onToggleExpanded: onToggleExpanded });
    return (React.createElement(WholesaleLocationCard, { header: header, address: address, url: url, linkText: linkText, isExpanded: isExpanded }));
};
const emailButtonProps = {
    'data-pw': 'emailButton',
    classNameButtonContent: style.emailButtonContent,
    className: style.emailButton,
    size: "small" /* ButtonSize.Small */,
    buttonType: "tertiary" /* ButtonVariant.Tertiary */,
    type: 'Button',
    isExternalLink: false,
    text: 'Näytä sähköpostiosoite',
};
const WholesalePersonCard = ({ data, isExpanded, onToggleExpanded }) => {
    const [showEmail, setShowEmail] = useState(false);
    const { businessTitle, name, email, phone } = data;
    const decodedEmail = useMemo(() => {
        try {
            return atob(email);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to decode email: ', e);
            return email;
        }
    }, [email]);
    return (React.createElement("article", { className: style.contactCard },
        React.createElement(Header, { name: name, title: businessTitle, icon: React.createElement(Icon, { type: "icon-userNormal" }), iconHideInMobile: true, isExpanded: isExpanded, onToggleExpanded: onToggleExpanded }),
        React.createElement("section", { className: combineClassNames(style.content, style.contactPersonContent), "aria-expanded": isExpanded },
            React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-phone", href: `tel:${phone}` }, phone),
            !showEmail && decodedEmail.length > 0 &&
                React.createElement(Button, Object.assign({}, emailButtonProps, { onClickAction: () => setShowEmail(!showEmail) })),
            showEmail && decodedEmail.length > 0 &&
                React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-mail", href: `mailto:${decodedEmail}` }, decodedEmail))));
};
const LocationCard = ({ data, isExpanded, language, linkText, onToggleExpanded }) => {
    const { locality, address, icon, slug, type } = data;
    const url = `${locationListPageUrls[language]}/${slug}`;
    const header = React.createElement(Header, { name: locality, title: type, iconHideInMobile: true, isExpanded: isExpanded, onToggleExpanded: onToggleExpanded, locationIcon: icon });
    return (React.createElement(WholesaleLocationCard, { header: header, address: address, url: url, linkText: linkText, isExpanded: isExpanded }));
};
const WholesaleLocationCard = ({ header, address, url, linkText, isExpanded }) => (React.createElement("article", { className: combineClassNames(style.wholesaleLocationCard, style.contactCard), "data-pw": "wholesaleContactCard" },
    header,
    React.createElement("section", { className: combineClassNames(style.content, style.desktopOnly) },
        React.createElement(TextWithIcon, { icon: "icon-location" }, address),
        React.createElement(TextWithIcon, { className: combineClassNames(style.link, style.linkInDesktop), icon: "chevron-right", href: url, reversed: true }, linkText),
        React.createElement(LinkWithScroll, { className: combineClassNames(style.wholesaleLocationCard, style.linkToFillContainer), to: url, "aria-hidden": true, tabIndex: -1 })),
    React.createElement("section", { className: combineClassNames(style.content, style.mobileOnly), "aria-expanded": isExpanded },
        React.createElement(TextWithIcon, { icon: "icon-location" }, address),
        React.createElement(TextWithIcon, { className: style.link, icon: "chevron-right", reversed: true },
            React.createElement(LinkWithScroll, { className: combineClassNames(style.wholesaleLocationCard), to: url }, linkText)))));
const SalesPersonCard = ({ data, isExpanded, language, onToggleExpanded }) => {
    const [showEmail, setShowEmail] = useState(false);
    const { businessTitle, name, email, salesAreas, salesType, phone } = data;
    const decodedEmail = useMemo(() => {
        try {
            return atob(email);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to decode email: ', e);
            return email;
        }
    }, [email]);
    return (React.createElement("article", { className: style.contactCard, "data-pw": "salesPersonContactCard" },
        React.createElement(Header, { name: name, title: businessTitle || '', icon: React.createElement(Icon, { type: "icon-userNormal" }), iconHideInMobile: true, isExpanded: isExpanded, onToggleExpanded: onToggleExpanded, extraContentOnLeft: React.createElement(SalesType, { text: translations[language].contactSalesType[salesType] }) }),
        React.createElement("section", { className: combineClassNames(style.content, style.contactPersonContent), "aria-expanded": isExpanded },
            salesAreas.length > 0 && (React.createElement(TextWithIcon, { icon: "icon-location" }, salesAreas.map((area) => area.name).join(', '))),
            React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-phone", href: `tel:${phone}` }, phone),
            !showEmail && decodedEmail.length > 0 &&
                React.createElement(Button, Object.assign({}, emailButtonProps, { onClickAction: () => setShowEmail(!showEmail) })),
            showEmail && decodedEmail.length > 0 &&
                React.createElement(TextWithIcon, { className: style.linkColor, icon: "icon-mail", href: `mailto:${decodedEmail}` }, decodedEmail))));
};
const SalesType = ({ text }) => {
    if (text) {
        return React.createElement("small", { className: style.salesType }, text);
    }
    else {
        return React.createElement("small", { "aria-hidden": "true", className: combineClassNames(style.salesType, style.salesTypehidden) }, "\u2002");
    }
};
