import React from 'react';
import { Icon } from '../Icon.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './ChipsFilter.scss';
export const ChipsFilter = ({ className, name, onSelected, selected, options, size }) => (React.createElement("div", { className: combineClassNames(styles.container, className), "data-pw": "chipsFilter" }, options.map((option) => (React.createElement(Chip, { key: option.id, name: name, icon: option.icon, label: option.label, onClick: () => {
        onSelected(option.id);
    }, selected: selected[option.id], value: option.id, size: size })))));
const Chip = ({ name, label, value, onClick, selected, icon, size }) => (React.createElement("label", { className: combineClassNames(styles.chip, size === 'lg' && styles.chipLarge), "data-selected": selected },
    icon && React.createElement(Icon, { type: icon, size: 30 }),
    React.createElement("input", { type: "checkbox", name: name, value: value, checked: !!selected, onChange: onClick }),
    label));
