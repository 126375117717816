import React from 'react';
import { Helmet } from 'react-helmet';
export const PreloadAsset = ({ images, gtin }) => {
    var _a;
    if (!images || !images[gtin !== null && gtin !== void 0 ? gtin : '']) {
        return null;
    }
    const { primary, additionals } = images[gtin !== null && gtin !== void 0 ? gtin : ''] || {};
    const carouselMainImageUrl = (primary === null || primary === void 0 ? void 0 : primary.url) || ((_a = additionals === null || additionals === void 0 ? void 0 : additionals[0]) === null || _a === void 0 ? void 0 : _a.url) || null;
    if (!carouselMainImageUrl) {
        return;
    }
    const imageSrcset = `${carouselMainImageUrl}?h=300&auto=format 1x, ${carouselMainImageUrl}?h=450&auto=format 1.5x, ${carouselMainImageUrl}?h=600&auto=format 2x, ${carouselMainImageUrl}?h=750&auto=format 2.5x, ${carouselMainImageUrl}?h=900&auto=format 3x`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("link", { rel: "preload", as: "image", href: carouselMainImageUrl, imageSrcSet: imageSrcset }))));
};
