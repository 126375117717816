import React from 'react';
import { Icon } from '../Icon.js';
import { combineClassNames } from '../util/css/classNames.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './TextWithIcon.scss';
export const TextWithIcon = ({ className, icon, children, href, reversed }) => {
    const combinedClassNames = combineClassNames(style.textWithIcon, className, reversed && style.reversed);
    const isInternal = href === null || href === void 0 ? void 0 : href.startsWith('/');
    if (href) {
        if (isInternal) {
            return (React.createElement(LinkWithScroll, { to: href, rel: "noopener noreferrer", className: combinedClassNames },
                React.createElement(Icon, { type: icon }),
                children));
        }
        return (React.createElement("a", { href: href, rel: "noopener noreferrer", className: combinedClassNames },
            React.createElement(Icon, { type: icon }),
            children));
    }
    return (React.createElement("div", { className: combinedClassNames },
        React.createElement(Icon, { type: icon }),
        children));
};
