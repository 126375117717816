export var ContactType;
(function (ContactType) {
    ContactType["All"] = "kaikki";
    ContactType["Noutotukut"] = "noutotukut";
    ContactType["Asiakaspalvelu"] = "asiakaspalvelu";
    ContactType["Sales"] = "myynti";
})(ContactType || (ContactType = {}));
const typeFilterOptions = {
    fi: {
        noutotukut: 'Noutotukut',
        erikoistukku: 'Erikoistukku',
        horeca: 'HoReCa',
        ketjumyynti: 'Ketjumyynti',
        julkishallinto: 'Julkishallinto',
        jälleenmyynti: 'Jälleenmyynti',
        palvelut: 'Palvelut',
    },
    en: {
        noutotukut: 'Wholesales',
        erikoistukku: 'Specialty wholesale',
        horeca: 'HoReCa',
        ketjumyynti: 'Restaurant chains',
        julkishallinto: 'Public operators',
        jälleenmyynti: 'Resales',
        palvelut: 'Services',
    },
    sv: {
        noutotukut: 'Grossistbutiker',
        erikoistukku: 'Specialgrossist',
        horeca: 'HoReCa',
        ketjumyynti: 'Restaurangkedjor',
        julkishallinto: 'Offentlig förvaltning',
        jälleenmyynti: 'Återförsäljning',
        palvelut: 'Tjänster',
    },
    ru: {
        noutotukut: '',
        erikoistukku: '',
        horeca: '',
        ketjumyynti: '',
        julkishallinto: '',
        jälleenmyynti: '',
        palvelut: '',
    },
};
const generateFilterQueryParamValueMap = (filterOptions) => {
    const result = {
        fi: { toDisplay: {}, toId: {} },
        en: { toDisplay: {}, toId: {} },
        sv: { toDisplay: {}, toId: {} },
        ru: { toDisplay: {}, toId: {} },
    };
    for (const [languageString, entry] of Object.entries(filterOptions)) {
        const language = languageString;
        for (const [key, value] of Object.entries(entry)) {
            const localizedKey = value.toLowerCase().replace(/ /g, '-');
            result[language].toDisplay[key] = localizedKey;
            result[language].toId[localizedKey] = key;
        }
    }
    return result;
};
export const typeFilterQueryParamValueMap = generateFilterQueryParamValueMap(typeFilterOptions);
export const getLocalizedTypeFilterOptions = (language) => {
    const typeFilterI18nTexts = typeFilterOptions[language];
    const ids = ['noutotukut', 'horeca', 'ketjumyynti', 'julkishallinto', 'jälleenmyynti', 'erikoistukku', 'palvelut'];
    return ids.map((id) => ({ id, label: typeFilterI18nTexts[id] || 'TranslationNotFound' }));
};
const regionFilterOptions = {
    fi: {
        'etelä-suomi': 'Etelä-Suomi',
        'itä-suomi': 'Itä-Suomi',
        'keski-suomi': 'Keski-Suomi',
        'länsi-suomi': 'Länsi-Suomi',
        'pohjois-suomi': 'Pohjois-Suomi',
    },
    en: {
        'etelä-suomi': 'Southern Finland',
        'itä-suomi': 'Eastern Finland',
        'keski-suomi': 'Central Finland',
        'länsi-suomi': 'Western Finland',
        'pohjois-suomi': 'Northern Finland',
    },
    sv: {
        'etelä-suomi': 'Södra Finland',
        'itä-suomi': 'Östra Finland',
        'keski-suomi': 'Inre Finland',
        'länsi-suomi': 'Västra Finland',
        'pohjois-suomi': 'Norra Finland',
    },
    ru: {
        'etelä-suomi': '',
        'itä-suomi': '',
        'keski-suomi': '',
        'länsi-suomi': '',
        'pohjois-suomi': '',
    },
};
export const regionFilterQueryParamValueMap = generateFilterQueryParamValueMap(regionFilterOptions);
export const getLocalizedRegionFilterOptions = (language) => {
    const regionFilterI18nTexts = regionFilterOptions[language];
    const ids = ['etelä-suomi', 'itä-suomi', 'keski-suomi', 'länsi-suomi', 'pohjois-suomi'];
    return ids.map((id) => ({ id, label: regionFilterI18nTexts[id] || 'TranslationNotFound' }));
};
const finnish = {
    contactsNotFound: 'Yhteystietoja ei löytynyt.',
    searchInputPlaceholder: 'Hae yhteystietoja',
    pageTitles: {
        [ContactType.All]: 'Yhteystiedot',
        [ContactType.Noutotukut]: 'Noutotukkujen yhteystiedot',
        [ContactType.Asiakaspalvelu]: 'Asiakaspalvelun yhteystiedot',
        [ContactType.Sales]: 'Myynnin yhteystiedot',
    },
    mainContactListPage: {
        customerServiceHeader: 'Asiakaspalvelu',
        customerServiceOpeningHours: 'Asiakaspalvelumme palvelee arkisin klo 7.30 – 18.00 sekä lauantaisin klo 8.30 – 15.30. Puhelun hinta on matkapuhelu- tai paikallisverkkomaksu.',
        description: 'Me Kesprossa suhtaudumme intohimoisesti HoReCa-alaan. Tavoitteenamme on - ei enempää eikä vähempää kuin - tehdä yhdessä asiakkaidemme kanssa ulkona syömisestä elämyksellisempää, vastuullisempaa ja entistä suositumpaa. Ollaan yhteydessä!',
        searchHeader: 'Myynti ja noutotukut',
    },
    mainHeaderTexts: {
        [ContactType.All]: 'Kaikki yhteystiedot',
        [ContactType.Noutotukut]: 'Noutotukut',
        [ContactType.Asiakaspalvelu]: 'Asiakaspalvelu',
        [ContactType.Sales]: 'Myynti',
    },
    contactList: {
        wholesaleHeader: 'Noutotukut',
        salesHeader: 'Myynti',
        locationsHeader: 'Muut sijainnit',
    },
    customerServiceBox: {
        frequentlyAskedQuestionsLabel: 'usein kysyttyä',
        frequentlyAskedQuestionsValue: 'Avaa kysymykset',
        emailLabel: 'sähköposti',
        phoneLabel: 'puhelin',
    },
};
const english = {
    contactsNotFound: 'Contacts could not be found.',
    searchInputPlaceholder: 'Search for contact information',
    pageTitles: {
        [ContactType.All]: 'Contact information',
        [ContactType.Noutotukut]: 'Wholesales',
        [ContactType.Asiakaspalvelu]: 'Customer service',
        [ContactType.Sales]: 'Sales',
    },
    mainContactListPage: {
        customerServiceHeader: 'Customer service',
        customerServiceOpeningHours: 'Customer service in English is available Monday to Friday from 8am to 3.30pm. A call costs mobile call charge or local network charge.',
        description: 'Kespro\'s food wholesale offers a wide range of high-quality products with reliable delivery and competitive pricing, as well as an unmatched service attitude. We provide service in person and in digital channels.',
        searchHeader: 'Sales and stores',
    },
    mainHeaderTexts: {
        [ContactType.All]: 'Contact information',
        [ContactType.Noutotukut]: 'Wholesales',
        [ContactType.Asiakaspalvelu]: 'Customer service',
        [ContactType.Sales]: 'Sales',
    },
    contactList: {
        wholesaleHeader: 'Wholesales',
        salesHeader: 'Sales',
        locationsHeader: 'Other locations',
    },
    customerServiceBox: {
        frequentlyAskedQuestionsLabel: 'Frequently asked questions',
        frequentlyAskedQuestionsValue: 'Open',
        emailLabel: 'Email',
        phoneLabel: 'Phone',
    },
};
const swedish = {
    contactsNotFound: 'Kontakter kunde inte hittas.',
    searchInputPlaceholder: 'Sök kontaktinformation',
    pageTitles: {
        [ContactType.All]: 'Kontakt information',
        [ContactType.Noutotukut]: 'Grossistbutiker',
        [ContactType.Asiakaspalvelu]: 'Kundtjänst',
        [ContactType.Sales]: 'Försäljning',
    },
    mainContactListPage: {
        customerServiceHeader: 'Kundtjänst',
        customerServiceOpeningHours: 'Kundtjänst på svenska betjänar mondag-fredag från klockan 7.30-15.30. En samtal kostar lokalnätsavgift eller mobilsamtalsavgift.',
        description: 'Matgrossisten erbjuder ett brett, högkvalitativt, och konkurrenskraftigt prissatt grossistutbud, med tillförlitlig leverans – utöver en ojämförlig servicenivå. Hos oss kan du få service både personligen och via digitala kanaler.',
        searchHeader: 'Försäljning och grossistbutiker',
    },
    mainHeaderTexts: {
        [ContactType.All]: 'Kontakt information',
        [ContactType.Noutotukut]: 'Grossistbutiker',
        [ContactType.Asiakaspalvelu]: 'Kundtjänst',
        [ContactType.Sales]: 'Försäljning',
    },
    contactList: {
        wholesaleHeader: 'Grossistbutiker',
        salesHeader: 'Försäljning',
        locationsHeader: 'Andra platser',
    },
    customerServiceBox: {
        frequentlyAskedQuestionsLabel: 'Vanliga frågor',
        frequentlyAskedQuestionsValue: 'Öppna',
        emailLabel: 'E-post',
        phoneLabel: 'Telefon',
    },
};
const russian = english;
export const i18nTexts = {
    fi: finnish,
    en: english,
    sv: swedish,
    ru: russian,
};
