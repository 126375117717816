import React, { useState } from 'react';
import * as R from 'ramda';
import { Dropdown } from './Dropdown.js';
import { RadioTree } from './RadioTree.js';
export const ProductCategoryDropdown = ({ categoryHierarchy, selectedCategory, productCategoryHitCounts, onCategorySelected, }) => {
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const mainCategories = pruneByCategoryHits(productCategoryHitCounts)(categoryHierarchy).children;
    const treeCategories = [categoryHierarchy].concat(mainCategories);
    const handleCategorySelected = (category) => {
        setIsDropdownOpen(false);
        onCategorySelected(category);
    };
    const handleCategoryExpanded = (categoryId) => {
        const isCategoryOpen = expandedCategories.some((expandedId) => expandedId === categoryId);
        if (isCategoryOpen) {
            const updatedExpandedCategories = expandedCategories.filter((expandedId) => expandedId !== categoryId);
            setExpandedCategories(updatedExpandedCategories);
        }
        else {
            setExpandedCategories([...expandedCategories, categoryId]);
        }
    };
    return (React.createElement(Dropdown, { label: `Tuotekategoria: ${getCategoryLabel(selectedCategory, categoryHierarchy)}`, isOpen: isDropdownOpen, setIsOpen: setIsDropdownOpen },
        React.createElement(RadioTree, { items: treeCategories, selectedItemId: selectedCategory ? selectedCategory.category.id : categoryHierarchy.category.id, selectItem: handleCategorySelected, expandedItems: expandedCategories, toggleItemExpanded: handleCategoryExpanded, getId: (item) => item.category.id, getLabel: (item) => getCategoryLabel(item, categoryHierarchy, productCategoryHitCounts), getChildren: (item) => item.children || [], isLeaf: (item) => item === categoryHierarchy || item.category.level === 4 })));
    function pruneByCategoryHits(hits) {
        const isMatchedCategory = (child) => child.category.level <= 2 ||
            R.includes(child.category.id, hits.map((h) => h.categoryId));
        const notMainOrHasChildren = (child) => child.category.level >= 3 || child.children.length > 0;
        return (category) => {
            const children = category.children
                .filter(isMatchedCategory)
                .map(pruneByCategoryHits(hits))
                .filter(notMainOrHasChildren);
            return Object.assign(Object.assign({}, category), { children });
        };
    }
    function getCategoryLabel(category, root, hits) {
        const isSelectedButNotRoot = category !== undefined && category.category.id !== root.category.id;
        const name = isSelectedButNotRoot ? category.category.name : 'Kaikki';
        const count = isSelectedButNotRoot && hits ? getCategoryHitCount(hits)(category) : undefined;
        return `${name} ${count ? `(${count})` : ''}`;
    }
    function getCategoryHitCount(hits) {
        return (category) => {
            if (category.category.level >= 3) {
                const hit = R.find((h) => h.categoryId === category.category.id, hits);
                return hit ? hit.hits : 0;
            }
            else {
                return R.sum(category.children.map(getCategoryHitCount(hits)));
            }
        };
    }
};
