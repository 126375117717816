import React from 'react';
import { VerticalContainer } from '../FlexboxContainer.js';
import inputStyles from '../Input/style.scss';
import styles from './ValidationErrors.scss';
export const ValidationErrors = (props) => {
    const { errors, strings, language } = props;
    if (errors === undefined || errors.length === 0) {
        return React.createElement("div", null);
    }
    else {
        const validationErrorElems = errors.map((error, index) => React.createElement("div", { key: `error-${index}`, className: inputStyles.invalid }, error.message[language]));
        return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: styles.validationErrors },
            React.createElement("h4", null, strings.checkThese),
            validationErrorElems));
    }
};
