export const HOME_PAGE_UPPER_CARDS = 3;
export const HOME_PAGE_LOWER_CARDS = 9;
export const IDEA_INSPIRATION_CARDS_DEFAULT_LIMIT = 9;
export const IDEA_INSPIRATION_PAGE_SIZE = 18;
export const PRODUCT_PAGE_SIZE = 48;
export const PRODUCT_BRAND_PRODUCTS_PAGE_SIZE = 50;
export const RECIPE_PAGE_SIZE = 20;
export const ARTICLE_PAGE_SIZE = 20;
export const PAGES_PAGE_SIZE = 20;
export const AUTO_SEARCH_DEBOUNCE_MS = 500;
export const RECIPE_FALLBACK_HERO_IMAGE = 'https://images.ctfassets.net/yqsam7930hsq/1KvvW78xmo2UKMEy4qMcOw/af5ec2cd5e74fdbd24530b3bb41ce102/resepti_fallback_default.jpg';
export const FALLBACK_HERO_WIDTH = 1126;
export const FALLBACK_HERO_HEIGHT = 743;
export const COMBINED_SEARCH_HERO_IMAGE = RECIPE_FALLBACK_HERO_IMAGE;
export const DEFAULT_RECIPE_SEO_RESPONSE = { title: '', slug: '', metaDescription: '', seoText: '' };
export const CRM_FORM_ERROR_KEY = 'CRM_form';
// The form ids must be prefixed and not used in urls.
// The distinctness need to be ensured so that form capture is able to find them.
export const REGISTRATION_FORM_ID = 'form__contact-request';
export const CONTACT_FORM_ID = 'form__contact';
export const NOT_FOUND_CONTENTFUL_PAGE_SLUG = '404';
export const INTOHIMONYT_MARKETING_SLUG = '/intohimonyt';
