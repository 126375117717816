var _a;
import * as rtk from '@reduxjs/toolkit';
const { configureStore } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
// Weird import, check reason: https://github.com/reduxjs/redux-toolkit/issues/1960
import { reducer as authReducer } from './reducers/authSlice.js';
import { reducer as contactsReducer } from './reducers/contactsSlice.js';
import { reducer as productBrandReducer, selectCategory as productBrandSelectCategory } from './reducers/productBrandSlice.js';
import { reducer as productsReducer, selectCategory, setProductsSearchState } from './reducers/productsSlice.js';
import { reducer as commonContactFormReducer } from './reducers/commonContactFormSlice.js';
import { reducer as registrationFormReducer } from './reducers/registrationFormSlice.js';
import { reducer as categoryReducer } from './reducers/categorySlice.js';
import { reducer as urlReducer } from './reducers/urlSlice.js';
import { reducer as i18nReducer } from './reducers/i18nSlice.js';
import { reducer as ideaInspirationReducer } from './reducers/ideaInspirationSlice.js';
import { reducer as combinedSearchReducer } from './reducers/combinedSearchSlice.js';
import { reducer as combinedSearchArticleReducer } from './reducers/combinedSearchArticleSlice.js';
import { reducer as articlesByTagReducer } from './reducers/articlesByTagSlice.js';
import { reducer as combinedSearchContentPageReducer } from './reducers/combinedSearchContentPageSlice.js';
import { reducer as combinedSearchContactsReducer } from './reducers/combinedSearchContactSlice.js';
import { reducer as combinedSearchProductReducer, setCombinedSearchProductState, setSelectedCategory as combinedSearchProductSetCategory } from './reducers/combinedSearchProductSlice.js';
import { reducer as combinedSearchRecipesReducer } from './reducers/combinedSearchRecipeSlice.js';
import { reducer as analyticsReducer } from './reducers/analyticsSlice.js';
import { api } from './services/api.js';
/*
This is almost a normal redux setup. The only difference is that for SSR we have to bring in the loaders/API -calls
dynamically because they are different on the server and on the client. That's why we wrap the store and the reducers in a custom function
*/
const createRootReducer = () => ({
    articlesByTag: articlesByTagReducer,
    auth: authReducer,
    category: categoryReducer,
    contacts: contactsReducer,
    commonContactForm: commonContactFormReducer,
    registrationForm: registrationFormReducer,
    i18n: i18nReducer,
    ideaInspiration: ideaInspirationReducer,
    products: productsReducer,
    combinedSearch: combinedSearchReducer,
    combinedSearchContentPage: combinedSearchContentPageReducer,
    combinedSearchArticle: combinedSearchArticleReducer,
    combinedSearchContacts: combinedSearchContactsReducer,
    combinedSearchRecipe: combinedSearchRecipesReducer,
    combinedSearchProduct: combinedSearchProductReducer,
    productBrand: productBrandReducer,
    url: urlReducer,
    [api.reducerPath]: api.reducer,
    analytics: analyticsReducer,
});
const pathsToNonSerializableStateProperties = [
    'category.hierarchy',
    'combinedSearchProduct.selectedCategory',
    'products.selectedCategory',
    'productBrand.selectedCategory',
];
const actionsWithNonSerializableValues = [
    selectCategory.type,
    setProductsSearchState.type,
    productBrandSelectCategory.type,
    combinedSearchProductSetCategory.type,
    setCombinedSearchProductState.type,
];
export function createStore(preloadedState) {
    return configureStore({
        reducer: createRootReducer(),
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: pathsToNonSerializableStateProperties,
                ignoredActions: actionsWithNonSerializableValues,
            },
            immutableCheck: {
                ignoredPaths: pathsToNonSerializableStateProperties,
            },
        }).concat([
            api.middleware,
        ]),
    });
}
