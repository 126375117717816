import { useGetRecipesQuery } from '../services/api.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { useEffect } from 'react';
import { setRecipeSearchResult } from '../reducers/combinedSearchRecipeSlice.js';
import { shallowEqual } from 'react-redux';
export const useCombinedSearchRecipeQuery = () => {
    const dispatch = useAppDispatch();
    const { recipeSearchResult, query } = useAppSelector((state) => ({
        recipeSearchResult: state.combinedSearchRecipe.recipeSearchResult,
        query: state.combinedSearchRecipe.query,
    }), shallowEqual);
    const { data, isFetching, isError } = useGetRecipesQuery(query, { skip: query.view.offset === 0 });
    useEffect(() => {
        if (data) {
            dispatch(setRecipeSearchResult(data));
        }
    }, [data]);
    return {
        data: recipeSearchResult || [],
        isError,
        isFetching,
    };
};
