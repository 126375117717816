import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../hooks.js';
import { selectCategoryWithUpdates } from '../reducers/productsSlice.js';
import { useGetCategoryHierarchyQuery } from '../services/api.js';
import { ProductCategoryFilter } from './ProductCategoryFilter.js';
import { combineClassNames } from '../util/css/classNames.js';
import { breakPoint } from '../util/image.js';
import style from './CategoryFilter.scss';
export const CategoryFilter = (props) => {
    useGetCategoryHierarchyQuery();
    const dispatch = useAppDispatch();
    const { push, replace } = useHistory();
    const { hierarchy, categoryHits, selectedCategoryState, totalHits } = useAppSelector((state) => {
        var _a, _b;
        return ({
            hierarchy: state.category.hierarchy,
            categoryHits: (_a = state.products.productSearchResult) === null || _a === void 0 ? void 0 : _a.categoryHits,
            totalHits: (_b = state.products.productSearchResult) === null || _b === void 0 ? void 0 : _b.totalHits,
            selectedCategoryState: state.products.selectedCategory,
        });
    }, shallowEqual);
    const [categoryFiltersOpen, setCategoryFiltersOpen] = useState(false);
    useEffect(() => {
        if (window) {
            // when first loading the page, close the filters in mobile view and open them in desktop view
            setCategoryFiltersOpen(window.innerWidth > breakPoint.TABLET);
        }
    }, []);
    if (!hierarchy || !categoryHits) {
        return React.createElement(React.Fragment, null);
    }
    const selectedCategory = selectedCategoryState || hierarchy;
    const selectCategory = (cat) => { dispatch(selectCategoryWithUpdates(cat, props.location, push, replace)); };
    return (React.createElement("div", { id: props.id, className: combineClassNames(style.productSearchResults__filters__container, props.id && props.id === 'sideFilter' ?
            style.sideCategory : style.filterUnderSearchBar) },
        React.createElement(ProductCategoryFilter, { categoryHierarchy: hierarchy, productCategoryHitCounts: categoryHits, totalHits: totalHits !== null && totalHits !== void 0 ? totalHits : 0, selectCategory: (category) => {
                selectCategory(category);
            }, selectedCategory: selectedCategory, isDropdownOpen: categoryFiltersOpen, onDropdownToggled: () => {
                setCategoryFiltersOpen(!categoryFiltersOpen);
            } })));
};
