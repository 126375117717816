import React from 'react';
import * as R from 'ramda';
import { VerticalContainer } from '../FlexboxContainer.js';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './DigitalServiceCard.scss';
export const DigitalServicesCard = (props) => {
    const { digitalServiceInfo, cardCount } = props;
    const cardCountClass = R.cond([
        [R.equals(3), () => styles.threeCards],
        [R.equals(2), () => styles.twoCards],
        [R.equals(1), () => styles.oneCard],
        [R.T, () => styles.card],
    ])(cardCount);
    return (React.createElement("div", { className: cardCountClass, title: digitalServiceInfo.Description },
        React.createElement("a", { href: digitalServiceInfo.Url, target: "_blank", rel: "noopener noreferrer" },
            React.createElement(VerticalContainer, { className: styles.contentContainer },
                React.createElement("img", { className: styles.logo, src: digitalServiceInfo.Icon, alt: digitalServiceInfo.Description, width: 42, height: 42 }),
                React.createElement("h3", { className: styles.title }, digitalServiceInfo.Title)))));
};
export const DigitalServicesCardSkeleton = (props) => {
    const cardCountClass = R.cond([
        [R.equals(3), () => styles.threeCards],
        [R.equals(2), () => styles.twoCards],
        [R.equals(1), () => styles.oneCard],
        [R.T, () => styles.card],
    ])(props.cardCount);
    return (React.createElement("div", { className: cardCountClass },
        React.createElement(VerticalContainer, { className: styles.contentContainer },
            React.createElement("div", { className: combineClassNames(styles.logo, styles.loading) }),
            React.createElement("div", { className: combineClassNames(styles.title, styles.loading) }, "\u00A0"))));
};
