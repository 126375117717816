import React from 'react';
import * as R from 'ramda';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { slugifyBrand } from '@kesko/kespro-product-service-api';
import { EmbeddedContentConsentRequiredHtml } from '../EmbeddedContentConsentRequired.js';
import { useAppSelector } from '../../hooks.js';
import { mapAnalyticsPropsToDataAttributes } from '../../util/analyticsDataAttributes.js';
import { combineClassNames, getVisibilityClassname } from '../../util/css/classNames.js';
import { getDefaultContentfulImgSrcSet } from '../../util/image.js';
import { htmlDecode } from '../../util/strings.js';
import style from './TextContent.scss';
const renderOptions = (isDisplayedOnNavigationBar, isConsent) => ({
    renderNode: Object.assign(Object.assign({}, (isDisplayedOnNavigationBar && {
        [BLOCKS.HEADING_2]: (node, next) => {
            const text = R.pathOr('', ['content', 0, 'value'])(node);
            const id = slugifyBrand(text);
            return `
                    <div class="${style.scrollMargin}" id="${id}"></div><h2 >${next(node.content)}</h2>
                `;
        },
    })), { [BLOCKS.EMBEDDED_ASSET]: (node, _next) => {
            const alt = node.data.target.fields.description;
            const src = node.data.target.fields.file.url;
            const width = node.data.target.fields.file.details.image.width;
            const height = node.data.target.fields.file.details.image.height;
            const { srcSet, sizes, fallback } = getDefaultContentfulImgSrcSet(src);
            return `<picture>
                <source srcset="${srcSet}" sizes="${sizes}" />
                <img src="${fallback}" alt="${alt}" width="${width}" height="${height}" class="${style.image}" />
            </picture>`;
        }, [BLOCKS.PARAGRAPH]: (node, next) => {
            const isEmptyParagraph = node.content.length === 1 && node.content[0].value.length === 0;
            return isEmptyParagraph ? '' : `<p>${next(node.content)}</p>`;
        } }),
    renderMark: {
        // TODO: Fix this. 'code' should be imported from @contentful/rich-text-types (MARKS.CODE)
        // unfortunately this is defined in a d.ts file which prevents importing
        ['code']: (html) => {
            const decodedHtml = htmlDecode(html);
            if (!isConsent && /<iframe/.test(decodedHtml)) {
                return EmbeddedContentConsentRequiredHtml;
            }
            return decodedHtml;
        },
    },
});
function hasOnlyHeading2(content) {
    var _a;
    if (content.content.length === 1) {
        return content.content[0].nodeType === 'heading-2';
    }
    else if (content.content.length === 2) {
        if (content.content[0].nodeType !== 'heading-2') {
            return false;
        }
        const paragraphCandidate = content.content[1];
        if (paragraphCandidate.nodeType === 'paragraph' &&
            ((_a = paragraphCandidate.content) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
            paragraphCandidate.content[0].nodeType === 'text' &&
            !paragraphCandidate.content[0].value) {
            return true;
        }
    }
    return false;
}
function getTextAlignmentStyle(alignment) {
    switch (alignment) {
        case "Keskitetty" /* TextContentAlignment.centered */:
            return style.centered;
        default:
            return style.left;
    }
}
export const TextContent = ({ content, isDisplayedOnNavigationBar, textAlignment, analyticsOptions, visibility, id, smallerMarginsOnTitles, }) => {
    const analyticsDataAttributes = mapAnalyticsPropsToDataAttributes(analyticsOptions);
    const hasConsent = useAppSelector((state) => state.analytics.hasConsentForEmbeddedContent);
    return (React.createElement("div", Object.assign({ id: id, className: combineClassNames(style.container, hasOnlyHeading2(content) && style.onlyHeading2, smallerMarginsOnTitles && style.smallerMarginsOnTitles, getTextAlignmentStyle(textAlignment), getVisibilityClassname(visibility)), dangerouslySetInnerHTML: {
            __html: documentToHtmlString(content, renderOptions(isDisplayedOnNavigationBar, hasConsent)),
        } }, analyticsDataAttributes)));
};
