import { __rest } from "tslib";
import React, { useState } from 'react';
import * as R from 'ramda';
import { Icon } from '../../Icon.js';
import { HorizontalContainer, VerticalContainer } from '../FlexboxContainer.js';
import { LogIn } from './LogIn.js';
import { AppMenu } from './AppMenu.js';
import { useLocation } from 'react-router-dom';
import { combineClassNames } from '../../util/css/classNames.js';
import { LinkWithScroll, NavLinkWithScroll } from '../../util/scroll.js';
import { isPartOfPath } from '../../util/strings.js';
import styles from './LargeHeader.scss';
export function LargeHeader(props) {
    const { primaryNavigationItems, secondaryNavigationItems, buttonNavigationItems, pathName, language, languageItems, homePageLink, isLoggedIn, } = props;
    const pathItem = R.find((item) => Boolean(item.subItems) && isPartOfPath(item.to, pathName), primaryNavigationItems.concat(secondaryNavigationItems));
    const subLinks = (pathItem && !pathItem.hideDesktopMenuBar) ? pathItem.subItems : [];
    const kesproNetUrl = isLoggedIn ? buttonNavigationItems.goToWebStoreAuthenticated : buttonNavigationItems.goToWebStore;
    return (React.createElement("section", { "data-pw": "header" },
        React.createElement("header", { className: combineClassNames(styles.largeHeader, styles.headerContainer) },
            React.createElement(LinkWithScroll, { className: styles.headerLogo, to: homePageLink, "data-pw": "logo" },
                React.createElement(Icon, { type: "kespro-logo-new", size: { width: 90, height: 42 }, title: "Kespro.com etusivulle" })),
            React.createElement(VerticalContainer, { className: styles.linkContainer, verticalAlign: "space-between" },
                React.createElement(HorizontalContainer, { className: combineClassNames(styles.secondaryLinksContainer, styles.topRow) },
                    secondaryNavigationItems.map(renderSecondaryNavLink),
                    React.createElement(LogIn, { className: styles.secondaryNavItem, isMobile: false, loginLink: buttonNavigationItems.logIn })),
                React.createElement(HorizontalContainer, { className: styles.bottomRow },
                    React.createElement(HorizontalContainer, { horizontalAlign: "flex-start" }, primaryNavigationItems.map((item) => React.createElement(NavLink, { key: item.caption, item: item }))),
                    React.createElement(HorizontalContainer, { className: styles.buttonLinkContainer },
                        React.createElement(LinkWithScroll, { className: styles.searchLink, to: buttonNavigationItems.search.to, "data-pw": "searchPageLink" },
                            React.createElement(Icon, { type: "search-light", size: 20 }),
                            buttonNavigationItems.search.caption),
                        React.createElement(LanguageSelect, { currentLanguage: language, languageItems: languageItems }),
                        React.createElement("a", { className: combineClassNames(styles.linkItem, styles.kespronetLinkItem), href: kesproNetUrl.to, target: "_blank", rel: "noopener noreferrer", "data-pw": "kespronetLink" },
                            React.createElement("div", { className: styles.linkIcon },
                                React.createElement(Icon, { type: "shopping-basket", size: 25 })),
                            React.createElement("p", { className: styles.linkText }, kesproNetUrl.caption)),
                        React.createElement(AppMenu, { className: "globalAppMenuContainerClass", isMobile: false, buttonCaption: buttonNavigationItems.digitalServices.caption },
                            React.createElement(LinkWithScroll, { className: combineClassNames(styles.linkItem, styles.becomeClientLink), to: buttonNavigationItems.becomeClient.to, "data-pw": "becomeClientLink" },
                                React.createElement("p", { className: combineClassNames(styles.becomeClientText, language ? styles[language] : undefined) }, buttonNavigationItems.becomeClient.caption))))))),
        subLinks.length ? (React.createElement("div", { className: styles.headerSubNav, "data-pw": "headerSubNav" }, subLinks.map((item) => (React.createElement(NavLinkWithScroll, { key: item.caption, to: item.to, className: styles.subNavItem, activeClassName: styles.active, exact: item.onlyActiveOnIndex }, item.caption))))) : undefined));
}
function renderSecondaryNavLink(item) {
    return (React.createElement(LinkWithScroll, { key: item.caption, to: item.to, className: styles.secondaryNavItem }, item.caption));
}
const LanguageSelect = ({ currentLanguage, languageItems }) => {
    const location = useLocation();
    const navigationItem = {
        caption: currentLanguage,
        to: location.pathname,
        subItems: languageItems.filter((item) => item.language !== currentLanguage),
    };
    return (React.createElement(NavLink, { className: styles.languageSelect, item: navigationItem, "data-pw": "languageSelect", ignoreActiveStyle: true }));
};
const NavLink = (_a) => {
    var _b;
    var { className, ignoreActiveStyle, item } = _a, dataAttributes = __rest(_a, ["className", "ignoreActiveStyle", "item"]);
    const [forceDropdownClose, setForceDropdownClose] = useState(false);
    const renderSubItems = !!((_b = item.subItems) === null || _b === void 0 ? void 0 : _b.length);
    const activeClassName = ignoreActiveStyle ? undefined : styles.active;
    const hoverClassName = (item.hideDesktopMenuBar && !ignoreActiveStyle) && styles.showDropdown;
    return (React.createElement("div", Object.assign({ key: item.caption, className: combineClassNames(styles.mainNavItemContainer, className) }, dataAttributes),
        React.createElement(NavLinkWithScroll, { to: item.to, className: styles.mainNavItem, activeClassName: combineClassNames(activeClassName, hoverClassName), exact: item.onlyActiveOnIndex, onMouseEnter: () => {
                setForceDropdownClose(false);
            } },
            React.createElement("p", null, item.caption),
            renderSubItems && React.createElement(Icon, { type: "chevron-down", noStyles: true })),
        !forceDropdownClose && renderSubItems && (React.createElement("div", { className: styles.dropDownContainer }, item.subItems.map((subItem) => (React.createElement(NavLinkWithScroll, { key: subItem.caption, to: subItem.to, className: styles.dropDownItem, activeClassName: activeClassName, onClick: () => {
                setForceDropdownClose(true);
            }, exact: subItem.onlyActiveOnIndex },
            React.createElement("p", null, subItem.caption))))))));
};
