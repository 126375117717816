import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BasePage } from './pages/BasePage/index.js';
import { KThemeProvider } from '@kesko/kesko-design-dna';
import { NamifestiPage } from './pages/NamifestiPage/NamifestiPage.js';
import { INTOHIMONYT_MARKETING_SLUG } from '../common/constants.js';
// This has to be in a separate file because this is also imported from server
// and importing main.tsx on server causes the server to crash due to normalize.css
export function AppRoot() {
    return (React.createElement(KThemeProvider, { useGlobalStyles: false },
        React.createElement(Switch, null,
            React.createElement(Route, { path: INTOHIMONYT_MARKETING_SLUG },
                React.createElement(NamifestiPage, null)),
            React.createElement(Route, { path: "/:language(en|sv|ru)?", render: (props) => React.createElement(BasePage, Object.assign({}, props)) }))));
}
