import React from 'react';
import { useGetPersonalisedContentQuery } from '../../services/api.js';
import { DigitalServicesWrapper } from './DigitalServicesWrapper.js';
import { PopularArticles } from './PopularArticles.js';
import { MaintenanceInfoBox } from '../MaintenanceInfoBox.js';
import { useAppSelector } from '../../hooks.js';
import { languageToLocale } from '../../../common/apiTypes.js';
import { EventList } from '../LandingPage/EventList.js';
import { ModularContent } from '../ModularContent.js';
export const PersonalisedContentWrapper = (props) => {
    const personalisedContent = useAppSelector((state) => state.auth.personalisedContent);
    const params = Object.assign(Object.assign({}, personalisedContent), { readSlugs: [], locale: languageToLocale(props.language), preview: props.preview });
    const useQueryResult = useGetPersonalisedContentQuery(params);
    const { data, isLoading } = useQueryResult;
    return (React.createElement("div", null,
        React.createElement(MaintenanceInfoBox, null),
        React.createElement(DigitalServicesWrapper, null),
        (data === null || data === void 0 ? void 0 : data.modularContentTop) &&
            React.createElement(ModularContent, { content: data === null || data === void 0 ? void 0 : data.modularContentTop, region: 'top' }),
        (data === null || data === void 0 ? void 0 : data.nearbyEvents) &&
            React.createElement(EventList, Object.assign({ language: props.language }, data === null || data === void 0 ? void 0 : data.nearbyEvents)),
        React.createElement(PopularArticles, { popularArticles: data === null || data === void 0 ? void 0 : data.popularArticles, isLoading: isLoading })));
};
