var _a, _b;
import { __rest } from "tslib";
import * as rtkQuery from '@reduxjs/toolkit/dist/query/index.js';
const { buildCreateApi, coreModule, fetchBaseQuery } = ((_a = rtkQuery.default) !== null && _a !== void 0 ? _a : rtkQuery);
import * as rtkQueryReact from '@reduxjs/toolkit/dist/query/react/index.js';
const { reactHooksModule } = ((_b = rtkQueryReact.default) !== null && _b !== void 0 ? _b : rtkQueryReact);
import { transformContactsInCombinedSearchResult } from '../util/contactsFilter.js';
const createApi = buildCreateApi(coreModule(), reactHooksModule());
export const baseQuery = fetchBaseQuery({ baseUrl: '/api/' });
export let api = createApi({
    baseQuery,
    endpoints: (builder) => ({
        getContacts: builder.query({
            query: (params) => ({ url: '/contacts', params }),
        }),
        getContactPageDescriptions: builder.query({
            query: (params) => ({ url: '/contact-page-descriptions', params }),
        }),
        getCombinedSearchResults: builder.query({
            query: (args) => ({
                url: 'combined-search',
                method: 'POST',
                body: args,
            }),
            transformResponse: (searchResult, _meta, query) => transformContactsInCombinedSearchResult(searchResult, query),
        }),
        getFooterContacts: builder.query({
            query: (_a) => {
                var { locale } = _a, params = __rest(_a, ["locale"]);
                return ({
                    url: `/footer-contacts/${locale}`,
                    params,
                });
            },
        }),
        getHomePageContent: builder.query({
            query: (params) => ({ url: 'homepage', params }),
        }),
        getIdeaInspirationCards: builder.query({
            query: (params) => ({ url: 'idea-inspiration/cards', params }),
        }),
        getIdeaInspirationHero: builder.query({
            query: (params) => ({
                url: 'idea-inspiration/hero',
                params,
            }),
        }),
        getLegalText: builder.query({
            query: (_a) => {
                var { textSubject } = _a, params = __rest(_a, ["textSubject"]);
                return ({
                    url: `legal-text/${textSubject}`,
                    params,
                });
            },
        }),
        getMagazines: builder.query({
            query: (params) => ({
                url: `magazines`,
                params,
            }),
        }),
        getMagazineIssues: builder.query({
            query: (params) => ({
                url: `magazine-issues`,
                params,
            }),
        }),
        getMaintenanceInfo: builder.query({
            query: (_params) => `interrupts`,
        }),
        getPurchasingContactInfo: builder.query({
            query: (_a) => {
                var { locale } = _a, params = __rest(_a, ["locale"]);
                return ({
                    url: `purchasing-contact-info/${locale}`,
                    params,
                });
            },
        }),
        getRecipe: builder.query({
            query: (query) => ({
                url: `recipes/${query.slug}`,
                params: {
                    preview: query.preview,
                },
            }),
        }),
        getRecipes: builder.query({
            query: (query) => ({
                url: 'recipes/search',
                method: 'POST',
                body: query,
            }),
        }),
        getRecipeSearchSeoState: builder.query({
            query: (slug) => `recipe/${slug}/seo`,
        }),
        getLandingPage: builder.query({
            query: (_a) => {
                var { slug, locale } = _a, params = __rest(_a, ["slug", "locale"]);
                const cleanedSlug = slug.charAt(0) === '/' ? slug.slice(1) : slug;
                return {
                    url: `landing-pages/${locale}/${encodeURIComponent(cleanedSlug)}`,
                    params,
                };
            },
        }),
        getPersonalisedContent: builder.query({
            query: () => ({
                url: `personalised-content`,
            }),
        }),
        getArticle: builder.query({
            query: ({ slug, preview }) => ({
                url: `articles/${slug}`,
                params: {
                    preview,
                },
            }),
        }),
        // TODO: Appears to be unused.
        getArticles: builder.query({
            query: (query) => ({
                url: 'articles',
                params: query,
            }),
        }),
        getArticlesByTag: builder.query({
            query: (query) => ({
                url: `/articles-by-tag`,
                params: query,
            }),
        }),
        getContentPages: builder.query({
            query: (query) => ({
                url: `/content-pages`,
                params: query,
            }),
        }),
        getProductSearchPhraseSuggestions: builder.query({
            query: (query) => ({
                url: `/product-search-phrase-suggestions`,
                params: query,
            }),
        }),
        getPartnerContent: builder.query({
            query: (query) => ({
                url: `/partner-content`,
                params: {
                    partner: query.partner,
                },
            }),
        }),
        getBrandCollection: builder.query({
            query: (_a) => {
                var { slug } = _a, cacheQueryParams = __rest(_a, ["slug"]);
                return ({
                    url: `brand-collection/${slug}`,
                    params: cacheQueryParams,
                });
            },
        }),
        getCategoryHierarchy: builder.query({
            query: () => 'categories/hierarchy',
        }),
        getCategoryHero: builder.query({
            query: (_a) => {
                var { categoryIdPrefix } = _a, cacheQueryParams = __rest(_a, ["categoryIdPrefix"]);
                return ({
                    url: `categories/hero/${categoryIdPrefix}`,
                    params: cacheQueryParams,
                });
            },
        }),
        getProductV2: builder.query({
            query: ({ ean, lang }) => ({
                url: `v2/products/${ean}/${lang}`,
            }),
        }),
        getFrontPageProducts: builder.query({
            query: (params) => ({
                url: 'products/front-page-products',
                params,
            }),
        }),
        getProducts: builder.query({
            query: (query) => ({
                url: 'products/search',
                method: 'POST',
                body: query,
            }),
        }),
        getProductBrandsCollection: builder.query({
            query: (params) => ({
                url: `product-brands`,
                params,
            }),
        }),
        getProductBrandPageContent: builder.query({
            query: (_a) => {
                var { slug } = _a, params = __rest(_a, ["slug"]);
                return ({
                    url: `product-brands/${slug}`,
                    params,
                });
            },
        }),
        getProductBrandPageProducts: builder.query({
            query: ({ slug, offset, limit, categories }) => `product-brands/${slug}/products?categories=${categories}&offset=${offset}&limit=${limit}`,
        }),
    }),
});
export function changeApiExports(newApi) {
    useGetContactsQuery = newApi.useGetContactsQuery;
    useGetContactPageDescriptionsQuery = newApi.useGetContactPageDescriptionsQuery;
    useGetCombinedSearchResultsQuery = newApi.useGetCombinedSearchResultsQuery;
    useLazyGetCombinedSearchResultsQuery = newApi.useLazyGetCombinedSearchResultsQuery;
    useGetArticlesByTagQuery = newApi.useGetArticlesByTagQuery;
    useGetContentPagesQuery = newApi.useGetContentPagesQuery;
    useGetProductSearchPhraseSuggestionsQuery = newApi.useGetProductSearchPhraseSuggestionsQuery;
    useGetPartnerContentQuery = newApi.useGetPartnerContentQuery;
    useGetFooterContactsQuery = newApi.useGetFooterContactsQuery;
    useGetHomePageContentQuery = newApi.useGetHomePageContentQuery;
    useGetIdeaInspirationHeroQuery = newApi.useGetIdeaInspirationHeroQuery;
    useGetIdeaInspirationCardsQuery = newApi.useGetIdeaInspirationCardsQuery;
    useGetLegalTextQuery = newApi.useGetLegalTextQuery;
    useGetMagazinesQuery = newApi.useGetMagazinesQuery;
    useGetMagazineIssuesQuery = newApi.useGetMagazineIssuesQuery;
    useGetMaintenanceInfoQuery = newApi.useGetMaintenanceInfoQuery;
    useGetPurchasingContactInfoQuery = newApi.useGetPurchasingContactInfoQuery;
    useGetRecipeQuery = newApi.useGetRecipeQuery;
    useGetRecipesQuery = newApi.useGetRecipesQuery;
    useGetRecipeSearchSeoStateQuery = newApi.useGetRecipeSearchSeoStateQuery;
    useGetLandingPageQuery = newApi.useGetLandingPageQuery;
    useGetPersonalisedContentQuery = newApi.useGetPersonalisedContentQuery;
    useGetArticleQuery = newApi.useGetArticleQuery;
    useGetBrandCollectionQuery = newApi.useGetBrandCollectionQuery;
    useGetProductV2Query = newApi.useGetProductV2Query;
    useGetCategoryHierarchyQuery = newApi.useGetCategoryHierarchyQuery;
    useGetCategoryHeroQuery = newApi.useGetCategoryHeroQuery;
    useGetFrontPageProductsQuery = newApi.useGetFrontPageProductsQuery;
    useGetProductsQuery = newApi.useGetProductsQuery;
    useLazyGetProductsQuery = newApi.useLazyGetProductsQuery;
    useGetProductBrandsCollectionQuery = newApi.useGetProductBrandsCollectionQuery;
    useGetProductBrandPageContentQuery = newApi.useGetProductBrandPageContentQuery;
    useGetProductBrandPageProductsQuery = newApi.useGetProductBrandPageProductsQuery;
    useGetArticlesQuery = newApi.useGetArticlesQuery;
    api = newApi;
}
export let { useGetContactsQuery, useGetContactPageDescriptionsQuery, useGetCombinedSearchResultsQuery, useLazyGetCombinedSearchResultsQuery, useGetArticlesByTagQuery, useGetContentPagesQuery, useGetProductSearchPhraseSuggestionsQuery, useGetPartnerContentQuery, useGetFooterContactsQuery, useGetHomePageContentQuery, useGetIdeaInspirationHeroQuery, useGetIdeaInspirationCardsQuery, useGetLegalTextQuery, useGetMaintenanceInfoQuery, useGetMagazinesQuery, useGetMagazineIssuesQuery, useGetPurchasingContactInfoQuery, useGetRecipeQuery, useGetRecipesQuery, useGetRecipeSearchSeoStateQuery, useGetLandingPageQuery, useGetPersonalisedContentQuery, useGetArticleQuery, useGetBrandCollectionQuery, 
// Don't use the data directly from useGetCategoryHierarchy as it needs some processing. Access it through Redux category state
useGetCategoryHierarchyQuery, useGetCategoryHeroQuery, useGetProductV2Query, useGetFrontPageProductsQuery, useGetProductsQuery, useLazyGetProductsQuery, useGetProductBrandsCollectionQuery, useGetProductBrandPageContentQuery, useGetProductBrandPageProductsQuery, useGetArticlesQuery, } = api;
