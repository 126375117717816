var _a;
import { findCategoryById } from '@kesko/kespro-ui-common';
import * as rtk from '@reduxjs/toolkit';
import { immerUnserializable } from '../util/redux.js';
import { COMBINED_SEARCH_PRODUCTS_PAGE_PATH } from '../../common/urlPaths.js';
import { setHandledUrl } from './urlSlice.js';
import QueryString from 'query-string';
import { COMBINED_SEARCH_ALL_SEARCHPHRASE, COMBINED_SEARCH_PRODUCTS_BRAND, COMBINED_SEARCH_PRODUCTS_CATEGORY, } from '../../common/queryStrings.js';
import * as R from 'ramda';
import { PRODUCT_PAGE_SIZE } from '../../common/constants.js';
import { setCombinedSearchState } from './combinedSearchSlice.js';
import { getQueryCategoryIds } from '../util/categories.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
export const initialState = {
    offset: 0,
    searchResultOffset: 0,
    categoryFiltersOpen: false,
    brands: [],
    brandFiltersOpen: false,
    selectedCategory: undefined,
    query: {
        query: '',
        boostTerm: '',
        filters: {
            brand: undefined,
            categoryId: undefined,
        },
        view: {
            offset: 0,
            limit: PRODUCT_PAGE_SIZE,
        },
    },
    hasMore: false,
};
export const resetFiltersWithUpdates = (location, push, replace) => (dispatch) => {
    dispatch(resetFilters());
    dispatch(updateCombinedSearchProductUrl(location, push, replace));
};
export const setCategoryWithUpdates = (category, location, push, replace) => (dispatch) => {
    dispatch(setSelectedCategory(category));
    dispatch(updateCombinedSearchProductUrl(location, push, replace));
};
export const setBrandWithUpdates = (brand, location, push, replace) => (dispatch) => {
    dispatch(setSelectedBrand(brand));
    dispatch(updateCombinedSearchProductUrl(location, push, replace));
};
export const updateCombinedSearchProductUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const selectedBrand = state.combinedSearchProduct.selectedBrand;
    const selectedCategory = state.combinedSearchProduct.selectedCategory;
    const searchPhrase = state.combinedSearch.searchPhrase;
    const newPath = getCombinedSearchProductUrl({ searchPhrase, selectedBrand, selectedCategory });
    const currentPath = getRelativeUrlFromLocation(location);
    if (location.pathname === COMBINED_SEARCH_PRODUCTS_PAGE_PATH && newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
export const combinedSearchProductSlice = createSlice({
    name: 'combinedSearchProduct',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setCombinedSearchState, (state, action) => {
            const { searchPhrase, searchResult } = action.payload;
            state.offset = 0;
            state.searchResultOffset = 0;
            state.productSearchResult = searchResult.hits.products;
            state.hasMore = state.productSearchResult.length < searchResult.totalHitCounts.products;
            state.brands = getUniqueBrands(searchResult.hits.products);
            state.query.query = searchPhrase;
            state.query.boostTerm = searchPhrase;
            state.query.view.offset = 0;
        });
    },
    reducers: {
        incrementOffset: (state) => {
            state.offset = state.offset + PRODUCT_PAGE_SIZE;
            state.query.view.offset = state.offset;
        },
        resetFilters: (state) => {
            state.selectedBrand = undefined;
            state.selectedCategory = undefined;
            state.query.filters = {
                brand: undefined,
                categoryId: undefined,
            };
        },
        setSelectedBrand: (state, action) => {
            state.selectedBrand = action.payload;
            state.query.filters.brand = state.selectedBrand ? [state.selectedBrand] : undefined;
        },
        setSelectedCategory: (state, action) => {
            const categoryIds = getQueryCategoryIds(action.payload, R.identity);
            return Object.assign(Object.assign({}, state), { selectedCategory: action.payload ? immerUnserializable(R.clone(action.payload)) : action.payload, query: Object.assign(Object.assign({}, state.query), { filters: {
                        categoryId: categoryIds,
                        brand: state.selectedBrand ? [state.selectedBrand] : undefined,
                    } }) });
        },
        setCombinedSearchProductState: (state, action) => {
            const { selectedBrand, selectedCategory, searchPhrase } = action.payload;
            const categoryIds = getQueryCategoryIds(selectedCategory, R.identity);
            return Object.assign(Object.assign({}, state), { selectedBrand, selectedCategory: selectedCategory ? immerUnserializable(R.clone(selectedCategory)) : selectedCategory, query: Object.assign(Object.assign({}, state.query), { query: searchPhrase, boostTerm: searchPhrase, filters: {
                        categoryId: categoryIds,
                        brand: selectedBrand ? [selectedBrand] : undefined,
                    } }) });
        },
        setProductSearchResult: (state, action) => {
            if (state.offset === 0 || !state.productSearchResult) {
                state.searchResultOffset = state.offset;
                state.productSearchResult = action.payload.results;
            }
            else if (state.searchResultOffset < state.offset) {
                state.searchResultOffset = state.offset;
                state.productSearchResult = state.productSearchResult.concat(action.payload.results);
            }
            state.hasMore = state.productSearchResult.length < action.payload.totalHits;
        },
        toggleBrandFiltersDropdown: (state) => {
            state.brandFiltersOpen = !state.brandFiltersOpen;
        },
        toggleCategoryFiltersDropdown: (state) => {
            state.categoryFiltersOpen = !state.categoryFiltersOpen;
        },
    },
});
export const { reducer } = combinedSearchProductSlice;
export const { incrementOffset, resetFilters, setCombinedSearchProductState, setSelectedBrand, setSelectedCategory, toggleBrandFiltersDropdown, toggleCategoryFiltersDropdown, setProductSearchResult, } = combinedSearchProductSlice.actions;
const getCombinedSearchProductUrl = (params) => {
    var _a;
    const categoryId = (_a = params.selectedCategory) === null || _a === void 0 ? void 0 : _a.category.id;
    const queryParams = QueryString.stringify({
        [COMBINED_SEARCH_ALL_SEARCHPHRASE]: params.searchPhrase || undefined,
        [COMBINED_SEARCH_PRODUCTS_BRAND]: params.selectedBrand,
        [COMBINED_SEARCH_PRODUCTS_CATEGORY]: categoryId !== 'root' ? categoryId : undefined,
    });
    return `${COMBINED_SEARCH_PRODUCTS_PAGE_PATH}?${queryParams}`;
};
export const getCombinedSearchProductStateFromLocation = (location, categoryHierarchy) => {
    const queryParams = QueryString.parse(location.search);
    const selectedBrand = queryParams[COMBINED_SEARCH_PRODUCTS_BRAND];
    const categoryId = queryParams[COMBINED_SEARCH_PRODUCTS_CATEGORY];
    const selectedCategory = categoryId && categoryHierarchy ? findCategoryById(categoryHierarchy, categoryId) : undefined;
    const searchPhrase = (queryParams[COMBINED_SEARCH_ALL_SEARCHPHRASE] || '');
    return {
        searchPhrase,
        selectedBrand,
        selectedCategory,
    };
};
function getUniqueBrands(products) {
    return R.uniq(R.reject(R.isNil, R.map((p) => p.brand, [...products])));
}
