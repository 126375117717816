import React from 'react';
import { Link } from 'react-router-dom';
import { COMBINED_SEARCH_ARTICLES_PAGE_PATH } from '../../common/urlPaths.js';
import { HorizontalContainer, VerticalContainer } from './FlexboxContainer.js';
import { scrollToTop } from '../util/scroll.js';
import styles from './ArticleTags.scss';
export const ArticleTags = (props) => {
    const { tags } = props;
    const tagBubbles = tags.map((tag) => {
        const linkTo = {
            pathname: `${COMBINED_SEARCH_ARTICLES_PAGE_PATH}/${tag.slug}`,
        };
        if (tag.visibility && tag.title) {
            const capitalizedTag = tag.title.charAt(0).toUpperCase() + tag.title.slice(1);
            return (React.createElement(Link, { className: styles.tagBubble, to: linkTo, key: tag.title, onClick: scrollToTop },
                React.createElement(HorizontalContainer, { wrap: false, className: styles.tagBubbleContent },
                    React.createElement("span", null, capitalizedTag))));
        }
        return undefined;
    });
    return (React.createElement(VerticalContainer, null,
        React.createElement("section", { className: styles.bubbleContainer }, tagBubbles)));
};
