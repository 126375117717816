import React from 'react';
import { LandingPageElementType, } from '../../common/apiTypes.js';
import { DateDisplay } from './DateDisplay.js';
import { getStaticContentfulImgSrcSet } from '../util/image.js';
import { mapAnalyticsPropsToDataAttributes } from '../util/analyticsDataAttributes.js';
import { FALLBACK_HERO_HEIGHT, FALLBACK_HERO_WIDTH, RECIPE_FALLBACK_HERO_IMAGE } from '../../common/constants.js';
import { Button } from './Button.js';
import { combineClassNames } from '../util/css/classNames.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './IdeaInspirationCard.scss';
export function filterTypeToCardType(filterType) {
    switch (filterType) {
        case 'artikkelit':
            return 'article';
        case 'reseptit':
            return 'recipe';
        default:
            return undefined;
    }
}
export function cardTypeToFilterType(type) {
    switch (type) {
        case 'article':
            return 'artikkelit';
        case 'recipe':
            return 'reseptit';
    }
}
export const IdeaInspirationCard = ({ mode, card, analyticsOptions }) => {
    const content = mode === 'long' ? renderCardLong(card) : renderCardShortOrHybrid(card, mode);
    const filterType = cardTypeToFilterType(card.type);
    const linkUrl = `/ideat-ja-inspiraatiot/${filterType}/${card.slug}`;
    const analyticsDataAttributes = mapAnalyticsPropsToDataAttributes(analyticsOptions, {
        promotionType: card.type,
        promotionName: card.title,
        promotionLink: linkUrl,
    });
    return (React.createElement(LinkWithScroll, Object.assign({ "data-pw": "ideaInspirationCard", className: style.link, to: linkUrl }, analyticsDataAttributes), content));
};
export const IdeaInspirationCardSkeleton = ({ mode }) => (React.createElement("div", { className: combineClassNames(style.link, style.linkSkeleton) },
    React.createElement("div", { className: `${style.articleCardSkeleton} ${style[mode]}` },
        React.createElement("div", { className: `${mode === 'short' ? style.cardHeaderShort : style.cardHeaderLong}` }),
        React.createElement("div", { className: combineClassNames(style.cardType, style.cardTypePlaceholder) },
            React.createElement("div", null)),
        React.createElement("div", { className: combineClassNames(style.cardTitle, style.cardTitlePlaceholder) },
            React.createElement("div", null)),
        React.createElement("div", { className: combineClassNames(style.cardPublishTime, style.cardPublishTimePlaceholder) },
            React.createElement("div", null)),
        mode === 'long' ? (React.createElement("div", { className: combineClassNames(style.cardLead, style.cardLeadPlaceholder) },
            React.createElement("div", null))) : undefined)));
const IMAGE_WIDTH = 430;
const IMAGE_HEIGHT = 245;
const FALLBACK_SRCSET = getStaticContentfulImgSrcSet(RECIPE_FALLBACK_HERO_IMAGE, FALLBACK_HERO_WIDTH, FALLBACK_HERO_HEIGHT);
const renderDate = (card, long = false) => {
    const date = card.liftUpTime || card.publishTime;
    return date ? (React.createElement("h4", { className: long ? style.longCardPublishTime : style.cardPublishTime },
        React.createElement(DateDisplay, { locale: "fi-FI", value: date }))) : undefined;
};
function renderCardShortOrHybrid(card, mode) {
    const { srcSet, fallback, width, height } = card.heroImageUrl
        ? Object.assign(Object.assign({}, getStaticContentfulImgSrcSet(card.heroImageUrl, IMAGE_WIDTH, IMAGE_HEIGHT)), { width: IMAGE_WIDTH, height: IMAGE_HEIGHT }) : Object.assign(Object.assign({}, FALLBACK_SRCSET), { width: FALLBACK_HERO_WIDTH, height: FALLBACK_HERO_HEIGHT });
    const readMore = 'lue lisää';
    return mode === 'hybrid' ? (React.createElement("div", { className: `${style.articleCard}` },
        React.createElement("div", { className: style.cardHeaderHybrid },
            React.createElement("picture", null,
                React.createElement("source", { srcSet: srcSet }),
                React.createElement("img", { alt: "", loading: "lazy", width: width, height: height, className: style.hybridImage, src: fallback })),
            React.createElement("div", { className: combineClassNames(style.cardInfo, style.hybridCardInfoPosition) },
                React.createElement("h4", { className: style.cardType }, cardTypeToLabel(card.type)),
                React.createElement("h3", { className: style.cardTitle }, card.title))),
        React.createElement("div", { className: combineClassNames(style.cardBody, style.hideOnSmall) },
            React.createElement("p", { className: combineClassNames(style.cardLead, style.staticHeightAndFade) }, card.lead),
            React.createElement(Button, { className: style.hybridButton, buttonType: "tertiary" /* ButtonVariant.Tertiary */, size: "small" /* ButtonSize.Small */, text: readMore, isHeroButtonWithoutLink: true, isExternalLink: false, type: LandingPageElementType.Button })))) : (React.createElement("div", { className: `${style.articleCard} ${style.short}` },
        React.createElement("div", { className: style.cardHeaderShort },
            React.createElement("picture", null,
                React.createElement("source", { srcSet: srcSet }),
                React.createElement("img", { alt: "", loading: "lazy", className: style.cardImageShort, width: width, height: height, src: fallback })),
            React.createElement("div", { className: combineClassNames(style.cardInfo, style.shortCardInfoPosition) },
                React.createElement("h4", { className: style.cardType }, cardTypeToLabel(card.type)),
                React.createElement("h3", { className: style.cardTitle }, card.title),
                renderDate(card)))));
}
function cardTypeToLabel(type) {
    switch (type) {
        case 'article':
            return 'artikkeli';
        case 'recipe':
            return 'resepti';
    }
}
function renderCardLong(card) {
    const { srcSet, fallback, width, height } = card.heroImageUrl
        ? Object.assign(Object.assign({}, getStaticContentfulImgSrcSet(card.heroImageUrl, IMAGE_WIDTH, IMAGE_HEIGHT)), { width: IMAGE_WIDTH, height: IMAGE_HEIGHT }) : Object.assign(Object.assign({}, FALLBACK_SRCSET), { width: FALLBACK_HERO_WIDTH, height: FALLBACK_HERO_HEIGHT });
    return (React.createElement("div", { className: `${style.articleCard} ${style.long}` },
        React.createElement("picture", { className: style.picture },
            React.createElement("source", { srcSet: srcSet }),
            React.createElement("img", { loading: "lazy", alt: "", className: style.cardHeaderLong, src: fallback, width: width, height: height })),
        React.createElement("div", { className: style.cardBody },
            React.createElement("h4", { className: style.longCardType }, cardTypeToLabel(card.type)),
            React.createElement("h3", { className: style.longCardTitle }, card.title),
            renderDate(card, true),
            React.createElement("p", { className: style.cardLead }, card.lead))));
}
