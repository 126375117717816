import React from 'react';
import * as R from 'ramda';
import { HorizontalContainer, VerticalContainer } from './FlexboxContainer.js';
import { Icon } from '../Icon.js';
import { ResultFilterBubble } from './ResultFilterBubble.js';
import { setFilter } from '../util/recipe.js';
import styles from './FilterCard.scss';
export const FilterCard = (props) => {
    const { extended, extend, selectFilter, filter, selectedFilter } = props;
    const selectedMainIndex = selectedFilter && selectedFilter.options ? R.findIndex((val) => !R.isNil(val))(selectedFilter.options) : -1;
    const selectedMainFilter = selectedMainIndex !== -1 ? selectedFilter.options[selectedMainIndex] : undefined;
    const chevronUpOrDown = extended
        ? 'chevron-up'
        : 'chevron-down';
    const cardHeaderPrimary = filter.categoryName.toUpperCase();
    let selectedOption;
    if (selectedMainFilter) {
        selectedOption = selectedMainFilter;
    }
    const renderOption = (key, name, formName, checked, onChange) => React.createElement(HorizontalContainer, { key: key, className: styles.optionRow, onClick: checked ? undefined : onChange },
        React.createElement("input", { className: styles.filterOptionRadio, type: 'radio', name: formName, checked: checked, onChange: () => undefined }),
        React.createElement("div", { className: styles.filterOptionLabel },
            React.createElement("span", null, name)));
    const renderFilters = () => {
        const selectAllText = 'Kaikki';
        if (filter.options && filter.options.length > 0) {
            const onClickHandler = selectedMainFilter
                ? () => { setFilter(selectedMainFilter.categoryName, selectFilter, 'deselect'); }
                : () => undefined;
            return (React.createElement(VerticalContainer, { className: styles.optionRowContainer },
                renderOption(`${filter.categoryName}_ALL`, selectAllText, filter.categoryName, !selectedMainFilter, onClickHandler),
                filter.options.map((option) => {
                    const key = `${filter.categoryName}${option.categoryName}`;
                    const name = option.categoryName;
                    const formName = filter.categoryName;
                    const selected = (selectedMainFilter === null || selectedMainFilter === void 0 ? void 0 : selectedMainFilter.categoryName) === option.categoryName;
                    const onChange = () => {
                        if (selected) {
                            setFilter(option.categoryName, selectFilter, 'deselect');
                        }
                        else {
                            setFilter(option.categoryName, selectFilter);
                        }
                    };
                    return renderOption(key, name, formName, selected, onChange);
                })));
        }
        else {
            return React.createElement("div", null, "Valitettavasti t\u00E4ss\u00E4 kategoriassa ei ole suodatusvaihtoehtoja.");
        }
    };
    const renderDeselectionButton = () => {
        if (!extended) {
            return (React.createElement(ResultFilterBubble, { primaryFilter: `1 AKTIIVINEN SUODATIN`, onClick: () => {
                    setFilter(selectedOption.categoryName, selectFilter, 'deselect');
                } }));
        }
        return undefined;
    };
    return (React.createElement(VerticalContainer, { "data-pw": "filterCard", className: extended
            ? `${styles.filterCard} ${styles.filterCardExtended}`
            : styles.filterCard },
        React.createElement(HorizontalContainer, { className: styles.filterHeaderRow, verticalAlign: "flex-end", onClick: () => { extend(); } },
            React.createElement("span", { className: styles.filterHeaderPrimary }, cardHeaderPrimary),
            React.createElement("div", { className: styles.chevron },
                React.createElement(Icon, { type: chevronUpOrDown, size: 25 }))),
        selectedOption ?
            renderDeselectionButton()
            : undefined,
        React.createElement(HorizontalContainer, null, extended ? renderFilters() : undefined)));
};
