import React from 'react';
import { renderLandingPageElement } from './LandingPage.js';
import style from './ABTesting.scss';
import { customPageEvent } from '../../reducers/analyticsSlice.js';
import { mapAnalyticsPropsToDataAttributes, reMapAnalyticsOptions } from '../../util/analyticsDataAttributes.js';
export const ABtesting = (props) => {
    React.useEffect(() => {
        if (window) {
            const location = window.location.pathname + window.location.search + window.location.hash;
            customPageEvent(location, props.option);
        }
    }, []);
    return (React.createElement("div", Object.assign({ id: `${props.option}-${props.id}`, "data-pw": `abTesting-${props.option}`, className: style.abTestingContainer }, mapAnalyticsPropsToDataAttributes(props.analyticsOptions)), props.content.map((el, index) => (React.createElement("div", { key: el.id, id: `${props.option}-${index}` }, renderLandingPageElement(reMapAnalyticsOptions(el, index, props.analyticsOptions), props.language, props.isLoggedIn))))));
};
