import React, { useEffect } from 'react';
import style from './SearchPhraseSuggestions.scss';
import { useGetProductSearchPhraseSuggestionsQuery } from '../services/api.js';
const splitAt = (index, word) => [word.slice(0, index), word.slice(index)];
const boldMatchCharacters = ({ word = '', characters = '' }, handleSuggestionClick) => {
    const beginsWithCharacters = word.startsWith(characters);
    const endOfSplittedWord = splitAt(characters.length, word)[1];
    return (React.createElement("li", { className: style.chip, key: `${word}-${characters}`, onKeyDown: (e) => {
            if (e.keyCode === 13 || e.key === 'Enter') {
                handleSuggestionClick(characters, word);
            }
        }, onClick: () => {
            if (beginsWithCharacters) {
                handleSuggestionClick(characters, `${characters}${endOfSplittedWord}`);
            }
            else {
                handleSuggestionClick(characters, word);
            }
        }, tabIndex: 0 }, beginsWithCharacters ?
        React.createElement(React.Fragment, null,
            React.createElement("b", null, characters),
            endOfSplittedWord)
        :
            React.createElement(React.Fragment, null, word)));
};
const BoldedSuggestions = (props) => {
    // Show the written search phrase as as first suggestion and add api results
    const suggestions = [props.searchPhrase].concat(props.suggestions);
    const bolded = suggestions.map((suggestion) => boldMatchCharacters({ word: suggestion, characters: props.searchPhrase }, props.handleSuggestionClick));
    return React.createElement("ul", { className: style.bottomPart, role: "list", tabIndex: 0 }, bolded);
};
export const SearchPhraseSuggestions = (props) => {
    const suggestions = useGetProductSearchPhraseSuggestionsQuery({ phrase: props.searchPhrase }).data;
    useEffect(() => {
        // Close search phrase suggestion box if user clicks outside of it or the search field or the focus goes away
        const handleClickOutside = (event) => {
            var _a, _b;
            const target = event.target;
            if (!((_b = (_a = props.searchContainerRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.contains(event.target)) || target.id === 'overlay') {
                props.setSuggestionBoxOpen(false);
            }
        };
        if (document) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleClickOutside);
        };
    }, []);
    return (React.createElement(React.Fragment, null, props.searchPhrase.length && suggestions && suggestions.length ?
        React.createElement(React.Fragment, null,
            React.createElement("div", { id: "overlay", className: style.displayOverlay }),
            React.createElement("section", { tabIndex: 0, id: "suggestionContainer", className: style.suggestionContainer, role: "dialog", "aria-labelledby": "Ehdotetut hakusanat" },
                React.createElement("h3", { className: style.topPart }, "Ehdotetut hakusanat"),
                React.createElement(BoldedSuggestions, { searchPhrase: props.searchPhrase, suggestions: suggestions, handleSuggestionClick: props.handleSuggestionClick })))
        :
            React.createElement(React.Fragment, null)));
};
