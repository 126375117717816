import React from 'react';
import * as R from 'ramda';
import { MagazineIssueList } from './MagazineIssueList/index.js';
import { defaultDescriptions } from '../../meta.js';
import { useGetMagazineIssuesQuery, useGetMagazinesQuery } from '../../services/api.js';
import { MAGAZINES_PAGE_PATH } from '../../../common/urlPaths.js';
import { FilterBar } from '../../components/FilterBar.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import styles from './style.scss';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
const PAGE_TITLE = 'Tuotekuvastot ja lehdet';
const findMagazineNameBySlug = (magazineSlug, magazines) => {
    const magazine = magazines.find((m) => m.slug === magazineSlug);
    return magazine === null || magazine === void 0 ? void 0 : magazine.name;
};
const pageTitle = (magazineSlug, magazines) => {
    const magazineName = findMagazineNameBySlug(magazineSlug, magazines);
    if (magazineName === undefined) {
        return PAGE_TITLE;
    }
    return `${magazineName} - Tuotekuvastot ja lehdet`;
};
const mapFilterBarProps = (pathName, issues) => {
    const indexFilter = { to: '/tuotekuvastot-ja-lehdet', caption: 'Kaikki', onlyActiveOnIndex: true };
    return {
        filters: [indexFilter, ...issuesToFilters(issues)],
        pathName,
        className: styles.filters,
    };
};
function issuesToFilters(issues) {
    const magazines = extractMagazines(issues);
    return magazines.map((magazine) => ({
        to: `/tuotekuvastot-ja-lehdet/${magazine.slug}`,
        caption: magazine.name,
    }));
}
function extractMagazines(issues) {
    function magazineName(issue) {
        return issue.magazine.name;
    }
    function toMagazine(issue) {
        return issue.magazine;
    }
    return R.uniqBy(magazineName, issues).map(toMagazine);
}
function mapMagazineIssueListProps(issues, magazineSlug) {
    if (magazineSlug) {
        const matchingIssues = issues.filter((issue) => issue.magazine.slug === magazineSlug);
        if (matchingIssues.length === 0) {
            return { issues: [] };
        }
        return { issues: matchingIssues };
    }
    else {
        return { issues };
    }
}
const MagazinesPage = ({ magazines, magazineIssues, magazineSlug, pathname }) => (React.createElement(VerticalContainer, { horizontalAlign: "center", wrap: false },
    React.createElement(KesproComHelmet, { title: pageTitle(magazineSlug, magazines), canonicalPath: magazineSlug ? `${MAGAZINES_PAGE_PATH}/${magazineSlug}` : MAGAZINES_PAGE_PATH, description: defaultDescriptions.magazines }),
    React.createElement("h1", null, PAGE_TITLE),
    React.createElement(FilterBar, Object.assign({}, mapFilterBarProps(pathname, magazineIssues))),
    React.createElement(MagazineIssueList, Object.assign({}, mapMagazineIssueListProps(magazineIssues, magazineSlug)))));
const MagazinesPageWrapper = ({ locale, location, magazineSlug }) => {
    const cacheQueryParams = parseCacheQueryParams(location);
    const magazinesQueryResult = useGetMagazinesQuery(Object.assign({ locale }, cacheQueryParams));
    const magazineIssuesQueryResult = useGetMagazineIssuesQuery(cacheQueryParams);
    if (magazinesQueryResult.data && magazineIssuesQueryResult.data) {
        return (React.createElement(MagazinesPage, { magazines: magazinesQueryResult.data, magazineIssues: magazineIssuesQueryResult.data, magazineSlug: magazineSlug, pathname: location.pathname }));
    }
    if (magazinesQueryResult.isError || magazineIssuesQueryResult.isError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, null);
};
export { MagazinesPageWrapper as MagazinesPage };
