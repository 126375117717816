import * as R from 'ramda';
import qs from 'query-string';
import { RECIPE_SEARCH_CATEGORY, RECIPE_SEARCH_INGREDIENTS } from '../../common/queryStrings.js';
import { matchPath } from 'react-router-dom';
export function getSelectedFilters(tag, selectedFilters) {
    const flattenFilters = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.map((o) => o === null || o === void 0 ? void 0 : o.options).flat();
    const categories = flattenFilters === null || flattenFilters === void 0 ? void 0 : flattenFilters.filter((f) => (f === null || f === void 0 ? void 0 : f.filterType) === 'category').map((c) => c === null || c === void 0 ? void 0 : c.categoryName).filter((cn) => !!cn);
    const mainIngredients = flattenFilters === null || flattenFilters === void 0 ? void 0 : flattenFilters.filter((f) => (f === null || f === void 0 ? void 0 : f.filterType) === 'mainIngredient').map((c) => c === null || c === void 0 ? void 0 : c.categoryName).filter((cn) => !!cn);
    return {
        categories: categories ? categories : [],
        mainIngredients: mainIngredients ? mainIngredients : [],
        tag,
    };
}
export function getSelectedFiltersBasedOnUrl(originalFilters, location) {
    const filters = R.clone(originalFilters);
    const path = location.pathname;
    if (!R.startsWith('/haku/reseptit', path)) {
        return {
            filters: [],
            tag: undefined,
        };
    }
    const query = qs.parse(location.search);
    const match = matchPath(path, {
        path: '/haku/reseptit/:tag',
        exact: false,
        strict: false,
    });
    const tagMatch = (match === null || match === void 0 ? void 0 : match.params.tag) ? match.params.tag : '';
    if (!query[RECIPE_SEARCH_CATEGORY] && !query[RECIPE_SEARCH_INGREDIENTS]) {
        return {
            filters: [],
            tag: tagMatch,
        };
    }
    const selectedFilters = getSelectedFiltersFromQueryParams(query, filters);
    return {
        filters: selectedFilters,
        tag: tagMatch,
    };
}
export function getSelectedFiltersFromQueryParams(query, filters) {
    const selectedFilters = [];
    filters.forEach((rootFilter, i) => {
        var _a;
        (_a = rootFilter.options) === null || _a === void 0 ? void 0 : _a.forEach((option, index) => {
            const slug = categoryNameToUrlSlug(option.categoryName || '');
            if (slug === query[RECIPE_SEARCH_CATEGORY] || slug === query[RECIPE_SEARCH_INGREDIENTS]) {
                const opt = [];
                opt[index] = option;
                const rootFilterWithOptions = Object.assign(Object.assign({}, rootFilter), { options: opt });
                selectedFilters[i] = rootFilterWithOptions;
            }
        });
    });
    return selectedFilters;
}
export function categoryNameToUrlSlug(categoryName) {
    return categoryName
        .replace(/[-,]/g, '')
        .replace(/'/g, ' ')
        .trim()
        .replace(/\s/g, '-')
        .toLowerCase()
        .replace(/ä/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/å/g, 'a');
}
export function setFilter(categoryName, selectFilter, filterOp = 'select') {
    if (!categoryName) {
        return;
    }
    else {
        selectFilter({
            categoryName,
            filterOp,
        });
    }
}
export function removeNullsFromRecipeFilters(selectedFiltersWithNulls) {
    const selectedFilters = [];
    for (let i = 0; i < selectedFiltersWithNulls.length; i++) {
        const filter = selectedFiltersWithNulls[i];
        if (filter) {
            selectedFilters[i] = filter;
            if (filter.options) {
                selectedFilters[i].options = removeNullsFromRecipeFilters(filter.options);
            }
        }
    }
    return selectedFilters;
}
