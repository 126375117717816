import React from 'react';
import { soMeItems, } from '../../staticData.js';
import { useGetContactsQuery, useGetFooterContactsQuery } from '../../services/api.js';
import { ContactBox } from './ContactBox.js';
import { WholesalesBox } from './WholesalesBox.js';
import { LinksBox } from './LinksBox.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { useAppSelector } from '../../hooks.js';
import { FlexContainer, VerticalContainer } from '../FlexboxContainer.js';
import { SocialMediaBar } from '../SocialMediaBar.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { LinkWithScroll } from '../../util/scroll.js';
import styles from './Footer.scss';
export function Footer(props) {
    const { languageItems, strings } = props;
    const locale = useAppSelector((state) => state.i18n.locale);
    const contactsQueryResult = useGetContactsQuery(Object.assign({ locale }, parseCacheQueryParams(props.location)));
    const footerContactsQueryResult = useGetFooterContactsQuery(Object.assign({ locale }, parseCacheQueryParams(props.location)));
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: styles.footer, htmlElem: "footer", "data-pw": "footer" },
        React.createElement("section", { className: styles.someAndLanguage },
            React.createElement(SocialMediaBar, { iconSize: 40, items: soMeItems }),
            React.createElement("div", { className: styles.horizontalLineMobile }),
            React.createElement("div", { className: styles.languageLinks, "data-pw": "languageSelect" }, languageItems.map((lang) => (React.createElement(LinkWithScroll, { key: lang.caption, to: lang.to }, lang.caption))))),
        React.createElement("div", { className: styles.horizontalLineDesktop }),
        React.createElement(FlexContainer, { className: styles.contactInfo, direction: "row", justifyContent: "space-between", directionMobile: "column", alignItemsMobile: "flex-start" },
            React.createElement("div", { className: styles.horizontalLineMobile }),
            React.createElement(LinksBox, { links: props.quickLinks, strings: strings }),
            React.createElement("div", { className: styles.horizontalLineMobile }),
            renderRtkQueryResult(ContactBox, { strings }, footerContactsQueryResult, (contactBoxProps, contacts) => (Object.assign(Object.assign({}, contactBoxProps), { contacts }))),
            React.createElement("div", { className: styles.horizontalLineMobile }),
            renderRtkQueryResult(WholesalesBox, { strings }, contactsQueryResult, (wholesalesBoxProps, contacts) => (Object.assign(Object.assign({}, wholesalesBoxProps), { contacts }))),
            React.createElement("div", { className: styles.horizontalLineMobile })),
        React.createElement("section", { className: styles.law },
            React.createElement("p", null,
                " ",
                strings.lawTextAddress,
                " "),
            React.createElement("div", { className: styles.lawLinks },
                React.createElement("a", { id: "kconsent-show-v1", href: "https://tietosuoja.kesko.fi/tietosuojakeskossa/evastekaytannot#kconsent=kespro.com", target: "_blank" }, strings.lawLinkCookieSettings),
                " |",
                React.createElement("a", { target: "_blank", href: "https://tietosuoja.kesko.fi/tietosuojakeskossa/evastekaytannot" }, strings.lawLinkCookiePolicy),
                " |",
                React.createElement("a", { href: "https://www.kespro.com/lakisaateinen/kayttoehdot" }, strings.lawLinkTermsOfUse),
                " |",
                React.createElement("a", { href: "https://www.kespro.com/lakisaateinen/tietosuojaseloste" }, strings.lawLinkPrivacyPolicy)))));
}
