import { useAppDispatch, useAppSelector } from '../hooks.js';
import { useEffect } from 'react';
import { useGetArticlesQuery } from '../services/api.js';
import { setArticleSearchResult } from '../reducers/combinedSearchArticleSlice.js';
import { shallowEqual } from 'react-redux';
export const useCombinedSearchArticlesQuery = () => {
    const dispatch = useAppDispatch();
    const { articleSearchResult, query } = useAppSelector((state) => ({
        articleSearchResult: state.combinedSearchArticle.articleSearchResult,
        query: state.combinedSearchArticle.query,
    }), shallowEqual);
    const { data, isFetching, isError } = useGetArticlesQuery(query, { skip: query.offset === 0 });
    useEffect(() => {
        if (data) {
            dispatch(setArticleSearchResult(data));
        }
    }, [data]);
    return {
        data: articleSearchResult || [],
        isError,
        isFetching,
    };
};
