import React from 'react';
import { getDefaultContentfulImgSrcSet } from '../util/image.js';
import { Heading } from './Heading.js';
import { Lead } from './Lead.js';
import style from './IdeaInspirationHero.scss';
const IdeaInspirationTitleLead = ({ lead, title, heroImageUrl, focalPoint, imageHeight, imageWidth }) => {
    const styleDefinition = { objectPosition: focalPoint !== null && focalPoint !== void 0 ? focalPoint : undefined };
    const { srcSet, sizes } = getDefaultContentfulImgSrcSet(heroImageUrl);
    return (React.createElement("section", { className: style.heroContainer },
        React.createElement("picture", { className: style.overlay },
            React.createElement("source", { srcSet: srcSet, sizes: sizes, type: "image/webp" }),
            React.createElement("img", { alt: "", className: style.heroImage, style: styleDefinition, src: `${heroImageUrl}?fm=jpg`, height: imageHeight, width: imageWidth })),
        React.createElement("section", { className: style.heroTexts },
            React.createElement(Heading, { className: style.heroHeading }, title),
            React.createElement(Lead, { className: style.heroLead }, lead))));
};
export const IdeaInspirationHero = ({ hero }) => React.createElement(IdeaInspirationTitleLead, Object.assign({}, hero));
