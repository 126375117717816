import React from 'react';
import styles from './clientOutreach.module.scss';
import { Button, useTranslation } from '@kesko/kespro-common-components';
import '@kesko/kespro-common-components/dist/assets/components/Button/button.css';
import { IconExternalLink } from '@kesko/kesko-design-dna';
const kesproNetBaseUrl = process.env.KESPRO_NET_BASE_URL;
export const ClientOutreach = ({ className, isLoggedIn, gtin }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: styles.clientOutreachQuestion }, t('client_outreach_question')),
        React.createElement(Button, { variant: "primary", size: "l", target: "_blank", href: `${kesproNetBaseUrl}/haku/${gtin}` },
            t('client_outreach_check_prices'),
            React.createElement(IconExternalLink, { color: "white", title: "Avautuu uudessa ikkunassa" })),
        !isLoggedIn && (React.createElement(Button, { variant: "secondary", size: "l", href: "/tukku/asiakkaaksi" }, t('client_outreach_onboarding'))),
        React.createElement("div", { className: styles.clientOutreachTertiary },
            React.createElement(Button, { variant: "tertiary", size: "l", href: `https://www.k-ruoka.fi/kauppa/tuote/${gtin}`, target: "_blank" },
                t('client_outreach_url_text'),
                ' ',
                React.createElement(IconExternalLink, { color: "var(--orange-70)", title: "Avautuu uudessa ikkunassa" })))));
};
