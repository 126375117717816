import React, { useState } from 'react';
import { Button } from '../components/Button.js';
import { constants } from '../constants.js';
import { combineClassNames } from '../../../util/css/classNames.js';
import styles from './NamifestiSection.module.scss';
const ariaContentId = 'aria-muutoksen-menu-content';
export const NamifestiSection = () => {
    const [expanded, setExpanded] = useState(false);
    return (React.createElement("section", { id: constants.sectionIds.namifesti, className: styles.container },
        React.createElement("h2", null, "Ravintola-alan Muutoksen menu"),
        React.createElement("p", null, "Ravintola-alalla on intohimoa vaikka muille jakaa, mutta sen lis\u00E4ksi tarvitsemme p\u00E4\u00E4t\u00F6ksi\u00E4, joiden ansiosta alalla on mahdollisuus toimia ja ty\u00F6llist\u00E4\u00E4 ihmisi\u00E4. T\u00E4m\u00E4 on tulevaisuus, jonka haluamme yhdess\u00E4 rakentaa ravintola-alalle."),
        React.createElement("div", { id: ariaContentId, className: combineClassNames(styles.content, expanded && styles.expanded) },
            React.createElement("div", null,
                React.createElement("h3", null, "Ruoka on kulttuuria"),
                React.createElement("p", null, "Ravintolat ovat ruokakulttuurin n\u00E4ytt\u00E4m\u00F6, jossa eri-ik\u00E4iset, eri taustoista ja kulttuureista tulevat ihmiset kokoontuvat yhteen. Antakaa arvoa sille, kuinka ravintoloiden p\u00F6ydiss\u00E4 parannetaan maailmaa.")),
            React.createElement("div", null,
                React.createElement("h3", null, "Suomi maistuu maailmalla"),
                React.createElement("p", null, "Suomella on kaikki edellytykset tulla maailmankuuluksi omaleimaisesta ruokakulttuuristaan, raaka-aineistaan ja luovuudestaan \u2013 kunhan niihin satsataan toden teolla.")),
            React.createElement("div", null,
                React.createElement("h3", null, "Ravintolat el\u00E4v\u00F6itt\u00E4v\u00E4t kaupunkeja ja kaupungit ravintoloita"),
                React.createElement("p", null, "Me haluamme, ett\u00E4 ravintola-alalle annetaan tila, tuki ja mahdollisuudet, joita se ansaitsee kaupunkikulttuurin keskeisen\u00E4 yll\u00E4pit\u00E4j\u00E4n\u00E4 ja matkailun edist\u00E4j\u00E4n\u00E4.")),
            React.createElement("div", null,
                React.createElement("h3", null, "Luottakaa meihin"),
                React.createElement("p", null, "Me tied\u00E4mme mit\u00E4 teemme. Antakaa ravintoloiden toimia vastuullisesti ja palvella asiakkaita. Liian kankea s\u00E4\u00E4ntely ja byrokratia rajoittavat enemm\u00E4n kuin suojelevat.")),
            React.createElement("div", null,
                React.createElement("h3", null, "Kaikki samassa p\u00F6yd\u00E4ss\u00E4"),
                React.createElement("p", null, "Maailman onnellisin kansa ansaitsee Suomen, jossa viihdyt\u00E4\u00E4n ja voidaan hyvin. Me haluamme, ett\u00E4 ihmiset voivat sy\u00F6d\u00E4 yhdess\u00E4 ja juhlia el\u00E4m\u00E4\u00E4 samassa p\u00F6yd\u00E4ss\u00E4."))),
        React.createElement(Button, { "aria-controls": ariaContentId, "aria-expanded": expanded, onClick: () => setExpanded(!expanded), color: 'white-on-orange' }, expanded ? 'Pienennä' : 'Lue koko vetoomus')));
};
