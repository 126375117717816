import React from 'react';
import qs from 'qs';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks.js';
import { setI18nSlugsForLandingPages } from '../../reducers/i18nSlice.js';
import { useGetLandingPageQuery } from '../../services/api.js';
import { LandingPage } from '../../components/LandingPage/LandingPage.js';
import { NOT_FOUND_CONTENTFUL_PAGE_SLUG } from '../../../common/constants.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { languageToLocale } from '../../../common/apiTypes.js';
import { useLocation } from 'react-router-dom';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { BYPASS_CACHE, PREVIEW } from '../../../common/queryStrings.js';
export const NotFoundPage = (props) => {
    var _a, _b;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const searchParams = {
        slug: NOT_FOUND_CONTENTFUL_PAGE_SLUG,
        locale: languageToLocale(props.language),
        bypassCache: (_a = queryParams[BYPASS_CACHE]) === null || _a === void 0 ? void 0 : _a.toString(),
        preview: (_b = queryParams[PREVIEW]) === null || _b === void 0 ? void 0 : _b.toString(),
    };
    const useQueryResult = useGetLandingPageQuery(searchParams);
    useEffect(() => {
        if (useQueryResult.data) {
            dispatch(setI18nSlugsForLandingPages(useQueryResult.data));
        }
    }, [useQueryResult.data]);
    const { data, isFetching, isError, isUninitialized } = useQueryResult;
    if (isUninitialized || isFetching) {
        return React.createElement(LoadingIndicator, null);
    }
    if (isError || !data) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LandingPage, { language: props.language, page: data });
};
