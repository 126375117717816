import React from 'react';
import { mapAnalyticsPropsToDataAttributes } from '../util/analyticsDataAttributes.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './FlexboxContainer.scss';
export const HorizontalContainer = (props) => {
    const { className, onClick, horizontalAlign, horizontalAlignMobile, verticalAlign, verticalAlignMobile, responsive, id } = props;
    const classNames = combineClassNames(className, styles.flex, styles.horizontal, styles[`flex-wrap--${props.wrap ? 'wrap' : 'nowrap'}`], styles[`justify-content--${horizontalAlign ? horizontalAlign : 'flex-start'}`], horizontalAlignMobile && styles[`justify-content-mobile--${horizontalAlignMobile}`], styles[`align-items--${verticalAlign ? verticalAlign : 'stretch'}`], verticalAlignMobile && styles[`align-items-mobile--${verticalAlignMobile}`], responsive && styles.horizontalResponsive);
    const attributes = Object.assign({ className: classNames, onClick, 'data-pw': props['data-pw'] }, mapAnalyticsPropsToDataAttributes(props.analyticsDataAttributes));
    if (id) {
        attributes.id = id;
    }
    return React.createElement(props.htmlElem || 'div', attributes, props.children);
};
export const VerticalContainer = (props) => {
    const { className, onClick, verticalAlign, verticalAlignMobile, horizontalAlign, horizontalAlignMobile, tabIndex } = props;
    const classNames = combineClassNames(className, styles.flex, styles.vertical, styles[`flex-wrap--${props.wrap ? 'wrap' : 'nowrap'}`], styles[`justify-content--${verticalAlign ? verticalAlign : 'flex-start'}`], verticalAlignMobile && styles[`justify-content-mobile--${verticalAlignMobile}`], styles[`align-items--${horizontalAlign ? horizontalAlign : 'stretch'}`], horizontalAlignMobile && styles[`align-items-mobile--${horizontalAlignMobile}`]);
    return React.createElement(props.htmlElem || 'div', Object.assign({ tabIndex, className: classNames, onClick, 'data-pw': props['data-pw'] }, mapAnalyticsPropsToDataAttributes(props.analyticsDataAttributes)), props.children);
};
export const FlexContainer = (props) => {
    const { className, direction, directionMobile, wrap, wrapMobile, justifyContent, justifyContentMobile, alignItems, alignItemsMobile, alignContent, alignContentMobile } = props;
    const style = {
        display: 'flex',
    };
    const classNames = combineClassNames(className, styles.flex, styles[`flex-direction--${direction ? direction : 'row'}`], directionMobile && styles[`flex-direction-mobile--${directionMobile}`], styles[`flex-wrap--${wrap ? wrap : 'nowrap'}`], wrapMobile && styles[`flex-wrap-mobile--${wrapMobile}`], styles[`justify-content--${justifyContent ? justifyContent : 'flex-start'}`], justifyContentMobile && styles[`justify-content-mobile--${justifyContentMobile}`], styles[`align-items--${alignItems ? alignItems : 'stretch'}`], alignItemsMobile && styles[`align-items-mobile--${alignItemsMobile}`], styles[`align-content--${alignContent ? alignContent : 'stretch'}`], alignContentMobile && styles[`align-content-mobile--${alignContentMobile}`]);
    return React.createElement(props.htmlElem || 'div', { style, className: classNames }, props.children);
};
