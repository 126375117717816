export function keyUpHandler(searchPhrase, onSearch) {
    return (event) => {
        if (event.keyCode === 13 || event.key === 'Enter') {
            event.currentTarget.blur();
            onSearch(searchPhrase);
        }
    };
}
export function onChangeHandler(onChange) {
    return (event) => {
        onChange(event.target.value);
    };
}
export function clearSearchPhrase(onChange) {
    return () => {
        onChange('');
    };
}
