import React from 'react';
import { AttributeBoxes } from '../../../components/Attribute.js';
import { RecipeCategories } from '../RecipeCategories/index.js';
import { Icon } from '../../../Icon.js';
import { VerticalContainer } from '../../../components/FlexboxContainer.js';
import { Heading } from '../../../components/Heading.js';
import { HeroHeader } from '../../../components/HeroHeader.js';
import { Lead } from '../../../components/Lead.js';
import { combineClassNames } from '../../../util/css/classNames.js';
import styles from './style.scss';
export const RecipeHeader = (props) => {
    const attributeBoxData = [
        {
            header: 'Julkaistu',
            text: props.publishTime,
            time: true,
        },
        {
            header: 'Kaupallinen yhteistyö',
            text: props.tradeCooperation,
            tradeCooperation: !!props.tradeCooperation,
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(VerticalContainer, { horizontalAlign: "center", wrap: false },
            React.createElement(HeroHeader, { img: props.heroImageUrl || '', childrenContainerStyle: combineClassNames(styles.header, styles.noGradient), headerContainerClassname: combineClassNames(styles.image, styles.noGradient) }, props.fromRecipeSearch ?
                React.createElement("button", { className: styles.backToSearch, onClick: () => { props.backToPrevLocation(); } },
                    React.createElement(Icon, { size: "21", type: "chevron-left-white" }),
                    React.createElement("span", { className: styles.backToSearchText }, "Takaisin hakuun"))
                : undefined)),
        React.createElement("section", { className: styles.container },
            React.createElement("section", { className: styles.headerLeadContainer },
                React.createElement(Heading, { className: styles.heading }, props.title),
                props.lead && React.createElement(Lead, null, props.lead)),
            React.createElement("div", { className: styles.attributeCategoryContainer },
                React.createElement(AttributeBoxes, { data: attributeBoxData, slug: props.tradeCooperationSlug }),
                React.createElement("section", null,
                    React.createElement(RecipeCategories, { categories: props.categories, mainIngredient: props.mainIngredient, tags: props.tags }))))));
};
