import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import style from './ContactInfoDescription.scss';
export class ContactInfoDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = { setToOverflowOnMount: false, shouldOverflow: false };
    }
    render() {
        const { setToOverflowOnMount, shouldOverflow } = this.state;
        return (React.createElement("div", { className: style.contactInfoDescription },
            React.createElement("div", { ref: (e) => {
                    if (e && e.clientHeight > 150 && !setToOverflowOnMount) {
                        this.setState({ setToOverflowOnMount: true, shouldOverflow: true });
                    }
                }, className: style.contactInfoDescription__container, "data-overflow": shouldOverflow ? 'true' : 'false' }, this.props.descriptionRichText ? (React.createElement("div", { className: style.contactInfoDescription__richTextDescription, dangerouslySetInnerHTML: { __html: documentToHtmlString(this.props.descriptionRichText) } })) : (this.props.description
                .split('\n')
                .filter((paragraph) => !!paragraph)
                .map((paragraph, index) => (React.createElement("p", { key: `contactInfoDescriptionParagraph-${index}`, className: style.contactInfoDescription__paragraph }, paragraph))))),
            this.state.setToOverflowOnMount && (React.createElement("button", { className: style.contactInfoDescripion__expandButton, onClick: () => {
                    this.setState({ shouldOverflow: !shouldOverflow });
                } }, this.state.shouldOverflow ? 'Näytä lisää' : 'Piilota'))));
    }
}
