var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import * as R from 'ramda';
import { api } from '../services/api.js';
import { immerUnserializable } from '../util/redux.js';
import { setCategoryParents } from '../util/categories.js';
const initialState = {
    hierarchy: undefined,
};
export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(api.endpoints.getCategoryHierarchy.matchFulfilled, (state, { payload }) => ({
            hierarchy: R.pipe(setCategoryParents, immerUnserializable)(R.clone(payload)),
        }));
    },
});
export const { reducer } = categorySlice;
