import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
import { Button } from '../components/Button.js';
import { Svg } from '../components/Svg.js';
import { combineClassNames } from '../../../util/css/classNames.js';
import { constants } from '../constants.js';
import styles from './FormSection.module.scss';
const Form = () => {
    const [honeypotField, setHoneypotField] = useState('');
    const [status, setStatus] = useState('normal');
    const [formData, setFormData] = useState({
        pseudonym: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        pseudonym: '',
        message: '',
    });
    const hasErrors = Object.values(errors).some((error) => !!error);
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        event.preventDefault();
        setStatus('send-pending');
        try {
            const response = yield fetch('/api/intohimonyt/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Object.assign(Object.assign({}, formData), { hp: honeypotField })),
            });
            if (response.ok) {
                setStatus('send-success');
            }
            else {
                setStatus('send-error');
            }
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to submit the feedback form', err);
            (_a = window.dtrum) === null || _a === void 0 ? void 0 : _a.reportError(err);
            setStatus('send-error');
        }
    });
    const handleChange = (event) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [event.target.name]: event.target.value })));
        if (event.target.name === 'message' && event.target.value.length > 0) {
            setErrors((prev) => (Object.assign(Object.assign({}, prev), { message: '' })));
        }
        else if (event.target.name === 'pseudonym' && event.target.value.length > 0) {
            setErrors((prev) => (Object.assign(Object.assign({}, prev), { message: '' })));
        }
    };
    if (status === 'send-success') {
        return (React.createElement("p", { className: styles.formSubmitSuccessText }, "Palautteesi on vastaanotettu!"));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit, autoComplete: 'off' },
            React.createElement("label", null,
                "Nimi tai nimimerkki",
                React.createElement("input", { type: 'text', name: 'pseudonym', className: combineClassNames(errors.pseudonym && styles.inputHasError), onBlur: (event) => {
                        if (event.target.value.length === 0) {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { pseudonym: 'Täytä tämä kenttä' })));
                        }
                        else if (event.target.value.length > 250) {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { pseudonym: 'Nimimerkkisi on liian pitkä' })));
                        }
                        else {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { pseudonym: '' })));
                        }
                    }, onChange: handleChange, value: formData.pseudonym, maxLength: 250, autoComplete: 'off', required: true }),
                errors.pseudonym && React.createElement("span", null, "T\u00E4yt\u00E4 t\u00E4m\u00E4 kentt\u00E4.")),
            React.createElement("label", null,
                "Jaa intohimosi ravintola-alan puolesta.",
                React.createElement("textarea", { name: 'message', className: combineClassNames(errors.message && styles.inputHasError), onBlur: (event) => {
                        if (event.target.value.length === 0) {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { message: 'Täytä tämä kenttä' })));
                        }
                        else if (event.target.value.length > 10000) {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { message: 'Viestisi on liian pitkä, pidä se alle 10 0000 merkkisenä' })));
                        }
                        else {
                            setErrors((prev) => (Object.assign(Object.assign({}, prev), { message: '' })));
                        }
                    }, onChange: handleChange, value: formData.message, rows: 8, autoComplete: 'off', maxLength: 10000, required: true }),
                errors.message && React.createElement("span", null, "T\u00E4yt\u00E4 t\u00E4m\u00E4 kentt\u00E4.")),
            React.createElement("label", { className: styles.honeypotField },
                "\u00C4l\u00E4 t\u00E4yt\u00E4 t\u00E4t\u00E4 kentt\u00E4\u00E4, k\u00E4ytet\u00E4\u00E4n automatisoidun roskapostin ker\u00E4\u00E4miseen!",
                React.createElement("input", { type: 'text', autoComplete: 'off', tabIndex: -1, onChange: (event) => setHoneypotField(event.target.value), value: honeypotField })),
            honeypotField && (React.createElement("p", { className: styles.formSubmitErrorText, "aria-live": 'assertive' }, "T\u00E4ytit piilotetun kent\u00E4n automatisoitujen l\u00E4hetysten esti\u00E4miseksi. Tyhjenn\u00E4 kentt\u00E4 \"Palautteen otsikko\".")),
            status === 'send-error' && (React.createElement("p", { className: styles.formSubmitErrorText }, "Lomakkeen l\u00E4hetys ep\u00E4onnistui, yrit\u00E4 my\u00F6hemmin uudestaan!")),
            React.createElement(Button, { type: 'submit', color: 'white-on-orange', disabled: hasErrors || formData.message.length === 0 || formData.pseudonym.length === 0 || status === 'send-pending' }, status === 'send-pending' ? 'Palauteviestiä lähetetään...' : 'Jaa intohimosi'))));
};
export const FormSection = () => (React.createElement("section", { id: constants.sectionIds.form, className: styles.container },
    React.createElement("h2", null,
        React.createElement(Svg, { name: 'title-feedback' }),
        React.createElement(AriaHeadingText, null, "Kerro terveisesi p\u00E4\u00E4tt\u00E4jille")),
    React.createElement("p", null, "Kerro terveisesi p\u00E4\u00E4tt\u00E4jille tai rummuta alan intohimon puolesta. Toivomme rakentavia ja inspiroivia viestej\u00E4, joilla nostetaan alan arvostusta ja vedotaan p\u00E4\u00E4tt\u00E4jiin alan puolesta. Kokoamme viestit yhteen ja toimitamme ne p\u00E4\u00E4tt\u00E4jien n\u00E4ht\u00E4viksi. L\u00E4hett\u00E4m\u00E4ll\u00E4 viestisi hyv\u00E4ksyt, ett\u00E4 se voidaan julkaista Kespron toimesta eri kanavissa."),
    React.createElement("div", { className: styles.formContainer },
        React.createElement(Form, null)),
    React.createElement(Svg, { className: styles.wineglassSticker, name: 'sticker-wine' }),
    React.createElement("hr", null)));
