import React from 'react';
import { stringToMarkdown } from '../../../common/stringTypes.js';
import { LegalTextSubjects } from '../../../common/apiTypes.js';
import { useGetLegalTextQuery } from '../../services/api.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LegalText } from '../../components/LegalText.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
function pageTitle(subject) {
    switch (subject) {
        case LegalTextSubjects.PRIVACY_POLICY:
            return 'Tietosuojaseloste';
        case LegalTextSubjects.TERMS_OF_USE:
            return 'Käyttöehdot';
        default:
            return '';
    }
}
function canonicalPath(subject) {
    switch (subject) {
        case LegalTextSubjects.PRIVACY_POLICY:
            return 'tietosuojaseloste';
        case LegalTextSubjects.TERMS_OF_USE:
            return 'kayttoehdot';
        default:
            return '';
    }
}
function metaDescription(subject) {
    switch (subject) {
        case LegalTextSubjects.PRIVACY_POLICY:
            return 'Tämä seloste koskee henkilötietojen käsittelyä Kespron asiakasrekisterissä.';
        case LegalTextSubjects.TERMS_OF_USE:
            return 'Täältä löydät kespro.com -sivuston käyttöehdot.';
        default:
            return '';
    }
}
export const LegalTextPage = ({ subject, location }) => {
    const legalTextQueryResult = useGetLegalTextQuery(Object.assign({ textSubject: subject }, parseCacheQueryParams(location)));
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { title: pageTitle(subject), canonicalPath: `/lakisaateinen/${canonicalPath(subject)}`, description: metaDescription(subject) }),
        renderRtkQueryResult(LegalText, {}, legalTextQueryResult, (_props, legalText) => ({
            body: stringToMarkdown(legalText.body.toString()),
        }), { fetch404PageFromContentful: true })));
};
