import * as React from 'react';
import { NotFoundPage } from '../pages/NotFoundPage/index.js';
import { LoadingIndicator } from '../components/LoadingIndicator.js';
import { NotFoundComponent } from '../components/NotFoundComponent.js';
export function renderRtkQueryResult(Element, elementProps, useQueryResult, mapUseQueryResultToProps, options) {
    const { data, isFetching, isError, isUninitialized } = useQueryResult;
    if (isUninitialized || (!(options === null || options === void 0 ? void 0 : options.showPreviousDataWhileLoading) && isFetching)) {
        return React.createElement(LoadingIndicator, { className: options === null || options === void 0 ? void 0 : options.loadingClassName });
    }
    if (isError || !data) {
        if (options === null || options === void 0 ? void 0 : options.fetch404PageFromContentful) {
            return React.createElement(NotFoundPage, { language: (options === null || options === void 0 ? void 0 : options.language) || 'fi' });
        }
        else {
            return React.createElement(NotFoundComponent, null);
        }
    }
    const props = mapUseQueryResultToProps(elementProps, data);
    return React.createElement(Element, Object.assign({}, props));
}
