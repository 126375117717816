import React from 'react';
import style from './Attribute.scss';
import { Locales } from '../../common/apiTypes.js';
import { DateDisplay } from './DateDisplay.js';
import { PARTNER_PAGE_PATH } from '../../common/urlPaths.js';
import { LinkWithScroll } from '../util/scroll.js';
const Attribute = ({ header, text, time, tradeCooperation, slug }) => React.createElement(React.Fragment, null, text &&
    React.createElement("section", { className: style.attributeBox },
        React.createElement("h3", { className: style.header }, header),
        time &&
            React.createElement("p", null,
                React.createElement(DateDisplay, { locale: Locales.FINNISH, value: text })),
        !time && !tradeCooperation &&
            React.createElement("p", null, text),
        tradeCooperation && slug &&
            React.createElement(LinkWithScroll, { to: `${PARTNER_PAGE_PATH}/${slug}` }, text),
        tradeCooperation && !slug &&
            React.createElement("p", null, text)));
export const AttributeBoxes = (props) => React.createElement("section", { className: style.attributeBoxContainer }, props.data.map((attribute) => {
    var _a;
    return React.createElement(Attribute, { key: `${attribute.header}-${attribute.time}`, header: attribute.header, text: attribute.text, time: attribute.time, tradeCooperation: attribute.tradeCooperation, slug: (_a = props.slug) !== null && _a !== void 0 ? _a : undefined });
}));
