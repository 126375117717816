import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { updateSearchResults } from '../reducers/combinedSearchSlice.js';
import { useLazyGetCombinedSearchResultsQuery } from '../services/api.js';
import { ARTICLE_PAGE_SIZE, PAGES_PAGE_SIZE, PRODUCT_PAGE_SIZE, RECIPE_PAGE_SIZE } from '../../common/constants.js';
import * as R from 'ramda';
import { isBrowser } from '../../common/util.js';
import { shallowEqual } from 'react-redux';
import { parseCacheQueryParams } from '../../common/queryStrings.js';
import { useLocation } from 'react-router-dom';
export const useCombinedSearchQuery = () => {
    var _a, _b;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { articleSearchState, combinedSearchState, contactsSearchState, contentPageSearchState, productSearchState, recipeSearchState, } = useAppSelector((state) => ({
        combinedSearchState: state.combinedSearch,
        articleSearchState: state.combinedSearchArticle,
        contactsSearchState: state.combinedSearchContacts,
        recipeSearchState: state.combinedSearchRecipe,
        productSearchState: state.combinedSearchProduct,
        contentPageSearchState: state.combinedSearchContentPage,
    }), shallowEqual);
    const query = Object.assign(Object.assign({}, parseCacheQueryParams(location)), { searchPhrase: combinedSearchState.debouncedSearchPhrase, productSearch: Object.assign(Object.assign({}, productSearchState.query.filters), { brand: ((_a = productSearchState.query.filters) === null || _a === void 0 ? void 0 : _a.brand)
                ? R.head((_b = productSearchState.query.filters) === null || _b === void 0 ? void 0 : _b.brand)
                : undefined }), recipeSearch: {
            filters: recipeSearchState.query.filters,
            tag: recipeSearchState.query.tag,
        }, productsPageSize: PRODUCT_PAGE_SIZE, articlesPageSize: ARTICLE_PAGE_SIZE, recipesPageSize: RECIPE_PAGE_SIZE, contentPagePageSize: PAGES_PAGE_SIZE });
    const [trigger, searchResult, { lastArg }] = useLazyGetCombinedSearchResultsQuery();
    const isomorphicEffect = isBrowser() ? useEffect : useMemo;
    isomorphicEffect(() => {
        if (searchResult.isUninitialized ||
            !R.equals(lastArg.productSearch, query.productSearch) ||
            (lastArg === null || lastArg === void 0 ? void 0 : lastArg.searchPhrase) !== query.searchPhrase) {
            void trigger(query, true);
        }
    }, [query]);
    useEffect(() => {
        if (searchResult.data) {
            dispatch(updateSearchResults(searchResult.data));
        }
    }, [searchResult.data]);
    const isSubSlicesInitialized = recipeSearchState.recipeSearchResult ||
        contactsSearchState.contactsSearchResult ||
        productSearchState.productSearchResult ||
        articleSearchState.articleSearchResult ||
        contentPageSearchState.contentPageSearchResult;
    return {
        data: searchResult.data,
        isError: searchResult.isError,
        isLoading: searchResult.isLoading || (searchResult.isSuccess && !isSubSlicesInitialized),
    };
};
