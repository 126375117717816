// Hardcoding the state at least now, could be changed to fetching the data from contentful
export const initialFilters = [
    {
        categoryName: 'Ruokalaji',
        options: [{
                categoryName: 'Alkuruoat',
                filterType: 'category',
            },
            {
                categoryName: 'Pääruoat',
                filterType: 'category',
            },
            {
                categoryName: 'Jälkiruoat',
                filterType: 'category',
            },
            {
                categoryName: 'Makea leivonta',
                filterType: 'category',
            },
            {
                categoryName: 'Suolainen leivonta',
                filterType: 'category',
            },
            {
                categoryName: 'Juomat ja smoothiet',
                filterType: 'category',
            },
            {
                categoryName: 'Salaatit ja bowlit',
                filterType: 'category',
            },
            {
                categoryName: 'Keitot',
                filterType: 'category',
            },
            {
                categoryName: 'Lisukkeet',
                filterType: 'category',
            }, {
                categoryName: 'Kastikkeet',
                filterType: 'category',
            }, {
                categoryName: 'Aamiainen',
                filterType: 'category',
            },
        ],
    },
    {
        categoryName: 'Pääraaka-aine',
        options: [
            {
                categoryName: 'Broileri ja linnut',
                filterType: 'mainIngredient',
            },
            {
                categoryName: 'Juusto',
                filterType: 'mainIngredient',
            },
            {
                categoryName: 'Kala ja äyriäiset',
                filterType: 'mainIngredient',
            },
            {
                categoryName: 'Kasvis',
                filterType: 'mainIngredient',
            },
            {
                categoryName: 'Punainen liha',
                filterType: 'mainIngredient',
            },
        ],
    },
];
