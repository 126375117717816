export const decodeKConsentCookie = (cookie) => {
    const allowed = /allowed~(.*?)~/.exec(cookie);
    if (allowed) {
        return {
            ga: allowed[1].includes('_ga_'),
        };
    }
    return {
        ga: false,
    };
};
