import React from 'react';
import { VerticalContainer } from './FlexboxContainer.js';
import { LoadMoreButton } from './LoadMoreButton.js';
import { RecipeSearchResultCard } from './RecipeSearchResultCard.js';
import style from './RecipeSearchResultCardList.scss';
export const RecipeSearchResultCardList = ({ cards, hasMoreCards, isError, isLoadingMore, onLoadMore }) => (React.createElement(VerticalContainer, { horizontalAlign: "center" },
    isError && React.createElement(ErrorMessage, null),
    React.createElement("div", { className: style.cardContainer },
        React.createElement(CardList, { cards: cards })),
    hasMoreCards && React.createElement(LoadMoreButton, { isLoading: isLoadingMore, onClick: onLoadMore })));
const CardList = ({ cards }) => {
    if (!cards.length) {
        return React.createElement("p", null, "Ei hakutuloksia");
    }
    return (React.createElement(React.Fragment, null, cards.map((card) => (React.createElement("div", { "data-pw": "recipeCard", className: style.cardWrapper, key: card.slug },
        React.createElement(RecipeSearchResultCard, { card: card }))))));
};
const ErrorMessage = () => (React.createElement("p", null, "Tapahtui virhe :( Yrit\u00E4 hetken p\u00E4\u00E4st\u00E4 uudestaan."));
