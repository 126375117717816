import * as React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
export const ForceLogOut = () => {
    React.useEffect(() => {
        const authChannel = new BroadcastChannel('auth-channel');
        authChannel.postMessage('logout');
        window.location.href = process.env.AZURE_LOGOUT_URI;
    }, []);
    return (React.createElement(React.Fragment, null));
};
