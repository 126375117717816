import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../Icon.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './SwipeVariant.scss';
var ScrollState;
(function (ScrollState) {
    ScrollState[ScrollState["NotScrollable"] = 0] = "NotScrollable";
    ScrollState[ScrollState["Left"] = 1] = "Left";
    ScrollState[ScrollState["Middle"] = 2] = "Middle";
    ScrollState[ScrollState["Right"] = 3] = "Right";
})(ScrollState || (ScrollState = {}));
export const SwipeContainer = ({ children, className }) => {
    const ref = React.useRef(null);
    const [scrollState, setScrollState] = useState(ScrollState.Left);
    const handleLeftScroll = (_event) => {
        if (ref.current) {
            ref.current.scrollLeft -= 100;
        }
    };
    const handleRightScroll = (_event) => {
        if (ref.current) {
            ref.current.scrollLeft += 100;
        }
    };
    const handleScroll = (event) => {
        setScrollState(getHorizontalScrollState(event.currentTarget));
    };
    useEffect(() => {
        if (!ref.current) {
            return () => undefined;
        }
        const handleResize = () => {
            setScrollState(getHorizontalScrollState(ref.current));
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]);
    return (React.createElement("div", { className: combineClassNames(styles.container, className) },
        scrollState !== ScrollState.NotScrollable && (React.createElement("button", { className: combineClassNames(styles.icon, scrollState === ScrollState.Left && styles.disabled), onClick: handleLeftScroll },
            React.createElement(Icon, { type: "chevron-left", size: 24 }))),
        React.createElement("ul", { className: styles.links, onScroll: handleScroll, ref: ref }, children),
        scrollState !== ScrollState.NotScrollable && (React.createElement("button", { className: combineClassNames(styles.icon, scrollState === ScrollState.Right && styles.disabled), onClick: handleRightScroll },
            React.createElement(Icon, { type: "chevron-right", size: 24 })))));
};
export const SwipeItem = ({ children, to }) => (React.createElement("li", null,
    React.createElement(NavLink, { className: styles.link, to: to }, children)));
const getHorizontalScrollState = (element) => {
    const { offsetWidth, scrollLeft, scrollWidth } = element;
    const atRight = offsetWidth + scrollLeft >= scrollWidth - 5;
    const atLeft = scrollLeft === 0;
    if (atRight && atLeft) {
        return ScrollState.NotScrollable;
    }
    if (atRight) {
        // Notice that the sum might vary a bit depending on the browser.
        return ScrollState.Right;
    }
    if (atLeft) {
        return ScrollState.Left;
    }
    return ScrollState.Middle;
};
