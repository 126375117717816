import React, { useEffect } from 'react';
import { combineClassNames } from '../util/css/classNames.js';
import { LinkWithScroll } from '../util/scroll.js';
import styles from './SearchTypeSelect.scss';
export const SearchTypeSelect = ({ activeItemId, items, className }) => {
    useEffect(() => {
        const activeElement = document.getElementById(`SearchTypeSelect-${activeItemId}`);
        if (activeElement) {
            activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }, [activeItemId]);
    return (React.createElement("nav", { className: combineClassNames(styles.searchTypeSelect, className) }, items.map((item) => (React.createElement(LinkWithScroll, { id: `SearchTypeSelect-${item.id}`, key: item.id, to: item.link, className: combineClassNames(styles.item, item.id === activeItemId && styles.active), "aria-label": item.count ? `${item.text} ${item.count} hakutulosta` : undefined, "aria-current": item.id === activeItemId ? 'page' : undefined },
        React.createElement("div", null, item.text),
        item.count !== undefined && React.createElement("div", null, item.count))))));
};
