import { useAppDispatch, useAppSelector } from '../hooks.js';
import { useGetProductsQuery } from '../services/api.js';
import { useEffect } from 'react';
import { setProductSearchResult } from '../reducers/combinedSearchProductSlice.js';
import { shallowEqual } from 'react-redux';
export const useCombinedSearchProductsQuery = () => {
    var _a;
    const dispatch = useAppDispatch();
    const { productSearchResult, query } = useAppSelector((state) => ({
        productSearchResult: state.combinedSearchProduct.productSearchResult,
        query: state.combinedSearchProduct.query,
    }), shallowEqual);
    const { data, isError, isFetching } = useGetProductsQuery(query, { skip: (((_a = query.view) === null || _a === void 0 ? void 0 : _a.offset) || 0) === 0 });
    useEffect(() => {
        if (data) {
            dispatch(setProductSearchResult(data));
        }
    }, [data]);
    return {
        data: productSearchResult || [],
        isError,
        isFetching,
    };
};
