import { defaultLanguageItems, languageBasePathRedirectTargetUrls, navigationItems, } from '../client/staticData.js';
const becomeClientUrls = Object.values(navigationItems.buttons).map((items) => items.becomeClient.to);
const becomeClientLanguageItems = Object.entries(navigationItems.buttons).map(([language, items]) => {
    if (language === 'ru') {
        // Russian language is not defined in contentful, yet.
        return {
            to: languageBasePathRedirectTargetUrls.ru,
            caption: language,
            language: 'ru',
        };
    }
    return {
        to: items.becomeClient.to,
        caption: language,
        language: language,
    };
});
export const mapLocalizedSlugsToLanguageNavigationItems = (location, i18nState) => {
    const url = location.pathname;
    if (becomeClientUrls.includes(url)) {
        return becomeClientLanguageItems;
    }
    if (location.pathname === i18nState.pageSlug) {
        return ['fi', 'en', 'sv', 'ru'].map((language) => ({
            language,
            to: i18nState.localizedSlugs[language].url,
            caption: language,
        }));
    }
    return defaultLanguageItems;
};
