import React from 'react';
import { useAppSelector } from '../hooks.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { OpeningHoursList } from './OpeningHoursList.js';
import { formatTodayOpened, getCompactHourEntries, getExceptionalHourEntries } from '../util/openingHours.js';
import style from './ContactInfoHours.scss';
export const ContactInfoHours = (props) => {
    const { title, upcomingExceptionsTitle, data, styling, } = props;
    const language = useAppSelector((state) => state.i18n.language);
    const exceptionalHoursEntries = getExceptionalHourEntries(data.exceptionalOpeningHours, language);
    const openHoursToday = formatTodayOpened(data, language);
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", wrap: false },
        React.createElement("h2", { className: style.hourList__heading }, title),
        React.createElement("div", { className: style.hourListOuterContainer },
            React.createElement(OpeningHoursList, { entries: getCompactHourEntries(data, language), styling: styling, title: openHoursToday.label, openHoursToday: openHoursToday.hours }),
            exceptionalHoursEntries.length > 0 && (React.createElement(OpeningHoursList, { title: upcomingExceptionsTitle, entries: exceptionalHoursEntries, styling: styling })))));
};
