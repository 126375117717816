import React from 'react';
import { LinkCard } from './LandingPage/LinkCard.js';
import { LoadMoreButton } from './LoadMoreButton.js';
import { combineClassNames } from '../util/css/classNames.js';
import style from './ContentPageSearchResultCardList.scss';
export const ContentPageSearchResultCardList = ({ cards, hasMoreCards, isError, isLoadingMore, onLoadMore, partnerPage }) => (React.createElement("div", { className: style.cardListContainer },
    isError && React.createElement(ErrorMessage, null),
    React.createElement("div", { className: style.cardContainer },
        React.createElement(CardList, { cards: cards, partnerPage: partnerPage })),
    hasMoreCards && onLoadMore && React.createElement(LoadMoreButton, { isLoading: isLoadingMore, onClick: onLoadMore })));
const CardList = ({ cards, className, partnerPage }) => {
    if (!cards.length) {
        return React.createElement("section", { className: style.noResults },
            React.createElement("p", null, "Ei hakutuloksia"));
    }
    return (React.createElement(React.Fragment, null, cards.map((card, index) => (React.createElement("div", { className: style.cardWrapper, key: index },
        React.createElement(LinkCard, { className: combineClassNames(className, partnerPage && style.widerLinkCard), image: card.image, link: card.link, title: card.title, size: card.size }))))));
};
const ErrorMessage = () => (React.createElement("p", null, "Tapahtui virhe :( Yrit\u00E4 hetken p\u00E4\u00E4st\u00E4 uudestaan."));
