import React from 'react';
import { Icon } from '../../Icon.js';
import { HorizontalContainer, VerticalContainer } from '../FlexboxContainer.js';
import { addZeroWidthSpaceBeforeAtSign } from '../../util/email.js';
import styles from './ContactField.scss';
const getIconType = (contactType) => {
    switch (contactType.type) {
        case 'email':
            return 'icon-mail';
        case 'phone':
            return 'icon-phone';
        case 'comment':
            return 'icon-comment';
    }
};
const getContactLink = (contact) => {
    switch (contact.type) {
        case 'email':
            return `mailto:${contact.contact}`;
        case 'phone':
            return `tel:${contact.contact}`;
        case 'comment':
            return `${contact.contact}`;
    }
};
const getContactHeading = (contact) => {
    if (contact.type === 'comment') {
        return contact.description;
    }
    if (contact.type === 'email') {
        return addZeroWidthSpaceBeforeAtSign(contact.contact);
    }
    return contact.contact;
};
const getDescription = (contact) => contact.type !== 'comment'
    ? React.createElement("pre", { className: styles.info }, contact.description)
    : undefined;
export const ContactField = ({ contact }) => (React.createElement("a", { href: getContactLink(contact), className: styles.contact },
    React.createElement(HorizontalContainer, { horizontalAlign: "flex-start", verticalAlign: "center", className: styles.contactField },
        React.createElement(Icon, { type: getIconType(contact) }),
        React.createElement(VerticalContainer, { horizontalAlign: "flex-start", className: styles.content },
            React.createElement("h4", null, getContactHeading(contact)),
            getDescription(contact)))));
