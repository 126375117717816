import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NotFoundPage } from '../NotFoundPage/index.js';
import { brandPageMeta } from '../../meta.js';
import { useGetCategoryHierarchyQuery, useGetProductBrandPageContentQuery, useGetProductBrandPageProductsQuery, } from '../../services/api.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { incrementOffset, selectCategory, setProducts } from '../../reducers/productBrandSlice.js';
import { marked } from 'marked';
import { Icon } from '../../Icon.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadMoreButton } from '../../components/LoadMoreButton.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { ProductBrandDescription } from '../../components/ProductBrandDescription.js';
import { ProductBrandProductList } from '../../components/ProductBrandProductList.js';
import { ProductCategoryDropdown } from '../../components/ProductCategoryDropdown.js';
import { getProductListProduct } from '../../util/products.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { shallowEqual } from 'react-redux';
import style from './style.scss';
const ProductBrandPage = ({ content, categoryHierarchy, products, selectedCategory, isLoadingMore, hasMore, slug }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { title, description, heroImageUrl, categoryHits } = content;
    const metaDescription = parseMetaDescriptionFromMarkdown(description);
    return (React.createElement("div", { className: style.productBrand },
        React.createElement(KesproComHelmet, { title: title, canonicalPath: `/tuotteet/tuotemerkit/${slug}`, description: metaDescription, pageMeta: brandPageMeta({
                name: title,
                image: heroImageUrl,
                description: metaDescription,
            }) }),
        React.createElement("div", { className: style.productBrand__hero, style: {
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 65%, rgba(0,0,0,1) 100%), url(${heroImageUrl})`,
            } },
            React.createElement("button", { onClick: () => { history.push('/tuotteet/tuotemerkit'); }, className: style.productBrand__hero__backButton },
                React.createElement(Icon, { type: 'chevron-left', size: 24 }),
                React.createElement("span", { className: style.productBrand__hero__backButton__name }, "Tuotemerkit")),
            React.createElement("h1", { className: style.productBrand__hero__heading }, title)),
        React.createElement("div", { className: style.productBrand__container },
            description && (React.createElement("div", { className: style.productBrand__description },
                React.createElement(ProductBrandDescription, { description: description }))),
            React.createElement("div", { className: style.productBrand__products, style: { width: description ? '75%' : '100%' } },
                React.createElement("div", { className: style.productBrand__products__productCategoryDropdownContainer },
                    React.createElement(ProductCategoryDropdown, { categoryHierarchy: categoryHierarchy, selectedCategory: selectedCategory, onCategorySelected: (category) => { dispatch(selectCategory(category)); }, productCategoryHitCounts: categoryHits })),
                React.createElement(ProductBrandProductList, { products: products.map((product) => getProductListProduct(product, categoryHierarchy)) }),
                hasMore && (React.createElement(LoadMoreButton, { className: style.loadMoreButton, onClick: () => { dispatch(incrementOffset()); }, isLoading: isLoadingMore }))))));
};
const ProductBrandPageWrapper = (props) => {
    const dispatch = useAppDispatch();
    const hierarchyQueryResult = useGetCategoryHierarchyQuery();
    const { categoryHierarchy, query, slug, products, selectedCategory, hasMore } = useAppSelector((state) => ({
        categoryHierarchy: state.category.hierarchy,
        query: state.productBrand.query,
        slug: state.productBrand.slug,
        products: state.productBrand.products,
        selectedCategory: state.productBrand.selectedCategory,
        hasMore: state.productBrand.hasMore,
    }), shallowEqual);
    const contentQueryResult = useGetProductBrandPageContentQuery(Object.assign({ slug }, parseCacheQueryParams(props.location)));
    const productsQueryResult = useGetProductBrandPageProductsQuery(query);
    useEffect(() => {
        if (productsQueryResult.data) {
            dispatch(setProducts(productsQueryResult.data));
        }
    }, [productsQueryResult.data]);
    if (hierarchyQueryResult.data && contentQueryResult.data && productsQueryResult.data && categoryHierarchy) {
        return (React.createElement(ProductBrandPage, { content: contentQueryResult.data, categoryHierarchy: categoryHierarchy, products: products || productsQueryResult.data.products, selectedCategory: selectedCategory, slug: slug, hasMore: hasMore, isLoadingMore: productsQueryResult.data && productsQueryResult.isFetching }));
    }
    if (hierarchyQueryResult.isError || contentQueryResult.isError || productsQueryResult.isError) {
        return React.createElement(NotFoundPage, { language: 'fi' });
    }
    return React.createElement(LoadingIndicator, { className: style.loading });
};
export { ProductBrandPageWrapper as ProductBrandPage };
const parseMetaDescriptionFromMarkdown = (markdownContent) => {
    const blocks = marked.lexer(markdownContent || '');
    const paragraphs = blocks.filter((block) => block.type === 'paragraph');
    return paragraphs.map(parseParagraphBlock).join(' ').substring(0, 160);
};
const parseParagraphBlock = (block) => block.tokens.map((token) => 'text' in token ? token.text : '').join('');
