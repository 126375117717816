var _a;
import { COMBINED_SEARCH_ALL_SEARCHPHRASE } from '../../common/queryStrings.js';
import { setHandledUrl } from './urlSlice.js';
import { COMBINED_SEARCH_CONTACTS_PAGE_PATH } from '../../common/urlPaths.js';
import QueryString from 'query-string';
import * as rtk from '@reduxjs/toolkit';
import { setCombinedSearchState } from './combinedSearchSlice.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    contactsSearchResult: [],
};
export const updateCombinedSearchContactUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.combinedSearch.searchPhrase;
    const newPath = getCombinedSearchContactUrl(searchPhrase);
    const currentPath = getRelativeUrlFromLocation(location);
    if (location.pathname === COMBINED_SEARCH_CONTACTS_PAGE_PATH && newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
const getCombinedSearchContactUrl = (searchPhrase) => {
    const queryParams = QueryString.stringify({
        [COMBINED_SEARCH_ALL_SEARCHPHRASE]: searchPhrase || undefined,
    });
    return `${COMBINED_SEARCH_CONTACTS_PAGE_PATH}?${queryParams}`;
};
export const combinedSearchContactsSlice = createSlice({
    name: 'combinedSearchContacts',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setCombinedSearchState, (state, action) => {
            state.contactsSearchResult = action.payload.searchResult.hits.contacts;
        });
    },
    reducers: {},
});
export const { reducer } = combinedSearchContactsSlice;
