import React from 'react';
import { Icon } from '../Icon.js';
import styles from './SocialMediaBar.scss';
export function SocialMediaBar(props) {
    const margin = props.iconSize ? `${props.iconSize / 2}px` : '10px';
    const style = {
        marginLeft: margin,
        marginRight: margin,
        color: 'white',
    };
    return (React.createElement("section", { className: styles.someBar }, props.items.map((link) => (React.createElement("a", { key: link.to, href: link.to, target: "_blank", rel: "noopener noreferrer", style },
        React.createElement(Icon, { type: link.iconType, size: props.iconSize, title: link.iconType }))))));
}
