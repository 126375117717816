import React from 'react';
import QueryString from 'query-string';
import { COMBINED_SEARCH_ARTICLES_PAGE_PATH, COMBINED_SEARCH_CONTACTS_PAGE_PATH, COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH, COMBINED_SEARCH_PRODUCTS_PAGE_PATH, COMBINED_SEARCH_RECIPES_PAGE_PATH, } from '../../common/urlPaths.js';
import { COMBINED_SEARCH_ALL_SEARCHPHRASE, COMBINED_SEARCH_PRODUCTS_CATEGORY, COMBINED_SEARCH_RECIPE_SEARCHPHRASE, RECIPE_SEARCH_CATEGORY, RECIPE_SEARCH_INGREDIENTS, } from '../../common/queryStrings.js';
import { SearchTypeSelect } from './SearchTypeSelect.js';
import { useAppSelector } from '../hooks.js';
import { getSelectedFilters, categoryNameToUrlSlug } from '../util/recipe.js';
import { shallowEqual } from 'react-redux';
export const CombinedSearchTypeSelect = ({ className, articlesByTagCount, isArticlesFilteredByTags, resultFilter }) => {
    const { searchPhrase, totalHitCounts, selectedBrand, selectedCategory, recipeActiveFilters, recipeTagSlug } = useAppSelector((state) => {
        var _a;
        return ({
            searchPhrase: state.combinedSearch.searchPhrase,
            totalHitCounts: state.combinedSearch.totalHitCounts,
            selectedBrand: state.combinedSearchProduct.selectedBrand,
            selectedCategory: state.combinedSearchProduct.selectedCategory,
            recipeTagSlug: (_a = state.combinedSearchRecipe.tag) === null || _a === void 0 ? void 0 : _a.slug,
            recipeActiveFilters: state.combinedSearchRecipe.activeFilters,
        });
    }, shallowEqual);
    const recipeFilters = getSelectedFilters(recipeTagSlug, recipeActiveFilters);
    return (React.createElement(SearchTypeSelect, { className: className, activeItemId: resultFilter, items: [
            {
                id: 'products',
                text: 'Tuotteet',
                link: createSearchLink({
                    pathname: COMBINED_SEARCH_PRODUCTS_PAGE_PATH,
                    searchPhrase,
                    selectedCategory,
                    selectedBrand,
                }),
                count: totalHitCounts.products,
            },
            {
                id: 'recipes',
                text: 'Reseptit',
                link: {
                    pathname: recipeFilters.tag
                        ? `${COMBINED_SEARCH_RECIPES_PAGE_PATH}/${categoryNameToUrlSlug(recipeFilters.tag)}`
                        : COMBINED_SEARCH_RECIPES_PAGE_PATH,
                    search: QueryString.stringify({
                        [COMBINED_SEARCH_RECIPE_SEARCHPHRASE]: searchPhrase || undefined,
                        [RECIPE_SEARCH_CATEGORY]: recipeFilters.categories.length ? categoryNameToUrlSlug(recipeFilters.categories[0]) : undefined,
                        [RECIPE_SEARCH_INGREDIENTS]: recipeFilters.mainIngredients.length ? categoryNameToUrlSlug(recipeFilters.mainIngredients[0]) : undefined,
                    }),
                },
                count: totalHitCounts.recipes,
            },
            {
                id: 'articles',
                text: 'Artikkelit',
                link: createSearchLink({
                    pathname: COMBINED_SEARCH_ARTICLES_PAGE_PATH,
                    searchPhrase,
                }),
                count: isArticlesFilteredByTags ? articlesByTagCount : totalHitCounts.articles,
            },
            {
                id: 'contentPages',
                text: 'Sisältösivut',
                link: createSearchLink({
                    pathname: COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH,
                    searchPhrase,
                }),
                count: totalHitCounts.contentPages,
            },
            {
                id: 'contacts',
                text: 'Yhteystiedot',
                link: createSearchLink({
                    pathname: COMBINED_SEARCH_CONTACTS_PAGE_PATH,
                    searchPhrase,
                }),
                count: totalHitCounts.contacts,
            },
        ] }));
};
const createSearchLink = (data) => ({
    pathname: data.pathname,
    search: QueryString.stringify({
        [COMBINED_SEARCH_ALL_SEARCHPHRASE]: data.searchPhrase || undefined,
        [COMBINED_SEARCH_PRODUCTS_CATEGORY]: data.selectedCategory && data.selectedCategory.categoryIdPrefix !== 'root'
            ? data.selectedCategory.category.id
            : undefined,
        tuotemerkki: data.selectedBrand,
    }),
});
