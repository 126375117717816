var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    formData: {
        message: '',
        contactMethod: 'no-contact',
        email: '',
        phone: '',
        clientInfo: '',
    },
    validationErrors: [],
};
export const commonContactFormSlice = createSlice({
    name: 'commonContactForm',
    initialState,
    reducers: {
        resetFormData: (_state) => initialState,
        updateFormData: (state, action) => {
            const { field, value } = action.payload;
            state.formData[field] = value;
            if (action.payload.field === 'contactMethod') {
                state.formData.phone = '';
                state.formData.email = '';
            }
            else {
                state.validationErrors = state.validationErrors.filter((error) => error.id !== field);
            }
        },
        updateValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
    },
});
export const { reducer } = commonContactFormSlice;
export const { resetFormData, updateFormData } = commonContactFormSlice.actions;
