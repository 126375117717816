import React, { useState } from 'react';
import { translations } from '../../staticData.js';
import { SwiperCarousel } from '../SwiperCarousel.js';
import { Pagination, Navigation } from 'swiper/modules';
import { EventCard } from './EventCard.js';
import { LoadMoreButton } from '../LoadMoreButton.js';
import { mergeAnalyticsOptions } from '../../util/analyticsDataAttributes.js';
import { slugifyBrand } from '@kesko/kespro-product-service-api';
import { combineClassNames } from '../../util/css/classNames.js';
import { breakPoint } from '../../util/image.js';
import style from './EventList.scss';
export const EventList = (props) => {
    const [cardsShown, setCardsShown] = useState(8);
    const content = renderContent(props);
    function showMore() {
        setCardsShown(cardsShown + 8);
    }
    return (React.createElement(React.Fragment, null,
        props.description && props.isDisplayedOnNavigationBar &&
            React.createElement("div", { className: style.scrollMargin, id: slugifyBrand(props.description) }),
        React.createElement("div", { className: style.contentContainer },
            React.createElement("h2", null, props.description),
            React.createElement("div", { className: combineClassNames(style.contentWrapper, style.hideOnTablet) }, content.slice(0, cardsShown)),
            React.createElement("div", { className: combineClassNames(style.loadMoreContainer, style.hideOnTablet) }, content.length > cardsShown &&
                React.createElement(LoadMoreButton, { onClick: showMore, altButtonText: "N\u00E4yt\u00E4 lis\u00E4\u00E4" })),
            React.createElement("div", { className: combineClassNames(style.mobileContentWrapper, style.hideOnMedium) },
                React.createElement(SwiperCarousel, { swiperOpts: {
                        modules: [
                            Navigation,
                            Pagination,
                        ],
                        pagination: {
                            bulletClass: combineClassNames('swiper-pagination-bullet', style.paginationBullet),
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 10,
                        },
                        slidesPerView: 1.5,
                        centeredSlides: true,
                        spaceBetween: 10,
                        loop: content.length >= 5,
                        breakpoints: {
                            [breakPoint.MOBILE]: {
                                slidesPerView: 2,
                            },
                        },
                    }, content: content.map(renderCardContainer) })))));
};
const renderContent = (eventList) => (eventList.events.length
    ? eventList.events.map((event, i) => (React.createElement(EventCard, Object.assign({ key: event.id, language: eventList.language }, event, { analyticsOptions: mergeAnalyticsOptions(eventList.analyticsOptions, { indexNumber: i }) }))))
    : [React.createElement("h3", { key: "1" }, translations[eventList.language].common.noUpcomingEvents)]);
const renderCardContainer = (element) => (React.createElement("div", { className: style.eventCard, key: element.key }, element));
