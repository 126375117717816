var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import { RECIPE_PAGE_SIZE } from '../../common/constants.js';
import QueryString from 'query-string';
import { COMBINED_SEARCH_RECIPE_SEARCHPHRASE } from '../../common/queryStrings.js';
import * as R from 'ramda';
import { setCombinedSearchState } from './combinedSearchSlice.js';
import { initialFilters } from './combinedSearchRecipeStateInitialFilters.js';
import { removeNullsFromRecipeFilters, getSelectedFilters, categoryNameToUrlSlug, getSelectedFiltersBasedOnUrl, } from '../util/recipe.js';
import { updateCombinedSearchRecipeUrl } from './urlChangeHandler.js';
const initialState = {
    offset: 0,
    searchResultOffset: 0,
    recipeSearchResult: undefined,
    hasMore: false,
    activeFilters: [],
    filters: initialFilters,
    expandedFilters: [true, true],
    slug: '',
    tag: undefined,
    error: undefined,
    query: {
        filters: {},
        query: '',
        view: {
            limit: RECIPE_PAGE_SIZE,
            offset: 0,
        },
    },
};
export const selectFilterWithUpdates = (selection, location, push, replace) => (dispatch, getState) => {
    var _a;
    dispatch(updateActiveFilters(selection));
    // The query is formed after the activeFilters are set.
    const state = getState();
    const searchPhrase = state.combinedSearch.debouncedSearchPhrase;
    const searchParams = getRecipeSearchParams(state.combinedSearchRecipe.activeFilters, (_a = state.combinedSearchRecipe.tag) === null || _a === void 0 ? void 0 : _a.slug, searchPhrase);
    const query = createRecipeSearchQuery(searchParams);
    dispatch(setQuery({ slug: searchParams.mainFilterSlug, query }));
    dispatch(updateCombinedSearchRecipeUrl(location, push, replace));
};
export const updateTagWithUpdates = (tag, location, push, replace) => (dispatch, getState) => {
    var _a;
    dispatch(updateTagFilter(tag));
    const state = getState();
    const searchPhrase = state.combinedSearch.debouncedSearchPhrase;
    const searchParams = getRecipeSearchParams(state.combinedSearchRecipe.activeFilters, (_a = state.combinedSearchRecipe.tag) === null || _a === void 0 ? void 0 : _a.slug, searchPhrase);
    const query = createRecipeSearchQuery(searchParams);
    dispatch(setQuery({ slug: searchParams.mainFilterSlug, query }));
    dispatch(updateCombinedSearchRecipeUrl(location, push, replace));
};
export const combinedSearchRecipeSlice = createSlice({
    name: 'combinedSearchRecipe',
    initialState,
    extraReducers(builder) {
        builder.addCase(setCombinedSearchState, (state, action) => {
            var _a;
            const { searchPhrase, searchResult } = action.payload;
            state.offset = 0;
            state.searchResultOffset = 0;
            state.recipeSearchResult = searchResult.hits.recipes;
            state.hasMore = state.recipeSearchResult.length === RECIPE_PAGE_SIZE;
            state.query.query = searchPhrase;
            state.query.view.offset = 0;
            state.error = (_a = searchResult.errors) === null || _a === void 0 ? void 0 : _a.recipes;
            if (searchResult.hits.recipeTag) {
                state.tag = searchResult.hits.recipeTag;
            }
        });
    },
    reducers: {
        incrementOffset: (state, _action) => {
            state.offset = state.offset + RECIPE_PAGE_SIZE;
            state.query.view.offset = state.offset;
        },
        expandFilter: (state, action) => {
            const filterIndex = action.payload;
            state.expandedFilters[filterIndex] = !state.expandedFilters[filterIndex];
        },
        setRecipeSearchResult: (state, action) => {
            const { recipes, error, recipeTag } = action.payload;
            if (state.offset === 0 || !state.recipeSearchResult) {
                state.searchResultOffset = state.offset;
                state.recipeSearchResult = recipes;
            }
            else if (state.searchResultOffset < state.offset) {
                state.searchResultOffset = state.offset;
                state.recipeSearchResult = state.recipeSearchResult.concat(recipes);
            }
            state.error = error;
            if (recipeTag) {
                state.tag = recipeTag;
            }
            state.hasMore = state.recipeSearchResult.length < action.payload.totalHits;
        },
        setCombinedSearchRecipeState: (state, action) => {
            const { filters, tag, searchPhrase } = action.payload;
            const searchParams = getRecipeSearchParams(filters, tag, searchPhrase);
            const query = createRecipeSearchQuery(searchParams);
            state.activeFilters = filters;
            state.slug = searchParams.mainFilterSlug;
            state.query = query;
            state.tag = tag ? { title: tag !== null && tag !== void 0 ? tag : '', slug: tag !== null && tag !== void 0 ? tag : '', visibility: true } : undefined;
        },
        updateActiveFilters: (state, action) => {
            const selection = action.payload;
            state.offset = 0;
            state.searchResultOffset = 0;
            state.activeFilters = selection
                ? setFilterSelection(action.payload, removeNullsFromRecipeFilters(state.activeFilters), state.filters)
                : [];
        },
        updateTagFilter: (state, action) => {
            state.offset = 0;
            state.searchResultOffset = 0;
            state.tag = action.payload;
        },
        setQuery(state, action) {
            state.slug = action.payload.slug;
            state.query = action.payload.query;
        },
    },
});
export const { reducer } = combinedSearchRecipeSlice;
export const { expandFilter, incrementOffset, setRecipeSearchResult, setCombinedSearchRecipeState, updateActiveFilters, updateTagFilter, setQuery, } = combinedSearchRecipeSlice.actions;
export const getRecipeSearchStateFromLocation = (filters, location) => {
    const queryParams = QueryString.parse(location.search);
    const parsedFilters = getSelectedFiltersBasedOnUrl(filters, location);
    return {
        filters: parsedFilters.filters,
        tag: parsedFilters.tag,
        searchPhrase: (queryParams[COMBINED_SEARCH_RECIPE_SEARCHPHRASE] || ''),
    };
};
const createRecipeSearchQuery = (query, offset = 0) => {
    const { searchPhrase, categories, mainIngredients } = query;
    return {
        query: searchPhrase,
        filters: Object.assign(Object.assign({}, ((categories === null || categories === void 0 ? void 0 : categories.length) && { categories })), ((mainIngredients === null || mainIngredients === void 0 ? void 0 : mainIngredients.length) && { mainIngredients })),
        tag: query.tag,
        view: {
            limit: RECIPE_PAGE_SIZE,
            offset,
        },
    };
};
const getRecipeSearchParams = (selectedFilters, tag, searchPhrase) => {
    const { categories, mainIngredients, tag: parsedTag } = getSelectedFilters(tag, selectedFilters);
    const slug = [categories, mainIngredients, ''].flat()[0];
    return {
        searchPhrase,
        categories,
        mainIngredients,
        tag: parsedTag,
        mainFilterSlug: categoryNameToUrlSlug(slug),
    };
};
function setFilterSelection(selection, selectedFilters, filters) {
    const REMOVE_ALL_CATEGORY_SELECTIONS = undefined;
    if (selection === REMOVE_ALL_CATEGORY_SELECTIONS) {
        return [];
    }
    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (filter.options && filter.options.length > 0) {
            filter.options.forEach((subFilter) => {
                if (subFilter.categoryName && R.includes(selection.categoryName, subFilter.categoryName)) {
                    if (selection.filterOp === 'deselect') {
                        delete selectedFilters[i];
                    }
                    else {
                        selectedFilters[i] = Object.assign(Object.assign({}, filter), { options: [
                                Object.assign({}, subFilter),
                            ] });
                    }
                }
            });
        }
    }
    return selectedFilters;
}
