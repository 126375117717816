import React from 'react';
import { incrementHeroCarouselViews } from '../../reducers/analyticsSlice.js';
import { Pagination, Navigation } from 'swiper/modules';
import { ContentCarouselContentType, LandingPageElementType, } from '../../../common/apiTypes.js';
import { Icon } from '../../Icon.js';
import { Hero } from './Hero.js';
import { SwiperCarousel } from '../SwiperCarousel.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { useLocation } from 'react-router-dom';
import { mergeAnalyticsOptions } from '../../util/analyticsDataAttributes.js';
import { slugifyBrand } from '@kesko/kespro-product-service-api';
import { IdeaInspirationCard } from '../IdeaInspirationCard.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { breakPoint } from '../../util/image.js';
import style from './ContentCarousel.scss';
export const ContentCarousel = (props) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const heroCarouselViews = useAppSelector((state) => state.analytics.heroCarouselViews);
    const orderedContents = reorderHeroContent(props, heroCarouselViews[location.pathname]);
    const uniqueNextClass = `${style.next}-${props.id}`;
    const uniquePrevClass = `${style.prev}-${props.id}`;
    const isHeroContent = props.contentType === ContentCarouselContentType.HERO;
    const [content] = React.useState(orderedContents);
    React.useEffect(() => {
        if (isHeroContent) {
            dispatch(incrementHeroCarouselViews(location.pathname));
        }
    }, []);
    return (!!content && content.length
        ? React.createElement("div", { "data-pw": "content-carousel" },
            props.title && props.isDisplayedOnNavigationBar &&
                React.createElement("div", { className: style.scrollMargin, id: slugifyBrand(props.title) }),
            props.title && React.createElement("h2", { className: style.title }, props.title),
            React.createElement("div", { className: isHeroContent ? style.relativeHero : style.relative },
                React.createElement("button", { "data-pw": "left-swiper-button", className: combineClassNames(style.swiperButton, isHeroContent ? style.prevHero : style.prev, uniquePrevClass) },
                    React.createElement(Icon, { type: "chevron-left", noStyles: true })),
                React.createElement("button", { "data-pw": "right-swiper-button", className: combineClassNames(style.swiperButton, isHeroContent ? style.nextHero : style.next, uniqueNextClass) },
                    React.createElement(Icon, { type: "chevron-right", noStyles: true })),
                React.createElement(SwiperCarousel, { swiperOpts: {
                        modules: [
                            Navigation,
                            Pagination,
                        ],
                        pagination: {
                            bulletClass: combineClassNames('swiper-pagination-bullet', style.paginationBullet),
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 10,
                        },
                        navigation: {
                            nextEl: `.${uniqueNextClass}`,
                            prevEl: `.${uniquePrevClass}`,
                        },
                        slidesPerView: 1,
                        spaceBetween: 10,
                        loop: isHeroContent || content.length >= 5,
                        breakpoints: !isHeroContent
                            ? {
                                [breakPoint.TABLET + 1]: {
                                    slidesPerView: Math.min(2, props.content.length),
                                },
                                [breakPoint.DESKTOP + 1]: {
                                    slidesPerView: Math.min(3, props.content.length),
                                },
                            } : undefined,
                    }, content: content.map(renderCarouselContentByType(props.analyticsOptions)) })))
        : React.createElement(React.Fragment, null));
};
const renderCarouselContentByType = (analyticsOptions) => (content, index) => {
    switch (content.type) {
        case LandingPageElementType.Hero:
            return (React.createElement("div", { className: style.heroCard, key: content.id },
                React.createElement(Hero, Object.assign({}, content, { analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) }))));
        case 'article':
        case 'recipe':
            return (React.createElement("div", { className: style.articleCard, key: content.slug },
                React.createElement(IdeaInspirationCard, { card: content, mode: "hybrid", analyticsOptions: mergeAnalyticsOptions(analyticsOptions, { indexNumber: index }) })));
        default:
            return React.createElement(React.Fragment, null);
    }
};
const reorderHeroContent = (content, indexToMove) => {
    if (content.contentType !== ContentCarouselContentType.HERO) {
        return content.content;
    }
    const contentArr = content.content;
    const arrLength = contentArr.length;
    const safeIndex = indexToMove % arrLength;
    const elementsToMove = contentArr.slice(0, safeIndex);
    const restElements = contentArr.slice(safeIndex, arrLength);
    return [...restElements, ...elementsToMove];
};
