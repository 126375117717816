import { __awaiter } from "tslib";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CRM_FORM_ERROR_KEY } from '../../../common/constants.js';
export const useFormCapture = () => {
    const [scriptLoadingStatus, setScriptLoadingStatus] = useState(1 /* ScriptLoadingStatus.Loading */);
    const [submitStatus, setSubmitStatus] = useState();
    const location = useLocation();
    const handleSubmit = (form) => __awaiter(void 0, void 0, void 0, function* () {
        const errorTimeoutId = setTimeout(() => {
            var _a;
            setSubmitStatus('send-error');
            (_a = window.dtrum) === null || _a === void 0 ? void 0 : _a.reportCustomError(CRM_FORM_ERROR_KEY, 'CRM form submission timed out');
        }, 15000);
        if (!MsCrmMkt) {
            setSubmitStatus('send-error');
        }
        else {
            try {
                yield MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(form);
                clearTimeout(errorTimeoutId);
                setSubmitStatus('send-success');
                return true;
            }
            catch (e) {
                clearTimeout(errorTimeoutId);
                // This part is unreachable in local environments, it seems that the sendFormCaptureToCrm stops the code execution here and after
                setSubmitStatus('send-error');
            }
        }
        return false;
    });
    useEffect(() => {
        setScriptLoadingStatus(1 /* ScriptLoadingStatus.Loading */);
        // The script should be dynamically loaded as it will register the forms that can be submitted.
        const script = document.createElement('script');
        script.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007';
        script.async = true;
        script.onerror = () => {
            var _a;
            setScriptLoadingStatus(0 /* ScriptLoadingStatus.Error */);
            (_a = window.dtrum) === null || _a === void 0 ? void 0 : _a.reportCustomError(CRM_FORM_ERROR_KEY, 'Failed to load CRM form-capture script');
        };
        script.onload = () => {
            setScriptLoadingStatus(2 /* ScriptLoadingStatus.Ready */);
        };
        const head = document.getElementsByTagName('head')[0];
        window.MsCrmMkt = undefined;
        head.appendChild(script);
        return () => {
            head.removeChild(script);
        };
    }, [location.pathname]);
    return {
        abort: () => {
            setSubmitStatus(undefined);
        },
        start: () => {
            setSubmitStatus('send-pending');
        },
        scriptLoadingStatus,
        submitStatus,
        submit: handleSubmit,
    };
};
