import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RecipeSearchResult } from './RecipeSearchResult.js';
import { ContentPageSearchResult } from './ContentPageSearchResult.js';
import { ProductSearchResultsContainer } from './ProductSearchResultsContainer.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { debounce } from 'lodash-es';
import { AUTO_SEARCH_DEBOUNCE_MS } from '../../common/constants.js';
import { setSearchPhrase, updateCombinedSearchDebouncedSearchPhrase } from '../reducers/combinedSearchSlice.js';
import { useGetRecipeSearchSeoStateQuery } from '../services/api.js';
import { incrementArticlesByTagOffset } from '../reducers/articlesByTagSlice.js';
import { incrementOffset as incrementArticleSearchOffset } from '../reducers/combinedSearchArticleSlice.js';
import { incrementOffset as incrementRecipeSearchOffset } from '../reducers/combinedSearchRecipeSlice.js';
import { incrementContentPageOffset } from '../reducers/combinedSearchContentPageSlice.js';
import { COMBINED_SEARCH_ARTICLES_PAGE_PATH, COMBINED_SEARCH_PRODUCTS_PAGE_PATH } from '../../common/urlPaths.js';
import { useCombinedSearchRecipeQuery } from '../hooks/useCombinedSearchRecipeQuery.js';
import { useCombinedSearchArticlesQuery } from '../hooks/useCombinedSearchArticlesQuery.js';
import { isLocation, isWholesale } from '../../common/apiTypes.js';
import { useArticlesByTagQuery } from '../hooks/useArticlesByTagQuery.js';
import { useContentPagesQuery } from '../hooks/useContentPagesQuery.js';
import { CombinedSearchTypeSelect } from './CombinedSearchTypeSelect.js';
import { SearchPhraseSuggestions } from './SearchPhraseSuggestions.js';
import { searchPhraseSuggestionClickedEvent } from '../reducers/analyticsSlice.js';
import { ContactList } from './ContactList.js';
import { VerticalContainer, HorizontalContainer } from './FlexboxContainer.js';
import { IdeaInspirationCardList, applyCardFilters } from './IdeaInspirationCardList.js';
import { KesproInfoBoxes } from './KesproInfoBoxes.js';
import { SearchBox } from './SearchBox.js';
import { scrollToTop } from '../util/scroll.js';
import { shallowEqual } from 'react-redux';
import { parseCacheQueryParams } from '../../common/queryStrings.js';
import styles from './CombinedSearch.scss';
export const CombinedSearch = (props) => {
    const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
    const [suggestionBoxOpen, setSuggestionBoxOpen] = useState(false);
    const [suggestionClicked, setSuggestionClicked] = useState(false);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { push, replace } = useHistory();
    const searchPhrase = useAppSelector((state) => state.combinedSearch.searchPhrase);
    const isArticlesFilteredByTags = !!props.articleType;
    const debouncedSearch = useCallback(debounce((val, loc) => {
        dispatch(updateCombinedSearchDebouncedSearchPhrase(val, loc, push, replace));
    }, AUTO_SEARCH_DEBOUNCE_MS), []);
    const searchContainerRef = React.useRef();
    const handleSearchPhraseChange = (value, isSuggestionClicked) => {
        dispatch(setSearchPhrase(value));
        debouncedSearch(value, location);
        setSuggestionBoxOpen(isSuggestionClicked ? false : true);
    };
    return (React.createElement(VerticalContainer, { horizontalAlign: "stretch", wrap: false, className: styles.combinedSearch },
        React.createElement("section", { className: styles.heroContainer, style: {
                backgroundImage: `
                    linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
                    url(${props.heroImageUrl}?w=1440)`,
            } },
            React.createElement("div", { className: styles.searchContainer, ref: searchContainerRef },
                React.createElement(SearchBox, { placeholder: "Hae", searchPhrase: searchPhrase, resultFilter: props.resultFilter, onChange: (value) => { handleSearchPhraseChange(value, false); }, onSearch: () => {
                        debouncedSearch.flush();
                    }, onToggleMobileFilters: () => {
                        setIsMobileFiltersOpen(!isMobileFiltersOpen);
                    }, onClearButtonClick: (value) => {
                        dispatch(setSearchPhrase(value));
                        debouncedSearch(value, location);
                        setSuggestionClicked(false);
                        setSuggestionBoxOpen(false);
                    }, onEnterPress: () => {
                        setSuggestionBoxOpen(false);
                    } }),
                location.pathname.startsWith(COMBINED_SEARCH_PRODUCTS_PAGE_PATH) && !suggestionClicked && suggestionBoxOpen &&
                    React.createElement(SearchPhraseSuggestions, { searchPhrase: searchPhrase, handleSuggestionClick: (writtenCharacters, clickedSuggestion) => {
                            handleSearchPhraseChange(clickedSuggestion, true);
                            searchPhraseSuggestionClickedEvent(writtenCharacters, clickedSuggestion);
                        }, setSuggestionBoxOpen: setSuggestionBoxOpen, searchContainerRef: searchContainerRef }))),
        React.createElement(HorizontalContainer, { className: styles.searchTypeSelectContainer, horizontalAlign: "center" },
            React.createElement(CombinedSearchTypeSelect, { className: styles.searchTypeSelect, articlesByTagCount: props.articlesByTagCount, isArticlesFilteredByTags: isArticlesFilteredByTags, resultFilter: props.resultFilter })),
        React.createElement(SearchResultRenderer, Object.assign({}, props, { searchPhrase: searchPhrase, urlParam: props.articleType || '', isMobileFiltersOpen: isMobileFiltersOpen, onCloseMobileFilters: () => {
                setIsMobileFiltersOpen(false);
            }, location: location, push: push, replace: replace })),
        props.resultFilter !== 'recipes' ? React.createElement(KesproInfoBoxes, null) : undefined));
};
const SearchResultRenderer = (props) => {
    switch (props.resultFilter) {
        case 'products':
            return React.createElement(ProductSearchResultsContainer, Object.assign({}, props));
        case 'recipes':
            return React.createElement(RecipeSearchResults, Object.assign({}, props));
        case 'articles':
            return React.createElement(ArticlesResult, Object.assign({}, props));
        case 'contacts':
            return React.createElement(ContactsSearchResults, null);
        case 'contentPages':
            return React.createElement(ContentPagesSearchResults, Object.assign({}, props));
        default:
            return React.createElement(React.Fragment, null);
    }
};
const ContactsSearchResults = () => {
    const contacts = useAppSelector((state) => state.combinedSearchContacts.contactsSearchResult);
    const wholesaleContacts = [];
    const locationContacts = [];
    const others = [];
    contacts.forEach((contact) => {
        if (isWholesale(contact)) {
            wholesaleContacts.push(contact);
        }
        else if (isLocation(contact)) {
            locationContacts.push(contact);
        }
        else {
            others.push(contact);
        }
    });
    wholesaleContacts.sort(R.ascend((c) => c.locality));
    others.sort(R.ascend((c) => c.name));
    return (React.createElement("div", { className: styles.contactsContainer }, contacts.length
        ?
            React.createElement(React.Fragment, null,
                React.createElement(ContactList, { contacts: wholesaleContacts, header: 'Noutotukut' }),
                React.createElement(ContactList, { contacts: locationContacts, header: 'Muut sijainnit' }),
                React.createElement(ContactList, { contacts: others, header: 'Myynti' }))
        : React.createElement("p", null, "Ei hakutuloksia")));
};
const ContentPagesSearchResults = (props) => {
    const dispatch = useAppDispatch();
    const { hasMore, offset } = useAppSelector((state) => ({
        hasMore: state.combinedSearchContentPage.hasMore,
        offset: state.combinedSearchContentPage.offset,
    }), shallowEqual);
    const contentPagesSearchQueryResult = useContentPagesQuery();
    return (React.createElement("div", null,
        React.createElement(ContentPageSearchResult, { pages: contentPagesSearchQueryResult.data, hasMore: hasMore, isError: contentPagesSearchQueryResult.isError, isLoaded: contentPagesSearchQueryResult.isFetching, isLoadingMore: offset > 0 && contentPagesSearchQueryResult.isFetching, loadMorePages: () => {
                dispatch(incrementContentPageOffset());
            }, location: props.location, push: props.push, replace: props.replace })));
};
const RecipeSearchResults = (props) => {
    const dispatch = useAppDispatch();
    const { lastUsedSearchPhrase, slug, activeFilters, hasMore, offset } = useAppSelector((state) => ({
        lastUsedSearchPhrase: state.combinedSearch.lastUsedSearchPhrase,
        slug: state.combinedSearchRecipe.slug,
        activeFilters: state.combinedSearchRecipe.activeFilters,
        hasMore: state.combinedSearchRecipe.hasMore,
        offset: state.combinedSearchRecipe.offset,
    }), shallowEqual);
    const searchQueryResult = useCombinedSearchRecipeQuery();
    const seoQueryResult = useGetRecipeSearchSeoStateQuery(Object.assign(Object.assign({}, parseCacheQueryParams(props.location)), { slug: slug }), { skip: !slug });
    return (React.createElement(RecipeSearchResult, { recipes: searchQueryResult.data, hasMore: hasMore, isError: searchQueryResult.isError, isLoaded: searchQueryResult.isFetching, isLoadingMore: offset > 0 && searchQueryResult.isFetching, loadMoreRecipes: () => {
            dispatch(incrementRecipeSearchOffset());
        }, recipeSeo: seoQueryResult.data, selectedFilters: activeFilters, isMobileFiltersOpen: props.isMobileFiltersOpen, onCloseMobileFilters: props.onCloseMobileFilters, lastUsedSearchPhrase: lastUsedSearchPhrase, location: props.location, push: props.push, replace: props.replace }));
};
const Articles = () => {
    const dispatch = useAppDispatch();
    const articleSearchQueryResult = useCombinedSearchArticlesQuery();
    const hasMore = useAppSelector((state) => state.combinedSearchArticle.hasMore);
    return (React.createElement(IdeaInspirationCardList, { cards: applyCardFilters('article', articleSearchQueryResult.data), hasMoreCards: hasMore, skeletonCardCount: 6, isLoadingMore: articleSearchQueryResult.isFetching, isError: articleSearchQueryResult.isError, onLoadMore: () => {
            dispatch(incrementArticleSearchOffset());
        } }));
};
const ArticlesByTag = (props) => {
    const dispatch = useAppDispatch();
    const articleSearchQueryResult = useArticlesByTagQuery(props.urlParam);
    const articleSearchResultByTagHasMore = useAppSelector((state) => state.articlesByTag.articleSearchResultByTagHasMore);
    const clearArticles = () => {
        props.push(COMBINED_SEARCH_ARTICLES_PAGE_PATH);
    };
    if (articleSearchQueryResult && props.urlParam) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: styles.header },
                "Artikkelit aiheella \"",
                props.urlParam,
                "\""),
            React.createElement(IdeaInspirationCardList, { cards: applyCardFilters('article', articleSearchQueryResult.data), hasMoreCards: articleSearchResultByTagHasMore, skeletonCardCount: 6, isLoadingMore: articleSearchQueryResult.isLoading, isError: articleSearchQueryResult.isError, onLoadMore: () => {
                    dispatch(incrementArticlesByTagOffset());
                } }),
            React.createElement(HorizontalContainer, { className: styles.helpTextContainer, horizontalAlign: "center", verticalAlign: "center" },
                React.createElement("p", null,
                    "Etk\u00F6 l\u00F6yt\u00E4nyt haluamaasi sis\u00E4lt\u00F6\u00E4? Muokkaa hakuasi tai ",
                    ' ',
                    React.createElement("button", { className: styles.resetButton, onClick: () => { clearArticles(); scrollToTop(); } }, "nollaa rajaukset")))));
    }
    return React.createElement(React.Fragment, null);
};
const ArticlesResult = (props) => {
    if (props.articleType) {
        return React.createElement(ArticlesByTag, Object.assign({}, props));
    }
    return React.createElement(Articles, null);
};
