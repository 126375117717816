import { __awaiter } from "tslib";
import React from 'react';
import { UAParser } from 'ua-parser-js';
import { createFieldValidator, createValidation, errorMessage, ifError, isBlank, isValidEmail, isValidPhoneNumber, } from '@kesko/kespro-ui-common';
import { VerticalContainer } from '../FlexboxContainer.js';
import { ContactMethodSelector } from './ContactMethodSelector.js';
import { FormStatusIndicator } from './FormStatusIndicator.js';
import { ValidationErrors } from './ValidationErrors.js';
import { formValidationErrors, translations } from '../../staticData.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { commonContactFormSlice, resetFormData, updateFormData } from '../../reducers/commonContactFormSlice.js';
import inputStyles from '../Input/style.scss';
import styles from './CommonContactForm.scss';
import { useFormCapture } from '../../util/hooks.js';
import { CONTACT_FORM_ID } from '../../../common/constants.js';
import { formSubmittedEvent } from '../../reducers/analyticsSlice.js';
import { EnvironmentField } from './EnvironmentField.js';
import { TextArea } from '../Input/TextArea.js';
import { TextBox } from '../Input/TextBox.js';
const fbField = createFieldValidator();
export const commonContactFormValidator = createValidation(fbField('message', (value) => !isBlank(value), formValidationErrors.emptyMessage), fbField('message', (value) => value.length <= 2000, formValidationErrors.tooLongMessage), fbField('email', (value, state) => state.contactMethod !== 'email' || isValidEmail(value), formValidationErrors.email), fbField('phone', (value, state) => state.contactMethod !== 'phone' || isValidPhoneNumber(value), formValidationErrors.phone));
export const CommonContactForm = (props) => {
    const { formData, validationErrors } = useAppSelector((state) => state.commonContactForm);
    const { strings, language } = props;
    const dispatch = useAppDispatch();
    const formCapture = useFormCapture();
    function handleFieldChange(field) {
        return (value) => {
            dispatch(updateFormData({ field, value }));
        };
    }
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        const data = Object.assign(Object.assign({}, formData), { clientInfo: getClientInfo() });
        formCapture.start();
        const errors = commonContactFormValidator.validate(data);
        if (errors.length > 0) {
            formCapture.abort();
            dispatch(commonContactFormSlice.actions.updateValidationErrors(errors));
        }
        else {
            const success = yield formCapture.submit(event.target);
            if (success) {
                formSubmittedEvent('feedback');
                dispatch(resetFormData());
            }
        }
    });
    if (formCapture.scriptLoadingStatus === 0 /* ScriptLoadingStatus.Error */) {
        return React.createElement("p", { className: styles.formCaptureError }, translations[language].common.formCaptureLoadingError);
    }
    return (React.createElement("form", { className: styles.form, id: CONTACT_FORM_ID, onSubmit: handleSubmit },
        React.createElement("input", { className: styles.hidden, type: "checkbox", name: "common contact request", checked: true, readOnly: true, "aria-hidden": true }),
        React.createElement(EnvironmentField, null),
        React.createElement(VerticalContainer, { horizontalAlign: "center" },
            formCapture.submitStatus !== 'send-success' && (React.createElement(React.Fragment, null,
                React.createElement(TextArea, { name: "message", label: strings.commonContactFormMessage, placeholder: strings.commonContactFormPlaceHolder, value: formData.message, onChange: handleFieldChange('message'), className: styles.textareaContainer, validationError: errorMessage(validationErrors, 'message', language) }),
                React.createElement("h3", { className: ifError(validationErrors, 'contactMethod', inputStyles.invalid) }, props.strings.commonContactFormHeader),
                React.createElement(ContactMethodSelector, { strings: strings, method: formData.contactMethod, allowNoContact: true, onChange: handleFieldChange('contactMethod') }),
                React.createElement(EmailOrPhoneField, { contactMethod: formData.contactMethod, email: formData.email, setEmail: handleFieldChange('email'), phone: formData.phone, setPhone: handleFieldChange('phone'), strings: strings, language: language }),
                React.createElement("input", { type: "submit", disabled: formCapture.submitStatus === 'send-pending' || formCapture.scriptLoadingStatus === 1 /* ScriptLoadingStatus.Loading */, value: strings.commonContactFormButton, className: inputStyles.button }))),
            React.createElement(FormStatusIndicator, { status: formCapture.submitStatus, strings: strings }),
            React.createElement(ValidationErrors, { errors: validationErrors, language: language, strings }))));
};
const EmailOrPhoneField = (props) => {
    const { contactMethod, email, setEmail, phone, setPhone, validationErrors, strings, language } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(TextBox, { name: "email", className: contactMethod === 'email' ? styles.contactInput : styles.hidden, label: strings.commonContactFormEmail, value: email, onChange: setEmail, validationError: errorMessage(validationErrors, email, language) }),
        React.createElement(TextBox, { name: "phone", className: contactMethod === 'phone' ? styles.contactInput : styles.hidden, label: strings.commonContactFormPhone, value: phone, onChange: setPhone, validationError: errorMessage(validationErrors, phone, language) })));
};
function getClientInfo() {
    return new UAParser().getResult();
}
