import { __awaiter } from "tslib";
import React from 'react';
import { createFieldValidator, createValidation, errorMessage, isBlank, isValidEmail, isValidPhoneNumber, } from '@kesko/kespro-ui-common';
import { VerticalContainer } from '../FlexboxContainer.js';
import { FormStatusIndicator } from './FormStatusIndicator.js';
import { ValidationErrors } from './ValidationErrors.js';
import { languageToLocale, LandingPageElementType } from '../../../common/apiTypes.js';
import { formValidationErrors } from '../../staticData.js';
import { useFormCapture } from '../../util/hooks.js';
import { REGISTRATION_FORM_ID } from '../../../common/constants.js';
import { EnvironmentField } from './EnvironmentField.js';
import { TextBox } from '../Input/TextBox.js';
import { TextArea } from '../Input/TextArea.js';
import { formSubmittedEvent } from '../../reducers/analyticsSlice.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { changeRegistrationFormField, resetRegistrationForm, setValidationErrors, } from '../../reducers/registrationFormSlice.js';
import { useGetLandingPageQuery } from '../../services/api.js';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button.js';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './RegistrationForm.scss';
const regField = createFieldValidator();
export const registrationFormValidator = createValidation(regField('forename', (value) => !isBlank(value), formValidationErrors.forename), regField('surname', (value) => !isBlank(value), formValidationErrors.surname), regField('email', isValidEmail, formValidationErrors.email), regField('phone', (value) => value.startsWith('+') && isValidPhoneNumber(value), formValidationErrors.phone), regField('companyName', (value) => !isBlank(value), formValidationErrors.companyName), regField('businessId', (value) => !isBlank(value), formValidationErrors.businessId), regField('locality', (value) => !isBlank(value), formValidationErrors.locality));
export const RegistrationForm = (props) => {
    const { formData, validationErrors } = useAppSelector((state) => state.registrationForm);
    const { strings, language } = props;
    const dispatch = useAppDispatch();
    const formCapture = useFormCapture();
    const history = useHistory();
    useGetLandingPageQuery({
        slug: strings.registrationFormSuccessRedirectPage,
        locale: languageToLocale(language),
    }, {
        skip: !formCapture.submitStatus,
    });
    function handleTextFieldChange(field) {
        return (value) => {
            dispatch(changeRegistrationFormField({ field, value }));
        };
    }
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        formCapture.start();
        const errors = registrationFormValidator.validate(formData);
        dispatch(setValidationErrors(errors));
        if (errors.length) {
            formCapture.abort();
        }
        else {
            const success = yield formCapture.submit(event.target);
            if (success) {
                formSubmittedEvent('registration');
                dispatch(resetRegistrationForm());
                if (strings.registrationFormSuccessRedirectPage !== '') {
                    history.push(strings.registrationFormSuccessRedirectPage);
                }
            }
        }
    });
    if (formCapture.scriptLoadingStatus === 0 /* ScriptLoadingStatus.Error */) {
        return React.createElement("p", { className: styles.formCaptureError }, strings.formCaptureLoadingError);
    }
    return (React.createElement("form", { className: styles.form, id: REGISTRATION_FORM_ID, onSubmit: handleSubmit },
        React.createElement("input", { className: styles.hidden, type: "checkbox", name: "contact request", checked: true, readOnly: true, "aria-hidden": true }),
        React.createElement(EnvironmentField, null),
        React.createElement(VerticalContainer, { horizontalAlign: "center" },
            formCapture.submitStatus !== 'send-success' && (React.createElement(FormBody, Object.assign({}, props, { formData: formData, isFormCaptureScriptLoading: formCapture.scriptLoadingStatus === 1 /* ScriptLoadingStatus.Loading */, onTextChange: handleTextFieldChange, status: formCapture.submitStatus, validationErrors: validationErrors }))),
            React.createElement(FormStatusIndicator, { status: formCapture.submitStatus, strings: Object.assign(Object.assign({}, strings), { formStatusSucces: strings.registrationFormStatusSuccess }) }),
            React.createElement(ValidationErrors, { errors: validationErrors, language: language, strings }))));
};
function required(label) {
    return `${label}*`;
}
const FormBody = (props) => {
    const { status, isFormCaptureScriptLoading, onTextChange, validationErrors, strings, language } = props;
    const { forename, surname, businessId, companyName, locality, additionalInfo, email, phone } = props.formData;
    const styleOptions = { borderFull: true, labelLeft: true, labelStyleSimple: true, textCenter: false };
    return (React.createElement(VerticalContainer, { horizontalAlign: "center" },
        React.createElement("div", { className: styles.formBody },
            React.createElement(TextBox, Object.assign({}, styleOptions, { className: styles.contactInput, name: "forename", label: required(strings.forename), onChange: onTextChange('forename'), value: forename, validationError: errorMessage(validationErrors, 'forename', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { className: styles.contactInput, name: "surname", onChange: onTextChange('surname'), label: required(strings.surname), value: surname, validationError: errorMessage(validationErrors, 'surname', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { className: styles.contactInput, name: "email", label: required(strings.commonContactFormEmail), onChange: onTextChange('email'), value: email, validationError: errorMessage(validationErrors, 'email', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { label: required(strings.registrationFormPhone), className: combineClassNames(styles.contactInput, styles.phoneNumberField), name: "phone", onChange: onTextChange('phone'), placeholder: "+3581234567", value: phone, validationError: errorMessage(validationErrors, 'phone', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { label: required(strings.businessName), name: "companyName", value: companyName, onChange: onTextChange('companyName'), className: styles.contactInput, validationError: errorMessage(validationErrors, 'companyName', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { label: required(strings.businessId), value: businessId, name: "businessId", onChange: onTextChange('businessId'), placeholder: strings.businessIdPlaceholder, className: styles.contactInput, validationError: errorMessage(validationErrors, 'businessId', language) })),
            React.createElement(TextBox, Object.assign({}, styleOptions, { name: "locality", label: required(strings.locality), value: locality, onChange: onTextChange('locality'), className: styles.contactInput, validationError: errorMessage(validationErrors, 'locality', language) }))),
        React.createElement(TextArea, Object.assign({}, styleOptions, { name: "additionalInfo", label: strings.additionalInfo, value: additionalInfo, onChange: onTextChange('additionalInfo'), className: styles.contactTextArea })),
        React.createElement(Button, { isDisabled: status === 'send-pending' || isFormCaptureScriptLoading, isSubmitButton: true, isExternalLink: false, size: "small" /* ButtonSize.Small */, text: strings.registrationFormButton, buttonType: "primary" /* ButtonVariant.Primary */, type: LandingPageElementType.Button })));
};
