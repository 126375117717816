import React from 'react';
import { FilterBar } from '../../components/FilterBar.js';
import style from './style.scss';
const filters = [
    { to: '/lakisaateinen/kayttoehdot', caption: 'Käyttöehdot' },
    { to: '/lakisaateinen/tietosuojaseloste', caption: 'Tietosuojaseloste' },
];
export function LegalPage(props) {
    return (React.createElement("div", null,
        React.createElement("div", { className: style.border },
            React.createElement(FilterBar, { filters: filters, pathName: props.location.pathname, className: style.filters })),
        props.children));
}
