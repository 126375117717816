import { isMainCategory, isSubcategory, } from '@kesko/kespro-ui-common';
const joinChildIds = (cat) => cat.children.map((c) => c.category.id).join(' ');
const getOwnId = (cat) => cat.category.id;
export function getQueryCategoryIds(category, transform) {
    return category && isMainCategory(category) ? transform(joinChildIds(category)) :
        category && isSubcategory(category) ? transform(getOwnId(category)) :
            undefined;
}
export function setCategoryParents(response) {
    // Set parent category for all categories (except the root)
    const stack = [response];
    while (stack.length > 0) {
        const parent = stack.pop();
        parent.children.forEach((c) => {
            c.parent = parent;
            stack.push(c);
        });
    }
    return response;
}
