import React from 'react';
import { Link } from 'react-router-dom';
import { commonContactFormPageUrls, contactListPageUrls } from '../../common/urlPaths.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './NotFoundComponent.scss';
export const NotFoundComponent = (props) => (React.createElement("div", { className: combineClassNames(styles.notFound, props.className) },
    React.createElement("h1", null, "Page not found :("),
    React.createElement("p", null,
        "The link you clicked may be broken or the page may have been removed. Visit ",
        React.createElement(Link, { to: "/" }, "the homepage"),
        " or ",
        React.createElement(Link, { to: contactListPageUrls.en }, "contact us"),
        " about the problem."),
    React.createElement("h1", null, "Sivua ei l\u00F6ytynyt :("),
    React.createElement("p", null,
        "Syyn\u00E4 voi olla rikkin\u00E4inen linkki tai sivu on mahdollisesti poistettu. Siirry ",
        React.createElement(Link, { to: "/" }, "kotisivulle"),
        " tai ",
        React.createElement(Link, { to: commonContactFormPageUrls.fi }, "ota yhteytt\u00E4"),
        " ongelmaan liittyen.")));
