import { getLastCategory, getCategoryLink } from '../../../../util/products.js';
export function transformBreadcrumbMap(categories, hierarchy) {
    if (!categories) {
        return undefined;
    }
    const breadcrumbList = [];
    let category = getLastCategory(categories, hierarchy);
    if (!category) {
        return new Map();
    }
    while (category && category.category.id !== 'root') {
        const categoryLink = getCategoryLink(category);
        breadcrumbList.push({ link: categoryLink, name: category.category.name });
        category = category.parent;
    }
    // Reverse breadcrumb list (comes from kespro-product-service in the wrong order)
    breadcrumbList.reverse();
    // populate breadcrumbMap with reversed list
    const breadcrumbMap = new Map();
    for (const item of breadcrumbList) {
        breadcrumbMap.set(item.link, item.name);
    }
    return breadcrumbMap;
}
export const transformSoloImages = (p) => {
    var _a;
    const images = {};
    (_a = p.images) === null || _a === void 0 ? void 0 : _a.forEach((image) => {
        images[p.ean] = {
            primary: {
                url: image.url,
                ean: p.ean,
                bynderId: '',
                angle: '',
                packagedNotPackaged: '',
                facing: '',
                variation: '',
                businessUnit: '',
                type: '',
                publishChannel: '',
                terms: '',
                productImageType: '',
                primary: true,
                limited: false,
                imageTypeCode: '',
                lastModified: '',
            },
            additionals: [],
            limited: {
                primary: {
                    url: '',
                    ean: '',
                    bynderId: '',
                    angle: '',
                    packagedNotPackaged: '',
                    facing: '',
                    variation: '',
                    businessUnit: '',
                    type: '',
                    publishChannel: '',
                    terms: '',
                    productImageType: '',
                    primary: false,
                    limited: true,
                    imageTypeCode: '',
                    lastModified: '',
                },
                additionals: [],
            },
            documents: [],
            limitedDocuments: [],
        };
    });
    return images;
};
/**
 * @param value string - slugify(`${name}-${ean}`)
 * @returns string
 * @description From kesko-dev/kespro-product-service: https://github.com/kesko-dev/kespro-product-service. Converts a string to a slug in a similar way that kespro-product-service does.
 */
export function slugify(value) {
    return value
        .toLowerCase()
        .trim()
        .replace(/[åä]+/g, 'a')
        .replace(/[ö]+/g, 'o')
        .replace(/[^\w-]+/g, '-')
        .replace(/-{2,}/g, '-')
        .replace(/^-+|-+$/g, '');
}
