import React, { useRef } from 'react';
import styles from './DigitalServicesWrapper.scss';
import { Cookies } from 'react-cookie';
import { getWindow } from '../../../common/util.js';
import { DigitalServicesCard, DigitalServicesCardSkeleton } from './DigitalServiceCard.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { setDigitalServices } from '../../reducers/authSlice.js';
const SKELETON_CARD_COUNT = 3;
export const DigitalServicesWrapper = () => {
    const dispatch = useAppDispatch();
    const { accountId, digitalServices, isLoggedIn } = useAppSelector((state) => state.auth);
    const intervalIdRef = useRef(undefined);
    const cookies = new Cookies();
    const window = getWindow();
    const checkCookie = (accountIdToCheck) => {
        if (isLoggedIn && cookies.get(accountIdToCheck)) {
            try {
                const services = cookies.get(accountIdToCheck);
                dispatch(setDigitalServices(services));
                window === null || window === void 0 ? void 0 : window.clearInterval(intervalIdRef.current);
            }
            catch (e) {
                window === null || window === void 0 ? void 0 : window.clearInterval(intervalIdRef.current);
            }
            intervalIdRef.current = undefined;
        }
    };
    React.useEffect(() => {
        if (isLoggedIn && !digitalServices) {
            const id = window === null || window === void 0 ? void 0 : window.setInterval(checkCookie, 100, accountId);
            intervalIdRef.current = id ? id : 0;
        }
        return () => {
            if (intervalIdRef.current !== undefined) {
                window === null || window === void 0 ? void 0 : window.clearInterval(intervalIdRef.current);
            }
        };
    }, [isLoggedIn]);
    const wrapperStyles = { '--min-repeat-count': isLoggedIn && (digitalServices === null || digitalServices === void 0 ? void 0 : digitalServices.length) ? digitalServices.length : SKELETON_CARD_COUNT };
    return isLoggedIn
        ? (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.containerTitle },
                React.createElement("h1", null, "Digitaaliset palvelusi")),
            React.createElement("div", { className: styles.cardWrapper, style: wrapperStyles }, digitalServices
                ? digitalServices.map((service) => (React.createElement(DigitalServicesCard, { digitalServiceInfo: service, cardCount: (digitalServices === null || digitalServices === void 0 ? void 0 : digitalServices.length) || 0, key: service.Title })))
                : renderSkeletonCards())))
        : React.createElement(React.Fragment, null);
};
const renderSkeletonCards = () => {
    const mockArr = Array.from(Array(SKELETON_CARD_COUNT).keys());
    return (mockArr.map((key) => React.createElement(DigitalServicesCardSkeleton, { cardCount: mockArr.length, key: key })));
};
