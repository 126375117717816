import React from 'react';
import { Pagination } from 'swiper/modules';
import { ProductBrandCard } from './ProductBrandCard.js';
import { combineClassNames } from '../util/css/classNames.js';
import { SwiperCarousel } from './SwiperCarousel.js';
import style from './ProductBrandsBanner.scss';
export const ProductBrandsBanner = ({ brands }) => (React.createElement(React.Fragment, null,
    React.createElement("ul", { className: style.desktopHighlightedBrandsList }, brands &&
        brands.length > 0 &&
        brands.map((brand) => (React.createElement("li", { key: brand.slug, className: style.highlightedBrandsList__item, "data-pw": "highlightedBrandsListItem" },
            React.createElement(ProductBrandCard, { brand: brand }))))),
    React.createElement("div", { className: style.mobileCarousel },
        React.createElement(SwiperCarousel, { swiperOpts: {
                modules: [
                    Pagination,
                ],
                pagination: {
                    bulletClass: combineClassNames('swiper-pagination-bullet', style.paginationBullet),
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 10,
                },
                slidesPerView: 1,
                loop: true,
            }, content: brands.map((brand) => (React.createElement(ProductBrandCard, { key: brand.slug, brand: brand }))) }))));
