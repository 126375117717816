import React from 'react';
import style from './Row.scss';
import { renderLandingPageElement } from './LandingPage.js';
import { reMapAnalyticsOptions } from '../../util/analyticsDataAttributes.js';
import { SwiperCarousel } from '../SwiperCarousel.js';
import { useAppSelector } from '../../hooks.js';
import { combineClassNames } from '../../util/css/classNames.js';
const getRowVisibilityByService = (rowProps, digitalServices) => {
    const services = rowProps.visibilityByServices;
    const serviceFound = digitalServices ? digitalServices.find((service) => service.Title === services) : undefined;
    if (serviceFound ||
        !rowProps.visibilityByServices || // maintain the visibility for the older rows that don't have this value set
        services === 'Kaikille') {
        return true;
    }
    return false;
};
export const Row = (props) => {
    const digitalServices = useAppSelector((state) => state.auth.digitalServices);
    const isRowVisible = getRowVisibilityByService(props, digitalServices);
    const hasSingleItem = props.content.length === 1;
    const renderRowContent = (content) => content.map((c, i) => (React.createElement("div", { key: c.id, className: combineClassNames(style.rowContentWrapper, showContentHorizontallyOnMobile && !hasSingleItem && style.contentHorizontallyOnMobile) }, renderLandingPageElement(reMapAnalyticsOptions(c, i, props.analyticsOptions), props.language, props.isLoggedIn))));
    const showContentHorizontallyOnMobile = props.mobileContentDirection === 'Vierekkäin';
    const rowContent = renderRowContent(props.content);
    const mobileContent = props.reverseMobileDirection ? renderRowContent([...props.content].reverse()) : rowContent;
    const hasMobileOnlyContent = (showContentHorizontallyOnMobile || props.reverseMobileDirection) && !hasSingleItem;
    return (React.createElement(React.Fragment, null, isRowVisible && (React.createElement("div", { className: showContentHorizontallyOnMobile ? style.swiperRowWrapper : style.rowWrapper },
        showContentHorizontallyOnMobile && !hasSingleItem && (React.createElement(SwiperCarousel, { swiperOpts: {
                slidesPerView: 1.35,
                spaceBetween: 8,
                loop: false,
                className: style.showOnlyOnMobile,
            }, content: mobileContent })),
        props.reverseMobileDirection && !hasSingleItem && !showContentHorizontallyOnMobile && (React.createElement("div", { className: combineClassNames(style.row, style.showOnlyOnMobile) }, mobileContent)),
        React.createElement("div", { className: combineClassNames(style.row, hasMobileOnlyContent && style.hideOnMobile) }, rowContent)))));
};
