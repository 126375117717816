import React from 'react';
import qs from 'query-string';
import { useGetBrandCollectionQuery } from '../../services/api.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { stringToMarkdown } from '../../../common/stringTypes.js';
import { BrandCard } from '../../components/BrandCard.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { MarkdownPresenter } from '../../components/MarkdownPresenter.js';
import styles from './style.scss';
const metaDescription = 'Tervetuloa tutustumaan laajaan valikoimaan brändituotteita – Vain Kesprosta.';
const BrandCollectionPage = ({ title, description, brandPages }) => (React.createElement("div", null,
    React.createElement(KesproComHelmet, { title: title, canonicalPath: "/tuotteet/vain-kesprosta", description: metaDescription }),
    React.createElement("h1", null, title),
    React.createElement(MarkdownPresenter, { markdownContent: stringToMarkdown(description), className: styles.description }),
    React.createElement("div", { className: styles.cards }, brandPages.map((page) => (React.createElement(BrandCard, { key: page.slug, title: page.title, heroImage: page.hero, logo: page.logo, linkUrl: `/tuotteet/tuotemerkit/${page.slug}`, cardCount: 3 }))))));
const BrandCollectionPageWrapper = (props) => {
    var _a;
    const queryParams = qs.parse(props.location.search);
    const searchParams = {
        slug: 'vain-kesprosta',
        preview: (_a = queryParams.preview) === null || _a === void 0 ? void 0 : _a.toString(),
    };
    const useQueryResult = useGetBrandCollectionQuery(searchParams);
    return renderRtkQueryResult(BrandCollectionPage, undefined, useQueryResult, (_props, data) => data, { fetch404PageFromContentful: true });
};
export { BrandCollectionPageWrapper as BrandCollectionPage };
