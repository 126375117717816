import React from 'react';
import { ProductList as GenericProductList } from '../ProductList.js';
import { useAppSelector } from '../../hooks.js';
import { getProductListProduct } from '../../util/products.js';
import style from './ProductList.scss';
export const ProductList = ({ products, title, error }) => {
    const hierarchy = useAppSelector((state) => state.category.hierarchy);
    return (React.createElement("section", { className: style.productList },
        React.createElement("h2", { className: style.title }, title),
        error && (React.createElement("p", { className: style.notFoundText }, "Tuotteiden hakemisessa tapahtui virhe. Yrit\u00E4 my\u00F6hemmin uudestaan.")),
        !error &&
            (products.length > 0 ? (React.createElement(GenericProductList, { className: products.length < 4 && style.centerProducts, products: products.map((product) => getProductListProduct(product, hierarchy)) })) : (React.createElement("p", { className: style.notFoundText }, "Tuotteita ei l\u00F6ytynyt.")))));
};
