import React from 'react';
import { Icon } from '../Icon.js';
import { HorizontalContainer } from './FlexboxContainer.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './ResultFilterBubble.scss';
export const ResultFilterBubble = (props) => {
    const { primaryFilter, secondaryFilter, onClick, className } = props;
    return secondaryFilter ?
        (React.createElement(HorizontalContainer, { onClick: onClick, className: combineClassNames(className ? className : '', styles.bubble), verticalAlign: "center" },
            React.createElement("span", null, primaryFilter),
            React.createElement(Icon, { type: "chevron-right", size: 20 }),
            React.createElement("span", null, secondaryFilter),
            React.createElement(Icon, { type: "clear", size: 25 })))
        : (React.createElement(HorizontalContainer, { onClick: onClick, className: combineClassNames(className ? className : '', styles.bubble, styles.bubblePrimaryOnly), verticalAlign: "center" },
            React.createElement("span", null, primaryFilter),
            React.createElement(Icon, { type: "clear", size: 25 })));
};
