import React from 'react';
import inputStyles from './style.scss';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './TextArea.scss';
export const TextArea = ({ label, className, name, placeholder, validationError, value, borderFull, labelLeft, labelStyleSimple, textCenter = true, onChange }) => {
    const containerClassNames = combineClassNames(inputStyles.labeledInput, labelLeft && inputStyles.alignStart, className);
    const labelSimple = labelStyleSimple ? combineClassNames(inputStyles.normalcase, inputStyles.labelTextLarge, inputStyles.normalWeight, inputStyles.lighterText) : '';
    const labelClassNames = combineClassNames(inputStyles.label, labelSimple, validationError && inputStyles.invalid);
    const borderClass = borderFull ? styles.borderFull : styles.borderDashed;
    const textAlignClass = textCenter ? styles.centerWithPadding : '';
    const textAreaClassNames = combineClassNames(styles.textarea, borderClass, textAlignClass);
    return (React.createElement("label", { className: containerClassNames },
        React.createElement("span", { className: labelClassNames }, label || ''),
        React.createElement("textarea", { name: name, defaultValue: value || '', onChange: (e) => { onChange(e.target.value); }, className: textAreaClassNames, placeholder: placeholder })));
};
