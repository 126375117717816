import React from 'react';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
import { Svg } from '../components/Svg.js';
import { constants } from '../constants.js';
import { getAspectRatioImgSrcSet } from '../../../util/image.js';
import { intohimoNyt } from '../../../config.js';
import styles from './IntohimoTyoryhmaSection.module.scss';
const heroWidth = 6092;
const heroHeight = 4061;
export const IntohimoTyoryhmaSection = () => {
    const { srcSet, fallback } = getAspectRatioImgSrcSet(intohimoNyt.heroImageUrl, 1.5, 'scale');
    return (React.createElement("section", { id: constants.sectionIds.intohimotyoryhma, className: styles.container },
        React.createElement("picture", { className: styles.hero },
            React.createElement("source", { srcSet: srcSet }),
            React.createElement("img", { alt: 'Intohimoty\u00F6ryhm\u00E4n j\u00E4senet yhteiskuvassa', loading: 'lazy', src: fallback, width: heroWidth, height: heroHeight })),
        React.createElement("div", { id: 'aria-tyoryhma-content', className: styles.content },
            React.createElement("h2", null,
                React.createElement(Svg, { name: 'title-tyoryhma' }),
                React.createElement(AriaHeadingText, null, "Intohimoty\u00F6ryhm\u00E4")),
            React.createElement("p", null, "Intohimoty\u00F6ryhm\u00E4 on ravintola-alan asiantuntijaryhm\u00E4, joka koostuu kokeista, salihenkil\u00F6kunnasta, ravintoloitsijoista ja muista alan ammattilaisista. Intohimoty\u00F6ryhm\u00E4n tavoitteena on edist\u00E4\u00E4 ravintola-alan arvostusta, kasvua ja elinvoimaisuutta. Ty\u00F6ryhm\u00E4n toiminta ei rajoitu pelkk\u00E4\u00E4n keskusteluun, vaan sen tarkoituksena on l\u00F6yt\u00E4\u00E4 konkreettisia ratkaisuja, jotka viev\u00E4t alaa eteenp\u00E4in ja tuovat hy\u00F6ty\u00E4 niin ammattilaisille kuin asiakkaillekin. Ryhm\u00E4n kokoonpano on vaihtuva, ensimm\u00E4isess\u00E4 kokoontumisessa olivat mukana Laura Styyra, Samuil Angelov, Pekka Ter\u00E4v\u00E4, Peggy Bauer, Laura Apukka, Jari L\u00F6nnberg, Tero M\u00E4ntykangas, Teemu Laurell, Jerry Tiittala, Anssi Nurminen."))));
};
