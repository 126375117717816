import React from 'react';
import { Icon } from '../Icon.js';
import { HorizontalContainer } from './FlexboxContainer.js';
import { combineClassNames, selectClassNames } from '../util/css/classNames.js';
import { onChangeHandler, keyUpHandler, clearSearchPhrase } from '../util/search.js';
import styles from './SearchBox.scss';
export const SearchBox = ({ className, searchPhrase, resultFilter, placeholder, onChange, onSearch, onToggleMobileFilters, onClearButtonClick, onEnterPress, staticVariant, }) => React.createElement(HorizontalContainer, { verticalAlign: "center", className: combineClassNames(styles.searchBar, className) },
    !staticVariant && React.createElement("div", { className: styles.showForMobile },
        React.createElement("button", { className: combineClassNames(styles.searchIcon, !!searchPhrase && styles.orange), onClick: () => {
                onSearch(searchPhrase);
            }, title: "Hae" },
            React.createElement(Icon, { type: "search" }))),
    React.createElement("input", { type: "search", "data-pw": "searchInput", value: searchPhrase, className: styles.searchInput, placeholder: placeholder || '', onChange: onChangeHandler(onChange), onKeyUp: keyUpHandler(searchPhrase, onSearch), onKeyDown: (e) => {
            if (onEnterPress && (e.keyCode === 13 || e.key === 'Enter')) {
                onEnterPress();
            }
        } }),
    searchPhrase && (React.createElement("button", { className: styles.clearButton, onClick: onClearButtonClick ? clearSearchPhrase(onClearButtonClick) : clearSearchPhrase(onChange) },
        React.createElement("span", { className: styles.clearIcon }, "\u00D7"))),
    React.createElement("div", { className: !staticVariant ? styles.hideForMobile : undefined },
        React.createElement("button", { className: combineClassNames(styles.searchIcon, !!searchPhrase && styles.orange), onClick: () => {
                onSearch(searchPhrase);
            }, title: "Hae" },
            React.createElement(Icon, { type: "search" }))),
    !staticVariant && renderShowFiltersButton(resultFilter, onToggleMobileFilters));
function renderShowFiltersButton(resultFilter, onToggleMobileFilters) {
    if (!onToggleMobileFilters) {
        return undefined;
    }
    const classNames = selectClassNames(styles, { showFiltersButton: true, showForMobile: true });
    return ((resultFilter === 'recipes') && (React.createElement("span", { className: classNames, onClick: () => { onToggleMobileFilters(); } },
        React.createElement(HorizontalContainer, { verticalAlign: "center" },
            "RAJAA",
            React.createElement(Icon, { type: "chevron-down", size: 30 })))));
}
