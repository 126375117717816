import React from 'react';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './FormStatusIndicator.scss';
export const FormStatusIndicator = (props) => {
    const { strings } = props;
    switch (props.status) {
        case 'send-pending':
            return React.createElement("div", { className: combineClassNames(styles.status, styles.sending) }, strings.formStatusSending);
        case 'send-error':
            return React.createElement("div", { className: combineClassNames(styles.status, styles.error) }, strings.formStatusError);
        case 'send-success':
            return React.createElement("div", { className: combineClassNames(styles.status, styles.success) }, strings.formStatusSucces);
        default:
            return React.createElement("div", null);
    }
};
