import React from 'react';
import inputStyles from './style.scss';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './TextBox.scss';
export const TextBox = ({ label, name, className, placeholder, value, validationError, borderFull, labelLeft, labelStyleSimple, textCenter = true, onChange, onBlur }) => {
    const containerClassNames = combineClassNames(inputStyles.labeledInput, labelLeft && inputStyles.alignStart, className);
    const labelSimple = labelStyleSimple ? combineClassNames(inputStyles.normalcase, inputStyles.labelTextLarge, inputStyles.normalWeight, inputStyles.lighterText) : '';
    const labelClassNames = combineClassNames(inputStyles.label, labelSimple, validationError && inputStyles.invalid);
    const borderClass = borderFull ? styles.borderFull : styles.borderDashed;
    const textAlignClass = textCenter ? styles.textAlignCenter : '';
    const textBoxClassNames = combineClassNames(styles.textbox, borderClass, textAlignClass);
    const handleBlur = (event) => {
        if (onBlur) {
            onBlur(event.target.value);
        }
    };
    return (React.createElement("label", { className: containerClassNames },
        React.createElement("span", { className: labelClassNames }, label || ''),
        React.createElement("input", { type: "text", name: name, value: value || '', onChange: (e) => { onChange(e.target.value); }, onBlur: handleBlur, className: textBoxClassNames, placeholder: placeholder })));
};
