import React, { useState } from 'react';
import { Icon } from '../../../Icon.js';
import { Svg } from './Svg.js';
import { combineClassNames } from '../../../util/css/classNames.js';
import { constants } from '../constants.js';
import styles from './Menu.module.scss';
const ariaMobileId = 'aria-mobile-navigation';
const Menu = () => {
    const [mobileExpanded, setMobileExpanded] = useState();
    const handleStickerLinkClick = () => setMobileExpanded(false);
    return (React.createElement("div", { className: styles.container },
        React.createElement("nav", { className: combineClassNames(styles.mobile, mobileExpanded === true && styles.expanded, mobileExpanded === false && styles.unexpanded), role: 'navigation' },
            React.createElement("button", { className: styles.menu, "aria-label": mobileExpanded ? 'Sulje valikko' : 'Avaa valikko', "aria-expanded": mobileExpanded, "aria-controls": ariaMobileId, onClick: () => setMobileExpanded(!mobileExpanded) },
                React.createElement(Icon, { type: mobileExpanded ? 'menu-close-x' : 'intohimo-menu', size: 32 })),
            React.createElement("div", { id: ariaMobileId, className: styles.content },
                React.createElement("h2", { className: styles.title }, "On the Menu"),
                React.createElement("div", { className: styles.stickerLinks },
                    React.createElement("a", { className: styles.namifestiSticker, href: `#${constants.sectionIds.namifesti}`, onClick: handleStickerLinkClick, "aria-label": 'Ravintola-alan Muutoksen menu' },
                        React.createElement(Svg, { name: 'menu-ravintola-alan-muutoksen-menu' })),
                    React.createElement("hr", null),
                    React.createElement("a", { className: styles.intohimotyoryhmaSticker, href: `#${constants.sectionIds.intohimotyoryhma}`, onClick: handleStickerLinkClick, "aria-label": 'Intohimoty\u00F6ryhm\u00E4' },
                        React.createElement(Svg, { name: 'menu-tyoryhma' })),
                    React.createElement("hr", null),
                    React.createElement("a", { className: styles.formSticker, href: `#${constants.sectionIds.form}`, onClick: handleStickerLinkClick, "aria-label": 'Jaa intohimosi' },
                        React.createElement(Svg, { name: 'menu-feedback' })),
                    React.createElement("hr", null),
                    React.createElement("a", { className: styles.qaSticker, href: `#${constants.sectionIds.qa}`, onClick: handleStickerLinkClick, "aria-label": 'Kysymykset ja vastaukset' },
                        React.createElement(Svg, { name: 'menu-qa' }))))),
        React.createElement("nav", { className: styles.desktop },
            React.createElement("a", { href: `#${constants.sectionIds.namifesti}` }, "Muutoksen menu"),
            React.createElement("span", null),
            React.createElement("a", { href: `#${constants.sectionIds.intohimotyoryhma}` }, "Ty\u00F6ryhm\u00E4"),
            React.createElement("span", null),
            React.createElement("a", { href: `#${constants.sectionIds.form}` }, "Jaa intohimosi"),
            React.createElement("span", null),
            React.createElement("a", { href: `#${constants.sectionIds.qa}` }, "Kysymyksi\u00E4 ja vastauksia"))));
};
// eslint-disable-next-line import/no-default-export
export default Menu;
