import React from 'react';
import { getDocument } from '../../common/util.js';
import { Link, NavLink } from 'react-router-dom';
import { delay } from 'lodash-es';
// Helper function to scroll into some element
export function scrollIntoView(elemId, args = true) {
    const doc = getDocument();
    if (doc === undefined) {
        return;
    }
    const elem = doc.getElementById(elemId);
    if (elem && elem.scrollIntoView) {
        elem.scrollIntoView(args);
    }
}
export function scrollToTop() {
    scrollTo(0, 0);
}
export const NavLinkWithScroll = (props) => {
    const onClick = getOnClickWithScroll(props);
    return React.createElement(NavLink, Object.assign({}, props, { onClick: onClick }));
};
export const LinkWithScroll = (props) => {
    const onClick = getOnClickWithScroll(props);
    return React.createElement(Link, Object.assign({}, props, { onClick: onClick }));
};
function getOnClickWithScroll(props) {
    const originalOnClick = props.onClick;
    return originalOnClick
        ? (e) => {
            originalOnClick(e);
            // These delays are used to make the scrolling happen on the new page to be
            // able to preserve scoll position when using browser back navigation
            delay(scrollToTop, 0);
        }
        : () => { delay(scrollToTop, 0); };
}
