import React, { useCallback, useState, useRef } from 'react';
import { debounce } from 'lodash-es';
// For some reason I can't import these from '..' even though it should work
// This results in "Element type is invalid: expected a string (for built-in components)" error
// That's why these are imported separately
import { CategoryBreadcrumbs } from './CategoryBreadcrumbs.js';
import { setDebouncedSearchPhraseWithUpdates, setSearchPhrase, } from '../reducers/productsSlice.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { AUTO_SEARCH_DEBOUNCE_MS } from '../../common/constants.js';
import { SearchPhraseSuggestions } from './SearchPhraseSuggestions.js';
import { CategoryFilter } from './CategoryFilter.js';
import { searchPhraseSuggestionClickedEvent } from '../reducers/analyticsSlice.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { SearchBox } from './SearchBox.js';
import { shallowEqual } from 'react-redux';
import { combineClassNames } from '../util/css/classNames.js';
import { useHistory } from 'react-router-dom';
import style from './ProductSearch.scss';
export const ProductSearch = (props) => {
    const { className, inactiveCategoryClassName, location, } = props;
    const { push, replace } = useHistory();
    const dispatch = useAppDispatch();
    const debouncedSearch = useCallback(debounce((val, loc) => {
        dispatch(setDebouncedSearchPhraseWithUpdates(val, loc, push, replace));
    }, AUTO_SEARCH_DEBOUNCE_MS), []);
    const [suggestionBoxOpen, setSuggestionBoxOpen] = useState(false);
    const [suggestionClicked, setSuggestionClicked] = useState(false);
    const searchContainerRef = useRef();
    const { searchPhrase, selected } = useAppSelector((state) => ({
        searchPhrase: state.products.searchPhrase,
        selected: state.products.selectedCategory,
    }), shallowEqual);
    const placeholder = selected
        ? `Hae kategoriasta ${selected.category.name}`
        : `Hae tuotteita`;
    const classNameToUse = combineClassNames(className, !selected ? inactiveCategoryClassName : undefined);
    const handleSearchPhraseChange = (writtenCharacters, isSuggestionClicked) => {
        dispatch(setSearchPhrase(writtenCharacters));
        debouncedSearch(writtenCharacters, location);
        setSuggestionBoxOpen(isSuggestionClicked ? false : true);
        setSuggestionClicked(isSuggestionClicked);
    };
    return (React.createElement(VerticalContainer, { className: classNameToUse },
        React.createElement(CategoryBreadcrumbs, null),
        React.createElement("div", { className: style.searchContainer, ref: searchContainerRef },
            React.createElement("div", { className: style.searchBar },
                React.createElement(SearchBox, { "data-pw": "productSearchInput", className: style.searchInput, staticVariant: true, searchPhrase: searchPhrase, placeholder: placeholder, onChange: (value) => {
                        handleSearchPhraseChange(value, false);
                    }, onClearButtonClick: () => {
                        dispatch(setSearchPhrase(''));
                        dispatch(setDebouncedSearchPhraseWithUpdates('', location, push, replace));
                        setSuggestionClicked(false);
                        setSuggestionBoxOpen(false);
                    }, onEnterPress: () => {
                        debouncedSearch.flush();
                        setSuggestionBoxOpen(false);
                    }, onSearch: () => {
                        debouncedSearch.flush();
                    } }),
                !suggestionClicked && suggestionBoxOpen &&
                    React.createElement(SearchPhraseSuggestions, { searchPhrase: searchPhrase, handleSuggestionClick: (writtenCharacters, clickedSuggestion) => {
                            handleSearchPhraseChange(clickedSuggestion, true);
                            searchPhraseSuggestionClickedEvent(writtenCharacters, clickedSuggestion);
                        }, setSuggestionBoxOpen: setSuggestionBoxOpen, searchContainerRef: searchContainerRef }))),
        React.createElement(CategoryFilter, { location: location })));
};
