import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetIdeaInspirationCardsQuery, useGetIdeaInspirationHeroQuery } from '../../services/api.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { debounce } from 'lodash-es';
import { ideaInspirationSlice, setDebouncedSearchPhraseWithUpdates, setIdeaInspirationSearchResult, setSearchPhrase, } from '../../reducers/ideaInspirationSlice.js';
import { AUTO_SEARCH_DEBOUNCE_MS, } from '../../../common/constants.js';
import styles from './style.scss';
import { IDEA_INSPIRATIONS_ARTICLES_PAGE_PATH, IDEA_INSPIRATIONS_PAGE_PATH, IDEA_INSPIRATIONS_RECIPES_PAGE_PATH, } from '../../../common/urlPaths.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { FilterBar } from '../../components/FilterBar.js';
import { VerticalContainer, HorizontalContainer } from '../../components/FlexboxContainer.js';
import { filterTypeToCardType } from '../../components/IdeaInspirationCard.js';
import { applyCardFilters, IdeaInspirationCardList } from '../../components/IdeaInspirationCardList.js';
import { IdeaInspirationHero } from '../../components/IdeaInspirationHero.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { SearchBox } from '../../components/SearchBox.js';
const filters = (search) => [
    { to: { pathname: '/ideat-ja-inspiraatiot', search }, caption: 'Kaikki', onlyActiveOnIndex: true },
    { to: { pathname: '/ideat-ja-inspiraatiot/reseptit', search }, caption: 'Reseptit' },
    { to: { pathname: '/ideat-ja-inspiraatiot/artikkelit', search }, caption: 'Artikkelit' },
];
const pageTitle = (filterType) => {
    switch (filterType) {
        case 'reseptit':
            return 'Reseptit - Ideat ja Inspiraatio';
        case 'artikkelit':
            return 'Artikkelit - Ideat ja Inspiraatio';
        default:
            return 'Ideat ja Inspiraatio';
    }
};
const canonicalPath = (filterType) => {
    switch (filterType) {
        case 'reseptit':
            return IDEA_INSPIRATIONS_RECIPES_PAGE_PATH;
        case 'artikkelit':
            return IDEA_INSPIRATIONS_ARTICLES_PAGE_PATH;
        default:
            return IDEA_INSPIRATIONS_PAGE_PATH;
    }
};
const metaDescription = (filterType) => {
    switch (filterType) {
        case 'reseptit':
            return 'Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa. Tutustu resepteihimme!';
        case 'artikkelit':
            return 'Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa. Tutustu inspiroiviin artikkeleihimme!';
        default:
            return 'Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa. Tutustu palveluumme!';
    }
};
function IdeaInspirationPage({ cards, cardType, hasMoreCards, hero, isError, isLoading, isLoadingMore, onLoadMoreCards, location }) {
    const searchPhrase = useAppSelector((state) => state.ideaInspiration.searchPhrase);
    const dispatch = useAppDispatch();
    const { push, replace } = useHistory();
    const filteredCards = applyCardFilters(cardType, cards);
    const debouncedSearch = useCallback(debounce((value, loc) => {
        dispatch(setDebouncedSearchPhraseWithUpdates(value, loc, push, replace));
    }, AUTO_SEARCH_DEBOUNCE_MS), []);
    return (React.createElement("div", null,
        React.createElement(VerticalContainer, { horizontalAlign: "stretch", wrap: false },
            hero && React.createElement(IdeaInspirationHero, { hero: hero }),
            React.createElement("section", { key: "body", className: styles.bodyContainer },
                React.createElement(HorizontalContainer, { horizontalAlign: "center", className: styles.searchContainer },
                    React.createElement(SearchBox, { searchPhrase: searchPhrase, onChange: (value) => {
                            dispatch(setSearchPhrase(value));
                            debouncedSearch(value, location);
                        }, onSearch: () => {
                            debouncedSearch.flush();
                        }, placeholder: "Hae sis\u00E4ll\u00F6st\u00E4" })),
                React.createElement(FilterBar, { filters: filters(location.search), pathName: location.pathname, className: styles.filterBar }),
                React.createElement(IdeaInspirationCardList, { cards: filteredCards, hasMoreCards: hasMoreCards, isError: isError, isLoading: isLoading, isLoadingMore: isLoadingMore, onLoadMore: onLoadMoreCards })))));
}
const IdeaInspirationPageWrapper = ({ location, match }) => {
    const dispatch = useAppDispatch();
    const { searchResult, query, offset, hasMore } = useAppSelector((state) => ({
        searchResult: state.ideaInspiration.searchResult,
        hasMore: state.ideaInspiration.hasMore,
        query: state.ideaInspiration.query,
        offset: state.ideaInspiration.offset,
    }), (a, b) => a.searchResult === b.searchResult && a.query === b.query);
    const cacheQueryParams = parseCacheQueryParams(location);
    const { data: heroData, isError: heroError } = useGetIdeaInspirationHeroQuery(cacheQueryParams);
    const { data, isError, isFetching } = useGetIdeaInspirationCardsQuery(Object.assign(Object.assign({}, query), cacheQueryParams));
    const filterType = match.params.filterType;
    const cardType = filterTypeToCardType(match.params.filterType || '');
    const handleLoadMoreCards = () => {
        dispatch(ideaInspirationSlice.actions.incrementOffset());
    };
    useEffect(() => {
        if (data) {
            dispatch(setIdeaInspirationSearchResult(data));
        }
    }, [data]);
    if ((heroData || heroError) && data) {
        const isLoading = isFetching && offset === 0;
        return (React.createElement(React.Fragment, null,
            React.createElement(KesproComHelmet, { title: pageTitle(filterType), canonicalPath: canonicalPath(filterType), description: metaDescription(filterType) }),
            React.createElement(IdeaInspirationPage, { cardType: cardType, cards: searchResult || data, hasMoreCards: hasMore, hero: heroData, isError: isError, isLoading: isLoading, isLoadingMore: data && isFetching, onLoadMoreCards: handleLoadMoreCards, location: location })));
    }
    if (heroError || isError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, { className: styles.loading });
};
export { IdeaInspirationPageWrapper as IdeaInspirationPage };
