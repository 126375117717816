import React from 'react';
import { mapAnalyticsPropsToDataAttributes } from '../../util/analyticsDataAttributes.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { getDefaultContentfulImgSrcSet, breakPoint, getStaticContentfulImgSrcSet } from '../../util/image.js';
import { LinkWithScroll } from '../../util/scroll.js';
import style from './LinkCard.scss';
const renderLinkCardContent = (props) => {
    const content = props.title || props.linkButtonText ?
        React.createElement("div", { className: props.size === "flex" /* LinkCardSize.Flex */ ? style.cardFooter : style.staticCardFooter },
            props.title &&
                React.createElement("p", { className: style.title }, props.title),
            props.linkButtonText && React.createElement("p", { className: style.linkText }, props.linkButtonText))
        :
            undefined;
    if (!props.image) {
        return content;
    }
    const { srcSet, fallback, sizes } = props.size === "flex" /* LinkCardSize.Flex */
        ? Object.assign(Object.assign({}, getDefaultContentfulImgSrcSet(props.image)), { fallback: props.image, sizes: `(max-width: ${breakPoint.MOBILE}px) 100vw, 50vw` }) : Object.assign(Object.assign({}, getStaticContentfulImgSrcSet(props.image, 300)), { fallback: props.image, sizes: undefined });
    const getStaticImageStyle = () => {
        if (!content) {
            return combineClassNames(style.staticCardWithoutContent, style.imageSize);
        }
        else if (content && props.linkButtonText) {
            return combineClassNames(style.staticImgContainerSmall, style.imageSize);
        }
        else {
            return combineClassNames(style.staticImgContainer, style.imageSize);
        }
    };
    return (React.createElement(React.Fragment, null, props.size === "static" /* LinkCardSize.Static */ ?
        React.createElement("div", { className: style.flexCardContainer },
            React.createElement("picture", { className: getStaticImageStyle() },
                React.createElement("source", { srcSet: srcSet, sizes: sizes, type: "image/webp" }),
                React.createElement("img", { alt: "", className: !content ? style.staticImgWithoutText : '', loading: "lazy", src: fallback, height: props.imageHeight, width: props.imageWidth })),
            content && content)
        :
            React.createElement(React.Fragment, null,
                React.createElement("picture", { className: combineClassNames(style.imgContainer, style.flexImgContainer, style.imageSize) },
                    React.createElement("source", { srcSet: srcSet, sizes: sizes, type: "image/webp" }),
                    React.createElement("img", { alt: "", loading: "lazy", className: style.imgContainer, src: fallback, height: props.imageHeight, width: props.imageWidth })),
                content && content)));
};
export const LinkCard = (props) => {
    if (!props.link) {
        return React.createElement(React.Fragment, null);
    }
    const analyticsDataAttributes = mapAnalyticsPropsToDataAttributes(props.analyticsOptions, { promotionName: props.title, promotionLink: props.link });
    return (React.createElement("div", Object.assign({ className: combineClassNames(style.linkCard, style[props.size], props.className) }, analyticsDataAttributes), props.isExternalLink
        ? (React.createElement("a", { href: props.link, target: "_blank", rel: "noopener noreferrer" }, renderLinkCardContent(props)))
        : (React.createElement(LinkWithScroll, { to: props.link[0] === '/' ? props.link : `/${props.link}` }, renderLinkCardContent(props)))));
};
