import React from 'react';
import { Icon } from '../Icon.js';
import { RadioGroup } from './RadioGroup.js';
import style from './BrandFilter.scss';
export const BrandFilter = ({ selectedBrand, selectBrand, brands, isDropdownOpen, onDropdownToggled, }) => (React.createElement("div", { className: style.brandFilter, "data-isdropdownopen": isDropdownOpen, "aria-expanded": isDropdownOpen },
    React.createElement("button", { "aria-label": isDropdownOpen ? 'Sulje brändisuodattimet' : 'Avaa brändisuodattimet', "aria-expanded": isDropdownOpen, "aria-controls": "Br\u00E4ndisuodattimet", className: style.brandFilter__title, onClick: onDropdownToggled, onKeyDown: () => onDropdownToggled },
        React.createElement("h3", null, "Br\u00E4ndit"),
        React.createElement(Icon, { type: isDropdownOpen ? 'chevron-up' : 'chevron-down', size: 24 })),
    isDropdownOpen &&
        React.createElement(RadioGroup, { ariaDescribedBy: "Br\u00E4ndisuodattimet", ariaLabel: "Br\u00E4ndit", items: mapBrandsToRadioGroupItems(brands, selectedBrand), selectedItemId: selectedBrand, selectItem: selectBrand, backButton: {
                isDisplayed: !!selectedBrand,
                onClick: () => { selectBrand(undefined); },
            } })));
function mapBrandsToRadioGroupItems(brands, selectedBrand) {
    if (!brands) {
        return [];
    }
    const mappedBrands = brands.map((brand) => ({
        id: brand,
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLocaleLowerCase(),
        group: 'brand',
    }));
    return selectedBrand
        ? mappedBrands
        : [
            {
                id: undefined,
                name: 'Kaikki',
                group: 'brand',
            },
            ...mappedBrands,
        ];
}
