var _a;
import * as rtk from '@reduxjs/toolkit';
import { appMenuClickHandler, appMenuErrorHandler, articleViewedHandler, customPageHandler, formSubmittedHandler, loginEventHandler, productPageNewClientLinkClickedHandler, productRecommendationClearEventHandler, productRecommendationEventHandler, searchPhraseSuggestionClickedHandler, } from '../analytics.js';
import { debounce } from 'lodash-es';
import { getWindow } from '../../common/util.js';
import { decodeKConsentCookie } from '../util/kconsent.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const window = getWindow();
if (window) {
    window.dataLayer = window.dataLayer || [];
}
const dataLayer = (window === null || window === void 0 ? void 0 : window.dataLayer) || [];
const ANALYTICS_DEBOUNCE = 1000;
const customPageEventRaw = customPageHandler(dataLayer);
export const customPageEvent = debounce(customPageEventRaw, ANALYTICS_DEBOUNCE);
const formSubmittedEventRaw = formSubmittedHandler(dataLayer);
export const formSubmittedEvent = debounce(formSubmittedEventRaw, ANALYTICS_DEBOUNCE);
const productPageNewClientLinkClickedEventRaw = productPageNewClientLinkClickedHandler(dataLayer);
export const productPageNewClientLinkClickedEvent = debounce(productPageNewClientLinkClickedEventRaw, ANALYTICS_DEBOUNCE);
export const articleViewedEvent = articleViewedHandler(dataLayer);
export const searchPhraseSuggestionClickedEvent = searchPhraseSuggestionClickedHandler(dataLayer);
const authenticationEventRaw = loginEventHandler(dataLayer);
export const authenticationEvent = debounce(authenticationEventRaw, ANALYTICS_DEBOUNCE);
const debouncedAppMenuClickEvent = debounce(appMenuClickHandler(dataLayer), ANALYTICS_DEBOUNCE);
const debouncedAppMenuErrorEvent = debounce(appMenuErrorHandler(dataLayer), ANALYTICS_DEBOUNCE);
export const productRecommendationEvent = productRecommendationEventHandler(dataLayer);
export const clearProductRecommendationData = productRecommendationClearEventHandler(dataLayer);
/**
 * The AppMenu analytics should be used at Header component due to duplicate messages it receives from LargeHeader and SmallHeader.
 */
export const appMenuEvent = (event) => {
    var _a;
    const message = (_a = event.data) === null || _a === void 0 ? void 0 : _a.message;
    if (message === 'appMenuClick') {
        debouncedAppMenuClickEvent(event.data.clickTarget);
    }
    if (message === 'appMenuError') {
        debouncedAppMenuErrorEvent(event.data.error);
    }
};
const initialState = {
    heroCarouselViews: {},
    abCookie: "a" /* ABCookieValue.A */,
    hasConsentForEmbeddedContent: false,
    userAnalyticsData: undefined,
};
export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        incrementHeroCarouselViews: (state, action) => {
            state.heroCarouselViews[action.payload] = state.heroCarouselViews[action.payload] + 1 || 1;
        },
        setABCookie: (state, action) => {
            if (action.payload) {
                const safeCookieOption = action.payload === "a" /* ABCookieValue.A */ ? "a" /* ABCookieValue.A */ : "b" /* ABCookieValue.B */;
                state.abCookie = safeCookieOption;
            }
        },
        setKConsentDetails: (state, action) => {
            const consent = decodeKConsentCookie(action.payload);
            state.hasConsentForEmbeddedContent = consent.ga;
        },
        setUserAnalyticsData: (state, action) => {
            state.userAnalyticsData = action.payload;
        },
    },
});
export const { reducer } = analyticsSlice;
export const { incrementHeroCarouselViews, setABCookie, setUserAnalyticsData, setKConsentDetails, } = analyticsSlice.actions;
