import React from 'react';
import { FormId } from '../../../common/apiTypes.js';
import { translations } from '../../staticData.js';
import { RegistrationForm } from '../ContactForm/RegistrationForm.js';
import { CommonContactForm } from '../ContactForm/CommonContactForm.js';
import style from './Form.scss';
const renderContent = (props) => {
    const { formId, language } = props;
    const strings = translations[language].common;
    switch (formId) {
        case FormId.CONTACT:
            return React.createElement(RegistrationForm, { language: language, strings: strings });
        case FormId.COMMON_CONTACT:
            return React.createElement(CommonContactForm, { language: language, strings: strings });
        default:
            return undefined;
    }
};
// Quick fix to get Header correctly/avoid duplicates
// refactor this away after getting rid of the static become client -page
const renderHeading = (props) => props.formId === FormId.COMMON_CONTACT
    ? undefined
    : React.createElement("h2", null, translations[props.language].common.contactUsHeader);
export const Form = (props) => (React.createElement("div", { className: style.contentWrapper },
    React.createElement("div", { className: style.formHeader }, renderHeading(props)),
    React.createElement("div", { className: style.content }, renderContent(props))));
