var _a;
import { __awaiter } from "tslib";
import { findCategoryById, isSecondLevelSubcategory } from '@kesko/kespro-ui-common';
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import * as R from 'ramda';
import * as QueryString from 'query-string';
import { PRODUCTS_PAGE_PATH } from '../../common/urlPaths.js';
import { PRODUCT_PAGE_SIZE } from '../../common/constants.js';
import { PRODUCT_CATEGORY, PRODUCT_SEARCHPHRASE } from '../../common/queryStrings.js';
import { setHandledUrl } from './urlSlice.js';
import { immerUnserializable } from '../util/redux.js';
import { api } from '../services/api.js';
import { getQueryCategoryIds } from '../util/categories.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
const initialState = {
    searchPhrase: '',
    debouncedSearchPhrase: '',
    selectedCategory: undefined,
    query: createProductSearchQuery('', undefined),
    offset: 0,
    productSearchResult: undefined,
    productSearchResultOffset: 0,
};
export const productsPageUrlChanged = (location) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    yield dispatch(api.endpoints.getCategoryHierarchy.initiate());
    const initState = getState();
    const { hierarchy } = initState.category;
    const { searchPhrase, selectedCategory } = getProductsStateFromLocation(location, hierarchy);
    dispatch(setProductsSearchState({ searchPhrase, selectedCategory }));
    dispatch(setHandledUrl(getRelativeUrlFromLocation(location)));
});
export const selectCategoryWithUpdates = (cat, location, push, replace) => (dispatch) => {
    dispatch(selectCategory(cat));
    dispatch(updateProductSearchUrl(location, push, replace));
};
export const setDebouncedSearchPhraseWithUpdates = (searchPhrase, location, push, replace) => (dispatch) => {
    dispatch(setDebouncedSearchPhrase(searchPhrase));
    dispatch(updateProductSearchUrl(location, push, replace));
};
export const updateProductSearchUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const selectedCategory = state.products.selectedCategory;
    const searchPhrase = state.products.searchPhrase;
    const newPath = getProductSearchUrl(searchPhrase, selectedCategory);
    const currentPath = getRelativeUrlFromLocation(location);
    if (newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
export const productsSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        selectCategory: (state, action) => {
            var _a, _b, _c, _d;
            if (((_a = state.selectedCategory) === null || _a === void 0 ? void 0 : _a.category.id) !== ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.category.id) ||
                ((_c = state.selectedCategory) === null || _c === void 0 ? void 0 : _c.categoryIdPrefix) !== ((_d = action.payload) === null || _d === void 0 ? void 0 : _d.categoryIdPrefix)) {
                return Object.assign(Object.assign({}, state), { productSearchResultOffset: 0, offset: 0, query: createProductSearchQuery(state.searchPhrase, action.payload), selectedCategory: action.payload ? immerUnserializable(R.clone(action.payload)) : action.payload });
            }
            return state;
        },
        setSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        setDebouncedSearchPhrase: (state, action) => {
            if (state.debouncedSearchPhrase !== action.payload) {
                state.debouncedSearchPhrase = action.payload;
                state.offset = 0;
                state.productSearchResultOffset = 0;
                state.query = createProductSearchQuery(action.payload, state.selectedCategory);
            }
        },
        setProductsSearchState: (state, action) => {
            const { searchPhrase, selectedCategory } = action.payload;
            if (state.debouncedSearchPhrase !== searchPhrase || state.selectedCategory !== selectedCategory) {
                return Object.assign(Object.assign({}, state), { offset: 0, searchResultOffset: 0, searchPhrase, debouncedSearchPhrase: searchPhrase, selectedCategory: selectedCategory
                        ? immerUnserializable(R.clone(selectedCategory))
                        : selectedCategory, query: createProductSearchQuery(searchPhrase, selectedCategory) });
            }
            return state;
        },
        incrementOffset: (state, _action) => {
            state.offset = state.offset + PRODUCT_PAGE_SIZE;
            state.query = createProductSearchQuery(state.debouncedSearchPhrase, state.selectedCategory, state.offset);
        },
        setProductSearchResult: (state, action) => {
            if (state.offset === 0 || !action.payload || !state.productSearchResult) {
                state.productSearchResultOffset = state.offset;
                state.productSearchResult = action.payload;
            }
            else if (state.productSearchResultOffset < state.offset) {
                state.productSearchResultOffset = state.offset;
                state.productSearchResult = {
                    totalHits: action.payload.totalHits,
                    results: state.productSearchResult.results.concat(action.payload.results),
                    categoryHits: state.productSearchResult.categoryHits,
                };
            }
        },
    },
});
export const { reducer } = productsSlice;
export const { selectCategory, setSearchPhrase, setDebouncedSearchPhrase, setProductsSearchState, setProductSearchResult, incrementOffset, } = productsSlice.actions;
export function createProductSearchQuery(searchPhrase, selectedCategory, offset = 0) {
    return {
        query: searchPhrase,
        boostTerm: searchPhrase,
        filters: getFilters(selectedCategory),
        view: { offset, limit: PRODUCT_PAGE_SIZE },
    };
}
function getFilters(category) {
    if (category) {
        return {
            categoryId: getQueryCategoryIds(category, (ids) => ids),
        };
    }
    else {
        return {};
    }
}
const isNotNil = R.complement(R.isNil);
const isSecondLevel = R.allPass([isNotNil, isSecondLevelSubcategory]);
export const getCategoryIdPrefixForHero = (category) => R.cond([
    [isSecondLevel, () => category.parent.categoryIdPrefix],
    [isNotNil, () => category.categoryIdPrefix],
    [R.T, () => 'root'],
])(category);
export function getProductSearchUrl(searchPhrase, selectedCategory) {
    const newQuery = QueryString.stringify({
        [PRODUCT_SEARCHPHRASE]: searchPhrase || undefined,
        [PRODUCT_CATEGORY]: selectedCategory && selectedCategory.category.id !== 'root' ? selectedCategory.category.id : undefined,
    });
    return `${PRODUCTS_PAGE_PATH}?${newQuery}`;
}
function getProductsStateFromLocation(location, categoryHierarchy) {
    const queryParams = QueryString.parse(location.search);
    const categoryId = queryParams[PRODUCT_CATEGORY];
    const selectedCategory = categoryId && categoryHierarchy ? findCategoryById(categoryHierarchy, categoryId) : undefined;
    const searchPhrase = (queryParams[PRODUCT_SEARCHPHRASE] || '');
    return {
        selectedCategory,
        searchPhrase,
    };
}
