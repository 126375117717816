import React from 'react';
import { HorizontalContainer } from '../FlexboxContainer.js';
import { IdeaInspirationCard, IdeaInspirationCardSkeleton } from '../IdeaInspirationCard.js';
import style from './PopularArticles.scss';
export const PopularArticles = (props) => (React.createElement("div", null,
    React.createElement("h2", null, "Luetuimmat artikkelit"),
    React.createElement(HorizontalContainer, { className: style.cardContainer, horizontalAlign: "center" }, !props.isLoading && props.popularArticles
        ? renderArticles(props.popularArticles)
        : renderArticleSkeletons())));
const renderArticles = (articles) => (articles.map((card) => (React.createElement("div", { className: style.smallCardContainer, key: card.slug },
    React.createElement(IdeaInspirationCard, { card: card, mode: "short" })))));
const renderArticleSkeletons = () => (Array.from(Array(3).keys()).map((key) => (React.createElement("div", { className: style.smallCardContainer, key: key },
    React.createElement(IdeaInspirationCardSkeleton, { mode: "short" })))));
