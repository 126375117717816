import React from 'react';
import { combineClassNames } from '../util/css/classNames.js';
import style from './OpeningHoursList.scss';
const mapListEntries = (props) => {
    const itemClass = combineClassNames(style.hoursListItem, style[props.styling]);
    return props.entries.map((entry, i) => (React.createElement("li", { key: i, className: itemClass, "data-columns": entry.description ? 3 : 2 },
        React.createElement("strong", { className: style.label }, entry.label),
        entry.description && React.createElement("span", { className: style.description }, entry.description),
        React.createElement("span", { className: style.time }, entry.time))));
};
export const OpeningHoursList = (props) => {
    const headClass = combineClassNames(style.hoursListHeader, style[props.styling]);
    return (React.createElement("div", { className: style.hoursListContainer },
        props.title && (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: headClass }, props.title),
            props.openHoursToday && React.createElement("p", { className: style.openHoursToday }, props.openHoursToday))),
        React.createElement("ul", { className: style.hoursList }, mapListEntries(props))));
};
