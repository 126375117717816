var _a;
import { __awaiter } from "tslib";
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import { setHandledUrl } from './urlSlice.js';
import QueryString from 'query-string';
import { contactSearchPhraseParamName, contactRegionFilterParamName, contactTypeFilterParamName, } from '../../common/queryStrings.js';
import { setI18nSlugsForContactPages } from './i18nSlice.js';
import { contactForMediaPageUrls, contactForSuppliersPageUrls, contactListPageUrls, customerServiceListPageUrls, locationListPageUrls, salesContactListPageUrls, wholesaleListPageUrls, } from '../../common/urlPaths.js';
import { ContactType, regionFilterQueryParamValueMap, typeFilterQueryParamValueMap, } from '../pages/ContactListPage/i18n.js';
import { api } from '../services/api.js';
import { languageToLocale, } from '../../common/apiTypes.js';
import { getRelativeUrlFromLocation, getLanguageFromUrl, getUpdateMethod } from '../util/url.js';
const initialState = {
    contactType: ContactType.All,
    searchPhrase: '',
    debouncedSearchPhrase: '',
    salesTypeFilters: {},
    regionFilters: {},
};
const getAlternateLocationUrls = (locale, path, state) => {
    const slug = path.split('/').pop();
    const queryResult = state.api.queries[`getContacts({"locale":"${locale}"})`];
    if ((queryResult === null || queryResult === void 0 ? void 0 : queryResult.status) === 'fulfilled') {
        const contacts = queryResult.data;
        const location = contacts.find((contact) => contact.contentType === 'location' && contact.slug === slug);
        if (location && location.alternateSlugs) {
            return {
                fi: `${locationListPageUrls.fi}/${location.alternateSlugs.fi}`,
                en: `${locationListPageUrls.en}/${location.alternateSlugs.en || location.alternateSlugs.fi}`,
                sv: `${locationListPageUrls.sv}/${location.alternateSlugs.sv || location.alternateSlugs.fi}`,
            };
        }
    }
    return {};
};
const getAlternateWholesaleUrls = (locale, path, state) => {
    const slug = path.split('/').pop();
    const queryResult = state.api.queries[`getContacts({"locale":"${locale}"})`];
    if ((queryResult === null || queryResult === void 0 ? void 0 : queryResult.status) === 'fulfilled') {
        const contacts = queryResult.data;
        const wholesale = contacts.find((contact) => contact.contentType === 'wholesale' && contact.slug === slug);
        if (wholesale && wholesale.alternateSlugs) {
            return {
                fi: `${wholesaleListPageUrls.fi}/${wholesale.alternateSlugs.fi}`,
                en: `${wholesaleListPageUrls.en}/${wholesale.alternateSlugs.en || wholesale.alternateSlugs.fi}`,
                sv: `${wholesaleListPageUrls.sv}/${wholesale.alternateSlugs.sv || wholesale.alternateSlugs.fi}`,
            };
        }
    }
    return {};
};
export const contactsUrlChanged = (location) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getRelativeUrlFromLocation(location);
    const language = getLanguageFromUrl(url);
    const stateFromLocation = getContactsStateFromLocation(location, language);
    dispatch(setContactsState(stateFromLocation));
    const path = location.pathname;
    if (contactListPageUrls[language] === path) {
        dispatch(setContactType(ContactType.All));
        dispatch(setI18nSlugsForContactPages({ language, url: path, urlCollection: contactListPageUrls }));
    }
    else if (contactForSuppliersPageUrls[language] === path) {
        dispatch(setI18nSlugsForContactPages({ language, url: path, urlCollection: contactForSuppliersPageUrls }));
    }
    else if (contactForMediaPageUrls[language] === path) {
        dispatch(setI18nSlugsForContactPages({ language, url: path, urlCollection: contactForMediaPageUrls }));
    }
    else if (salesContactListPageUrls[language] === path) {
        dispatch(setContactType(ContactType.Sales));
        dispatch(setI18nSlugsForContactPages({ language, url: path, urlCollection: salesContactListPageUrls }));
    }
    else if (wholesaleListPageUrls[language] === path) {
        dispatch(setContactType(ContactType.Noutotukut));
        dispatch(setI18nSlugsForContactPages({ language, url: path, urlCollection: wholesaleListPageUrls }));
    }
    else if (wholesaleListPageUrls[language] && path.startsWith(wholesaleListPageUrls[language])) {
        const locale = languageToLocale(language);
        yield dispatch(api.endpoints.getContacts.initiate({ locale }));
        dispatch(setI18nSlugsForContactPages({
            language,
            url: location.pathname,
            urlCollection: getAlternateWholesaleUrls(locale, path, getState()),
        }));
    }
    else if (customerServiceListPageUrls[language] === url) {
        dispatch(setContactType(ContactType.Asiakaspalvelu));
        dispatch(setI18nSlugsForContactPages({ language, url, urlCollection: customerServiceListPageUrls }));
    }
    else if (locationListPageUrls[language] === url) {
        dispatch(setI18nSlugsForContactPages({ language, url, urlCollection: locationListPageUrls }));
    }
    else if (locationListPageUrls[language] && url.startsWith(locationListPageUrls[language])) {
        const locale = languageToLocale(language);
        yield dispatch(api.endpoints.getContacts.initiate({ locale }));
        dispatch(setI18nSlugsForContactPages({
            language,
            url: path,
            urlCollection: getAlternateLocationUrls(locale, path, getState()),
        }));
    }
    dispatch(setHandledUrl(url));
});
export const setDebouncedSearchPhraseWithUpdates = (searchPhrase, location, push, replace) => (dispatch) => {
    dispatch(setDebouncedSearchPhrase(searchPhrase));
    dispatch(updateContactsSearchUrl(location, push, replace));
};
export const toggleTypeFilterWithUpdates = (optionId, location, push, replace) => (dispatch) => {
    dispatch(toggleTypeFilter(optionId));
    dispatch(updateContactsSearchUrl(location, push, replace));
};
export const toggleRegionFilterWithUpdates = (optionId, location, push, replace) => (dispatch) => {
    dispatch(toggleRegionFilter(optionId));
    dispatch(updateContactsSearchUrl(location, push, replace));
};
export const updateContactsSearchUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.contacts.searchPhrase;
    const currentPath = getRelativeUrlFromLocation(location);
    const language = getLanguageFromUrl(currentPath);
    const newPath = getContactsSearchUrl(searchPhrase, location.pathname, { type: state.contacts.salesTypeFilters, region: state.contacts.regionFilters }, language);
    if (newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContactType: (state, action) => {
            state.contactType = action.payload;
        },
        setSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        setDebouncedSearchPhrase: (state, action) => {
            state.debouncedSearchPhrase = action.payload;
        },
        setContactsState: (state, action) => {
            const { searchPhrase, salesTypeFilters, regionFilters } = action.payload;
            state.searchPhrase = searchPhrase;
            state.debouncedSearchPhrase = searchPhrase;
            state.salesTypeFilters = salesTypeFilters;
            state.regionFilters = regionFilters;
        },
        toggleTypeFilter: (state, action) => {
            const optionId = action.payload;
            state.salesTypeFilters[optionId] = !state.salesTypeFilters[optionId];
        },
        toggleRegionFilter: (state, action) => {
            const optionId = action.payload;
            state.regionFilters[optionId] = !state.regionFilters[optionId];
        },
    },
});
export const { reducer } = contactsSlice;
export const { setContactType, setSearchPhrase, setDebouncedSearchPhrase, setContactsState, toggleRegionFilter, toggleTypeFilter } = contactsSlice.actions;
export const getContactsSearchUrl = (searchPhrase, path, filters, language) => {
    const filterReducer = (accumulator, [optionId, selected]) => selected ? [...accumulator, optionId] : accumulator;
    const typeFilters = Object.entries(filters.type)
        .reduce(filterReducer, [])
        .map((optionId) => typeFilterQueryParamValueMap[language].toDisplay[optionId])
        .sort();
    const regionFilters = Object.entries(filters.region)
        .reduce(filterReducer, [])
        .map((optionId) => regionFilterQueryParamValueMap[language].toDisplay[optionId])
        .sort();
    const newQuery = QueryString.stringify({
        [contactSearchPhraseParamName[language]]: searchPhrase || undefined,
        [contactTypeFilterParamName[language]]: typeFilters.length ? typeFilters : undefined,
        [contactRegionFilterParamName[language]]: regionFilters.length ? regionFilters : undefined,
    }, { arrayFormat: 'comma' });
    return `${path}?${newQuery}`;
};
const parseTypeFilterFromQueryParam = (rawValues, language, valueMaps) => {
    if (typeof rawValues === 'string') {
        const optionId = valueMaps[language].toId[rawValues];
        if (optionId) {
            return { [optionId]: true };
        }
    }
    if (Array.isArray(rawValues)) {
        return rawValues.reduce((accumulator, rawValue) => {
            const optionId = valueMaps[language].toId[rawValue];
            if (optionId) {
                return (Object.assign(Object.assign({}, accumulator), { [optionId]: true }));
            }
            return accumulator;
        }, {});
    }
    return {};
};
const getContactsStateFromLocation = (location, language) => {
    const queryParams = QueryString.parse(location.search, { arrayFormat: 'comma' });
    const salesTypeFilters = parseTypeFilterFromQueryParam(queryParams[contactTypeFilterParamName[language]], language, typeFilterQueryParamValueMap);
    const regionFilters = parseTypeFilterFromQueryParam(queryParams[contactRegionFilterParamName[language]], language, regionFilterQueryParamValueMap);
    const searchPhrase = (queryParams[contactSearchPhraseParamName[language]] || '');
    return { searchPhrase, salesTypeFilters, regionFilters };
};
