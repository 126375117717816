import React from 'react';
import { isRootCategory } from '@kesko/kespro-ui-common';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../hooks.js';
import { useGetCategoryHierarchyQuery } from '../services/api.js';
import { HorizontalContainer } from './FlexboxContainer.js';
import { PRODUCT_CATEGORY, PRODUCT_SEARCHPHRASE } from '../../common/queryStrings.js';
import { PRODUCTS_PAGE_PATH } from '../../common/urlPaths.js';
import { shallowEqual } from 'react-redux';
import qs from 'query-string';
import style from './CategoryBreadcrumbs.scss';
export const CategoryBreadcrumbs = () => {
    useGetCategoryHierarchyQuery();
    const { categoryHierarchy, searchPhrase, selectedCategory } = useAppSelector((state) => ({
        categoryHierarchy: state.category.hierarchy,
        searchPhrase: state.products.searchPhrase,
        selectedCategory: state.products.selectedCategory,
    }), shallowEqual);
    if (!categoryHierarchy) {
        return null;
    }
    const selectedCategoryOrCategoryHierarchy = selectedCategory || categoryHierarchy;
    if (!isRootCategory(selectedCategoryOrCategoryHierarchy)) {
        const breadCrumbs = [renderBreadcrumb(selectedCategoryOrCategoryHierarchy, searchPhrase)];
        let category = selectedCategoryOrCategoryHierarchy.parent;
        while (!isRootCategory(category)) {
            breadCrumbs.unshift(renderBreadcrumb(category, searchPhrase));
            category = category.parent;
        }
        const url = searchPhrase
            ? { pathname: PRODUCTS_PAGE_PATH, search: qs.stringify({ [PRODUCT_SEARCHPHRASE]: searchPhrase }) }
            : PRODUCTS_PAGE_PATH;
        return (React.createElement(HorizontalContainer, { verticalAlign: "center", className: style.categoryBreadcrumbs, wrap: true },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Link, { to: url, className: style.categoryName }, "Tuotteet")),
                breadCrumbs)));
    }
    else {
        return React.createElement("div", null);
    }
};
function renderBreadcrumb(category, searchPhrase) {
    const url = {
        pathname: PRODUCTS_PAGE_PATH,
        search: qs.stringify(Object.assign({ [PRODUCT_CATEGORY]: category.category.id }, (!!searchPhrase && { [PRODUCT_SEARCHPHRASE]: searchPhrase }))),
    };
    return (React.createElement("li", { key: category.category.id },
        React.createElement(Link, { to: url, className: style.categoryName }, category.category.name)));
}
