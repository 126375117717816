import React from 'react';
import * as R from 'ramda';
import { getStaticContentfulImgSrcSet } from '../util/image.js';
import { LinkWithScroll } from '../util/scroll.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './BrandCard.scss';
const imageWidth = 325;
const imageHeight = 140;
const logoWidth = 70;
const logoHeight = 55;
const renderImagePart = (hero) => {
    const { srcSet, fallback } = getStaticContentfulImgSrcSet(hero, imageWidth, imageHeight);
    return (React.createElement("picture", null,
        React.createElement("source", { srcSet: srcSet, type: "image/webp" }),
        React.createElement("img", { width: imageWidth, height: imageHeight, alt: "", loading: "lazy", className: styles.cardHeader, src: fallback })));
};
export const BrandCard = ({ cardCount, className, linkUrl, heroImage, title, logo = '', partnerContent }) => {
    const cardCountClass = partnerContent ?
        styles.cardsEqualWidth
        :
            R.cond([
                [R.equals(3), () => styles.threeCards],
                [R.equals(2), () => styles.twoCards],
                [R.equals(1), () => styles.oneCard],
                [R.T, () => undefined],
            ])(cardCount);
    const { srcSet, fallback } = getStaticContentfulImgSrcSet(logo, logoWidth, logoHeight);
    return (React.createElement("div", { className: combineClassNames(styles.cardWrapper, cardCountClass, className) },
        React.createElement(LinkWithScroll, { to: linkUrl },
            React.createElement(VerticalContainer, { horizontalAlign: "stretch", className: styles.productCard },
                React.createElement("section", { className: styles.imageContainer }, heroImage && renderImagePart(heroImage)),
                React.createElement("picture", { className: styles.iconContainer }, logo ?
                    React.createElement(React.Fragment, null,
                        React.createElement("source", { srcSet: srcSet, type: "image/webp" }),
                        React.createElement("img", { alt: title, width: logoWidth, height: logoHeight, loading: "lazy", src: fallback }))
                    :
                        React.createElement(React.Fragment, null)),
                title && (React.createElement("div", { className: styles.cardBody },
                    React.createElement("h3", { className: styles.cardTitle }, title)))))));
};
