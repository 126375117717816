import React from 'react';
import { Redirect } from 'react-router-dom';
/**
 * See root.ts/handleMatch which statuscodes are supported
 * Docs: https://v5.reactrouter.com/web/guides/server-rendering
 */
export const RedirectWithStatus = ({ from, to, status, staticContext }) => {
    if (staticContext) {
        staticContext.statusCode = status;
    }
    ;
    return React.createElement(Redirect, { from: from, to: to });
};
