import React from 'react';
import qs from 'query-string';
import { categoryNameToUrlSlug } from '../../../util/recipe.js';
import { scrollToTop } from '../../../util/scroll.js';
import { RECIPE_SEARCH_CATEGORY, RECIPE_SEARCH_INGREDIENTS } from '../../../../common/queryStrings.js';
import { Link } from 'react-router-dom';
import { COMBINED_SEARCH_RECIPES_PAGE_PATH } from '../../../../common/urlPaths.js';
import { HorizontalContainer, VerticalContainer } from '../../../components/FlexboxContainer.js';
import styles from './style.scss';
const renderCategory = (category, mainIngredient, tag) => {
    const optTagPath = tag ? `/${tag.slug}` : '';
    const linkTo = {
        pathname: `${COMBINED_SEARCH_RECIPES_PAGE_PATH}${categoryNameToUrlSlug(optTagPath)}`,
        search: qs.stringify({
            [RECIPE_SEARCH_CATEGORY]: category ? categoryNameToUrlSlug(category) : undefined,
            [RECIPE_SEARCH_INGREDIENTS]: mainIngredient ? categoryNameToUrlSlug(mainIngredient) : undefined,
        }),
    };
    if ((tag === null || tag === void 0 ? void 0 : tag.visibility) || category || mainIngredient) {
        return (React.createElement(Link, { className: tag ? styles.tagBubble : styles.categoryBubble, to: linkTo, key: category, onClick: scrollToTop },
            React.createElement(HorizontalContainer, { wrap: false, className: styles.categoryBubbleContent },
                React.createElement("span", null,
                    category,
                    mainIngredient,
                    tag && tag.title))));
    }
    return undefined;
};
export const RecipeCategories = (props) => {
    const { categories, mainIngredient, tags } = props;
    const categoryBubbles = categories.map((c) => renderCategory(c, undefined, undefined));
    const ingredientBubbles = mainIngredient.map((mi) => renderCategory(undefined, mi, undefined));
    const tagBubbles = tags.map((t) => renderCategory(undefined, undefined, t));
    return (React.createElement(VerticalContainer, null,
        React.createElement("section", { className: styles.bubbleContainer },
            categoryBubbles,
            ingredientBubbles),
        React.createElement("section", { className: styles.bubbleContainer }, tagBubbles)));
};
