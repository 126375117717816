const finnish = {
    becomeCustomer: 'Tule asiakkaaksi',
    contactUs: 'Ota yhteyttä',
    contactPeopleListHeader: 'Yhteyshenkilöt',
    contactPeopleListDisclaimer: 'Puhelun hinta on matkapuhelu- tai paikallisverkkomaksu. Kespron sähköpostiosoitteet ovat muotoa etunimi.sukunimi@kesko.fi',
    openingHoursTitle: 'Aukioloajat',
    phoneCallPricing: 'Puhelun hinta on matkapuhelu- tai paikallisverkkomaksu.',
    upcomingExceptionsTitle: 'Tulevat poikkeukset',
};
const english = {
    becomeCustomer: 'Become customer',
    contactUs: 'Contact',
    contactPeopleListHeader: 'Contact',
    contactPeopleListDisclaimer: 'A call costs mobile call charge or local network charge.',
    phoneCallPricing: 'A call costs mobile call charge or local network charge.',
    openingHoursTitle: 'Opening Hours',
    upcomingExceptionsTitle: ' Exceptions to opening hours',
};
const swedish = {
    becomeCustomer: 'Bli kund',
    contactUs: 'Ta kontakt',
    contactPeopleListHeader: 'Kontakt',
    contactPeopleListDisclaimer: 'En samtal kostar lokalnätsavgift eller mobilsamtalsavgift.',
    openingHoursTitle: 'Öppettider',
    phoneCallPricing: 'En samtal kostar lokalnätsavgift eller mobilsamtalsavgift.',
    upcomingExceptionsTitle: 'Avvikande öppettider',
};
const russian = english;
export const i18nTexts = {
    fi: finnish,
    en: english,
    sv: swedish,
    ru: russian,
};
