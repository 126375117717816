import React from 'react';
import { Svg } from '../components/Svg.js';
import { constants } from '../constants.js';
import styles from './QaSection.module.scss';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
const Accordion = ({ title, children }) => (React.createElement("details", { className: styles.accordion },
    React.createElement("summary", null,
        React.createElement("h4", { className: styles.accordionTitle }, title)),
    React.createElement("div", null, children)));
export const QaSection = () => (React.createElement("section", { id: constants.sectionIds.qa, className: styles.container },
    React.createElement("h2", null,
        React.createElement(Svg, { className: styles.bookSticker, name: 'sticker-book' }),
        React.createElement(Svg, { className: styles.title, name: 'title-qa' }),
        React.createElement(AriaHeadingText, null, "Kysymykset ja vastaukset")),
    React.createElement("div", { className: styles.content },
        React.createElement("h3", null, "Intohimoty\u00F6ryhm\u00E4"),
        React.createElement(Accordion, { title: 'Mik\u00E4 on Intohimoty\u00F6ryhm\u00E4 ja mihin sen toiminta keskittyy?' },
            React.createElement("p", null, "Intohimoty\u00F6ryhm\u00E4 on Kespron tukema vaihtuva ryhm\u00E4 intohimoisia ravintola-alan ammattilaisia ja vaikuttajia ja toimintatapa, jonka avulla pyrit\u00E4\u00E4n kehitt\u00E4m\u00E4\u00E4n ravintola-alaa ja ruokakulttuuria Suomessa. Ryhm\u00E4 keskittyy alan haasteiden ratkaisemiseen, sen arvostuksen nostamiseen ja tulevaisuuden mahdollisuuksien luomiseen koko toimialalle.")),
        React.createElement(Accordion, { title: 'Ketk\u00E4 ovat mukana Intohimoty\u00F6ryhm\u00E4ss\u00E4?' },
            React.createElement("p", null, "Ryhm\u00E4\u00E4n kuuluu alan kokeneita ammattilaisia saleista ja keitti\u00F6ist\u00E4: ravintoloitsijoita, kokkeja, tuottajia ja muita alan toimijoita. Ryhm\u00E4n kokoonpano el\u00E4\u00E4 tarpeen ja tilanteen mukaan, jotta keskustelut pysyv\u00E4t monipuolisina ja ajankohtaisina.")),
        React.createElement(Accordion, { title: 'Mik\u00E4 on Kespron rooli Intohimoty\u00F6ryhm\u00E4ss\u00E4?' },
            React.createElement("p", null, "Kespro toimii ryhm\u00E4n alullepanijana, tukijana ja alustana. Kespron roolina on auttaa viem\u00E4\u00E4n ryhm\u00E4ss\u00E4 syntyneit\u00E4 ideoita k\u00E4yt\u00E4nt\u00F6\u00F6n ja mahdollistaa keskustelut, jotka edist\u00E4v\u00E4t ravintola-alan kehityst\u00E4 ja n\u00E4kyvyytt\u00E4.")),
        React.createElement(Accordion, { title: 'Mit\u00E4 tavoitteita Intohimoty\u00F6ryhm\u00E4ll\u00E4 on?' },
            React.createElement("p", null, "Ty\u00F6ryhm\u00E4 pyrkii l\u00F6yt\u00E4m\u00E4\u00E4n konkreettisia ratkaisuja alan haasteisiin, vahvistamaan sen yhteiskunnallista merkityst\u00E4 sek\u00E4 luomaan pohjaa kaupalliselle menestykselle. Lis\u00E4ksi tavoitteena on tuoda esiin alan positiivisia puolia ja rohkaista toimijoita yhteisiin ponnistuksiin.")),
        React.createElement(Accordion, { title: 'Miten Intohimoty\u00F6ryhm\u00E4n ty\u00F6 n\u00E4kyy konkreettisesti?' },
            React.createElement("p", null, "Ty\u00F6ryhm\u00E4n keskusteluista ja ajatuksista syntyy toimenpide-ehdotuksia, jotka Kespro ja ryhm\u00E4n j\u00E4senet pyrkiv\u00E4t viem\u00E4\u00E4n k\u00E4yt\u00E4nt\u00F6\u00F6n. Lis\u00E4ksi ryhm\u00E4 antaa \u00E4\u00E4nen alan toimijoille ja tuo esiin ravintola-alan ja ruokakulttuurin merkityksen suomalaisessa yhteiskunnassa.")),
        React.createElement("h3", null, "Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtuma ja Muutoksen menu"),
        React.createElement(Accordion, { title: 'Mik\u00E4 on Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtuma?' },
            React.createElement("p", null, "Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtuma on hyv\u00E4n mielen mielenosoitus, joka j\u00E4rjestet\u00E4\u00E4n Eduskuntatalon edess\u00E4. Tapahtuman tarkoituksena on tuoda ravintola-alan viesti suoraan p\u00E4\u00E4tt\u00E4jille ja her\u00E4tt\u00E4\u00E4 keskustelua alan tarpeista ja haasteista. P\u00E4\u00E4tt\u00E4j\u00E4t kutsutaan saman p\u00F6yd\u00E4n \u00E4\u00E4reen alan edustajien kanssa kuulemaan n\u00E4kemyksi\u00E4 ja nauttimaan keitti\u00F6n herkkuja.")),
        React.createElement(Accordion, { title: 'Mik\u00E4 on Muutoksen menu, ja miten se liittyy Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtumaan?' },
            React.createElement("p", null, "Muutoksen menu on symbolinen julistus ja kokoelma keskeisi\u00E4 viestej\u00E4, jotka on kirjattu p\u00F6yt\u00E4liinaan. Muutoksen menu esitet\u00E4\u00E4n eduskunnalle ratkaisuehdotuksena ravintola-alan haasteisiin, ja se n\u00E4kyy my\u00F6s valtakunnallisesti eri medioissa. Teksti on konkreettinen muistutus siit\u00E4, mit\u00E4 ravintola-ala tarvitsee kukoistaakseen, ja se on Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtuman ydin.")),
        React.createElement(Accordion, { title: 'Mit\u00E4 tavoitteita Intohimoa p\u00F6yt\u00E4\u00E4n -tapahtumalla on?' },
            React.createElement("p", null, "Intohimoa p\u00F6yt\u00E4\u00E4n tuo alan toimijat ja p\u00E4\u00E4tt\u00E4j\u00E4t yhteen keskustelemaan ravintola-alan merkityksest\u00E4, haasteista ja tulevaisuudesta. Tapahtuma tavoittelee paitsi yhteist\u00E4 ymm\u00E4rryst\u00E4 alan tarpeista my\u00F6s sitoutumista alan huomioimiseen p\u00E4\u00E4t\u00F6ksenteossa.")),
        React.createElement(Accordion, { title: 'Millaisia tavoitteita tapahtuman median\u00E4kyvyyteen liittyy?' },
            React.createElement("p", null, "Tapahtuman ja Intohimoty\u00F6ryhm\u00E4n toivotaan her\u00E4tt\u00E4v\u00E4n laajaa valtakunnallista huomiota, jonka kautta keskustelu alaa parantavista toimista ei j\u00E4\u00E4 vain Kespron ja intohimoty\u00F6ryhm\u00E4l\u00E4isten piiriin. Kespron pitk\u00E4n aikav\u00E4lin tavoitteena on vahvistaa asemaansa alaa koskevien n\u00E4kemysten tarjoajana ja ilmi\u00F6iden kommentoijana.")),
        React.createElement(Accordion, { title: 'Miten yleis\u00F6 voi osallistua tai seurata tapahtumaa ja osoittaa tukensa Muutoksen menulle?' },
            React.createElement("p", null,
                "Tapahtuman ja Muutoksen menun p\u00E4\u00E4viestit jaetaan sosiaalisen median kanavissa ja mediassa. Viestit n\u00E4kyv\u00E4t my\u00F6s suurimpien medioiden sivuilla ymp\u00E4ri Suomea. Muutoksen menu tuo kuuluviin alan \u00E4\u00E4nen, ja tapahtuma tavoittaa yleis\u00F6n my\u00F6s eduskuntatalon edess\u00E4. ",
                React.createElement("a", { href: "" }, "kespro.com/intohimonyt"),
                "-sivustolla ravintola-alan ty\u00F6ntekij\u00E4t ymp\u00E4ri maan voivat j\u00E4tt\u00E4\u00E4 oman intohimonsa osoituksia p\u00E4\u00E4tt\u00E4jille.")))));
