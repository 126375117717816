var EventType;
(function (EventType) {
    EventType[EventType["Click"] = 0] = "Click";
    EventType[EventType["Form"] = 1] = "Form";
})(EventType || (EventType = {}));
const customEventHandler = (dataLayer) => (category, action, label, nonInteraction = false) => {
    dataLayer.push({
        event: 'custom.event',
        customEvent: {
            gaCategory: EventType[category],
            gaAction: action,
            gaLabel: label,
            gaNonInteraction: nonInteraction,
        },
    });
};
export const customPageHandler = (dataLayer) => (customPagePath, abTestingVersion) => {
    dataLayer.push({
        event: 'custom.page',
        customPage: {
            path: customPagePath,
        },
        activeVariant: abTestingVersion,
    });
};
export const customArticleViewedHandler = (dataLayer) => (article) => {
    var _a;
    dataLayer.push({
        event: 'custom.articleViewed',
        customArticleViewed: {
            slug: article.slug,
            author: (_a = article.author) === null || _a === void 0 ? void 0 : _a.name,
            publishTime: article.publishTime,
            tags: article.tags,
        },
    });
};
export const customSearchPhraseSuggestionClickedHandler = (dataLayer) => (writtenCharacters, clickedSuggestion) => {
    dataLayer.push({
        event: 'search_suggestion',
        search_input: writtenCharacters,
        suggested_query: clickedSuggestion,
    });
};
export const loginEventHandler = (dataLayer) => (loginAnalyticsData) => {
    dataLayer.push({
        event: 'login',
        loginInfo: {
            loginType: loginAnalyticsData.loginEventType,
            userInfo: loginAnalyticsData.analyticsData,
        },
    });
};
export const appMenuClickHandler = (dataLayer) => (target) => {
    dataLayer.push({
        event: 'appmenu_click',
        target,
    });
};
export const appMenuErrorHandler = (dataLayer) => (error) => {
    dataLayer.push({
        event: 'appmenu_error',
        error,
    });
};
export const formSubmittedHandler = (dataLayer) => (formName) => {
    customEventHandler(dataLayer)(EventType.Form, formName);
};
export const productPageNewClientLinkClickedHandler = (dataLayer) => () => {
    customEventHandler(dataLayer)(EventType.Click, 'UAPainikeTuotesivu', 'Asiakkaaksi-painike tuotesivulla');
};
export const articleViewedHandler = (dataLayer) => (article) => {
    customArticleViewedHandler(dataLayer)(article);
};
export const searchPhraseSuggestionClickedHandler = (dataLayer) => (writtenCharacters, clickedSuggestion) => {
    customSearchPhraseSuggestionClickedHandler(dataLayer)(writtenCharacters, clickedSuggestion);
};
export const productRecommendationClearEventHandler = (dataLayer) => (data) => {
    dataLayer.push(data);
};
export const productRecommendationEventHandler = (dataLayer) => (event) => (data) => (parent) => {
    const { item_list_id, item_list_name, item_list_title, model_date } = parent;
    dataLayer.push({
        event,
        // We can't change the name to model_date because that would break analytics history
        model_date_substitutes: model_date,
        ecommerce: {
            item_list_name,
            item_list_id,
            item_list_title,
            items: data,
        },
    });
};
