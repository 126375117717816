import React from 'react';
import { VerticalContainer } from './FlexboxContainer.js';
import { LoadMoreButton } from './LoadMoreButton.js';
import { ProductList } from './ProductList.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './ProductSearchResult.scss';
export const ProductSearchResult = ({ isError, isLoading, isLoadingMore, products, onLoadMore, hasMore, className }) => (React.createElement(VerticalContainer, { horizontalAlign: "center", className: combineClassNames(styles.productSearchResult, className) },
    isError && React.createElement(ErrorMessage, null),
    React.createElement(ProductList, { products: products }),
    hasMore && React.createElement(LoadMoreButton, { className: styles.loadMoreButton, isLoading: isLoadingMore, onClick: onLoadMore }),
    React.createElement(StatusMessage, { isLoading: isLoading, products: products })));
const StatusMessage = ({ isLoading, products }) => {
    if (isLoading) {
        return React.createElement("p", { className: styles.loading }, "Ladataan ...");
    }
    if (!products.length) {
        return React.createElement("p", null, "Ei hakutuloksia");
    }
    return null;
};
const ErrorMessage = () => (React.createElement("p", null, "Tapahtui virhe :( Yrit\u00E4 hetken p\u00E4\u00E4st\u00E4 uudestaan."));
