import * as R from 'ramda';
import React from 'react';
import { VerticalContainer, HorizontalContainer } from './FlexboxContainer.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './RadioTree.scss';
export const RadioTree = (props) => (React.createElement(VerticalContainer, { className: styles.radioTree }, props.items.map(renderItem(0, props))));
function renderItem(depth, props) {
    return (item, index) => (React.createElement(VerticalContainer, { key: index },
        React.createElement(HorizontalContainer, { verticalAlign: "center", className: styles.treeItem },
            React.createElement("div", { className: styles.itemRadio, style: { paddingLeft: `${30 * depth}px` } }, renderRadio(item, props)),
            React.createElement("div", { className: combineClassNames(styles.itemLabel, isSelected(item, props) && styles.selected), onClick: () => { selectItem(item, props); } }, props.getLabel(item)),
            React.createElement("div", { className: styles.itemToggle }, props.isLeaf(item)
                ? undefined
                : renderToggle(item, props))),
        ...isExpanded(item, props)
            ? props.getChildren(item).map(renderItem(depth + 1, props))
            : []));
}
function renderRadio(item, props) {
    return (React.createElement("div", { className: styles.radioOuter, onClick: () => { selectItem(item, props); } },
        React.createElement("div", { className: combineClassNames(styles.radioInner, isSelected(item, props) && styles.selected) })));
}
function renderToggle(item, props) {
    return (React.createElement("div", { className: styles.radioToggle, onClick: () => { props.toggleItemExpanded(props.getId(item)); } }, isExpanded(item, props) ? '-' : '+'));
}
function selectItem(item, props) {
    props.selectItem(item);
}
function isSelected(item, props) {
    return props.selectedItemId === props.getId(item);
}
function isExpanded(item, props) {
    return R.includes(props.getId(item), props.expandedItems);
}
