import React from 'react';
import style from './Button.scss';
import { KButton } from '@kesko/kesko-design-dna';
import { LinkWithScroll } from '../util/scroll.js';
import { combineClassNames } from '../util/css/classNames.js';
const mapButtonStyle = (type) => {
    switch (type) {
        case "primary" /* ButtonVariant.Primary */:
            return style.primary;
        case "secondary" /* ButtonVariant.Secondary */:
            return style.secondary;
        case "tertiary" /* ButtonVariant.Tertiary */:
            return style.tertiary;
        default:
            return "primary" /* ButtonVariant.Primary */;
    }
};
export const Button = (props) => {
    var _a;
    const buttonVariantStyle = mapButtonStyle(props.buttonType);
    const link = props.link && props.isExternalLink ?
        React.createElement("a", { className: combineClassNames(buttonVariantStyle, props.classNameButtonContent), href: props.link }, props.text)
        :
            React.createElement(LinkWithScroll, { className: combineClassNames(buttonVariantStyle, props.classNameButtonContent), to: (_a = props.link) !== null && _a !== void 0 ? _a : '' }, props.text);
    const text = React.createElement("span", { className: combineClassNames(buttonVariantStyle, props.classNameButtonContent) }, props.text);
    return (React.createElement(KButton, { "data-pw": props['data-pw'], onClick: props.onClickAction, disabled: props.isDisabled, type: props.isSubmitButton ? 'submit' : 'button', className: combineClassNames(style.button, style.wordBreak, props.className), size: props.size, variant: props.buttonType }, props.link ? link : text));
};
