import React from 'react';
import * as R from 'ramda';
import { VerticalContainer, HorizontalContainer, } from '../FlexboxContainer.js';
import { Icon } from '../../Icon.js';
import { HashLink } from 'react-router-hash-link';
import { AppMenu } from './AppMenu.js';
import { LogIn } from './LogIn.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { LinkWithScroll, NavLinkWithScroll } from '../../util/scroll.js';
import styles from './MobileMenu.scss';
export function MobileMenu(props) {
    const { primaryNavigationItems, secondaryNavigationItems, buttonNavigationItems, toggleMenu, isLoggedIn, language, languageItems, } = props;
    const kesproNetUrl = isLoggedIn ? buttonNavigationItems.goToWebStoreAuthenticated : buttonNavigationItems.goToWebStore;
    return (React.createElement(HorizontalContainer, { className: styles.mobileMenuContainer },
        React.createElement("div", { className: styles.mobileMenu, "data-pw": "mobileMenu" },
            React.createElement("div", { className: styles.menuMainNav }, primaryNavigationItems.map((item) => renderMenuItem(item, props, true))),
            React.createElement("div", { className: styles.menuSecondaryNav }, secondaryNavigationItems.map((item) => renderMenuItem(item, props, false))),
            React.createElement("div", { className: styles.menuLinks },
                React.createElement("a", { className: styles.linkItem, href: kesproNetUrl.to, target: "_blank", rel: "noopener noreferrer", "data-pw": "kespronetLink" },
                    React.createElement(Icon, { type: "shopping-basket", size: 24 }),
                    React.createElement("span", { className: styles.linkText }, kesproNetUrl.caption),
                    React.createElement(Icon, { type: "chevron-right", size: 24 })),
                isLoggedIn &&
                    React.createElement("div", { className: styles.linkItem },
                        React.createElement(AppMenu, { className: "globalMobileAppMenuContainerClass", isMobile: true, buttonCaption: buttonNavigationItems.digitalServices.caption })),
                React.createElement("div", { className: styles.loginLinkItem },
                    React.createElement(LogIn, { className: styles.logInLinkText, isMobile: true, loginLink: buttonNavigationItems.logIn, onLoggedInClick: toggleMenu })),
                React.createElement(HashLink, { className: styles.linkItem, to: buttonNavigationItems.mobileContactUs.to, onClick: toggleMenu, smooth: true },
                    React.createElement(Icon, { type: "speech-bubble", size: 24 }),
                    React.createElement("span", { className: styles.linkText }, buttonNavigationItems.mobileContactUs.caption))),
            React.createElement(HorizontalContainer, { className: isLoggedIn ? styles.hidden : styles.becomeClientContainer, horizontalAlign: "center", verticalAlign: "center" },
                React.createElement(LinkWithScroll, { to: buttonNavigationItems.becomeClient.to, className: styles.becomeClientLink, onClick: toggleMenu, "data-pw": "becomeClientLink" }, buttonNavigationItems.becomeClient.caption)),
            React.createElement(LanguageSelect, { currentLanguage: language, items: languageItems, onClick: toggleMenu })),
        React.createElement("div", { className: styles.transparent, onClick: toggleMenu })));
}
const languageSelectTexts = {
    fi: 'Suomeksi',
    en: 'In English',
    sv: 'På svenska',
    // Russian is now shown.
    ru: '',
};
const languageItemIndices = { fi: 0, sv: 1, en: 2 };
const LanguageSelect = ({ currentLanguage, items, onClick }) => {
    const sortedItems = items.reduce((result, item) => {
        const { language } = item;
        if (language !== 'ru') {
            result.splice(languageItemIndices[language], 0, Object.assign(Object.assign({}, item), { caption: languageSelectTexts[language] }));
        }
        return result;
    }, []);
    return (React.createElement(HorizontalContainer, { className: styles.languageContainer, "data-pw": "languageSelect" }, sortedItems.map((item) => (React.createElement(NavLinkWithScroll, { key: item.language, to: item.to, className: styles.languageLink, activeClassName: styles.languageActive, isActive: () => item.language === currentLanguage, onClick: onClick, "data-pw": "languageLink" }, item.caption)))));
};
function renderMenuItem(item, props, isPrimary) {
    const { openSubMenus, pathName, } = props;
    const subNavOpen = isSubNavOpen(item, openSubMenus);
    const linkStyle = isPrimary ? styles.mainNavLink : styles.secondaryNavLink;
    return (React.createElement(VerticalContainer, { key: item.to, className: combineClassNames(styles.navLinkWrapper, subNavOpen && styles.openSubNav) },
        item.subItems ? (React.createElement(HorizontalContainer, { verticalAlign: "center", className: combineClassNames(linkStyle, shouldSubMenuHeaderBeActive(item, openSubMenus, pathName) && styles.active, subNavOpen && styles.mainNavLinkSubMenuOpen), onClick: maybeCloseMenu(item, props), "data-pw": "subNavLinkContainer" },
            React.createElement("span", null, item.caption),
            item.subItems && (React.createElement("div", { className: combineClassNames(styles.navChevron, subNavOpen ? styles.up : undefined) },
                React.createElement(Icon, { type: "chevron-down", size: 24 }))))) : (React.createElement(NavLinkWithScroll, { to: item.to, className: linkStyle, activeClassName: styles.active, exact: item.onlyActiveOnIndex, onClick: maybeCloseMenu(item, props) },
            React.createElement("span", null, item.caption))),
        subNavOpen && (React.createElement(VerticalContainer, { className: styles.subNavContainer }, renderSubNav(item.subItems, props)))));
}
function shouldSubMenuHeaderBeActive(item, openSubMenus, pathName) {
    let isAnySubMenuActive = false;
    if (item.subItems) {
        isAnySubMenuActive = R.any(R.propSatisfies((to) => R.startsWith(to, pathName), 'to'))(item.subItems);
    }
    if (isAnySubMenuActive) {
        const isSubMenuClosed = !R.includes(item.caption, openSubMenus);
        return isSubMenuClosed;
    }
    return false;
}
function isSubNavOpen(item, openMobileSubMenus) {
    return item.subItems && R.includes(item.caption, openMobileSubMenus);
}
function renderSubNav(subItems, props) {
    return subItems.map((subItem) => (React.createElement(NavLinkWithScroll, { key: subItem.caption, to: subItem.to, className: styles.subNavLink, activeClassName: styles.activeSubMenuItem, exact: subItem.onlyActiveOnIndex, onClick: maybeCloseMenu(subItem, props), "data-pw": "subNavLink" }, subItem.caption)));
}
function maybeCloseMenu(item, props) {
    return () => {
        if (!item.subItems) {
            props.toggleMenu();
        }
        else {
            props.toggleSubMenu(item.caption);
        }
    };
}
