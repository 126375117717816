var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import { ARTICLE_PAGE_SIZE } from '../../common/constants.js';
const initialState = {
    offset: 0,
    searchResultOffset: 0,
    articleSearchResultByTag: undefined,
    articleSearchResultByTagHasMore: false,
    hasMore: false,
    tag: '',
    articleTotalHitCount: undefined,
    query: {
        searchPhrase: '',
        offset: 0,
        limit: ARTICLE_PAGE_SIZE,
    },
};
export const articlesByTagSlice = createSlice({
    name: 'articlesByTag',
    initialState,
    reducers: {
        incrementArticlesByTagOffset: (state, _action) => {
            state.offset = state.offset + ARTICLE_PAGE_SIZE;
            state.query.offset = state.offset;
        },
        updateTagFilter: (state, action) => {
            state.tag = action.payload;
        },
        updateHasMore: (state) => {
            var _a;
            state.articleSearchResultByTagHasMore = ((_a = state.articleSearchResultByTag) === null || _a === void 0 ? void 0 : _a.length) === ARTICLE_PAGE_SIZE;
        },
        setArticlesByTagSearchResult: (state, action) => {
            const { articles, total, tag } = action.payload;
            state.articleSearchResultByTag = articles;
            state.articleTotalHitCount = total;
            state.tag = tag;
        },
    },
});
export const { reducer } = articlesByTagSlice;
export const { incrementArticlesByTagOffset, updateTagFilter, setArticlesByTagSearchResult, updateHasMore } = articlesByTagSlice.actions;
