import * as R from 'ramda';
import QueryString from 'query-string';
import { contactListPageUrls, IDEA_INSPIRATIONS_PAGE_PATH, IDEA_INSPIRATIONS_ARTICLES_PAGE_PATH, IDEA_INSPIRATIONS_RECIPES_PAGE_PATH, PRODUCT_BRAND_PAGE_PATH, PRODUCTS_PAGE_PATH, COMBINED_SEARCH_ROOT_PATH, COMBINED_SEARCH_ARTICLES_PAGE_PATH, COMBINED_SEARCH_RECIPES_PAGE_PATH, } from '../../common/urlPaths.js';
import { getRelativeUrlFromLocation, getUpdateMethod } from '../util/url.js';
import { productsPageUrlChanged } from './productsSlice.js';
import { productBrandPageUrlChanged } from './productBrandSlice.js';
import { contactsUrlChanged } from './contactsSlice.js';
import { ideaInspirationPageUrlChanged } from './ideaInspirationSlice.js';
import { combinedSearchUrlChange } from './combinedSearchSlice.js';
import { setActiveLanguage } from './i18nSlice.js';
import { setHandledUrl } from './urlSlice.js';
import { COMBINED_SEARCH_ALL_SEARCHPHRASE, COMBINED_SEARCH_RECIPE_SEARCHPHRASE, RECIPE_SEARCH_CATEGORY, RECIPE_SEARCH_INGREDIENTS, } from '../../common/queryStrings.js';
import { getSelectedFilters, categoryNameToUrlSlug } from '../util/recipe.js';
export function createLocationChangeHandler(store) {
    const pathNameHandlers = {
        [IDEA_INSPIRATIONS_PAGE_PATH]: (location) => {
            store.dispatch(ideaInspirationPageUrlChanged(location));
        },
        [IDEA_INSPIRATIONS_ARTICLES_PAGE_PATH]: (location) => {
            store.dispatch(ideaInspirationPageUrlChanged(location));
        },
        [IDEA_INSPIRATIONS_RECIPES_PAGE_PATH]: (location) => {
            store.dispatch(ideaInspirationPageUrlChanged(location));
        },
        [PRODUCTS_PAGE_PATH]: (location) => {
            store.dispatch(productsPageUrlChanged(location));
        },
    };
    return (location) => {
        const state = store.getState();
        const url = getRelativeUrlFromLocation(location);
        store.dispatch(setActiveLanguage(url));
        if (state.url.handledUrl === url) {
            return;
        }
        const handler = pathNameHandlers[location.pathname];
        if (handler) {
            handler(location);
        }
        else {
            handleByPattern(store, location);
        }
    };
}
const handleByPattern = (store, location) => {
    const pathname = location.pathname;
    if (pathname.startsWith(COMBINED_SEARCH_ROOT_PATH)) {
        store.dispatch(combinedSearchUrlChange(location));
    }
    else if (pathname.startsWith(`${PRODUCT_BRAND_PAGE_PATH}/`)) {
        store.dispatch(productBrandPageUrlChanged(location));
    }
    else if (pathname.startsWith(contactListPageUrls.fi) ||
        pathname.startsWith(contactListPageUrls.en) ||
        pathname.startsWith(contactListPageUrls.sv)) {
        store.dispatch(contactsUrlChanged(location));
    }
};
export const updateCombinedSearchArticleUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.combinedSearch.searchPhrase;
    const newPath = getCombinedSearchArticleUrl(searchPhrase);
    const currentPath = getRelativeUrlFromLocation(location);
    if (location.pathname === COMBINED_SEARCH_ARTICLES_PAGE_PATH && newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
const getCombinedSearchArticleUrl = (searchPhrase) => {
    const queryParams = QueryString.stringify({
        [COMBINED_SEARCH_ALL_SEARCHPHRASE]: searchPhrase || undefined,
    });
    return `${COMBINED_SEARCH_ARTICLES_PAGE_PATH}?${queryParams}`;
};
export const updateCombinedSearchRecipeUrl = (location, push, replace) => (dispatch, getState) => {
    const state = getState();
    const searchPhrase = state.combinedSearch.searchPhrase;
    const selectedFilters = state.combinedSearchRecipe.activeFilters;
    const tag = state.combinedSearchRecipe.tag;
    const newPath = getRecipeSearchUrl(tag === null || tag === void 0 ? void 0 : tag.slug, searchPhrase, selectedFilters);
    const currentPath = getRelativeUrlFromLocation(location);
    if (location.pathname.startsWith(COMBINED_SEARCH_RECIPES_PAGE_PATH) && newPath !== currentPath) {
        dispatch(setHandledUrl(newPath));
        if (getUpdateMethod(newPath, currentPath) === 'push') {
            push(newPath);
        }
        else {
            replace(newPath);
        }
    }
};
const getRecipeSearchUrl = (tag, searchPhrase, selectedFilters) => {
    const { categories, mainIngredients } = getSelectedFilters(tag, selectedFilters);
    const category = R.head(categories);
    const mainIngredient = R.head(mainIngredients);
    const newQuery = QueryString.stringify({
        [COMBINED_SEARCH_RECIPE_SEARCHPHRASE]: searchPhrase || undefined,
        [RECIPE_SEARCH_CATEGORY]: category ? categoryNameToUrlSlug(category) : undefined,
        [RECIPE_SEARCH_INGREDIENTS]: mainIngredient ? categoryNameToUrlSlug(mainIngredient) : undefined,
    });
    const optTagPath = tag ? `/${categoryNameToUrlSlug(tag)}` : '';
    const queryParameters = newQuery ? `?${newQuery}` : '';
    return `/haku/reseptit${optTagPath}${queryParameters}`;
};
