var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
import { ARTICLE_PAGE_SIZE } from '../../common/constants.js';
import { initializeSearchPhrase, setCombinedSearchState } from './combinedSearchSlice.js';
const initialState = {
    offset: 0,
    searchResultOffset: 0,
    articleSearchResult: undefined,
    hasMore: false,
    query: {
        searchPhrase: '',
        offset: 0,
        limit: ARTICLE_PAGE_SIZE,
    },
};
export const combinedSearchArticleSlice = createSlice({
    name: 'combinedSearchArticle',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setCombinedSearchState, (state, action) => {
            const { searchResult, searchPhrase } = action.payload;
            state.offset = 0;
            state.searchResultOffset = 0;
            state.articleSearchResult = searchResult.hits.articles;
            state.hasMore = state.articleSearchResult.length === ARTICLE_PAGE_SIZE;
            state.query = {
                searchPhrase,
                offset: 0,
                limit: ARTICLE_PAGE_SIZE,
            };
        });
        builder.addCase(initializeSearchPhrase, (state, action) => {
            state.query.searchPhrase = action.payload;
        });
    },
    reducers: {
        incrementOffset: (state, _action) => {
            state.offset = state.offset + ARTICLE_PAGE_SIZE;
            state.query.offset = state.offset;
        },
        setArticleSearchResult: (state, action) => {
            if (!state.articleSearchResult || !action.payload) {
                state.searchResultOffset = state.offset;
                state.articleSearchResult = action.payload;
            }
            else if (state.searchResultOffset < state.offset) {
                state.searchResultOffset = state.offset;
                state.articleSearchResult = state.articleSearchResult.concat(action.payload);
            }
            state.hasMore = state.articleSearchResult.length === state.offset + ARTICLE_PAGE_SIZE;
        },
    },
});
export const { reducer } = combinedSearchArticleSlice;
export const { incrementOffset, setArticleSearchResult } = combinedSearchArticleSlice.actions;
