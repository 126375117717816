import React from 'react';
import { Link } from 'react-router-dom';
import { contactListPageUrls, wholesaleListPageUrls } from '../../common/urlPaths.js';
import { useAppSelector } from '../hooks.js';
import { Icon } from '../Icon.js';
import { HeroHeader } from './HeroHeader.js';
import { combineClassNames } from '../util/css/classNames.js';
import style from './ContactInfoPageHeader.scss';
export const ContactInfoPageHeader = ({ heroImageUrl, isLocation, }) => {
    const language = useAppSelector((state) => state.i18n.language);
    const previousPageLink = isLocation ? contactListPageUrls[language] : wholesaleListPageUrls[language];
    return (React.createElement("div", null,
        React.createElement(HeroHeader, { img: heroImageUrl, childrenContainerStyle: combineClassNames(style.header, style.noGradient), headerContainerClassname: combineClassNames(style.image, style.noGradient) }, previousPageLink ? (React.createElement("button", { className: style.header__backButton },
            React.createElement(Link, { to: previousPageLink },
                React.createElement(Icon, { type: 'chevron-left', size: 28 })))) : (React.createElement(React.Fragment, null)))));
};
