import React from 'react';
import style from './ShowMore.scss';
export class ShowMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shouldOverflow: false, setToOverflowOnMount: false };
    }
    render() {
        const { setToOverflowOnMount, shouldOverflow } = this.state;
        const { clientHeightThreshold, children } = this.props;
        return (React.createElement("div", { className: style.showMore },
            React.createElement("div", { ref: (e) => {
                    if (e && e.clientHeight > (clientHeightThreshold || 300) && !setToOverflowOnMount) {
                        this.setState({ setToOverflowOnMount: true, shouldOverflow: true });
                    }
                }, className: style.showMore__container, "data-overflow": shouldOverflow ? 'true' : 'false' },
                React.createElement("div", { className: style.showMore__container__mask }),
                children),
            setToOverflowOnMount && (React.createElement("button", { className: style.showMore__expandButton, type: "button", onClick: () => {
                    this.setState({ shouldOverflow: !shouldOverflow });
                } }, shouldOverflow ? 'Näytä lisää' : 'Piilota'))));
    }
}
