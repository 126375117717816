export const contactListPageUrls = {
    fi: '/yhteystiedot',
    en: '/en/contact-information',
    sv: '/sv/kontakt-information',
    ru: '/en/contact-information',
};
export const contactForSuppliersPageUrls = {
    fi: `${contactListPageUrls.fi}/tavarantoimittajille`,
    en: `${contactListPageUrls.en}/information-for-suppliers`,
    sv: `${contactListPageUrls.sv}/information-till-leverantörer`,
};
export const contactForMediaPageUrls = {
    fi: `${contactListPageUrls.fi}/medialle`,
    en: `${contactListPageUrls.en}/media-contact`,
    sv: `${contactListPageUrls.sv}/media-kontakt`,
};
export const wholesaleListPageUrls = {
    fi: `${contactListPageUrls.fi}/noutotukut`,
    en: `${contactListPageUrls.en}/wholesales`,
    sv: `${contactListPageUrls.sv}/grossistbutiker`,
};
export const customerServiceListPageUrls = {
    fi: `${contactListPageUrls.fi}/asiakaspalvelu`,
    en: `${contactListPageUrls.en}/customer-service`,
    sv: `${contactListPageUrls.sv}/kundtjänst`,
};
export const salesContactListPageUrls = {
    fi: `${contactListPageUrls.fi}/myynti`,
    en: `${contactListPageUrls.en}/sales`,
    sv: `${contactListPageUrls.sv}/försäljning`,
};
export const locationListPageUrls = {
    fi: `${contactListPageUrls.fi}/sijainnit`,
    en: `${contactListPageUrls.en}/locations`,
    sv: `${contactListPageUrls.sv}/platser`,
};
export const PARTNER_PAGE_PATH = '/kumppani';
export const IDEA_INSPIRATIONS_PAGE_PATH = '/ideat-ja-inspiraatiot';
export const IDEA_INSPIRATIONS_ARTICLES_PAGE_PATH = '/ideat-ja-inspiraatiot/artikkelit';
export const IDEA_INSPIRATIONS_RECIPES_PAGE_PATH = '/ideat-ja-inspiraatiot/reseptit';
export const PRODUCTS_PAGE_PATH = '/tuotteet';
export const MAGAZINES_PAGE_PATH = '/tuotekuvastot-ja-lehdet';
export const PRODUCT_BRAND_PAGE_PATH = '/tuotteet/tuotemerkit';
export const COMBINED_SEARCH_ROOT_PATH = '/haku';
export const COMBINED_SEARCH_PRODUCTS_PAGE_PATH = '/haku/tuotteet';
export const COMBINED_SEARCH_ARTICLES_PAGE_PATH = '/haku/artikkelit';
export const COMBINED_SEARCH_CONTACTS_PAGE_PATH = '/haku/yhteystiedot';
export const COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH = '/haku/sisallot';
export const COMBINED_SEARCH_RECIPES_PAGE_PATH = '/haku/reseptit';
export const commonContactFormPageUrls = {
    // TODO: Replace with /yhteydenotto when page is in use.
    fi: '/yhteystiedot#ota-yhteytta',
    en: '/en/contact',
    sv: '/sv/kontakt',
    ru: '/yhteystiedot#ota-yhteytta',
};
export const foodservicePageUrls = {
    fi: '/tukku',
    en: '/en/foodservice-wholesaler',
    sv: '/sv/foodservice-grossist',
    ru: '/ru/kespro-po-russki',
};
