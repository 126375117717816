import React from 'react';
import { Icon } from '../../Icon.js';
import styles from './IEDeprecatedBanner.scss';
const IE_DEPRECATED_INFO_SHOWN = 'IEDeprecatedInfoShown';
export const IEDeprecatedBanner = () => {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (!getInfoShownFromSessionStorage()) {
            const ua = navigator.userAgent;
            const isIe = ua.indexOf('Trident/') >= 0 || ua.indexOf('MSIE ') >= 0;
            setOpen(isIe);
        }
    }, []);
    const closeBanner = () => {
        setOpen(false);
        setInfoShownToSessionStorage();
    };
    const setInfoShownToSessionStorage = () => {
        sessionStorage.setItem(IE_DEPRECATED_INFO_SHOWN, 'true');
    };
    const getInfoShownFromSessionStorage = () => sessionStorage.getItem(IE_DEPRECATED_INFO_SHOWN);
    return (React.createElement(React.Fragment, null, open ?
        (React.createElement("div", { className: styles.banner },
            React.createElement("div", { className: styles.bannerContainer },
                React.createElement("div", { className: styles.bannerNoticeIcon },
                    React.createElement(Icon, { type: "notice", size: 35 })),
                React.createElement("p", null,
                    "Internet Explorer -selain poistuu k\u00E4yt\u00F6st\u00E4 15.6.2022. Vaihda selaimeksesi uusin versio ",
                    React.createElement("a", { href: "https://www.microsoft.com/fi-fi/edge", target: "blank", rel: "noopener noreferrer" }, "Microsoft Edgest\u00E4"),
                    ", ",
                    React.createElement("a", { href: "https://www.google.fi/chrome/", target: "blank", rel: "noopener noreferrer" }, "Google Chromesta"),
                    ", ",
                    React.createElement("a", { href: "https://www.mozilla.org/fi/", target: "blank", rel: "noopener noreferrer" }, "Mozilla Firefoxista"),
                    " tai ",
                    React.createElement("a", { href: "https://www.apple.com/safari/", target: "blank", rel: "noopener noreferrer" }, "Safarista.")),
                React.createElement("p", null,
                    "Voit lukea lis\u00E4\u00E4 ",
                    React.createElement("a", { href: "https://support.microsoft.com/fi-fi/windows/internet-explorer-lataukset-d49e1f0d-571c-9a7b-d97e-be248806ca70#ID0EBBD=Windows_10", target: "blank", rel: "noopener noreferrer" }, "Microsoftin sivuilta."))),
            React.createElement("button", { className: styles.bannerCloseButton, onClick: closeBanner },
                React.createElement(Icon, { type: "clear", size: 35 })))) : undefined));
};
