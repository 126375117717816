import React from 'react';
import { Icon } from '../Icon.js';
import style from './RadioGroup.scss';
export const RadioGroup = ({ items, selectItem, selectedItemId, backButton, ariaLabel, ariaDescribedBy }) => (React.createElement("div", { className: style.radioGroupContainer, "aria-describedby": ariaDescribedBy },
    backButton && backButton.isDisplayed && (React.createElement("button", { "data-pw": "radioGroupBackButton", className: style.radioGroup__backButton, type: "button", onClick: backButton.onClick },
        React.createElement(Icon, { type: "chevron-left", size: 24 }),
        "Takaisin")),
    React.createElement("ul", { className: style.radioGroup__list, "aria-label": ariaLabel }, items.map((item, index) => (React.createElement("li", { className: style.radioGroup__item, key: item.id || item.name, onKeyDown: (e) => {
            // If enter is pressed
            if (e && e.key === 'Enter') {
                selectItem(item.id);
            }
        }, "data-isselected": selectedItemId === item.id || (!selectedItemId && index === 0), tabIndex: 0 },
        React.createElement("input", { type: "radio", name: item.group, id: item.id, onClick: () => { selectItem(item.id); }, checked: selectedItemId === item.id || (!selectedItemId && index === 0), tabIndex: -1, readOnly: true }),
        React.createElement("label", { htmlFor: item.id },
            item.name,
            " ",
            item.hitCount && item.hitCount > 0 && React.createElement("span", null, item.hitCount))))))));
