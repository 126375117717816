import React from 'react';
import { NotFoundPage } from '../NotFoundPage/index.js';
import { LandingPageElementType, } from '../../../common/apiTypes.js';
import { locationMeta } from '../../meta.js';
import { useGetContactsQuery } from '../../services/api.js';
import { useMemo } from 'react';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { TextContent } from '../../components/LandingPage/TextContent.js';
import { useAppSelector } from '../../hooks.js';
import { locationListPageUrls } from '../../../common/urlPaths.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { Button } from '../../components/Button.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { ContactInfoPageHeader } from '../../components/ContactInfoPageHeader.js';
import style from './style.scss';
const ContactInfoPageHelmet = ({ contactId, location }) => {
    const language = useAppSelector((state) => state.i18n.language);
    const canonicalPath = `${locationListPageUrls[language]}/${contactId}`;
    const pageMeta = locationMeta(location);
    return React.createElement(KesproComHelmet, { title: location.name, canonicalPath: canonicalPath, description: pageMeta.description, pageMeta: pageMeta });
};
const LocationContactInfoPage = ({ contacts, contactId, language }) => {
    var _a;
    const location = useMemo(() => contacts.find((contact) => contact.contentType === 'location' && contact.slug === contactId), [contacts, contactId]);
    return (location ?
        React.createElement("article", { className: style.contactInfoPage },
            React.createElement(ContactInfoPageHelmet, { location: location, contactId: contactId }),
            React.createElement(ContactInfoPageHeader, { isLocation: true, heroImageUrl: location.image }),
            React.createElement("h1", null, location.name),
            React.createElement(TextContent, { type: LandingPageElementType.TextContent, id: contactId, textAlignment: "Keskitetty" /* TextContentAlignment.centered */, content: location.description }),
            location.ctaButtonVisibility && location.ctaButtonLink && location.ctaButtonText && (React.createElement("div", { className: style.contactLinkContainer },
                React.createElement(Button, { isExternalLink: (_a = location.isCtaButtonLinkExternal) !== null && _a !== void 0 ? _a : false, link: location.ctaButtonLink, text: location.ctaButtonText, size: "small" /* ButtonSize.Small */, type: LandingPageElementType.Button, buttonType: "primary" /* ButtonVariant.Primary */ }))))
        :
            React.createElement(NotFoundPage, { language: language }));
};
const ContactInfoPageWrapper = (props) => {
    const contactId = props.match.params.contactId;
    const locale = useAppSelector((state) => state.i18n.locale);
    const contactsQueryResult = useGetContactsQuery(Object.assign({ locale }, parseCacheQueryParams(props.location)));
    return renderRtkQueryResult(LocationContactInfoPage, { contactId }, contactsQueryResult, (contactInfoPageProps, contacts) => (Object.assign(Object.assign({}, contactInfoPageProps), { contacts })), { fetch404PageFromContentful: true });
};
export { ContactInfoPageWrapper as LocationContactInfoPage };
