import React, { useState } from 'react';
import { HorizontalContainer, VerticalContainer } from '../FlexboxContainer.js';
import { HashLink } from 'react-router-hash-link';
import { Button } from '../Button.js';
import { LandingPageElementType } from '../../../common/apiTypes.js';
import { LinkWithScroll } from '../../util/scroll.js';
import styles from './LinksBox.scss';
import { NewsletterSubscriptionModal } from './NewsletterSubscriptionModal.js';
export function LinksBox(props) {
    const { links, strings } = props;
    const [isNewsletterSignUpModalOpen, setIsNewsletterSignUpModalOpen] = useState(false);
    const halfwayIndex = Math.round(links.length / 2);
    const leftList = linkList(links.slice(0, halfwayIndex));
    const rightList = linkList(links.slice(halfwayIndex));
    const closeModal = () => {
        setIsNewsletterSignUpModalOpen(false);
    };
    const openModal = () => {
        setIsNewsletterSignUpModalOpen(true);
    };
    return (React.createElement(VerticalContainer, { horizontalAlign: "flex-start", className: styles.linksbox },
        React.createElement("h3", null, strings.linksHeader),
        React.createElement(HorizontalContainer, { horizontalAlign: "space-between", className: styles.links },
            leftList,
            rightList),
        React.createElement(Button, { "data-pw": "newsletterSignUpButton", classNameButtonContent: styles.newsLetterSignUpButtonContent, className: styles.newsletterSignUpButton, size: "small" /* ButtonSize.Small */, buttonType: "primary" /* ButtonVariant.Primary */, type: LandingPageElementType.Button, isExternalLink: false, text: 'Tilaa uutiskirje', onClickAction: openModal }),
        React.createElement(NewsletterSubscriptionModal, { isOpen: isNewsletterSignUpModalOpen, onClose: closeModal })));
}
function linkList(links) {
    const linksElems = links.map((link) => React.createElement("li", { key: link.caption }, link.external ?
        React.createElement("a", { href: link.to, className: styles.link, target: link.blank ? '_blank' : undefined, rel: link.blank ? 'noopener noreferrer' : undefined }, link.caption)
        : link.hashLink
            ? React.createElement(HashLink, { to: link.to, className: styles.link, target: link.blank ? '_blank' : undefined, smooth: true }, link.caption)
            : React.createElement(LinkWithScroll, { to: link.to, className: styles.link, target: link.blank ? '_blank' : undefined }, link.caption)));
    return React.createElement("ul", { className: styles.list }, linksElems);
}
