import React from 'react';
import * as R from 'ramda';
import { VerticalContainer } from '../../../components/FlexboxContainer.js';
import style from './style.scss';
const renderSteps = (steps) => {
    let count = 0;
    return steps.map((step, index) => {
        const isTitle = R.endsWith(':', step);
        if (!isTitle) {
            count += 1;
        }
        return (isTitle ?
            React.createElement("h3", { key: index }, step)
            :
                React.createElement("div", { key: index, className: style.step },
                    React.createElement("p", null,
                        count,
                        ". ",
                        step)));
    });
};
export const RecipeInstructions = ({ header, steps }) => (React.createElement(VerticalContainer, { className: style.methodLayout, horizontalAlign: "center", wrap: false },
    React.createElement("h2", null, header),
    React.createElement("div", { className: style.horizontalLine }),
    React.createElement("div", { className: style.steps }, renderSteps(steps))));
