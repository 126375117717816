import React from 'react';
import * as R from 'ramda';
import { IdeaInspirationCardList } from './IdeaInspirationCardList.js';
import styles from './RecommendationList.scss';
const recommendationToContentCard = (r) => ({
    slug: r.slug,
    title: r.title,
    heroImageUrl: r.imageUrl,
    lead: r.lead,
    type: r.type,
});
const LIMIT = 3;
const recommendationsToContentCards = R.pipe((list) => list.length > LIMIT ? list.slice(0, LIMIT) : list, R.map(recommendationToContentCard));
const renderRecommendations = (recommendations) => (React.createElement("div", { className: styles.recommendations },
    React.createElement("h3", null, "Suosittelemme"),
    React.createElement(IdeaInspirationCardList, { cards: recommendationsToContentCards(recommendations), hasMoreCards: false, renderMode: 'bigcards' })));
export const RecommendationList = ({ recommendations }) => (recommendations.length > 0
    ? renderRecommendations(recommendations)
    : React.createElement("div", null));
