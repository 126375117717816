import React from 'react';
import { ContactField } from './ContactField.js';
import { VerticalContainer } from '../FlexboxContainer.js';
import style from './ContactBox.scss';
export const ContactBox = (props) => {
    const { strings, contacts } = props;
    return (React.createElement(VerticalContainer, { "data-pw": "footerContactBox", horizontalAlign: "flex-start", className: style.contactBox },
        React.createElement("h3", null, strings.contactHeader),
        contacts.map((contact) => (React.createElement(ContactField, { contact: contact, key: contact.contact })))));
};
