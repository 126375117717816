import { useEffect, useMemo, useState } from 'react';
import { getProductListProduct } from '../../../../util/products.js';
import { clearProductRecommendationData, productRecommendationEvent } from '../../../../reducers/analyticsSlice.js';
export const useProductCardAnalytics = (productName, productData, substitutes) => {
    var _a;
    const productRecommendationParentData = useMemo(() => {
        var _a;
        return ({
            item_list_id: (_a = productData === null || productData === void 0 ? void 0 : productData.gtin) !== null && _a !== void 0 ? _a : '',
            item_list_name: productName !== null && productName !== void 0 ? productName : '',
            item_list_title: 'substitute',
            model_date: substitutes.model_date,
        });
    }, [productData]);
    const [didMount, setDidMount] = useState(false);
    const mapProductToAnalyticsData = (p, i, parentData) => {
        const { item_list_name, item_list_id } = parentData;
        const categoryNames = Array.from({ length: 4 }, (_, index) => { var _a, _b, _c; return (_c = (_b = (_a = p.categories) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; });
        return {
            item_id: p.ean,
            item_name: p.name,
            index: i,
            item_brand: p.brand,
            item_category: categoryNames[0],
            item_category2: categoryNames[1],
            item_category3: categoryNames[2],
            item_category4: categoryNames[3],
            item_list_id,
            item_list_name,
        };
    };
    const [substitutesData, setSubstitutesData] = useState((_a = substitutes.products) === null || _a === void 0 ? void 0 : _a.map((p, i) => ({
        productCardData: getProductListProduct(p),
        analyticsData: mapProductToAnalyticsData(p, i, productRecommendationParentData),
    })));
    useEffect(() => {
        if (substitutes.products) {
            clearProductRecommendationData({ ecommerce: null });
            let productCardData = substitutesData;
            if (didMount || !productCardData) {
                productCardData = substitutes.products.map((p, i) => ({
                    productCardData: getProductListProduct(p),
                    analyticsData: mapProductToAnalyticsData(p, i, productRecommendationParentData),
                }));
                setSubstitutesData(productCardData);
            }
            else {
                setDidMount(true);
            }
            productRecommendationEvent('view_item_list')(productCardData.map((p) => p.analyticsData))(productRecommendationParentData);
        }
    }, [substitutes.products]);
    const handleGoogleAnalyticsEvent = (product, slugifiedPath) => (event) => {
        event.preventDefault();
        clearProductRecommendationData({ ecommerce: null });
        productRecommendationEvent('select_item')([product.analyticsData])(productRecommendationParentData);
        setTimeout(() => {
            window.location.href = slugifiedPath;
        }, 0);
    };
    return { substitutesData, handleGoogleAnalyticsEvent };
};
