import React from 'react';
import { Svg } from '../components/Svg.js';
import styles from './HeroSection.module.scss';
import { intohimoNyt } from '../../../config.js';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
export const HeroSection = () => (React.createElement("div", { className: styles.overflowContainer },
    React.createElement("section", { className: styles.container },
        React.createElement(Svg, { className: styles.curlyBackground, name: 'curly-background' }),
        React.createElement("div", { className: styles.videoContainer },
            React.createElement("video", { width: 1980, height: 1080, preload: 'auto', autoPlay: true, muted: true, loop: true, playsInline: true },
                React.createElement("source", { src: intohimoNyt.heroVideoUrl, type: 'video/mp4' }))),
        React.createElement("h1", null,
            React.createElement(Svg, { name: 'title-main' }),
            React.createElement(AriaHeadingText, null, "Ravintola-alan Muutoksen menu"))),
    React.createElement(Svg, { className: styles.mouthSticker, name: 'sticker-mouth' })));
