import React, { useState } from 'react';
import { Icon } from '../../../Icon.js';
import { Link } from 'react-router-dom';
import { NewsletterSubscriptionModal } from '../../../components/Footer/NewsletterSubscriptionModal.js';
import styles from './Footer.module.scss';
export const Footer = () => {
    const [isNewsLetterModalOpen, setNewsLetterModalOpen] = useState(false);
    return (React.createElement("footer", { className: styles.container },
        React.createElement("nav", { className: styles.links },
            React.createElement("button", { onClick: () => setNewsLetterModalOpen(true) }, "Tilaa uutiskirje"),
            React.createElement("span", null),
            React.createElement("a", { id: 'kconsent-show-v1', href: 'https://tietosuoja.kesko.fi/tietosuojakeskossa/evastekaytannot#kconsent=kespro.com', target: '_blank' }, "Ev\u00E4steasetukset"),
            React.createElement("span", null),
            React.createElement("a", { target: '_blank', href: 'https://tietosuoja.kesko.fi/tietosuojakeskossa/evastekaytannot' }, "Ev\u00E4stek\u00E4yt\u00E4nn\u00F6t"),
            React.createElement("span", null),
            React.createElement(Link, { to: '/lakisaateinen/kayttoehdot', target: '_blank' }, "K\u00E4ytt\u00F6ehdot"),
            React.createElement("span", null),
            React.createElement(Link, { to: '/lakisaateinen/tietosuojaseloste', target: '_blank' }, "Tietosuojaseloste")),
        React.createElement("p", null,
            "Powered by ",
            React.createElement(Icon, { type: 'intohimo-kespro-logo', title: 'Kespro', size: { width: 76, height: 28 }, className: styles.kesproLogo })),
        React.createElement(NewsletterSubscriptionModal, { isOpen: isNewsLetterModalOpen, onClose: () => setNewsLetterModalOpen(false) })));
};
