var _a;
import { __awaiter } from "tslib";
import * as rtk from '@reduxjs/toolkit';
import { PRODUCT_BRAND_PRODUCTS_PAGE_SIZE } from '../../common/constants.js';
import { immerUnserializable } from '../util/redux.js';
import * as R from 'ramda';
import { matchPath } from 'react-router-dom';
import { PRODUCT_BRAND_PAGE_PATH } from '../../common/urlPaths.js';
import { setHandledUrl } from './urlSlice.js';
import { getRelativeUrlFromLocation } from '../util/url.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    slug: '',
    offset: 0,
    hasMore: false,
    products: undefined,
    productsOffset: 0,
    selectedCategory: undefined,
    query: {
        categories: undefined,
        slug: '',
        offset: 0,
    },
};
export const productBrandPageUrlChanged = (location) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const newState = getProductBrandSlugFromLocation(location);
    dispatch(setProductBrandPageState(newState));
    dispatch(setHandledUrl(getRelativeUrlFromLocation(location)));
});
export const productBrandSlice = createSlice({
    name: 'productBrand',
    initialState,
    reducers: {
        incrementOffset: (state) => {
            state.offset = state.offset + PRODUCT_BRAND_PRODUCTS_PAGE_SIZE;
            state.query = createProductBrandQuery(state.slug, state.offset, state.selectedCategory);
        },
        selectCategory: (state, action) => {
            var _a, _b, _c, _d;
            if (((_a = state.selectedCategory) === null || _a === void 0 ? void 0 : _a.category.id) !== ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.category.id) ||
                ((_c = state.selectedCategory) === null || _c === void 0 ? void 0 : _c.categoryIdPrefix) !== ((_d = action.payload) === null || _d === void 0 ? void 0 : _d.categoryIdPrefix)) {
                return Object.assign(Object.assign({}, state), { offset: 0, productsOffset: 0, selectedCategory: action.payload ? immerUnserializable(R.clone(action.payload)) : action.payload, query: createProductBrandQuery(state.slug, 0, action.payload) });
            }
            return state;
        },
        setProducts: (state, action) => {
            if (state.offset === 0 || !state.products) {
                state.productsOffset = state.offset;
                state.products = action.payload.products;
            }
            else if (state.productsOffset < state.offset) {
                state.productsOffset = state.offset;
                state.products = state.products.concat(action.payload.products);
            }
            state.hasMore = state.products.length < action.payload.totalHits;
        },
        setProductBrandPageState: (state, action) => {
            if (state.slug !== action.payload) {
                state.offset = 0;
                state.productsOffset = 0;
                state.slug = action.payload;
                state.query = createProductBrandQuery(state.slug, state.offset);
            }
        },
    },
});
export const { reducer } = productBrandSlice;
export const { incrementOffset, selectCategory, setProducts, setProductBrandPageState } = productBrandSlice.actions;
const createCategoriesQueryParameter = (selectedCategory) => {
    var _a;
    if (!selectedCategory || ((_a = selectedCategory.category) === null || _a === void 0 ? void 0 : _a.level) === 1) {
        return '';
    }
    const { category, children } = selectedCategory;
    if (category.level === 2) {
        return children.map((childCategory) => childCategory.category.id).join(',');
    }
    return category.id;
};
const createProductBrandQuery = (slug, offset, selectedCategory) => ({
    slug,
    offset,
    limit: PRODUCT_BRAND_PRODUCTS_PAGE_SIZE,
    categories: createCategoriesQueryParameter(selectedCategory),
});
const getProductBrandSlugFromLocation = (location) => {
    const match = matchPath(location.pathname, {
        path: `${PRODUCT_BRAND_PAGE_PATH}/:slug`,
        exact: true,
    });
    return (match === null || match === void 0 ? void 0 : match.params.slug) || '';
};
