import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { incrementOffset as incrementProductsOffset, resetFiltersWithUpdates, setBrandWithUpdates, setCategoryWithUpdates, toggleBrandFiltersDropdown, toggleCategoryFiltersDropdown, } from '../reducers/combinedSearchProductSlice.js';
import { useCombinedSearchProductsQuery } from '../hooks/useCombinedSearchProductsQuery.js';
import { BrandFilter } from './BrandFilter.js';
import { ProductCategoryFilter } from './ProductCategoryFilter.js';
import { ProductSearchDescription } from './ProductSearchDescription.js';
import { ProductSearchResult } from './ProductSearchResult.js';
import { breakPoint } from '../util/image.js';
import { getProductListProduct } from '../util/products.js';
import { shallowEqual } from 'react-redux';
import style from './ProductSearchResultsContainer.scss';
const CategoryBrandFilters = (props) => {
    const dispatch = useAppDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductCategoryFilter, { categoryHierarchy: props.categoryHierarchy, productCategoryHitCounts: props.categoryHitCounts, totalHits: props.totalHits, selectCategory: (category) => {
                dispatch(setCategoryWithUpdates(category, props.location, props.push, props.replace));
            }, selectedCategory: props.selectedCategory, isDropdownOpen: props.categoryFiltersOpen, onDropdownToggled: () => {
                dispatch(toggleCategoryFiltersDropdown());
            } }),
        React.createElement(BrandFilter, { selectBrand: (brand) => {
                dispatch(setBrandWithUpdates(brand, props.location, props.push, props.replace));
            }, selectedBrand: props.selectedBrand, brands: props.brands, isDropdownOpen: props.brandFiltersOpen, onDropdownToggled: () => {
                dispatch(toggleBrandFiltersDropdown());
            } })));
};
export const ProductSearchResultsContainer = ({ onCloseMobileFilters, isMobileFiltersOpen, location, push, replace, }) => {
    const { categoryHierarchy, brands, categoryFiltersOpen, selectedCategory, brandFiltersOpen, selectedBrand, hasMore, offset, lastUsedSearchPhrase, totalHitCounts, } = useAppSelector((state) => ({
        categoryHierarchy: state.category.hierarchy,
        brands: state.combinedSearchProduct.brands,
        categoryFiltersOpen: state.combinedSearchProduct.categoryFiltersOpen,
        selectedCategory: state.combinedSearchProduct.selectedCategory,
        brandFiltersOpen: state.combinedSearchProduct.brandFiltersOpen,
        selectedBrand: state.combinedSearchProduct.selectedBrand,
        hasMore: state.combinedSearchProduct.hasMore,
        offset: state.combinedSearchProduct.offset,
        lastUsedSearchPhrase: state.combinedSearch.lastUsedSearchPhrase,
        totalHitCounts: state.combinedSearch.totalHitCounts,
    }), shallowEqual);
    const dispatch = useAppDispatch();
    const productsSearchQueryResult = useCombinedSearchProductsQuery();
    const products = productsSearchQueryResult.data.map((p) => getProductListProduct(p, categoryHierarchy));
    useEffect(() => {
        if (window && window.innerWidth > breakPoint.TABLET) {
            // when first loading the page, close the filters in mobile view and open them in desktop view
            dispatch(toggleCategoryFiltersDropdown());
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: style.mobileFilterView }, categoryHierarchy &&
            React.createElement(CategoryBrandFilters, { categoryHierarchy: categoryHierarchy, categoryHitCounts: totalHitCounts.productCategories, totalHits: totalHitCounts.products, selectedCategory: selectedCategory, categoryFiltersOpen: categoryFiltersOpen, selectedBrand: selectedBrand, brands: brands, brandFiltersOpen: brandFiltersOpen, location: location, push: push, replace: replace })),
        React.createElement("div", { className: style.productSearchResultsContainer },
            React.createElement("div", { "data-ismobilefilteropen": isMobileFiltersOpen, className: style.productSearchResults__filterContainer },
                React.createElement("div", { className: style.productSearchResults__filters },
                    React.createElement("div", { className: style.productSearchResults__filters__header },
                        React.createElement("h2", null, "Rajaa hakua"),
                        React.createElement("button", { onClick: () => { dispatch(resetFiltersWithUpdates(location, push, replace)); } }, "Nollaa rajaukset")),
                    React.createElement("div", { className: style.productSearchResults__filters__container }, categoryHierarchy &&
                        React.createElement(CategoryBrandFilters, { categoryHierarchy: categoryHierarchy, categoryHitCounts: totalHitCounts.productCategories, totalHits: totalHitCounts.products, selectedCategory: selectedCategory, categoryFiltersOpen: categoryFiltersOpen, selectedBrand: selectedBrand, brands: brands, brandFiltersOpen: brandFiltersOpen, location: location, push: push, replace: replace })),
                    React.createElement("div", { className: style.filterBottomPart },
                        React.createElement("button", { className: style.productSearchResults__filters__useFiltersButton, onClick: onCloseMobileFilters }, "K\u00E4yt\u00E4")))),
            React.createElement("div", { className: style.productSearchDescriptionAndResults },
                React.createElement(ProductSearchDescription, { searchPhrase: lastUsedSearchPhrase, selectedBrand: selectedBrand, selectedCategory: selectedCategory, productHitCount: totalHitCounts.products }),
                React.createElement(ProductSearchResult, { products: products, isError: productsSearchQueryResult.isError, isLoadingMore: offset > 0 && productsSearchQueryResult.isFetching, hasMore: hasMore, onLoadMore: () => {
                        dispatch(incrementProductsOffset());
                    }, className: style.productSearchResults })))));
};
