import React from 'react';
import { RecipeHeader } from './RecipeHeader/index.js';
import { RecipeIngredients } from './RecipeIngredients/index.js';
import { RecipeInstructions } from './RecipeInstructions/index.js';
import { recipeMeta, defaultDescriptions } from '../../meta.js';
import { useGetRecipeQuery } from '../../services/api.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { KesproInfoBoxes } from '../../components/KesproInfoBoxes.js';
import { RecommendationList } from '../../components/RecommendationList.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import styles from './style.scss';
const RecipePage = ({ referredFromRecipeSearch, slug, recipe, toPreviousPage }) => {
    const pageMeta = recipeMeta(recipe);
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { title: recipe.seoTitle, canonicalPath: `/ideat-ja-inspiraatiot/reseptit/${slug}`, description: pageMeta ? pageMeta.description : defaultDescriptions.recipe, pageMeta: pageMeta }),
        React.createElement(RecipeHeader, { fromRecipeSearch: referredFromRecipeSearch, backToPrevLocation: toPreviousPage, title: recipe.name, heroImageUrl: recipe.heroImageUrl, lead: recipe.lead, publishTime: recipe.publishTime, tradeCooperation: recipe.tradeCooperation, tradeCooperationSlug: recipe.tradeCooperationSlug, categories: recipe.categories, mainIngredient: recipe.mainIngredient, tags: recipe.tags }),
        React.createElement(VerticalContainer, null,
            React.createElement("section", { className: styles.recipeContainer },
                React.createElement(RecipeIngredients, { ingredients: recipe.ingredients }),
                React.createElement(RecipeInstructions, { header: "Valmistus", steps: recipe.instructions })),
            React.createElement(KesproInfoBoxes, null),
            React.createElement(RecommendationList, { recommendations: recipe.recommendations }))));
};
const RecipePageWrapper = (props) => {
    var _a, _b;
    const slug = props.match.params.recipeSlug;
    const params = Object.assign({ slug }, parseCacheQueryParams(props.location));
    const recipeQueryResult = useGetRecipeQuery(params);
    const referredFromRecipeSearch = ((_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.from) === '/haku/reseptit';
    const handleGoToPreviousPage = () => {
        props.history.goBack();
    };
    return renderRtkQueryResult(RecipePage, { toPreviousPage: handleGoToPreviousPage, referredFromRecipeSearch, slug }, recipeQueryResult, (recipePageProps, recipe) => (Object.assign(Object.assign({}, recipePageProps), { recipe })), { loadingClassName: styles.loading, fetch404PageFromContentful: true });
};
export { RecipePageWrapper as RecipePage };
