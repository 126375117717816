import * as R from 'ramda';
import { toDate } from '../../common/apiTypes.js';
import { translations } from '../staticData.js';
const localizeHourRange = (hourRange, language) => {
    if (hourRange === 'suljettu') {
        return translations[language].common.openingHoursClosed;
    }
    // The English time could be shown in AM and PM, but is not in specifications yet.
    return hourRange;
};
export const compactOpeningHours = (dayEntries, language) => {
    const createRun = (e) => ({
        start: e.label,
        end: undefined,
        hours: e.time,
    });
    if (dayEntries.length === 0) {
        return [];
    }
    const runs = R.tail(dayEntries).reduce((entries, next) => {
        const last = R.last(entries);
        return (last.hours === next.time)
            ? R.init(entries).concat([Object.assign(Object.assign({}, last), { end: next.label })])
            : entries.concat(createRun(next));
    }, [
        createRun(R.head(dayEntries)),
    ]);
    return runs.map(({ start, end, hours }) => ({
        label: end ? `${start}-${end}` : start,
        time: localizeHourRange(hours, language),
    }));
};
const isToday = (exceptionalOpeningHour) => {
    const date = toDate(exceptionalOpeningHour.date);
    const today = new Date();
    return date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
};
const findToday = R.find(isToday);
const todayOpened = (fields) => {
    const exceptionalOpeningToday = findToday(fields.exceptionalOpeningHours);
    if (exceptionalOpeningToday) {
        return exceptionalOpeningToday.time;
    }
    else {
        const dayIndex = new Date().getDay();
        switch (dayIndex) {
            case 0: return fields.openingHoursSun;
            case 1: return fields.openingHoursMon;
            case 2: return fields.openingHoursTue;
            case 3: return fields.openingHoursWed;
            case 4: return fields.openingHoursThu;
            case 5: return fields.openingHoursFri;
            case 6: return fields.openingHoursSat;
            default: return undefined;
        }
    }
};
const dateToDayLabel = (date, language) => {
    const abbreviations = translations[language].abbreviations;
    switch (date.getDay()) {
        case 0: return abbreviations.sunday;
        case 1: return abbreviations.monday;
        case 2: return abbreviations.tuesday;
        case 3: return abbreviations.wednesday;
        case 4: return abbreviations.thursday;
        case 5: return abbreviations.friday;
        case 6: return abbreviations.saturday;
        default: return '';
    }
};
const startOfCurrentDay = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};
const isTodayOrLater = (exceptionalOpeningHour) => {
    const date = exceptionalOpeningHour.date;
    const startOfDay = startOfCurrentDay();
    const diff = startOfDay.getTime() - date;
    return diff <= 0;
};
const formatLabel = (date, language) => `${dateToDayLabel(date, language)} ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
const toOpeningHourListEntry = ({ description, time, date }, language) => {
    const formattedDate = formatLabel(toDate(date), language);
    return {
        label: formattedDate,
        description,
        time: localizeHourRange(time, language),
    };
};
export const getExceptionalHourEntries = (hours, language) => {
    const filteredHours = R.sortWith([R.ascend(R.prop('date')), R.ascend(R.prop('time'))], hours.filter(isTodayOrLater));
    return filteredHours.map((hour) => toOpeningHourListEntry(hour, language));
};
export const getCompactHourEntries = (data, language) => {
    const abbreviations = translations[language].abbreviations;
    const dayEntries = [
        { label: abbreviations.monday, time: data.openingHoursMon },
        { label: abbreviations.tuesday, time: data.openingHoursTue },
        { label: abbreviations.wednesday, time: data.openingHoursWed },
        { label: abbreviations.thursday, time: data.openingHoursThu },
        { label: abbreviations.friday, time: data.openingHoursFri },
        { label: abbreviations.saturday, time: data.openingHoursSat },
        { label: abbreviations.sunday, time: data.openingHoursSun },
    ];
    return compactOpeningHours(dayEntries, language);
};
export const formatTodayOpened = (openingHours, language) => {
    const todayValue = todayOpened(openingHours);
    if (todayValue === 'suljettu') {
        return {
            label: translations[language].common.openingHoursClosedToday,
        };
    }
    else {
        return {
            label: translations[language].common.openingHoursOpenToday,
            hours: todayValue,
        };
    }
};
