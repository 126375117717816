import React, { useEffect, useState } from 'react';
import { LargeHeader } from './LargeHeader.js';
import { SmallHeader } from './SmallHeader.js';
import { languageBasePathRedirectTargetUrls, } from '../../staticData.js';
import { LoginModal } from './LoginModal.js';
import { appMenuEvent } from '../../reducers/analyticsSlice.js';
import { useAppSelector } from '../../hooks.js';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './Header.scss';
export function Header(props) {
    const { primaryNavigationItems, secondaryNavigationItems, buttonNavigationItems, pathName, language, languageItems, } = props;
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const [openMobileSubMenus, setOpenMobileSubMenus] = useState([]);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(true);
    const homePageLink = languageBasePathRedirectTargetUrls[language];
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    const toggleMobileSubMenu = (subMenu) => {
        if (openMobileSubMenus.includes(subMenu)) {
            setOpenMobileSubMenus(openMobileSubMenus.filter((item) => item !== subMenu));
        }
        else {
            setOpenMobileSubMenus([...openMobileSubMenus, subMenu]);
        }
    };
    useEffect(() => {
        // Handles the AppMenu analytics.
        const handleMessageReceived = (event) => {
            appMenuEvent(event);
            if (event.data === 'closeWidget') {
                setMobileMenuOpen(false);
            }
        };
        window.addEventListener('message', handleMessageReceived);
        return () => {
            window.removeEventListener('message', handleMessageReceived);
        };
    }, []);
    return (React.createElement("header", { className: combineClassNames(isSticky ? styles.sticky : undefined) },
        React.createElement("div", { className: styles.desktop },
            React.createElement(LargeHeader, { primaryNavigationItems: primaryNavigationItems, secondaryNavigationItems: secondaryNavigationItems, buttonNavigationItems: buttonNavigationItems, pathName: pathName, language: language, languageItems: languageItems, homePageLink: homePageLink, isLoggedIn: isLoggedIn })),
        React.createElement("div", { className: styles.mobile },
            React.createElement(SmallHeader, { menuOpen: mobileMenuOpen, openMobileSubMenus: openMobileSubMenus, toggleSubMenu: toggleMobileSubMenu, toggleMenu: toggleMobileMenu, pathName: pathName, primaryNavigationItems: primaryNavigationItems, secondaryNavigationItems: secondaryNavigationItems, buttonNavigationItems: buttonNavigationItems, isSticky: isSticky, setIsSticky: setIsSticky, homePageLink: homePageLink, isLoggedIn: isLoggedIn, language: language, languageItems: languageItems })),
        React.createElement(LoginModal, { loginLink: buttonNavigationItems.logIn })));
}
