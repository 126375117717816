import React from 'react';
import { LandingPageElementType } from '../../../common/apiTypes.js';
import { mapAnalyticsPropsToDataAttributes } from '../../util/analyticsDataAttributes.js';
import { Button } from '../Button.js';
import { FilterBar, FilterBarMode } from '../FilterBar.js';
import { PreloadedImage } from '../PreloadedImage.js';
import { combineClassNames, getVisibilityClassname } from '../../util/css/classNames.js';
import { getDefaultContentfulImgSrcSet } from '../../util/image.js';
import { LinkWithScroll } from '../../util/scroll.js';
import style from './Hero.scss';
export const Hero = (props) => {
    var _a, _b;
    const { srcSet, sizes } = getDefaultContentfulImgSrcSet(props.image || '');
    const filterBarFilters = props.anchorLinks.map((link) => ({
        caption: link.title,
        to: `#${link.id}`,
    }));
    let ctaButtonVisibility = props.ctaButtonVisibility;
    if (ctaButtonVisibility && props.isLoggedIn) {
        ctaButtonVisibility = !props.hideCtaButtonForLoggedInUsers;
    }
    const analyticsDataAttributes = mapAnalyticsPropsToDataAttributes(props.analyticsOptions, { promotionType: props.type, promotionName: props.title, promotionLink: props.link });
    const linkButtonPropertiesExist = props.link && props.linkButtonText && props.linkButtonAppearance !== "hidden" /* LinkButtonAppearance.Hidden */;
    return (React.createElement(React.Fragment, null,
        React.createElement(HeroLinkContents, Object.assign({}, props, { showCtaButton: ctaButtonVisibility, dataAttributes: analyticsDataAttributes }),
            props.image && (React.createElement(PreloadedImage, { imageUrl: props.image, imageHeight: props.imageHeight, imageWidth: props.imageWidth, imageSrcset: srcSet, imageSizes: sizes, alt: props.altText ? props.altText : undefined, containerClassName: style.imageContainer, style: props.focalPoint ? { objectPosition: props.focalPoint } : undefined })),
            React.createElement("div", { className: combineClassNames(style.heroContentContainer, style[props.titleColor], props.size === "narrower" /* HeroType.Narrower */ && style.narrowerContent) },
                props.usePageTitle ? (React.createElement("h1", { className: style.headingh1 }, props.title)) : (React.createElement("h2", { className: style.headingh2 }, props.title)),
                props.size !== "narrow" /* HeroType.Narrow */ && props.lead && (React.createElement("p", { className: combineClassNames(style.lead, props.size === "regular" /* HeroType.Regular */ && style.hideOnMobile) }, props.lead)),
                linkButtonPropertiesExist && (React.createElement(Button, { className: combineClassNames(style.smallerKButton, style.hideOnMobile), buttonType: "primary" /* ButtonVariant.Primary */, size: "small" /* ButtonSize.Small */, text: (_a = props.linkButtonText) !== null && _a !== void 0 ? _a : '', isHeroButtonWithoutLink: true, isExternalLink: false, type: LandingPageElementType.Button })))),
        props.lead && props.size !== "narrower" /* HeroType.Narrower */ && (React.createElement("div", { className: combineClassNames(style.contentContainer, props.size === "regular" /* HeroType.Regular */ ? style.hideOnLarge : undefined) },
            React.createElement("p", { className: style.lead }, props.lead))),
        props.size === "regular" /* HeroType.Regular */ && linkButtonPropertiesExist && (React.createElement(HeroLinkContents, Object.assign({}, props, { showCtaButton: ctaButtonVisibility }),
            React.createElement("div", { className: style.heroButtonContainer },
                React.createElement(Button, { className: style.smallerKButton, buttonType: "secondary" /* ButtonVariant.Secondary */, size: "small" /* ButtonSize.Small */, text: (_b = props.linkButtonText) !== null && _b !== void 0 ? _b : '', isHeroButtonWithoutLink: true, isExternalLink: false, type: LandingPageElementType.Button })))),
        ((props.showH2AnchorNavigation && filterBarFilters.length > 0) || ctaButtonVisibility) && (React.createElement("div", { className: combineClassNames(style.navBar, props.stickyH2AnchorNavigation && style.sticky, getVisibilityClassname(props.visibility)) },
            React.createElement(FilterBar, { filters: props.showH2AnchorNavigation ? filterBarFilters : [], pathName: "/", disableActiveStyle: true, mode: FilterBarMode.navbar }),
            ctaButtonVisibility && (React.createElement(Button, { className: style.smallerKButton, type: LandingPageElementType.Button, id: `button-${props.ctaButtonLink}`, link: props.ctaButtonLink, isExternalLink: props.isCtaButtonLinkExternal, buttonType: "primary" /* ButtonVariant.Primary */, size: "small" /* ButtonSize.Small */, text: props.ctaButtonText }))))));
};
function HeroLinkContents(props) {
    if (props.link) {
        if (props.isExternalLink) {
            return (React.createElement("a", Object.assign({ className: getHeroContainerClassName(props), href: props.link }, props.dataAttributes), props.children));
        }
        else {
            return (React.createElement(LinkWithScroll, Object.assign({ className: getHeroContainerClassName(props), to: props.link }, props.dataAttributes), props.children));
        }
    }
    else {
        return (React.createElement("div", Object.assign({ className: getHeroContainerClassName(props) }, props.dataAttributes), props.children));
    }
}
function getHeroSizeClassName(props) {
    if (props.size === "narrow" /* HeroType.Narrow */) {
        return style.narrow;
    }
    else if (props.size === "narrower" /* HeroType.Narrower */) {
        return style.narrower;
    }
    else {
        return undefined;
    }
}
function getHeroContainerClassName(props) {
    return combineClassNames(style.heroContainer, getHeroSizeClassName(props), getVisibilityClassname(props.visibility));
}
