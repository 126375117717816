import React from 'react';
import { navigationItems } from '../staticData.js';
import { contactListPageUrls } from '../../common/urlPaths.js';
import { useAppSelector } from '../hooks.js';
import { useHistory } from 'react-router-dom';
import { FilterBar } from './FilterBar.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { Lead } from './Lead.js';
import { MarkdownPresenter } from './MarkdownPresenter.js';
import style from '../pages/ContactListPage/style.scss';
export const PurchasingContactInfo = (props) => {
    const language = useAppSelector((state) => state.i18n.language);
    const history = useHistory();
    const menuBarItems = navigationItems.secondary[language].find((item) => item.subItems && item.to.startsWith(contactListPageUrls[language]));
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", wrap: false },
        React.createElement("div", { className: style.mobileNavContainer, "data-pw": "headerSubNav" },
            React.createElement(FilterBar, { filters: (menuBarItems === null || menuBarItems === void 0 ? void 0 : menuBarItems.subItems) || [], pathName: history.location.pathname, className: style.hideOnLarge })),
        React.createElement("h1", null, props.contactsHeader),
        props.contactsLead && React.createElement(Lead, null, props.contactsLead),
        React.createElement(MarkdownPresenter, { key: "markdown", markdownContent: props.contactsBody })));
};
