import React from 'react';
import { AriaHeadingText } from '../components/AriaHeadingText.js';
import { Svg } from '../components/Svg.js';
import styles from './FaktatSection.module.scss';
export const FaktatSection = () => (React.createElement("section", { className: styles.container },
    React.createElement("h2", null,
        React.createElement(Svg, { name: 'title-facts' }),
        React.createElement(AriaHeadingText, null, "Faktat p\u00F6yt\u00E4\u00E4n")),
    React.createElement("div", { className: styles.content },
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-01' }),
                React.createElement(AriaHeadingText, null, "140000 ammattilaista")),
            React.createElement("p", null, "Ravintola- ja matkailuala ty\u00F6llist\u00E4\u00E4 yli 140 000 ihmist\u00E4, tehden siit\u00E4 yhden Suomen suurimmista ty\u00F6llist\u00E4jist\u00E4.")),
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-02' }),
                React.createElement(AriaHeadingText, null, "Miljardi ulkomailta")),
            React.createElement("p", null, "Ulkomaiset matkailijat k\u00E4yttiv\u00E4t suomalaisiin ravintoloihin miljardi euroa vuodessa vuonna 2019, tuoden merkitt\u00E4v\u00E4sti kansainv\u00E4lisi\u00E4 euroja Suomen talouteen.")),
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-03' }),
                React.createElement(AriaHeadingText, null, "Elinvoimainen Suomi")),
            React.createElement("p", null, "Matkailu on toiseksi suurin palveluviennin ala Suomessa, ja ruokamatkailu voi tuoda alueille valtavasti taloudellista hy\u00F6ty\u00E4, vahvistaen samalla elinvoimaisuutta, paikallisidentiteetti\u00E4 ja maabr\u00E4ndi\u00E4.")),
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-04' }),
                React.createElement(AriaHeadingText, null, "Kohti kest\u00E4vint\u00E4")),
            React.createElement("p", null, "Suomen tavoitteena on olla Pohjoismaiden kest\u00E4vimmin kasvava matkailukohde, ja me ravintoloiden puolella teemme jatkuvaa ty\u00F6t\u00E4 t\u00E4m\u00E4n tavoitteen eteen.")),
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-05' }),
                React.createElement(AriaHeadingText, null, "6,7 miljardia euroa")),
            React.createElement("p", null, "Ravintola-ala tuo Suomen talouteen 6,7 miljardia euroa vuodessa (2023e), vastaten 2,5 % BKT:sta, ja on talouden merkitt\u00E4v\u00E4 kivijalka.")),
        React.createElement("div", null,
            React.createElement("h3", null,
                React.createElement(Svg, { name: 'fact-06' }),
                React.createElement(AriaHeadingText, null, "Onnellisuuden resepti")),
            React.createElement("p", null, "Tutkitusti t\u00E4rkein syy suomalaisille sy\u00F6d\u00E4 ulkona on arjen pirist\u00E4minen \u2013 ravintolat tuovat valtavasti iloa, kohtaamisia ja hyvinvointia yhteiskuntaan."))),
    React.createElement("p", { className: styles.source },
        React.createElement("small", null, "L\u00E4hteet: Kesko, MaRa, Visit Finland"))));
