export const mapAnalyticsPropsToDataAttributes = (analyticsOptions, overrideOptions) => {
    if (!analyticsOptions) {
        return undefined;
    }
    const mergedOptions = Object.assign(Object.assign({}, analyticsOptions), overrideOptions);
    const { indexNumber, promotionParentType, promotionType, promotionName, promotionLink, promotionLocation, } = mergedOptions;
    return {
        'data-index-number': indexNumber,
        'data-promotion-parent-type': promotionParentType,
        'data-promotion-type': promotionType,
        'data-promotion-name': promotionName,
        'data-promotion-link': promotionLink,
        'data-promotion-location': promotionLocation,
    };
};
export const mergeAnalyticsOptions = (analyticsOptions, overrideOptions) => {
    if (!analyticsOptions) {
        return undefined;
    }
    return (Object.assign(Object.assign({}, analyticsOptions), overrideOptions));
};
export const reMapAnalyticsOptions = (element, index, analyticsOptions) => analyticsOptions
    ? Object.assign(Object.assign({}, element), { analyticsOptions: Object.assign(Object.assign({}, analyticsOptions), { indexNumber: index, promotionType: element.type }) }) : element;
