import React from 'react';
import { useGetMaintenanceInfoQuery } from '../../services/api.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { Lead } from '../../components/Lead.js';
import { MarkdownPresenter } from '../../components/MarkdownPresenter.js';
import { renderRtkQueryResult } from '../../util/asyncData.js';
const MaintenanceInfoPageBody = ({ info }) => {
    const maintenanceInfos = info.map((item, index) => React.createElement(VerticalContainer, { key: index, horizontalAlign: "center", wrap: false },
        React.createElement("h2", null, item.title),
        React.createElement(Lead, null, item.lead),
        React.createElement(MarkdownPresenter, { key: "markdown", markdownContent: item.body })));
    return (info.length ?
        React.createElement(React.Fragment, null, maintenanceInfos)
        :
            React.createElement(React.Fragment, null));
};
export const MaintenanceInfoPage = () => {
    const maintanceInfoQueryResult = useGetMaintenanceInfoQuery();
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { title: "Kespronet-h\u00E4iri\u00F6tiedotteet", canonicalPath: "/kespronet-hairiotiedotteet" },
            React.createElement("meta", { name: "robots", content: "noindex,nofollow" })),
        renderRtkQueryResult(MaintenanceInfoPageBody, {}, maintanceInfoQueryResult, (props, info) => (Object.assign(Object.assign({}, props), { info })), { fetch404PageFromContentful: true })));
};
