import React from 'react';
import { useAppSelector } from '../hooks.js';
import style from './EmbeddedContentConsentRequired.scss';
import { TextWithIcon } from './TextWithIcon.js';
const h2Title = 'Sisältö edellyttää evästeiden hyväksymisen';
const pPartOne = 'Jos haluat nähdä tämän sisällön, ';
const pLinkText = 'siirry evästeasetuksiin';
const pLinkHref = '#kconsent=kespro.com';
const pPartTwo = ' hyväksymään evästeet.';
export const EmbeddedContentConsentRequiredHtml = `
    <section class="${style.container}">
        <div class="${style.content}">
            <h2>${h2Title}</h2>
            <p>${pPartOne}<a href="${pLinkHref}">${pLinkText}</a>${pPartTwo}</p>
        </div>
    </section>
`;
export const EmbeddedContentConsentRequired = ({ children, alternativeLink, enabled = true }) => {
    const hasConsent = useAppSelector((state) => state.analytics.hasConsentForEmbeddedContent);
    if (hasConsent || !enabled) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement("section", { className: style.container },
        React.createElement("div", { className: style.content },
            React.createElement("h2", null, h2Title),
            React.createElement("p", null,
                pPartOne,
                React.createElement("a", { href: pLinkHref }, pLinkText),
                pPartTwo),
            alternativeLink && (React.createElement(TextWithIcon, { className: style.alternativeLink, href: alternativeLink, icon: "chevron-right", reversed: true }, "Avaa sis\u00E4lt\u00F6 ulkoisessa palvelussa")))));
};
