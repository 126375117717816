import React from 'react';
import { baseUrl } from '../../../config.js';
import { defaultLocalizedDescriptions } from '../../../meta.js';
import { useGetPurchasingContactInfoQuery } from '../../../services/api.js';
import { contactForMediaPageUrls, contactForSuppliersPageUrls } from '../../../../common/urlPaths.js';
import { KesproComHelmet } from '../../../components/KesproComHelmet.js';
import { PurchasingContactInfo } from '../../../components/PurchasingContactInfo.js';
import { renderRtkQueryResult } from '../../../util/asyncData.js';
import { parseCacheQueryParams } from '../../../../common/queryStrings.js';
const descriptionFromPath = (path) => {
    const language = path.substring(0, 2);
    switch (language) {
        case 'en':
            return defaultLocalizedDescriptions.contact.en;
        case 'sv':
            return defaultLocalizedDescriptions.contact.sv;
        default:
            return undefined;
    }
};
const PurchasingContactInfoPage = ({ canonicalPath, content, description, slug }) => {
    const urlCollection = slug === 'suppliers' ? contactForSuppliersPageUrls : contactForMediaPageUrls;
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { title: content.title, canonicalPath: canonicalPath, description: description || descriptionFromPath(canonicalPath) },
            React.createElement("link", { rel: "alternate", hrefLang: "x-default", href: `${baseUrl}${urlCollection.fi}` }),
            React.createElement("link", { rel: "alternate", hrefLang: "fi", href: `${baseUrl}${urlCollection.fi}` }),
            React.createElement("link", { rel: "alternate", hrefLang: "en", href: `${baseUrl}${urlCollection.en}` }),
            React.createElement("link", { rel: "alternate", hrefLang: "sv", href: `${baseUrl}${urlCollection.sv}` })),
        React.createElement(PurchasingContactInfo, { contactsHeader: content.title, contactsLead: content.lead, contactsBody: content.body })));
};
export const PurchasingContactInfoPageWrapper = ({ canonicalPath, description, slug, locale, location, }) => {
    const purchasingContactInfoQueryResult = useGetPurchasingContactInfoQuery(Object.assign({ locale }, parseCacheQueryParams(location)));
    return renderRtkQueryResult(PurchasingContactInfoPage, { canonicalPath, description, slug }, purchasingContactInfoQueryResult, (props, data) => (Object.assign(Object.assign({}, props), { slug, content: data[slug] })), { fetch404PageFromContentful: true });
};
export { PurchasingContactInfoPageWrapper as PurchasingContactInfoPage };
