import { useEffect } from 'react';
import { useGetContentPagesQuery } from '../services/api.js';
import { setContentPagesSearchResult } from '../reducers/combinedSearchContentPageSlice.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { shallowEqual } from 'react-redux';
export const useContentPagesQuery = () => {
    const dispatch = useAppDispatch();
    const { contentPageSearchResult, query } = useAppSelector((state) => ({
        contentPageSearchResult: state.combinedSearchContentPage.contentPageSearchResult,
        query: state.combinedSearchContentPage.query,
    }), shallowEqual);
    const { data, isFetching, isError } = useGetContentPagesQuery(query, { skip: query.offset === 0 });
    useEffect(() => {
        if (data) {
            dispatch(setContentPagesSearchResult(data));
        }
    }, [data]);
    return {
        data: contentPageSearchResult || [],
        isError,
        isFetching,
    };
};
