import React from 'react';
import * as R from 'ramda';
import { useGetCategoryHierarchyQuery, useGetRecipeSearchSeoStateQuery } from '../../services/api.js';
import { useAppSelector } from '../../hooks.js';
import { useCombinedSearchQuery } from '../../hooks/useCombinedSearchQuery.js';
import { COMBINED_SEARCH_HERO_IMAGE } from '../../../common/constants.js';
import { useArticlesByTagQuery } from '../../hooks/useArticlesByTagQuery.js';
import { COMBINED_SEARCH_ARTICLES_PAGE_PATH, COMBINED_SEARCH_CONTACTS_PAGE_PATH, COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH, COMBINED_SEARCH_PRODUCTS_PAGE_PATH, COMBINED_SEARCH_RECIPES_PAGE_PATH, } from '../../../common/urlPaths.js';
import { CombinedSearch } from '../../components/CombinedSearch.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { getSelectedFilters } from '../../util/recipe.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { shallowEqual } from 'react-redux';
import styles from './style.scss';
const canonicalPaths = {
    articles: COMBINED_SEARCH_ARTICLES_PAGE_PATH,
    contacts: COMBINED_SEARCH_CONTACTS_PAGE_PATH,
    contentPages: COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH,
    products: COMBINED_SEARCH_PRODUCTS_PAGE_PATH,
    recipes: COMBINED_SEARCH_RECIPES_PAGE_PATH,
};
const SearchPage = ({ articlesByTagCount, articleType, resultFilter, recipeSeo }) => {
    const recipeSearchState = useAppSelector((state) => {
        var _a;
        return ({
            activeFilters: state.combinedSearchRecipe.activeFilters,
            title: (_a = state.combinedSearchRecipe.tag) === null || _a === void 0 ? void 0 : _a.title,
        });
    }, shallowEqual);
    const { title, description } = getPageTitleAndDescription(resultFilter, recipeSearchState.activeFilters, recipeSearchState.title, recipeSeo);
    const canonicalPath = articleType
        ? `${canonicalPaths[resultFilter]}/${articleType}`
        : canonicalPaths[resultFilter];
    return (React.createElement("div", null,
        React.createElement(KesproComHelmet, { canonicalPath: canonicalPath, title: title, description: description }),
        React.createElement(VerticalContainer, { horizontalAlign: "stretch", wrap: false },
            React.createElement(CombinedSearch, { heroImageUrl: COMBINED_SEARCH_HERO_IMAGE, resultFilter: resultFilter, articleType: articleType, articlesByTagCount: articlesByTagCount }))));
};
const SearchPageWrapper = ({ articleType, resultFilter, location }) => {
    const articlesByTagQueryResult = useArticlesByTagQuery(articleType || '');
    const slug = useAppSelector((state) => state.combinedSearchRecipe.slug);
    const seoQueryResult = useGetRecipeSearchSeoStateQuery(Object.assign(Object.assign({}, parseCacheQueryParams(location)), { slug: slug }), { skip: !slug });
    const categoryHierarchyQueryResult = useGetCategoryHierarchyQuery();
    const combinedSearchQueryResult = useCombinedSearchQuery();
    if (categoryHierarchyQueryResult.isLoading
        || combinedSearchQueryResult.isLoading
        || articlesByTagQueryResult.isLoading) {
        return React.createElement(LoadingIndicator, { className: styles.loading });
    }
    if (categoryHierarchyQueryResult.isError
        || combinedSearchQueryResult.isError
        || articlesByTagQueryResult.isError) {
        return React.createElement(NotFoundComponent, null);
    }
    return (React.createElement(SearchPage, { articlesByTagCount: articlesByTagQueryResult.total, articleType: articleType, resultFilter: resultFilter, recipeSeo: seoQueryResult.data }));
};
export { SearchPageWrapper as SearchPage };
function getPageTitleAndDescription(resultFilter, selectedRecipeFilters, tag, recipeSeo) {
    if (resultFilter === 'recipes') {
        const { categories, mainIngredients } = getSelectedFilters(tag, selectedRecipeFilters);
        const possibleFilterStrings = [R.head(categories), R.head(mainIngredients)].filter((fs) => fs).join(' & ');
        const recipeSeoMeta = recipeSeo === null || recipeSeo === void 0 ? void 0 : recipeSeo.metaDescription;
        if (possibleFilterStrings) {
            return {
                title: `${possibleFilterStrings} -reseptit`,
                description: recipeSeoMeta
                    ? recipeSeoMeta
                    : `Ammattikeittiön ${possibleFilterStrings.toLowerCase()} -reseptit`,
            };
        }
        return {
            title: 'Reseptit',
            description: recipeSeoMeta
                ? recipeSeoMeta
                : `Ammattikeittiön reseptit`,
        };
    }
    return {
        title: 'Haku',
        description: undefined,
    };
}
