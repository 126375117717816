import React from 'react';
import { Icon } from '../Icon.js';
import { VerticalContainer, HorizontalContainer } from './FlexboxContainer.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './Dropdown.scss';
export const Dropdown = (props) => {
    const { className, isOpen, setIsOpen, label } = props;
    return (React.createElement("div", { className: combineClassNames(styles.dropdownAnchor, className, isOpen && styles.open) },
        React.createElement(VerticalContainer, { className: combineClassNames(styles.dropdown, isOpen && styles.open) },
            React.createElement(HorizontalContainer, { className: combineClassNames(styles.dropdownHead, isOpen && styles.open), verticalAlign: "center", onClick: () => {
                    setIsOpen(!isOpen);
                } },
                React.createElement("div", { className: styles.title }, label),
                React.createElement("div", { className: styles.chevron },
                    React.createElement(Icon, { type: isOpen ? 'chevron-up' : 'chevron-down' }))),
            isOpen ? renderBody(props) : undefined)));
};
function renderBody({ children }) {
    return (React.createElement("div", { className: styles.dropdownBody }, children));
}
