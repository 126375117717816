import React, { useEffect } from 'react';
import { getMainCategory } from '@kesko/kespro-ui-common';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { getCategoryIdPrefixForHero, incrementOffset, setProductSearchResult } from '../../reducers/productsSlice.js';
import { defaultDescriptions } from '../../meta.js';
import { Link } from 'react-router-dom';
import { useGetCategoryHeroQuery, useGetCategoryHierarchyQuery, useGetFrontPageProductsQuery, useGetProductsQuery, } from '../../services/api.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { getProductListProduct } from '../../util/products.js';
import { CategoryFilter } from '../../components/CategoryFilter.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { Heading } from '../../components/Heading.js';
import { HeroHeader } from '../../components/HeroHeader.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { Lead } from '../../components/Lead.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { ProductList } from '../../components/ProductList.js';
import { ProductSearch } from '../../components/ProductSearch.js';
import { ProductSearchResult } from '../../components/ProductSearchResult.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import style from './style.scss';
const HeroHeaderWrapper = (props) => {
    const selectedCategory = useAppSelector((state) => state.products.selectedCategory);
    const mainCategory = selectedCategory ? getMainCategory(selectedCategory) : undefined;
    const categorySelected = !!mainCategory;
    const { data } = useGetCategoryHeroQuery(Object.assign({ categoryIdPrefix: getCategoryIdPrefixForHero(selectedCategory) }, parseCacheQueryParams(props.location)));
    if (!data) {
        return null;
    }
    const headerProps = {
        contactInfo: false,
        className: style.hideHeroInMobile,
        altMobileStyle: !!mainCategory,
        img: data.heroImage,
        imgHeight: data.height,
        imgWidth: data.width,
        containerClassName: style.heroContainer,
        children: [
            React.createElement(Heading, { key: "heroTitle", className: combineClassNames(style.headingMobileStyle, categorySelected && style.heroTitle) }, mainCategory ? (React.createElement(Link, { to: `/tuotteet?kategoria=${mainCategory.category.id}`, className: style.heroTitleLink }, mainCategory.category.name)) : (data.title)),
            React.createElement(Lead, { key: "heroLead", className: combineClassNames(style.lead, categorySelected && style.leadCategorySelected) }, data.lead || ''),
        ],
    };
    return React.createElement(HeroHeader, Object.assign({}, headerProps));
};
const ProductListWrapper = (props) => {
    if (props.frontPageProducts && props.hierarchy) {
        return React.createElement(ProductList, { products: props.frontPageProducts.map((p) => getProductListProduct(p, props.hierarchy)), className: style.productList });
    }
    if (props.frontPageProductsError || props.hierarchyError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, null);
};
const ProductSearchResultWrapper = (props) => {
    var _a;
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (props.data) {
            dispatch(setProductSearchResult(props.data));
        }
    }, [props.data]);
    if (props.hierarchy) {
        const searchResultRaw = props.productSearchResult || props.data;
        const products = searchResultRaw
            ? searchResultRaw.results.map((p) => getProductListProduct(p, props.hierarchy))
            : [];
        return (React.createElement("div", { className: style.resultContainer },
            React.createElement(ResultCount, { count: ((_a = props.data) === null || _a === void 0 ? void 0 : _a.totalHits) || 0 }),
            React.createElement(ProductSearchResult, { className: style.productList, products: products, hasMore: searchResultRaw && products.length < searchResultRaw.totalHits, isLoading: !props.data && props.isFetching, isError: props.isError, onLoadMore: () => {
                    dispatch(incrementOffset());
                }, isLoadingMore: props.data && props.isFetching })));
    }
    if (!props.hierarchyData && props.hierarchyError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, null);
};
const ProductListToggler = (props) => {
    const dispatch = useAppDispatch();
    const isSearch = useAppSelector((state) => state.products.debouncedSearchPhrase !== '' || state.products.selectedCategory !== undefined);
    const { data: hierarchyData, isError: hierarchyError } = useGetCategoryHierarchyQuery();
    const { hierarchy, productSearchResult, query } = useAppSelector((state) => ({
        hierarchy: state.category.hierarchy,
        productSearchResult: state.products.productSearchResult,
        query: state.products.query,
    }), shallowEqual);
    const { data: frontPageProducts, isError: frontPageProductsError } = useGetFrontPageProductsQuery(parseCacheQueryParams(props.location));
    const { data, isFetching, isError } = useGetProductsQuery(query);
    useEffect(() => {
        if (data) {
            dispatch(setProductSearchResult(data));
        }
    }, [data]);
    if (isSearch) {
        return React.createElement(ProductSearchResultWrapper, { hierarchy: hierarchy, hierarchyData: hierarchyData, hierarchyError: hierarchyError, productSearchResult: productSearchResult, data: data, isFetching: isFetching, isError: isError });
    }
    else {
        return React.createElement(ProductListWrapper, { frontPageProductsError: frontPageProductsError, hierarchy: hierarchy, hierarchyError: hierarchyError, frontPageProducts: frontPageProducts, isError: frontPageProductsError });
    }
};
const pageTitle = 'Tuotteet';
const ProductTitle = () => {
    const productTitle = useAppSelector((state) => state.products.debouncedSearchPhrase
        ? state.products.debouncedSearchPhrase
        : state.products.selectedCategory
            ? ''
            : 'Suosittua juuri nyt');
    if (!productTitle) {
        return null;
    }
    return React.createElement("h2", { className: style.productTitle }, productTitle);
};
const ProductsPageBody = ({ location }) => (React.createElement(VerticalContainer, { horizontalAlign: "stretch", className: style.page },
    React.createElement(HeroHeaderWrapper, { location: location }),
    React.createElement(ProductSearch, { className: style.searchContainer, inactiveCategoryClassName: style.searchContainerFrontPage, location: location }),
    React.createElement(ProductTitle, null),
    React.createElement("div", { className: style.contentContainer },
        React.createElement(CategoryFilter, { id: "sideFilter", location: location }),
        React.createElement(ProductListToggler, { location: location }))));
const ResultCount = ({ count }) => {
    if (count < 1) {
        return null;
    }
    if (count === 1) {
        return React.createElement("h3", { className: style.resultCount }, "1 tuote");
    }
    return React.createElement("h3", { className: style.resultCount },
        count.toLocaleString('fi-FI'),
        " tuotetta");
};
export const ProductsPage = (props) => (React.createElement("div", null,
    React.createElement(KesproComHelmet, { title: pageTitle, canonicalPath: "/tuotteet", description: defaultDescriptions.products }),
    React.createElement(ProductsPageBody, { location: props.location })));
