import React from 'react';
import ReactModal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../Icon.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { HorizontalContainer } from '../FlexboxContainer.js';
import { setIsLoggedOut } from '../../reducers/authSlice.js';
import styles from './LoginModal.scss';
import { SSO_POSTLOGIN_URL } from '../../../common/queryStrings.js';
export const LoginModal = (props) => {
    const { loginLink } = props;
    const dispatch = useAppDispatch();
    const showLoginModal = useAppSelector((state) => state.auth.showLoginModal);
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        setOpen(showLoginModal);
    }, [showLoginModal]);
    const closeModal = () => {
        document.body.style.overflow = 'visible';
        dispatch(setIsLoggedOut({ showLoginModal: false, justLoggedOut: false }));
        fetch('/kirjaudu/sulje', { method: 'POST' })
            .catch(() => window.location.href = '/');
    };
    // Always redirect user to current page when logging in from modal
    const logIn = () => {
        const linkWithRedirect = `${loginLink.to}&${SSO_POSTLOGIN_URL}=${location.pathname}`;
        window.location.href = linkWithRedirect;
    };
    return (React.createElement(React.Fragment, null, open ?
        React.createElement(ReactModal, { isOpen: showLoginModal, onRequestClose: closeModal, onAfterOpen: () => document.body.style.overflow = 'hidden', appElement: document.getElementById('app') || undefined, className: styles.modal, style: { overlay: { zIndex: 999 } } },
            React.createElement("div", { className: styles.row },
                React.createElement(HorizontalContainer, null,
                    React.createElement(Icon, { type: "notice", size: 35 }),
                    React.createElement("p", null, "Kirjautumisesi on vanhentunut. Ole hyv\u00E4 ja kirjaudu uudelleen."))),
            React.createElement("div", { className: styles.row },
                React.createElement(HorizontalContainer, null,
                    React.createElement("div", { onClick: closeModal, className: styles.button }, "Sulje"),
                    React.createElement("div", { onClick: logIn, className: styles.button }, "Kirjaudu"))))
        : undefined));
};
