import React from 'react';
import { getStaticContentfulImgSrcSet } from '../util/image.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './ProductBrandCard.scss';
const WIDTH = 600;
const HEIGHT = 276;
const LOGO_WIDTH = 70;
const LOGO_HEIGHT = 55;
export const ProductBrandCard = ({ brand }) => {
    const { srcSet, fallback } = getStaticContentfulImgSrcSet(brand.hero, WIDTH, HEIGHT);
    const logoSrcSetRes = brand.logo ? getStaticContentfulImgSrcSet(brand.logo, LOGO_WIDTH, LOGO_HEIGHT) : undefined;
    const logoSrcSet = logoSrcSetRes === null || logoSrcSetRes === void 0 ? void 0 : logoSrcSetRes.srcSet;
    const logoFallback = logoSrcSetRes === null || logoSrcSetRes === void 0 ? void 0 : logoSrcSetRes.fallback;
    return (React.createElement("div", { className: style.productBrandCardContainer },
        React.createElement(LinkWithScroll, { to: `/tuotteet/tuotemerkit/${encodeURIComponent(brand.slug)}` },
            React.createElement("picture", null,
                React.createElement("source", { srcSet: srcSet, type: "image/webp" }),
                React.createElement("img", { loading: "lazy", alt: brand.title, className: style.productBrandCard__heroImage, src: fallback, width: WIDTH, height: HEIGHT })),
            React.createElement("div", { className: style.productBrandCard__info },
                logoSrcSet && logoFallback && (React.createElement("picture", { className: style.productBrandCard__info__logoContainer },
                    React.createElement("source", { srcSet: logoSrcSet, type: "image/webp" }),
                    React.createElement("img", { loading: "lazy", alt: "", src: logoFallback, width: LOGO_WIDTH, height: LOGO_HEIGHT }))),
                React.createElement("h2", { className: style.productBrandCard__info__title }, brand.title)))));
};
