import React from 'react';
import styles from './MaintenanceInfoBox.scss';
import { useGetMaintenanceInfoQuery } from '../services/api.js';
import { MarkdownPresenter } from './MarkdownPresenter.js';
import { renderRtkQueryResult } from '../util/asyncData.js';
const InfoBox = ({ info }) => {
    const maintenanceInfos = info.map((item, index) => React.createElement("div", { key: index, className: styles.maintenanceInfo },
        React.createElement("h2", null, item.title),
        React.createElement(MarkdownPresenter, { key: "markdown", className: styles.markdownPresenterText, markdownContent: item.body })));
    return (info.length ?
        React.createElement("div", { className: styles.maintenanceInfoContainer }, maintenanceInfos)
        :
            React.createElement(React.Fragment, null));
};
export const MaintenanceInfoBox = () => {
    const maintanceInfoQueryResult = useGetMaintenanceInfoQuery();
    return (React.createElement(React.Fragment, null, renderRtkQueryResult(InfoBox, {}, maintanceInfoQueryResult, (props, info) => (Object.assign(Object.assign({}, props), { info })))));
};
