import * as R from 'ramda';
import React from 'react';
import { Icon } from '../Icon.js';
import { VerticalContainer } from './FlexboxContainer.js';
import { combineClassNames } from '../util/css/classNames.js';
import { breakPoint } from '../util/image.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './ProductCard.scss';
export const ProductCard = ({ card, cardCount, twoColumnsTabletView, onProductClicked }) => {
    const cardCountClass = R.cond([
        [R.equals(3), () => style.threeCards],
        [R.equals(2), () => style.twoCards],
        [R.equals(1), () => style.oneCard],
        [R.T, () => undefined],
    ])(cardCount);
    const tabletCardClass = twoColumnsTabletView && style.twoCardsTablet;
    return (
    // Had to wrap Link in a div because flex didn't work on IE10
    React.createElement("div", { className: combineClassNames(style.cardWrapper, cardCountClass, tabletCardClass), "data-pw": "productCard", onClick: onProductClicked },
        React.createElement(LinkWithScroll, { to: `/tuotteet/${card.slug}` },
            React.createElement(VerticalContainer, { horizontalAlign: "stretch", className: style.productCard },
                renderImagePart(card),
                renderFrozenIcon(card),
                React.createElement(VerticalContainer, { horizontalAlign: "center", verticalAlign: "center", className: style.iconContainer },
                    React.createElement(Icon, { type: card.categoryIconType, size: 20 })),
                React.createElement("div", { className: style.cardBody },
                    React.createElement("h4", { className: style.cardType }, card.category),
                    React.createElement("h3", { className: style.cardTitle }, card.title))))));
};
const HEIGHT = 180;
const BASE_QUERY = `&h=${HEIGHT}&auto=format&bg=f4f4f4&fit=fill`;
const DESKTOP_WIDTH = 400;
const QUERY_DESKTOP = `w=${DESKTOP_WIDTH}${BASE_QUERY}`;
const TABLET_WIDTH = 315;
const QUERY_TABLET = `w=${TABLET_WIDTH}${BASE_QUERY}`;
const MOBILE_WIDTH = 220;
const QUERY_MOBILE = `w=${MOBILE_WIDTH}${BASE_QUERY}`;
// SrcSets are separately handled here because this doesn't use contentful API and every srcSet has different aspect ratio
function renderImagePart(card) {
    if (card.heroImageUrl && card.heroImageUrl.length > 0) {
        return (React.createElement("picture", { className: style.pictureContainer },
            React.createElement("source", { media: `(max-width: ${breakPoint.MOBILE}px)`, srcSet: `${card.heroImageUrl}?${QUERY_MOBILE}&fm=webp`, width: MOBILE_WIDTH, height: HEIGHT, type: "image/webp" }),
            React.createElement("source", { media: `(max-width: ${breakPoint.TABLET}px)`, srcSet: `${card.heroImageUrl}?${QUERY_TABLET}&fm=webp`, width: TABLET_WIDTH, height: HEIGHT, type: "image/webp" }),
            React.createElement("source", { media: `(min-width: ${breakPoint.TABLET + 1}px)`, srcSet: `${card.heroImageUrl}?${QUERY_DESKTOP}&fm=webp`, width: DESKTOP_WIDTH, height: HEIGHT, type: "image/webp" }),
            React.createElement("img", { alt: "", loading: "lazy", className: style.cardHeader, src: `${card.heroImageUrl}?${QUERY_TABLET}&fm=jpg`, width: TABLET_WIDTH, height: HEIGHT })));
    }
    else {
        return (React.createElement("div", { className: combineClassNames(style.iconCardHeader, style.noPic) },
            React.createElement(Icon, { type: card.categoryIconType, size: 100 })));
    }
}
function renderFrozenIcon(card) {
    return (card.isFrozen
        ? React.createElement("div", { className: style.productClassIcon },
            React.createElement(Icon, { type: 'pakasteet', size: 15 }))
        : []);
}
