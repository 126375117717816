import React from 'react';
import { useLocation } from 'react-router-dom';
import { getStaticContentfulImgSrcSet } from '../util/image.js';
import { LinkWithScroll } from '../util/scroll.js';
import style from './RecipeSearchResultCard.scss';
export const RecipeSearchResultCard = ({ card }) => {
    const content = renderCard(card);
    const location = useLocation();
    return (React.createElement(LinkWithScroll, { className: style.link, to: { pathname: `/ideat-ja-inspiraatiot/reseptit/${card.slug}`, state: { from: location.pathname } } }, content));
};
function renderCard(card) {
    const { srcSet, fallback } = getStaticContentfulImgSrcSet(card.heroImageUrl, 325);
    return (React.createElement("div", { className: `${style.card}` },
        React.createElement("picture", { className: style.cardHeaderContainer },
            React.createElement("source", { srcSet: srcSet, type: "image/webp" }),
            React.createElement("img", { alt: "", loading: "lazy", className: style.cardHeader, src: fallback, width: card.heroImageWidth, height: card.heroImageHeight })),
        React.createElement("div", { className: style.cardBody },
            React.createElement("h3", { className: style.cardTitle }, card.title),
            React.createElement("p", { className: style.cardLead }, card.lead))));
}
