import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './SwiperCarousel.scss';
export const SwiperCarousel = (props) => {
    const { content, swiperOpts } = props;
    if (!(content === null || content === void 0 ? void 0 : content.length)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Swiper, Object.assign({}, swiperOpts), content.map((element) => (React.createElement(SwiperSlide, { key: element.key, className: style.slide }, element)))));
};
