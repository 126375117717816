import * as R from 'ramda';
import { atMostOneCharDiffAtTheEnd } from './strings.js';
const toPairs = R.toPairs;
const filterUndef = R.filter(([, value]) => value !== undefined);
export const objectToUrlParams = R.pipe(toPairs, filterUndef, R.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`), (pairs) => pairs.join('&'));
export function getUpdateMethod(newQuery, prevQuery) {
    return atMostOneCharDiffAtTheEnd(newQuery, prevQuery) ? 'replace' : 'push';
}
/**
 * Returns  url including search parameter and hash. If you only need path, use location.pathname.
 */
export function getRelativeUrlFromLocation(location) {
    return `${location.pathname}${location.search}${location.hash}`;
}
export function getLanguageFromUrl(path) {
    if (path.startsWith('/en/')) {
        return 'en';
    }
    if (path.startsWith('/sv/')) {
        return 'sv';
    }
    if (path.startsWith('/ru/')) {
        return 'ru';
    }
    return 'fi';
}
