import React from 'react';
import style from './RecipeSearchCategoryFilterString.scss';
const createH1 = (title) => React.createElement("h1", { className: style.recipeSearchCategoryFilterString }, title);
const createH2 = (title) => React.createElement("h2", { className: style.recipeSearchCategoryFilterString }, title);
export const RecipeSearchCategoryFilterString = ({ searchWords, selectedFilters, useH2Tag }) => {
    const categoryFilterString = selectedFilters && selectedFilters.length
        ? formatCategoryFilterString(selectedFilters)
        : `"kaikki"`;
    const singularOrPluralText = searchWords ? `Reseptit hakusanoilla "${searchWords}"` : 'Reseptit ';
    const title = `${singularOrPluralText}${getCategoryPrefix(selectedFilters.length)} ${categoryFilterString}`;
    return useH2Tag ? createH2(title) : createH1(title);
};
function formatCategoryFilterString(selectedFilters) {
    return selectedFilters
        .map((selectedFilter) => selectedFilter.secondaryFilter ? selectedFilter.secondaryFilter : selectedFilter.primaryFilter)
        .map((filterString, index) => `"${filterString}"${getFilterSuffix(index, selectedFilters.length)}`)
        .join('');
}
function getCategoryPrefix(filterAmount) {
    return filterAmount === undefined || filterAmount < 2 ? 'kategoriasta' : 'kategorioista';
}
function getFilterSuffix(index, filterAmount) {
    if (index + 2 < filterAmount) {
        return ', ';
    }
    else if (index + 1 < filterAmount) {
        return ' ja ';
    }
    else {
        return '';
    }
}
