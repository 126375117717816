var _a;
import * as rtk from '@reduxjs/toolkit';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    formData: {
        email: '',
        phone: '',
        forename: '',
        surname: '',
        companyName: '',
        businessId: '',
        locality: '',
        additionalInfo: '',
    },
    validationErrors: [],
};
export const registrationFormSlice = createSlice({
    name: 'registrationForm',
    initialState,
    reducers: {
        resetRegistrationForm: (_state) => initialState,
        changeRegistrationFormField: (state, action) => {
            const { field, value } = action.payload;
            state.formData[field] = value;
            state.validationErrors = state.validationErrors.filter((error) => error.id !== field);
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload;
        },
    },
});
export const { reducer } = registrationFormSlice;
export const { changeRegistrationFormField, resetRegistrationForm, setValidationErrors } = registrationFormSlice.actions;
