import React from 'react';
import { PreloadedImage } from './PreloadedImage.js';
import { combineClassNames } from '../util/css/classNames.js';
import { getAspectRatioImgSrcSet, getDefaultContentfulImgSrcSet } from '../util/image.js';
import styles from './HeroHeader.scss';
export const HeroHeader = (props) => {
    const { className, img, imgAspectRatio, imgHeight, imgWidth, children, headerBar, altMobileStyle, childrenContainerStyle, headerContainerClassname, containerClassName, fallbackImgHeight, fallbackImgUrl, fallbackImgWidth, } = props;
    const { srcSet, sizes } = imgAspectRatio
        ? getAspectRatioImgSrcSet(img, imgAspectRatio)
        : getDefaultContentfulImgSrcSet(img);
    return (React.createElement("header", { className: combineClassNames(styles.headerContainer, headerContainerClassname), "data-pw": "heroHeader" },
        React.createElement(PreloadedImage, { imageUrl: img, imageHeight: imgHeight, imageWidth: imgWidth, imageSrcset: srcSet, imageSizes: sizes, imageClassName: combineClassNames(className, styles.heroImage), containerClassName: combineClassNames(styles.heroImageContainer, containerClassName), fallbackImageUrl: fallbackImgUrl, fallbackImageHeight: fallbackImgHeight, fallbackImageWidth: fallbackImgWidth }),
        React.createElement("div", { className: combineClassNames(childrenContainerStyle || '', altMobileStyle ? styles.altHeader : styles.header) },
            children,
            getHeaderBar(headerBar))));
};
function getHeaderBar(headerBar) {
    if (headerBar) {
        return (React.createElement("div", { className: styles.headingBar }, headerBar));
    }
    else {
        return undefined;
    }
}
