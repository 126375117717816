import React from 'react';
import { useAppSelector } from '../hooks.js';
import { ContactCard } from './ContactCard.js';
import { combineClassNames } from '../util/css/classNames.js';
import style from './ContactList.scss';
const finnish = {
    linkText: 'Katso aukioloajat ja yhteystiedot',
};
const swedish = {
    linkText: 'Se öppettider och kontaktuppgifter',
};
const english = {
    linkText: 'See opening hours and information',
};
const i18nTexts = {
    fi: finnish,
    sv: swedish,
    en: english,
    ru: finnish,
};
export const ContactList = ({ contacts, header, maxItemsInRow = 4 }) => {
    const language = useAppSelector((state) => state.i18n.language);
    const linkText = i18nTexts[language].linkText;
    const items = contacts.map((c, i) => (React.createElement(ContactCard, { key: `contact-${i}`, contact: c, linkText: linkText, language: language })));
    const combinedClassName = combineClassNames(style.cardList, maxItemsInRow === 2 && style.maxItemsInRow2, maxItemsInRow === 4 && style.maxItemsInRow4);
    return (React.createElement(React.Fragment, null,
        items.length > 0 && header && React.createElement("h2", null, header),
        React.createElement("div", { className: combinedClassName, "data-pw": "contactList" }, items)));
};
