var _a;
import { __awaiter } from "tslib";
import * as rtk from '@reduxjs/toolkit';
import { COMBINED_SEARCH_ARTICLES_PAGE_PATH, COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH, COMBINED_SEARCH_PRODUCTS_PAGE_PATH, COMBINED_SEARCH_RECIPES_PAGE_PATH, } from '../../common/urlPaths.js';
import { getRecipeSearchStateFromLocation, setCombinedSearchRecipeState, setRecipeSearchResult, } from './combinedSearchRecipeSlice.js';
import { getCombinedSearchProductStateFromLocation, setCombinedSearchProductState, updateCombinedSearchProductUrl, } from './combinedSearchProductSlice.js';
import { updateCombinedSearchContactUrl } from './combinedSearchContactSlice.js';
import { COMBINED_SEARCH_ALL_SEARCHPHRASE } from '../../common/queryStrings.js';
import { setHandledUrl } from './urlSlice.js';
import QueryString from 'query-string';
import { updateCombinedSearcContentPageUrl } from './combinedSearchContentPageSlice.js';
import { getRelativeUrlFromLocation } from '../util/url.js';
import { updateCombinedSearchArticleUrl, updateCombinedSearchRecipeUrl } from './urlChangeHandler.js';
const { createSlice } = ((_a = rtk.default) !== null && _a !== void 0 ? _a : rtk);
const initialState = {
    searchPhrase: '',
    debouncedSearchPhrase: '',
    lastUsedSearchPhrase: '',
    totalHitCounts: {
        total: 0,
        products: 0,
        productCategories: [],
        recipes: 0,
        articles: 0,
        contacts: 0,
        contentPages: 0,
        other: 0,
    },
};
export const combinedSearchUrlChange = (location) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const initState = getState();
    const queryParams = QueryString.parse(location.search);
    let searchPhrase = (queryParams[COMBINED_SEARCH_ALL_SEARCHPHRASE] || '');
    if (location.pathname.startsWith(COMBINED_SEARCH_RECIPES_PAGE_PATH)) {
        const recipeSearchState = getRecipeSearchStateFromLocation(initState.combinedSearchRecipe.filters, location);
        searchPhrase = recipeSearchState.searchPhrase;
        dispatch(setCombinedSearchRecipeState(recipeSearchState));
    }
    else if (location.pathname === COMBINED_SEARCH_PRODUCTS_PAGE_PATH) {
        const productSearchState = getCombinedSearchProductStateFromLocation(location, initState.category.hierarchy);
        searchPhrase = productSearchState.searchPhrase;
        dispatch(setCombinedSearchProductState(productSearchState));
    }
    // There is no need to explicitly initialize the Article and Contact search states.
    dispatch(initializeSearchPhrase(searchPhrase));
    dispatch(setHandledUrl(getRelativeUrlFromLocation(location)));
});
/*
* Always call this thunk action instead of setDebouncedSearchPhrase directly because
* other slices depend on the fact that the searchPhrase is not set again if it doesn't change
*/
export const updateCombinedSearchDebouncedSearchPhrase = (searchPhrase, location, push, replace) => (dispatch, getState) => {
    const state = getState();
    if (state.combinedSearch.debouncedSearchPhrase !== searchPhrase) {
        dispatch(setDebouncedSearchPhrase(searchPhrase));
        if (location.pathname.startsWith(COMBINED_SEARCH_RECIPES_PAGE_PATH)) {
            dispatch(updateCombinedSearchRecipeUrl(location, push, replace));
        }
        else if (location.pathname === COMBINED_SEARCH_PRODUCTS_PAGE_PATH) {
            dispatch(updateCombinedSearchProductUrl(location, push, replace));
        }
        else if (location.pathname.startsWith(COMBINED_SEARCH_ARTICLES_PAGE_PATH)) {
            dispatch(updateCombinedSearchArticleUrl(location, push, replace));
        }
        else if (location.pathname.startsWith(COMBINED_SEARCH_CONTENTPAGE_PAGE_PATH)) {
            dispatch(updateCombinedSearcContentPageUrl(location, push, replace));
        }
        else {
            dispatch(updateCombinedSearchContactUrl(location, push, replace));
        }
    }
};
export const updateSearchResults = (result) => (dispatch, getState) => {
    const state = getState();
    const { searchPhrase } = state.combinedSearch;
    dispatch(setCombinedSearchState({
        searchPhrase,
        searchResult: result,
    }));
};
export const combinedSearchSlice = createSlice({
    name: 'combinedSearch',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setRecipeSearchResult, (state, action) => {
            if (state.totalHitCounts) {
                state.totalHitCounts.recipes = action.payload.totalHits;
            }
        });
    },
    reducers: {
        setSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        setDebouncedSearchPhrase: (state, action) => {
            state.debouncedSearchPhrase = action.payload;
        },
        initializeSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
            state.debouncedSearchPhrase = action.payload;
            state.lastUsedSearchPhrase = action.payload;
        },
        setCombinedSearchState: (state, action) => {
            const { searchResult } = action.payload;
            state.totalHitCounts = searchResult.totalHitCounts;
            state.lastUsedSearchPhrase = state.debouncedSearchPhrase;
        },
    },
});
export const { reducer } = combinedSearchSlice;
export const { initializeSearchPhrase, setCombinedSearchState, setSearchPhrase, setDebouncedSearchPhrase, } = combinedSearchSlice.actions;
